import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import testData from '../MainContent/riskMatrixTestData.json'; // Assuming path is correct

// CSS
import styles from './RiskMatrix.module.scss';

// Helper to combine CSS module class names (if you have it)
// import clsx from 'clsx';
// Otherwise, use the simple helper:
const clsx = (...args) => args.filter(Boolean).join(' ');

const categories = [
  // Keep your categories array as is
  { name: 'AI', subcategories: ['LLM', 'Image', 'Video', 'Voice'] },
  { name: 'Ethereum', subcategories: ['ZK', 'NFTs'] },
  { name: 'Quantum Computers', subcategories: ['Quantum Cryptography', 'Quantum Simulation'] },
  { name: 'Biotechnology', subcategories: ['Gene Editing', 'Synthetic Biology', 'Bioinformatics', 'Regenerative Medicine'] },
  { name: 'BCI', subcategories: ['Invasive BCI', 'Non-invasive BCI', 'Neurofeedback'] },
  { name: 'Robotics', subcategories: ['Autonomous Robots', 'Humanoid Robots'] },
  { name: 'Renewable Energy', subcategories: ['Solar Power', 'Wind Power'] },
  { name: 'Nuclear Weapons', subcategories: ['Nuclear Proliferation', 'Arms Control'] },
  { name: 'Government', subcategories: ['Domestic', 'International'] },
];

class TechGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedCellId: '', // Can be "CatX.SubX.CatY.SubY" or "CatX_vs_CatY"
      selectedCellData: null,
      existingComments: [],
      valence: 'opportunity',
      comment: '',
      intensity: 5,
      heatmap: this.calculateHeatmapValues(),
      activeCategoryX: null,
      activeCategoryY: null,
      activeSubcategoryX: null,
      activeSubcategoryY: null,
      hoveredRowIndex: null,
      hoveredColIndex: null,
      hoveredSubRowIndex: null,
      hoveredSubColIndex: null,
    };
  }

  // --- Data Processing ---

  calculateHeatmapValues = () => {
    const heatmap = {};
    if (!Array.isArray(testData)) {
      console.error("Error: Imported testData is not an array!", testData);
      return heatmap;
    }

    testData.forEach((commentData, index) => {
      if (!commentData || typeof commentData !== 'object') {
        // console.warn(`Skipping item at index ${index} in testData: Item is not a valid object.`, commentData);
        return;
      }
      if (typeof commentData.cell !== 'string' || !commentData.cell) {
        // console.warn(`Skipping item at index ${index} due to missing, null, or invalid 'cell' property:`, commentData);
        return;
      }

      const cellParts = commentData.cell.split('.');
      if (cellParts.length < 4) {
        // console.warn(`Skipping item at index ${index} due to unexpected 'cell' format "${commentData.cell}":`, commentData);
        return;
      }

      const [catX, /* subX */, catY /*, subY */] = cellParts;
      const key = `${catY}_${catX}`;

      const intensity = Number(commentData.intensity);
      const valence = commentData.valence;
      if (typeof valence !== 'string' || isNaN(intensity) || intensity === 0) { // Ignore invalid/zero intensity
           // console.warn(`Skipping item at index ${index} due to missing/invalid valence or intensity:`, commentData);
           return;
      }

      const value = (valence === 'risk' ? -1 : 1) * intensity;
      heatmap[key] = (heatmap[key] || 0) + value;
    });
    return heatmap;
  };

  getCellValue = (catY, catX) => {
    return this.state.heatmap[`${catY}_${catX}`] || 0;
  };

  // --- Updated to handle both cell ID formats ---
  getCommentsForCell = (cellId) => {
    if (typeof cellId !== 'string' || !cellId) return [];

    // Check if it's a top-level ID (e.g., "CategoryX_vs_CategoryY")
    if (cellId.includes('_vs_')) {
        const parts = cellId.split('_vs_');
        if (parts.length !== 2) return [];
        const [catX, catY] = parts;
        // Filter comments where the cell string starts with CatX and contains .CatY.
        // This captures all sub-interactions between CatX and CatY.
        // Note: This might be slow on very large datasets. Consider optimizing if needed.
        return testData.filter(commentData =>
            commentData && typeof commentData.cell === 'string' &&
            commentData.cell.startsWith(catX + '.') &&
            commentData.cell.includes('.' + catY + '.')
        );
    } else {
        // Assume it's a specific subcategory ID ("CatX.SubX.CatY.SubY")
        return testData.filter((commentData) =>
            commentData && commentData.cell === cellId
        );
    }
  };


  // --- Event Handlers ---

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      // Reset form state when modal opens or closes fully
      comment: !prevState.modal ? '' : prevState.comment,
      valence: !prevState.modal ? 'opportunity' : prevState.valence,
      intensity: !prevState.modal ? 5 : prevState.intensity,
      // Clear selection details if modal is closing
      selectedCellId: prevState.modal ? '' : prevState.selectedCellId,
      existingComments: prevState.modal ? [] : prevState.existingComments,
      selectedCellData: prevState.modal ? null : prevState.selectedCellData,
    }));
  };

  handleCategoryXClick = (categoryXName) => {
    this.setState(prevState => ({
      activeCategoryX: prevState.activeCategoryX === categoryXName ? null : categoryXName,
      activeSubcategoryX: null,
      // Also potentially clear the *other* category if you want single axis selection at a time
      // activeCategoryY: prevState.activeCategoryX === categoryXName ? prevState.activeCategoryY : null,
      // activeSubcategoryY: prevState.activeCategoryX === categoryXName ? prevState.activeSubcategoryY : null,
    }));
  };

  handleCategoryYClick = (categoryYName) => {
    this.setState(prevState => ({
      activeCategoryY: prevState.activeCategoryY === categoryYName ? null : categoryYName,
      activeSubcategoryY: null,
       // Also potentially clear the *other* category
      // activeCategoryX: prevState.activeCategoryY === categoryYName ? prevState.activeCategoryX : null,
      // activeSubcategoryX: prevState.activeCategoryY === categoryYName ? prevState.activeSubcategoryX : null,
    }));
  };

  handleSubcategoryXClick = (subcategoryXName) => {
    this.setState(prevState => ({
      activeSubcategoryX: prevState.activeSubcategoryX === subcategoryXName ? null : subcategoryXName,
    }));
  };

  handleSubcategoryYClick = (subcategoryYName) => {
     this.setState(prevState => ({
      activeSubcategoryY: prevState.activeSubcategoryY === subcategoryYName ? null : subcategoryYName,
    }));
  };

  // --- Updated to open modal for top-level cells ---
  handleCellClick = (catY, catX) => {
    // Clicking a main grid cell opens the modal for the CatX vs CatY interaction
    console.log(`Main grid cell clicked: Y=${catY}, X=${catX}`);
    const selectedCellId = `${catX}_vs_${catY}`; // Use specific format
    const existingComments = this.getCommentsForCell(selectedCellId);
    const cellValue = this.getCellValue(catY, catX); // Get aggregated value

    this.setState({
      // Set active categories if not already set
      activeCategoryY: catY,
      activeCategoryX: catX,
      // Clear subcategories when clicking main grid cell
      activeSubcategoryX: null,
      activeSubcategoryY: null,
      // Modal state
      selectedCellId,
      selectedCellData: { catY, catX, value: cellValue }, // Store top-level context
      existingComments,
      modal: true,
    });
  };


  handleSubCellClick = (rowIndex, colIndex, subY, subX) => {
    // Opens modal for specific subcategory intersection
    const { activeCategoryX, activeCategoryY } = this.state;
    if (!activeCategoryX || !activeCategoryY || !subX || !subY) return;

    const selectedCellId = `${activeCategoryX}.${subX}.${activeCategoryY}.${subY}`;
    const existingComments = this.getCommentsForCell(selectedCellId);
    // const cellValue = getSubCellValue(subY, subX); // Calculate if needed

    this.setState({
      selectedCellId,
      // selectedCellData: { rowIndex, colIndex, subY, subX, value: cellValue },
      existingComments,
      modal: true,
      activeSubcategoryX: subX, // Ensure these are set upon click
      activeSubcategoryY: subY,
    });
  };

  handleSaveComment = () => {
    const { selectedCellId, comment, valence, intensity } = this.state;
    if (!selectedCellId || !comment) {
        alert("Comment cannot be empty.");
        return;
    }

    // Determine the cell ID to save. If it's a top-level (_vs_), keep it.
    // If it's a sub-level, keep the full ID.
    const finalCellId = selectedCellId; // Use the ID as stored in state

    console.log('Saving comment for:', finalCellId);
    console.log('Comment:', comment);
    console.log('Valence:', valence);
    console.log('Intensity:', intensity);

    const newComment = {
      cell: finalCellId, // Save with the appropriate ID format
      comment,
      valence,
      intensity: Number(intensity),
    };

    // --- IMPORTANT: Update your data source here ---
    // Example: Pushing to local array (for demo only - doesn't persist)
     testData.push(newComment);
    // In a real app:
    // - Make an API call to save the comment
    // - Update state based on API response
    // - Potentially refetch data or update locally optimistically

    // Recalculate heatmap (if saving affects aggregation) and close modal
    this.setState(prevState => ({
        // Only recalculate heatmap if the saved comment was specific enough to affect it
        // (Might need more complex logic if _vs_ comments should influence heatmap)
        heatmap: this.calculateHeatmapValues(), // Recalculate for simplicity now
        // Find comments for the *currently selected cell* after adding the new one
        existingComments: this.getCommentsForCell(prevState.selectedCellId)
    }), () => {
        // Close modal AFTER state update, potentially clearing form
        this.toggleModal();
    });
  };


  handleValenceChange = (event) => this.setState({ valence: event.target.value });
  handleCommentChange = (event) => this.setState({ comment: event.target.value });
  handleIntensityChange = (event) => this.setState({ intensity: event.target.value });

  handleCellMouseEnter = (rowIndex, colIndex) => this.setState({ hoveredRowIndex: rowIndex, hoveredColIndex: colIndex });
  handleCellMouseLeave = () => this.setState({ hoveredRowIndex: null, hoveredColIndex: null });

  handleSubCellMouseEnter = (rowIndex, colIndex) => this.setState({ hoveredSubRowIndex: rowIndex, hoveredSubColIndex: colIndex });
  handleSubCellMouseLeave = () => this.setState({ hoveredSubRowIndex: null, hoveredSubColIndex: null });

  // --- Rendering Logic ---

  getColorByValue = (value) => {
    if (value === 0) return undefined; // Let the .emptyCell style handle it
    const maxIntensity = 10; // Or determine dynamically based on data range
    const intensity = Math.min(Math.abs(value) / maxIntensity, 1);
    const baseOpacity = 0.15; // Minimum visibility
    const finalOpacity = baseOpacity + intensity * (1 - baseOpacity);

    if (value > 0) {
      // Green range (adjust RGB values for desired shade)
      return `rgba(0, 200, 0, ${finalOpacity})`;
    } else {
      // Red range
      return `rgba(200, 0, 0, ${finalOpacity})`;
    }
  };

  renderMainGrid = () => {
    const { activeCategoryX, activeCategoryY, hoveredRowIndex, hoveredColIndex } = this.state;
    const numCategories = categories.length;
    const activeYIndex = categories.findIndex(cat => cat.name === activeCategoryY);
    const activeXIndex = categories.findIndex(cat => cat.name === activeCategoryX);

    return (
      <div
        className={styles.gridContainer}
        style={{
          // Adjust fixed width for Y header, use minmax for X headers
          gridTemplateColumns: `100px repeat(${numCategories}, minmax(90px, 1fr))`,
          gridTemplateRows: `auto repeat(${numCategories}, minmax(50px, auto))`, // Auto row height for headers
        }}
      >
        <div className={styles.cell} style={{ gridColumn: 1, gridRow: 1, backgroundColor: '#2a2a3a' }} />

        {categories.map((catX, index) => (
          <div
            key={`header-x-${catX.name}`}
            className={clsx(
              styles.cell, styles.headerCell,
              activeCategoryX === catX.name && styles.activeHeaderCell
            )}
            style={{ gridColumn: index + 2, gridRow: 1 }}
            onClick={() => this.handleCategoryXClick(catX.name)}
          >
            {catX.name}
          </div>
        ))}

        {categories.map((catY, rowIndex) => (
          <React.Fragment key={`row-${catY.name}`}>
            <div
              key={`header-y-${catY.name}`}
              className={clsx(
                styles.cell, styles.headerCell,
                activeCategoryY === catY.name && styles.activeHeaderCell
              )}
              style={{ gridColumn: 1, gridRow: rowIndex + 2 }}
              onClick={() => this.handleCategoryYClick(catY.name)}
            >
              {catY.name}
            </div>

            {categories.map((catX, colIndex) => {
              const isDiagonal = rowIndex === colIndex;
              const cellValue = isDiagonal ? 0 : this.getCellValue(catY.name, catX.name);
              const isHovered = rowIndex === hoveredRowIndex && colIndex === hoveredColIndex;
              // Highlight if either the row or col category is active
              const isHighlighted = !isDiagonal && (catY.name === activeCategoryY || catX.name === activeCategoryX);
              const hasValue = cellValue !== 0;
              const backgroundColor = isDiagonal ? undefined : this.getColorByValue(cellValue); // Let CSS handle diagonal/empty bg

              return (
                <div
                  key={`cell-${rowIndex}-${colIndex}`}
                  className={clsx(
                    styles.cell,
                    styles.gridCell,
                    isDiagonal && styles.diagonalCell,
                    !isDiagonal && !hasValue && styles.emptyCell,
                    isHighlighted && styles.highlighted,
                    isHovered && !isDiagonal && hasValue && styles.popOutEffect // Pop out non-diagonal cells with value
                  )}
                  style={{
                    gridColumn: colIndex + 2,
                    gridRow: rowIndex + 2,
                    backgroundColor: backgroundColor, // Apply calculated color or let CSS handle default/empty/diagonal
                    cursor: isDiagonal ? 'not-allowed' : 'pointer',
                  }}
                  onMouseEnter={() => this.handleCellMouseEnter(rowIndex, colIndex)}
                  onMouseLeave={this.handleCellMouseLeave}
                  onClick={() => !isDiagonal && this.handleCellClick(catY.name, catX.name)} // Now clickable
                >
                  {/* {isDiagonal ? '' : cellValue} */}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    );
  }

 renderSubGrid = () => {
    // ... (renderSubGrid logic remains largely the same)
    // Just ensure it uses the updated CSS classes and getColorByValue
    const {
        activeCategoryX, activeCategoryY,
        activeSubcategoryX, activeSubcategoryY,
        hoveredSubRowIndex, hoveredSubColIndex
    } = this.state;

    const categoryX = categories.find(cat => cat.name === activeCategoryX);
    const categoryY = categories.find(cat => cat.name === activeCategoryY);

    if (!categoryX?.subcategories?.length || !categoryY?.subcategories?.length) {
        return (
           <Row>
               <Col>
                 {/* Optional message */}
                 {this.renderSubcategorySelectors()}
               </Col>
           </Row>
        )
    }

    const subcategoriesX = categoryX.subcategories;
    const subcategoriesY = categoryY.subcategories;
    const numSubX = subcategoriesX.length;
    const numSubY = subcategoriesY.length;

    const activeSubXIndex = subcategoriesX.findIndex(sub => sub === activeSubcategoryX);
    const activeSubYIndex = subcategoriesY.findIndex(sub => sub === activeSubcategoryY);

    const getSubCellValue = (subY, subX) => {
        const cellId = `${activeCategoryX}.${subX}.${activeCategoryY}.${subY}`;
        const comments = this.getCommentsForCell(cellId); // Use existing function
        let value = 0;
        comments.forEach(c => {
            const intensity = Number(c.intensity);
            if(!isNaN(intensity)) {
                value += (c.valence === 'risk' ? -1 : 1) * intensity;
            }
        });
        return value;
    };

    return (
        <Row>
            <Col>
                {this.renderSubcategorySelectors()} {/* Keep selectors */}

                <h4 style={{ marginTop: '20px' }}>Interaction: {activeCategoryY} vs {activeCategoryX}</h4>
                <div
                    className={styles.subgridContainer}
                    style={{
                        gridTemplateColumns: `100px repeat(${numSubX}, minmax(100px, 1fr))`, // Min width for sub headers
                        gridTemplateRows: `auto repeat(${numSubY}, minmax(40px, auto))`,
                    }}
                >
                    <div className={clsx(styles.cell, styles.diagonalCell)} style={{ gridColumn: 1, gridRow: 1 }} />

                    {subcategoriesX.map((subX, index) => (
                        <div
                            key={`subhead-x-${subX}`}
                            className={clsx(
                                styles.cell, styles.headerCell,
                                activeSubcategoryX === subX && styles.activeHeaderCell
                            )}
                            style={{ gridColumn: index + 2, gridRow: 1 }}
                            onClick={() => this.handleSubcategoryXClick(subX)}
                        >
                            {subX}
                        </div>
                    ))}

                    {subcategoriesY.map((subY, rowIndex) => (
                        <React.Fragment key={`subrow-${subY}`}>
                            <div
                                key={`subhead-y-${subY}`}
                                className={clsx(
                                    styles.cell, styles.headerCell,
                                    activeSubcategoryY === subY && styles.activeHeaderCell
                                )}
                                style={{ gridColumn: 1, gridRow: rowIndex + 2 }}
                                onClick={() => this.handleSubcategoryYClick(subY)}
                            >
                                {subY}
                            </div>

                            {subcategoriesX.map((subX, colIndex) => {
                                const cellValue = getSubCellValue(subY, subX);
                                const isHovered = rowIndex === hoveredSubRowIndex && colIndex === hoveredSubColIndex;
                                // Highlight if specific subcategory row/col is active
                                const isHighlighted = subY === activeSubcategoryY || subX === activeSubcategoryX;
                                const hasValue = cellValue !== 0;
                                const backgroundColor = this.getColorByValue(cellValue);

                                return (
                                    <div
                                        key={`subcell-${rowIndex}-${colIndex}`}
                                        className={clsx(
                                            styles.cell, styles.gridCell,
                                            !hasValue && styles.emptyCell,
                                            isHighlighted && styles.highlighted,
                                            isHovered && hasValue && styles.popOutEffect
                                        )}
                                        style={{
                                            gridColumn: colIndex + 2,
                                            gridRow: rowIndex + 2,
                                            backgroundColor: backgroundColor,
                                        }}
                                        onMouseEnter={() => this.handleSubCellMouseEnter(rowIndex, colIndex)}
                                        onMouseLeave={this.handleSubCellMouseLeave}
                                        onClick={() => this.handleSubCellClick(rowIndex, colIndex, subY, subX)}
                                    >
                                       {/* {cellValue} */}
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    ))}
                </div>
            </Col>
        </Row>
    );
}


// --- renderSubcategorySelectors remains the same ---
renderSubcategorySelectors = () => {
    const { activeCategoryX, activeCategoryY, activeSubcategoryX, activeSubcategoryY } = this.state;
    const categoryX = categories.find(cat => cat.name === activeCategoryX);
    const categoryY = categories.find(cat => cat.name === activeCategoryY);

    return(
        <>
            {categoryX?.subcategories?.length > 0 && (
                <>
                    <h5>Subcategories for {activeCategoryX} (X-Axis):</h5>
                    <div className={styles.subcategoryButtons}>
                        {categoryX.subcategories.map((sub) => (
                            <Button
                                key={`sel-x-${sub}`}
                                onClick={() => this.handleSubcategoryXClick(sub)}
                                color={activeSubcategoryX === sub ? 'primary' : 'secondary'}
                                outline={activeSubcategoryX !== sub}
                                size="sm"
                            >
                                {sub}
                            </Button>
                        ))}
                         <Button
                                key="sel-x-clear"
                                onClick={() => this.handleSubcategoryXClick(null)}
                                color="light" // Use light for clear button
                                size="sm"
                                outline
                                disabled={!activeSubcategoryX}
                            >
                                Clear X
                            </Button>
                    </div>
                </>
             )}
             {categoryY?.subcategories?.length > 0 && (
                 <>
                     <h5>Subcategories for {activeCategoryY} (Y-Axis):</h5>
                     <div className={styles.subcategoryButtons}>
                         {categoryY.subcategories.map((sub) => (
                             <Button
                                 key={`sel-y-${sub}`}
                                 onClick={() => this.handleSubcategoryYClick(sub)}
                                 color={activeSubcategoryY === sub ? 'primary' : 'secondary'}
                                 outline={activeSubcategoryY !== sub}
                                 size="sm"
                             >
                                 {sub}
                             </Button>
                         ))}
                          <Button
                                key="sel-y-clear"
                                onClick={() => this.handleSubcategoryYClick(null)}
                                color="light" // Use light for clear button
                                size="sm"
                                outline
                                disabled={!activeSubcategoryY}
                            >
                                Clear Y
                            </Button>
                     </div>
                </>
             )}
        </>
    );
}

  // --- Updated Modal Rendering ---
  renderModal = () => {
      const { modal, selectedCellId, existingComments, comment, valence, intensity } = this.state;

      // Format title based on cell ID type
      let modalTitle = "Comment on Interaction";
      if (selectedCellId) {
          if (selectedCellId.includes('_vs_')) {
              const [catX, catY] = selectedCellId.split('_vs_');
              modalTitle = `Interaction: ${catX} vs ${catY}`;
          } else {
              // Assuming format CatX.SubX.CatY.SubY
              const parts = selectedCellId.split('.');
              if (parts.length === 4) {
                  modalTitle = `${parts[0]} (${parts[1]}) vs ${parts[2]} (${parts[3]})`;
              } else {
                 modalTitle = `Comment on: ${selectedCellId}`; // Fallback
              }
          }
      }

      const isTopLevelComment = selectedCellId && selectedCellId.includes('_vs_');

      return (
          <Modal isOpen={modal} toggle={this.toggleModal} className={styles.riskMatrixCommentModal} size="lg">
            <ModalHeader toggle={this.toggleModal} className={styles.riskMatrixModalHeader}>
              {modalTitle}
            </ModalHeader>
            <ModalBody className={styles.riskMatrixModalBody}>
              {existingComments.length > 0 ? (
                <>
                  <h5>Existing comments:</h5>
                  <ul className={styles.riskMatrixModalCommentList}>
                    {existingComments.map((cmt, index) => (
                      <li key={index} className={styles.riskMatrixModalCommentItem}>
                        "{cmt.comment}" - <strong>{cmt.valence}</strong> (Intensity: {cmt.intensity})
                        {/* Show specific cell ID if viewing aggregated top-level comments */}
                        {isTopLevelComment && cmt.cell !== selectedCellId && (
                            <small>From: {cmt.cell}</small>
                        )}
                      </li>
                    ))}
                  </ul>
                  <hr style={{ borderColor: '#555' }}/>
                </>
              ) : (
                <p>No comments yet for this interaction.</p>
              )}

              <h5 className={styles.riskMatrixModalNewComment}>Add a new comment:</h5>
              <Input
                type="textarea"
                className="form-control" // Use regular BS class
                placeholder="Enter your comment here..."
                value={comment}
                onChange={this.handleCommentChange}
                rows={3}
                style={{ backgroundColor: '#303040', color: '#e0e0e0', border: '1px solid #555' }} // Inline for specificity if needed
              />
              <FormGroup tag="fieldset" className={styles.riskMatrixModalValence}>
                <legend>Select Type:</legend>
                <FormGroup check inline>
                  <Input
                    id="valenceOpportunity"
                    type="radio"
                    name="valence"
                    value="opportunity"
                    checked={valence === 'opportunity'}
                    onChange={this.handleValenceChange}
                    className="form-check-input"
                  />
                   <Label check for="valenceOpportunity" className="form-check-label">
                    Opportunity
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                 <Input
                    id="valenceRisk"
                    type="radio"
                    name="valence"
                    value="risk"
                    checked={valence === 'risk'}
                    onChange={this.handleValenceChange}
                    className="form-check-input"
                  />
                  <Label check for="valenceRisk" className="form-check-label">
                    Risk
                  </Label>
                </FormGroup>
              </FormGroup>
              <FormGroup className={styles.riskMatrixModalIntensity}>
                <Label for="intensity" style={{ color: '#f0f0f0' }}>Intensity: {intensity}</Label>
                <Input
                  id="intensity"
                  type="range"
                  name="intensity"
                  className="form-range" // Use BS class for styling
                  min="1" max="10" step="1"
                  value={intensity}
                  onChange={this.handleIntensityChange}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter className={styles.riskMatrixModalFooter}>
              <Button
                color="primary"
                onClick={this.handleSaveComment}
                disabled={!comment}
              >
                Save Comment
              </Button>{' '}
              <Button color="secondary" onClick={this.toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
      );
  }


  render() {
    const { activeCategoryX, activeCategoryY } = this.state;

    return (
      <Container className={styles.container} fluid>
        <Row>
          <Col>
            <h3 style={{ color: '#f0f0f0', marginBottom: '20px' }}>Technology Interaction Matrix</h3>
            {this.renderMainGrid()}
          </Col>
        </Row>

        {activeCategoryX && activeCategoryY && this.renderSubGrid()}

        {this.renderModal()}
      </Container>
    );
  }
}

export default TechGrid;