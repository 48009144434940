/***************************************************
 * FILE: CreateSurvey.jsx
 * LOCATION: my-app/client/src/components/SurveyTool/CreateSurvey.jsx
 ***************************************************/

import React, { Component } from 'react';
import {
  Button,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faClipboard,
  faPlus,
  faTimes,
  faBookmark,
  faCheck,
  faPenNib,
  faGlobe
} from '@fortawesome/free-solid-svg-icons';
import styles from './CreateSurvey.module.scss';
import contractScripts from '../Buttons/contractScripts.js';
import { ethers } from 'ethers';
import sha256 from 'crypto-js/sha256';

import DatabaseTool from '../AiDatabase/DatabaseTool.jsx'; // SINGLE DatabaseTool with minified mode

import { callAnthropicAI } from '../../utilities/aiScripts.js';

class CreateSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      questions: [],
      addingQuestionType: 'Question Type',
      surveySubmitted: false,
      surveyHash: '',
      isSubmitting: false,
      progress: 0,
      showJson: false,
      isStandaloneQuestion: false,
      associatedSurveyId: '',
      surveyAddedSuccessfully: false,
      questionsAddedSuccessfully: false,
      uploadedQuestions: [],
      submissionError: '',
      copySurveyIdSuccess: false,
      copySurveyLinkSuccess: false,

      // Additional:
      showAutoTool: false,    // controls manual vs. auto creation
      documentURLs: [],       // store doc URLs if auto approach used
    };

    // If parent provided pre-formed questions or a pre-formed survey
    if (props.preformedQuestions && props.preformedQuestions.length > 0) {
      this.state.questions = props.preformedQuestions;
      if (props.preformedSurvey && props.preformedSurvey.title) {
        this.state.title = props.preformedSurvey.title;
        this.state.isStandaloneQuestion = false;
      } else {
        this.state.isStandaloneQuestion = true;
      }
    }
  }

  componentDidMount() {
    if (this.props.preformedQuestions && this.props.preformedQuestions.length > 0) {
      this.setState({
        questions: this.props.preformedQuestions,
        title: this.props.preformedSurvey?.title || '',
        isStandaloneQuestion: !this.props.preformedSurvey?.title,
      }, () => {
        this.updateSurveyHash();
      });
    } else {
      this.loadFromLocalStorage();
    }

    this.setState({
      isSubmitting: false,
      progress: 0,
      submissionError: '',
    });
  }

  loadFromLocalStorage = () => {
    const savedSurvey = localStorage.getItem('unfinishedSurvey');
    if (savedSurvey) {
      try {
        const parsedSurvey = JSON.parse(savedSurvey);
        if (parsedSurvey.questions && Array.isArray(parsedSurvey.questions)) {
          parsedSurvey.questions = parsedSurvey.questions.map(q => ({
            ...q,
            options: q.type === 'multichoice' && !Array.isArray(q.options) ? [] : q.options,
          }));
        }
        this.setState(parsedSurvey, this.updateSurveyHash);
      } catch (error) {
        console.error('Error parsing saved survey from localStorage:', error);
      }
    }
  };

  saveToLocalStorage = () => {
    localStorage.setItem('unfinishedSurvey', JSON.stringify(this.state));
  };

  generateQuestionId = (type, prompt, options = []) => {
    let dataToHash = `${type}:${prompt.toLowerCase()}`;
    if (type === 'multichoice' && options.length > 0) {
      dataToHash += `:${options.join(',').toLowerCase()}`;
    }
    const hash = sha256(dataToHash).toString();
    return '0x' + hash;
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  addQuestion = () => {
    const type = this.state.addingQuestionType;
    if (!type || type === 'Question Type') return;

    const newQuestionId = this.generateQuestionId(type, '', []);
    const newQuestionData = {
      id: newQuestionId,
      type: type,
      prompt: '',
      options: type === "multichoice" ? [] : undefined,
      associatedSurveyId: '',
      tags: [],
    };

    this.setState(prevState => ({
      questions: [...prevState.questions, newQuestionData],
      addingQuestionType: 'Question Type'
    }), () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleQuestionChange = (index, key, value) => {
    const { questions } = this.state;
    const updated = [...questions];
    const q = { ...updated[index], [key]: value };

    const opts = q.options || [];
    q.id = this.generateQuestionId(q.type, q.prompt, opts);

    updated[index] = q;
    this.setState({ questions: updated }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleOptionChange = (qIdx, optIdx, val) => {
    const { questions } = this.state;
    const q = { ...questions[qIdx] };
    if (!Array.isArray(q.options)) q.options = [];
    q.options[optIdx] = val;

    q.id = this.generateQuestionId(q.type, q.prompt, q.options);

    const updated = [...questions];
    updated[qIdx] = q;
    this.setState({ questions: updated }, this.saveToLocalStorage);
  };

  addOption = (questionIndex) => {
    const { questions } = this.state;
    const q = { ...questions[questionIndex] };
    if (!q.options) q.options = [];
    q.options.push('');

    const updated = [...questions];
    updated[questionIndex] = q;
    this.setState({ questions: updated }, this.saveToLocalStorage);
  };

  removeOption = (questionIndex, optionIndex) => {
    const { questions } = this.state;
    const q = { ...questions[questionIndex] };
    if (!Array.isArray(q.options)) q.options = [];
    q.options = q.options.filter((_, i) => i !== optionIndex);

    const updated = [...questions];
    updated[questionIndex] = q;
    this.setState({ questions: updated }, this.saveToLocalStorage);
  };

  removeQuestion = (index) => {
    const updated = this.state.questions.filter((_, i) => i !== index);
    this.setState({ questions: updated }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  bookmarkQuestion = (questionId) => {
    let bookmarkedQuestions = JSON.parse(localStorage.getItem('bookmarkedQuestions')) || [];
    if (!bookmarkedQuestions.includes(questionId)) {
      bookmarkedQuestions.push(questionId);
      localStorage.setItem('bookmarkedQuestions', JSON.stringify(bookmarkedQuestions));
      console.log(`Question ${questionId} bookmarked.`);
    } else {
      console.log(`Question ${questionId} is already bookmarked.`);
    }
  };

  updateSurveyHash = () => {
    const { title, isStandaloneQuestion } = this.state;
    if (isStandaloneQuestion) {
      this.setState({ surveyHash: '' });
    } else {
      const surveyData = { title };
      const newHash = "0x" + sha256(JSON.stringify(surveyData)).toString();
      this.setState({ surveyHash: newHash });
    }
  };

  // Remove the logic that sets `title: ''` upon toggle so the AI-suggested title persists
  toggleStandaloneQuestion = () => {
    this.setState((prev) => ({
      isStandaloneQuestion: !prev.isStandaloneQuestion,
      // Do not overwrite 'title' here anymore
      surveyAddedSuccessfully: false,
      questionsAddedSuccessfully: false,
      submissionError: '',
    }), () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleAssociatedSurveyIdChange = (index, val) => {
    const updated = [...this.state.questions];
    updated[index] = { ...updated[index], associatedSurveyId: val };
    this.setState({ questions: updated }, this.saveToLocalStorage);
  };

  handleTagsChange = (index, val) => {
    const arr = val.split(',').map((t) => t.trim());
    const updated = [...this.state.questions];
    updated[index] = { ...updated[index], tags: arr };
    this.setState({ questions: updated }, this.saveToLocalStorage);
  };

  removeDuplicateQuestions = (questions) => {
    const unique = [];
    const setIds = new Set();
    for (const q of questions) {
      if (!setIds.has(q.id)) {
        unique.push(q);
        setIds.add(q.id);
      }
    }
    return unique;
  };

  createSurvey = async () => {
    if (!this.props.loginComplete) {
      this.props.toggleLoginModal(true);
      return;
    }

    this.setState({ isSubmitting: true, progress: 0 });

    const { title, questions, isStandaloneQuestion, documentURLs } = this.state;
    try {
      if (isStandaloneQuestion) {
        if (questions.length === 0) {
          alert("Please add at least one question.");
          this.setState({ isSubmitting: false, progress: 0 });
          return;
        }
        this.setState({ progress: 10 });

        const uniqueQuestions = this.removeDuplicateQuestions(questions);

        const questionDataArray = [];
        const surveyIds = [];
        for (const q of uniqueQuestions) {
          let qD = {
            type: q.type,
            prompt: q.prompt,
            id: q.id,
            associatedSurveyId: q.associatedSurveyId || '',
            tags: q.tags || [],
            options: q.type === 'multichoice' ? q.options : undefined,
            creator: this.props.account,
          };
          questionDataArray.push(qD);
          const sid = q.associatedSurveyId || ethers.constants.HashZero;
          surveyIds.push(sid);
        }
        this.setState({ progress: 50 });

        const questionIds = uniqueQuestions.map(q => q.id);
        const { receipt, uploadedQuestions } = await contractScripts.addQuestions(
          this.props.provider,
          questionIds,
          questionDataArray,
          surveyIds
        );

        console.log("Questions submitted:", receipt);
        this.setState({
          questionsAddedSuccessfully: true,
          isSubmitting: false,
          progress: 100,
          uploadedQuestions,
        });
        localStorage.removeItem('unfinishedSurvey');
      } else {
        if (!title.trim()) {
          alert("Please enter a survey title.");
          this.setState({ isSubmitting: false, progress: 0 });
          return;
        }
        const sd = { title, creator: this.props.account, documentURLs };
        const newHash = "0x" + sha256(JSON.stringify(sd)).toString();
        this.setState({ surveyHash: newHash }, async () => {
          const questionDataArray = questions.map((q) => ({
            ...q,
            tags: q.tags || [],
            associatedSurveyId: newHash,
            creator: this.props.account,
          }));
          const completeSurveyData = {
            surveyID: newHash,
            title,
            questionIDs: questions.map(q => q.id),
            creator: this.props.account,
            documentURLs,
          };

          const { receipt } = await contractScripts.addSurveyWithQuestions(
            this.props.provider,
            newHash,
            completeSurveyData,
            questions.map(q => q.id),
            questionDataArray
          );

          console.log("Survey submitted:", receipt);
          this.setState({
            surveyAddedSuccessfully: true,
            isSubmitting: false,
            progress: 100,
            surveyHash: newHash,
          });
          localStorage.removeItem('unfinishedSurvey');
        });
      }
    } catch (error) {
      console.error("Failed to create survey:", error);
      this.setState({
        isSubmitting: false,
        progress: 0,
        submissionError: error.message || 'An error occurred while creating the survey.',
      });
    }
  };

  toggleShowJson = () => {
    this.setState(prev => ({ showJson: !prev.showJson }));
  };

  copyQuestionIdToClipboard = (qid) => {
    navigator.clipboard.writeText(qid)
      .then(() => console.log('Copied question ID:', qid))
      .catch(err => console.error('Failed to copy QID:', err));
  };

  // FIX #3: Ensure we copy the correct link with <surveyID>, not [object Object]
  copySurveyLinkToClipboard = (surveyID = null) => {
    let finalID = surveyID;
    if (!finalID) {
      const params = new URLSearchParams(window.location.search);
      const paramID = params.get('surveyID');
      finalID = paramID || this.state.surveyHash;
    }
    const link = `${window.location.origin}/survey/${String(finalID)}`;
    navigator.clipboard.writeText(link)
      .then(() => {
        console.log('Copied link:', link);
        this.setState({ copySurveyLinkSuccess: true });
        setTimeout(() => this.setState({ copySurveyLinkSuccess: false }), 2000);
      })
      .catch(err => console.error('Failed to copy link:', err));
  };

  /**
   * If the user loads a new URL in the DatabaseTool, we want to discard
   * old un-submitted questions. So if docURLs is different from our
   * existing doc URLs, we replace the entire question array instead of merging.
   */
  handleAutoQuestionsGenerated = (questionsArray, docURLs, aiTitle) => {
    // Check if docURLs is new or if we had none previously
    const oldURLs = this.state.documentURLs;
    let isNewURL = false;
    if (docURLs && docURLs.length > 0) {
      if (!oldURLs || oldURLs.length === 0) {
        isNewURL = true;
      } else if (docURLs[0] !== oldURLs[0]) {
        isNewURL = true;
      }
    }

    if (isNewURL) {
      // Clear old un-submitted questions
      this.setState({
        questions: questionsArray,
        documentURLs: docURLs || [],
      }, () => {
        // If we are in Survey mode and AI gave a title, apply it
        if (!this.state.isStandaloneQuestion && aiTitle) {
          this.setState({ title: aiTitle }, () => {
            this.updateSurveyHash();
            this.saveToLocalStorage();
          });
        } else {
          this.updateSurveyHash();
          this.saveToLocalStorage();
        }
      });
    } else {
      // same or no docURL => just merge
      const combined = [...this.state.questions, ...questionsArray];
      this.setState({
        questions: combined,
        documentURLs: docURLs || oldURLs,
      }, () => {
        if (!this.state.isStandaloneQuestion && aiTitle) {
          this.setState({ title: aiTitle }, () => {
            this.updateSurveyHash();
            this.saveToLocalStorage();
          });
        } else {
          this.updateSurveyHash();
          this.saveToLocalStorage();
        }
      });
    }
  };

  // Suggest tags with an AI call if user wants them (still allowed, but not typically used if we do single call)
  suggestTagsForQuestion = async (qIndex) => {
    const question = this.state.questions[qIndex];
    if (!question || !question.prompt.trim()) {
      alert('Please fill in a question prompt first.');
      return;
    }
    try {
      const raw = await callAnthropicAI(
        `Return a JSON array of short tags relevant to this question: "${question.prompt}"`
      );
      let arr = [];
      try {
        arr = JSON.parse(raw);
      } catch (e) {
        const matched = raw.match(/\[.*\]/s);
        if (matched) arr = JSON.parse(matched[0]);
      }
      if (!Array.isArray(arr)) arr = [];
      if (this.props.defaultTags && Array.isArray(this.props.defaultTags) && this.props.defaultTags.length > 0) {
        const lowerSet = new Set(this.props.defaultTags.map(t => t.toLowerCase()));
        const intersection = arr.filter(t => lowerSet.has(t.toLowerCase()));
        if (intersection.length > 0) arr = intersection;
      }
      this.handleTagsChange(qIndex, arr.join(', '));
      alert(`Suggested tags: ${arr.join(', ')}`);
    } catch (err) {
      console.error('Tag suggestion error:', err);
      alert('AI tag suggestion failed. See console.');
    }
  };

  render() {
    const {
      title, questions, isSubmitting, progress,
      showJson, isStandaloneQuestion,
      surveyAddedSuccessfully, questionsAddedSuccessfully,
      uploadedQuestions, submissionError,
      showAutoTool, documentURLs,
    } = this.state;

    const surveyID = isStandaloneQuestion ? '' : this.state.surveyHash;

    let jsonData = {};
    if (isStandaloneQuestion) {
      jsonData = {
        questions: questions.map(q => ({
          prompt: q.prompt,
          type: q.type,
          id: q.id,
          associatedSurveyId: q.associatedSurveyId || '',
          tags: q.tags || [],
          ...(q.type === "multichoice" && { options: q.options })
        }))
      };
    } else {
      jsonData = {
        surveyID: surveyID,
        title: title,
        documentURLs,
        questionIDs: questions.map(q => q.id),
        questions: questions.map(q => ({
          prompt: q.prompt,
          type: q.type,
          id: q.id,
          tags: q.tags || [],
          ...(q.type === "multichoice" && { options: q.options })
        }))
      };
    }

    const manualCreationUI = (
      <>
        <div className={styles.modeToggle}>
          <Label className={styles.toggleLabel}>Upload Survey</Label>
          <div
            className={styles.toggleSwitch}
            onClick={this.toggleStandaloneQuestion}
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              cursor: 'pointer',
              backgroundColor: '#ccc',
              borderRadius: '15px',
              position: 'relative',
              width: '60px',
              height: '30px',
              verticalAlign: 'middle',
            }}
          >
            <div
              style={{
                backgroundColor: isStandaloneQuestion ? '#4caf50' : '#fff',
                borderRadius: '50%',
                width: '28px',
                height: '28px',
                position: 'absolute',
                top: '1px',
                left: isStandaloneQuestion ? '31px' : '1px',
                transition: 'left 0.2s',
              }}
            />
          </div>
          <Label className={styles.toggleLabel} style={{ marginLeft: '10px' }}>
            Upload Questions
          </Label>
        </div>

        {!isStandaloneQuestion && (
          <Input
            className={styles.surveyTitleInput}
            placeholder="Survey Title (required)"
            value={title}
            onChange={this.handleTitleChange}
            required
          />
        )}

        {questions.map((question, qIndex) => (
          <div key={qIndex} className={styles.questionContainer}>
            <div className={styles.questionHeader}>
              <strong className={styles.questionTypeText}>
                #{qIndex + 1}: {question.type.charAt(0).toUpperCase() + question.type.slice(1)} Question
              </strong>
              <Button className={styles.removeQuestionButton} onClick={() => this.removeQuestion(qIndex)}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
            <Input
              className={styles.questionPromptInput}
              placeholder="Question prompt"
              value={question.prompt}
              onChange={e => this.handleQuestionChange(qIndex, 'prompt', e.target.value)}
            />
            {question.type === "multichoice" && (
              <div className={styles.optionsContainer}>
                {question.options.map((option, oIndex) => (
                  <div key={oIndex} className={styles.optionItem}>
                    <Input
                      placeholder="Option"
                      value={option}
                      onChange={e => this.handleOptionChange(qIndex, oIndex, e.target.value)}
                      className={styles.optionInput}
                    />
                    <Button className={styles.removeOptionButton} onClick={() => this.removeOption(qIndex, oIndex)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                ))}
                {question.options.length < 10 && (
                  <Button className={styles.addOptionButton} onClick={() => this.addOption(qIndex)}>
                    <FontAwesomeIcon icon={faPlus} /> Add Option
                  </Button>
                )}
              </div>
            )}
            <div className={styles.questionMetadata}>
              <div className={styles.questionId}>Question ID: {question.id || 'Generating...'}</div>
              <Input
                className={styles.tagsInput}
                placeholder="Tags (comma-separated)"
                value={question.tags ? question.tags.join(', ') : ''}
                onChange={e => this.handleTagsChange(qIndex, e.target.value)}
              />
              <Button
                size="sm"
                color="info"
                style={{ marginTop: '5px' }}
                onClick={() => this.suggestTagsForQuestion(qIndex)}
              >
                Suggest Tags
              </Button>
              {isStandaloneQuestion && (
                <div className={styles.associatedSurveyContainer}>
                  <Label className={styles.associatedSurveyLabel}>
                    Associated Survey:
                  </Label>
                  <Input
                    className={styles.associatedSurveyInput}
                    placeholder="Optional"
                    value={question.associatedSurveyId}
                    onChange={e => this.handleAssociatedSurveyIdChange(qIndex, e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        ))}

        <div className={styles.addQuestionContainer}>
          <select
            className={styles.questionTypeSelect}
            value={this.state.addingQuestionType}
            onChange={e => this.setState({ addingQuestionType: e.target.value })}
          >
            <option value="Question Type">Question Type</option>
            <option value="freeform">Freeform</option>
            <option value="rating">Rating</option>
            <option value="multichoice">Multichoice</option>
            <option value="binary">Binary</option>
          </select>
          <Button className={styles.addQuestionButton} onClick={this.addQuestion}>
            <FontAwesomeIcon icon={faPlus} /> Add Question(s)
          </Button>
        </div>

        {!isStandaloneQuestion && (
          <p className={styles.surveyIdPreview}>Survey ID: {surveyID || 'Generating...'}</p>
        )}

        <Button
          className={`${styles.createSurveyButton} ${submissionError ? styles.errorButton : ''}`}
          onClick={this.createSurvey}
          disabled={
            isSubmitting ||
            (!this.state.isStandaloneQuestion && !this.state.title.trim()) ||
            this.state.questions.length === 0
          }
        >
          {isSubmitting ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin /> Creating...
            </>
          ) : (
            isStandaloneQuestion ? "Add Questions" : "Create Survey"
          )}
        </Button>

        {submissionError && (
          <div className={styles.errorMessage}>{submissionError}</div>
        )}

        {questionsAddedSuccessfully && (
          <div className={styles.surveySubmissionConfirmation}>
            <h3>Questions Added Successfully!</h3>
            {uploadedQuestions && uploadedQuestions.length > 0 && (
              <div className={styles.uploadedQuestionsList}>
                <h4>Uploaded Questions:</h4>
                <ul>
                  {uploadedQuestions.map(({ questionId, arweaveTxId }, index) => (
                    <li key={index} className={styles.uploadedQuestionItem}>
                      <a href={`https://arweave.net/${arweaveTxId}`} target="_blank" rel="noopener noreferrer">
                        Question ID: {questionId}
                      </a>
                      <Button
                        className={styles.copyQuestionIdButton}
                        onClick={() => this.copyQuestionIdToClipboard(questionId)}
                      >
                        <FontAwesomeIcon icon={faClipboard} />
                      </Button>
                      <Button
                        className={styles.bookmarkQuestionButton}
                        onClick={() => this.bookmarkQuestion(questionId)}
                      >
                        <FontAwesomeIcon icon={faBookmark} />
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {surveyAddedSuccessfully && (
          <div className={styles.surveySubmissionConfirmation}>
            <h3>Survey Created Successfully!</h3>
            <p>
              Survey ID: {surveyID}
              <Button
                className={styles.copySurveyIdButton}
                onClick={() => this.copyQuestionIdToClipboard(surveyID)}
              >
                <FontAwesomeIcon
                  icon={this.state.copySurveyIdSuccess ? faCheck : faClipboard}
                  style={{ color: this.state.copySurveyIdSuccess ? 'green' : 'inherit' }}
                />
              </Button>
            </p>
            <div className={styles.surveyActions}>
              <Button
                className={styles.copySurveyLinkButton}
                onClick={this.copySurveyLinkToClipboard}
              >
                Copy Survey Link
              </Button>
              <a
                href={`/survey/${surveyID}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.viewSurveyLink}
              >
                View Survey
              </a>
            </div>
          </div>
        )}

        <Button className={styles.toggleJsonButton} onClick={this.toggleShowJson}>
          {showJson ? "Hide JSON" : "Show JSON"}
        </Button>
        {showJson && (
          <pre className={styles.jsonDisplay}>
            {JSON.stringify(jsonData, null, 2)}
          </pre>
        )}
      </>
    );

    return (
      <div className={`${styles.createSurveyContainer} ${this.props.miniaturized ? styles.miniaturized : ''}`}>
        {/* Top icons: Manual vs. Auto from URL */}
        {!this.props.miniaturized && (
          <div style={{ display: 'flex', gap: '15px', marginBottom: '20px' }}>
            <Button
              onClick={() => this.setState({ showAutoTool: false })}
              color={showAutoTool ? 'secondary' : 'primary'}
            >
              <FontAwesomeIcon icon={faPenNib} /> Manual
            </Button>
            <Button
              onClick={() => this.setState({ showAutoTool: true })}
              color={showAutoTool ? 'primary' : 'secondary'}
            >
              <FontAwesomeIcon icon={faGlobe} /> Auto from URL
            </Button>
          </div>
        )}

        {this.state.showAutoTool ? (
          <div style={{ marginTop: '20px' }}>
            <DatabaseTool
              minified={true}
              hideEncryption={true}
              provider={this.props.provider}
              network={this.props.network}
              account={this.props.account}
              loginComplete={this.props.loginComplete}
              toggleLoginModal={this.props.toggleLoginModal}
              defaultTags={this.props.defaultTags || []}
              onQuestionsGenerated={this.handleAutoQuestionsGenerated}
            />
          </div>
        ) : (
          manualCreationUI
        )}
      </div>
    );
  }
}

export default CreateSurvey;
