import React, { Component } from "react";

// components
import ExplorerTabPluginExpanded from "components/MainContent/ExplorerTabPluginExplainer";
import SurveyTool from "components/SurveyTool/SurveyTool.jsx";

// CSS and styles
import artHeader from '../../assets/img/artHeader.png';
import styles from './SurveyPage.module.scss';

const surveyExplainText = 'Survey and question platform allowing detailed responses, which can be encrypted for privacy and retroactive evaluation by ZK systems (opt-in). An upgraded version of pol.is incorporating advanced question formats, preference weighing, and group filtering. Audio input processed by OpenAI – not stored by this app. Responses stored permanently on Arweave';

class SurveyComponent extends Component {
    render() {
        // Minimal usage stubs:
        // if (this.props.defaultTags) {
        //   console.log('[SurveyPage] Received defaultTags:', this.props.defaultTags);
        //   // In a real scenario, you might auto-apply them to newly created questions, etc.
        // }
        // if (this.props.defaultFilterState) {
        //   console.log('[SurveyPage] Received defaultFilterState:', this.props.defaultFilterState);
        //   // Possibly pass to child filter or SurveyTool logic
        // }
        // if (this.props.orgInfo) {
        //   console.log('[SurveyPage] Received orgInfo:', this.props.orgInfo);
        // }
    
        return (
          <div>
            <ExplorerTabPluginExpanded
              explainText={surveyExplainText}
              headerImage={artHeader}
            />
    
            <SurveyTool
              surveyID={this.props.surveyID}
              displayAnswerMode={this.props.displayAnswerMode}
              viewAddress={this.props.viewAddress}
              toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
              account={this.props.account}
              provider={this.props.provider}
              joined={this.props.joined}
              XPBalance={this.props.XPBalance}
              ETHbalance={this.props.ETHBalance}
              availableETH={this.props.availableETH}
              lobby={this.props.lobby}
              paid={this.props.paid}
              loginComplete={this.props.loginComplete}
              loginInProgress={this.props.loginInProgress}
              network={this.props.network}
              refreshSurveyResponsesByID={this.props.refreshSurveyResponsesByID}
              refreshQuestionMetadata={this.props.refreshQuestionMetadata}
              refreshQuestionResponses={this.props.refreshQuestionResponses}
    
              // Pass the stubs down
              defaultTags={this.props.defaultTags}
              defaultFilterState={this.props.defaultFilterState}
              orgInfo={this.props.orgInfo}
    
              autoOpenResults={this.props.autoOpenResults}
              filterState={this.props.filterState}
            />
          </div>
        );
      }
}

export default SurveyComponent;
