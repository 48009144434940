/***************************************************
 * FILE: SBTsList.jsx
 * LOCATION: my-app/client/src/components/SBTs/SBTsList.jsx
 ***************************************************/

import React, { useEffect, useState, useRef } from 'react';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
import styles from './SBTsList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExpand, faSync, faTrash, faPlus, faLock } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import SBTPage from './SBTPage';
import { featured_SBTs_LIST } from '../../variables/CONTRACT_ADDRESSES.js';
import CreateGroup from './CreateSBTGroup';

/**
 * SBTsList
 *
 * Displays a list of all known SBTs (pulled from localStorage cache and updated from chain).
 * Also can show “Featured” SBTs, “Minting Live,” and “Expired” sections.
 *
 * Props:
 *   - provider
 *   - network
 *   - account
 *   - loginComplete
 *   - miniaturized (bool)
 *   - toggleLoginModal
 *   - viewMode (string): 'standard' or 'modal'. Determines the styling context. Defaults to 'standard'.
 *
 * This component fetches SBT data, merges them with local cache, and re-renders in a list format.
 * If miniaturized = true, it organizes them more compactly.  The viewMode prop controls which set of
 * CSS rules (.standardViewContainer or .modalViewContainer) is applied.
 */
const SBTsList = ({
  provider,
  network,
  account,
  loginComplete,
  miniaturized,
  toggleLoginModal,
  // NEW prop: 'standard' (default) or 'modal'
  viewMode = 'standard'
}) => {
  const [sbtList, setSbtList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cache, setCache] = useState(JSON.parse(localStorage.getItem('sbtCache')) || {});
  const [refreshing, setRefreshing] = useState(false);
  const [lastFetchBlock, setLastFetchBlock] = useState(0);

  // We want to allow “Hide/Exclude password‐locked SBTs” in **both** standard and modal now.
  const [excludePasswordLocked, setExcludePasswordLocked] = useState(false);

  const [showCreateGroup, setShowCreateGroup] = useState(false);

  const isMounted = useRef(true);
  const refreshIntervalRef = useRef(null);

  // For stable ordering
  const originalLoadOrderRef = useRef({});
  const originalLoadCounterRef = useRef(0);

  const BATCH_SIZE = 5;

  // -------------------------
  // MAIN FETCH & CACHE LOGIC
  // -------------------------
  const fetchSBTs = async (forceRefresh = false, showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }

    const networkID = network?.id;
    if (!networkID) {
      console.error('Network ID is undefined in SBTsList. Cannot proceed.');
      if (showLoading && isMounted.current) {
        setLoading(false);
      }
      return;
    }

    try {
      const cachedNetworkData = cache[networkID];
      const cachedSbtList = cachedNetworkData ? cachedNetworkData.sbtList : {};
      const cacheLatestBlock = cachedNetworkData ? cachedNetworkData.lastBlock : 0;
      const latestBlock = await contractScripts.getLatestBlockNumber(provider);

      let newSbts = [];
      if (cacheLatestBlock < latestBlock || forceRefresh) {
        // fetch newly created SBTs from [cacheLatestBlock+1..latestBlock]
        newSbts = await contractScripts.getSbtsCreated(
          provider,
          cacheLatestBlock + 1,
          latestBlock
        );
      }

      // Combine old & new addresses
      const existingSbtAddresses = Object.keys(cachedSbtList).map(a => a.toLowerCase());
      const newSbtAddresses = newSbts.map(sbt => sbt.sbtAddress.toLowerCase());
      const allSbtAddresses = [...new Set([...existingSbtAddresses, ...newSbtAddresses])];

      // Force-include featured SBT addresses
      const featuredLower = featured_SBTs_LIST.map(addr => addr.toLowerCase());
      for (const fAddr of featuredLower) {
        if (!allSbtAddresses.includes(fAddr)) {
          allSbtAddresses.push(fAddr);
        }
      }

      const processedSbts = {};
      // process in small batches
      for (let i = 0; i < allSbtAddresses.length; i += BATCH_SIZE) {
        const batch = allSbtAddresses.slice(i, i + BATCH_SIZE);
        const batchPromises = batch.map(async sbtAddrLower => {
          try {
            const cachedSBT = cachedSbtList[sbtAddrLower];
            let sbtInfo, mintedAddresses, burnedAddresses;
            let fromBlockForFetch = 0;

            if (
              cachedSBT &&
              cachedSBT.sbtInfo &&
              cachedSBT.mintedAddresses &&
              cachedSBT.burnedAddresses &&
              typeof cachedSBT.blockNumber === 'number'
            ) {
              sbtInfo = cachedSBT.sbtInfo;
              mintedAddresses = cachedSBT.mintedAddresses;
              burnedAddresses = cachedSBT.burnedAddresses;

              if (cachedSBT.blockNumber < latestBlock) {
                fromBlockForFetch = cachedSBT.blockNumber + 1;
              } else {
                fromBlockForFetch = latestBlock;
              }
            } else {
              // not in cache or incomplete => fetch from scratch
              sbtInfo = await contractScripts.getSbtMetadata(provider, sbtAddrLower);
            }

            if (!mintedAddresses || !burnedAddresses || fromBlockForFetch < latestBlock) {
              const eventsFromBlock = mintedAddresses && burnedAddresses
                ? fromBlockForFetch
                : 0;
              const newlyMinted = await contractScripts.getAddressesWhoMintedSBT(
                provider,
                sbtAddrLower,
                eventsFromBlock,
                'latest'
              );
              const newlyBurned = await contractScripts.getAddressesWhoBurnedSBT(
                provider,
                sbtAddrLower,
                eventsFromBlock,
                'latest'
              );

              if (!mintedAddresses || !burnedAddresses) {
                mintedAddresses = newlyMinted.map(a => a.toLowerCase());
                burnedAddresses = newlyBurned.map(a => a.toLowerCase());
              } else {
                // merge sets
                const mintedSet = new Set(mintedAddresses);
                newlyMinted.forEach(a => mintedSet.add(a.toLowerCase()));
                mintedAddresses = Array.from(mintedSet);

                const burnedSet = new Set(burnedAddresses);
                newlyBurned.forEach(a => burnedSet.add(a.toLowerCase()));
                burnedAddresses = Array.from(burnedSet);
              }
            }

            // stable load ordering
            if (typeof originalLoadOrderRef.current[sbtAddrLower] !== 'number') {
              originalLoadCounterRef.current += 1;
              originalLoadOrderRef.current[sbtAddrLower] = originalLoadCounterRef.current;
            }

            return {
              sbtAddress: sbtAddrLower,
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
              blockNumber: latestBlock
            };
          } catch (err) {
            console.error(`Error processing SBT ${sbtAddrLower}:`, err);
            return null;
          }
        });

        const batchResults = await Promise.all(batchPromises);
        batchResults.forEach(res => {
          if (res) {
            const addrLower = res.sbtAddress.toLowerCase();
            processedSbts[addrLower] = res;
          }
        });
      }

      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          lastBlock: latestBlock,
          sbtList: {
            ...cachedSbtList,
            ...processedSbts,
          }
        }
      };

      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      setCache(newCache);
      setLastFetchBlock(latestBlock);

      // finalize sbtDetails
      let sbtDetails = Object.values(newCache[networkID].sbtList).filter(
        sbt => sbt && sbt.sbtAddress && sbt.sbtInfo && sbt.mintedAddresses && sbt.burnedAddresses
      );

      // sort by net minted desc, then stable load order
      sbtDetails.sort((a, b) => {
        const netA = a.mintedAddresses.length - a.burnedAddresses.length;
        const netB = b.mintedAddresses.length - b.burnedAddresses.length;
        if (netB !== netA) {
          return netB - netA;
        }
        const orderA = originalLoadOrderRef.current[a.sbtAddress.toLowerCase()] || 0;
        const orderB = originalLoadOrderRef.current[b.sbtAddress.toLowerCase()] || 0;
        return orderA - orderB;
      });

      // remove duplicates
      const unique = [];
      const seen = new Set();
      for (const item of sbtDetails) {
        const addrLower = item.sbtAddress.toLowerCase();
        if (!seen.has(addrLower)) {
          seen.add(addrLower);
          unique.push(item);
        }
      }

      if (isMounted.current) {
        setSbtList(unique);
        if (showLoading) {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching SBTs:", error);
      if (isMounted.current && showLoading) {
        setLoading(false);
      }
    }
  };

  // -------------------------
  // COMPONENT LIFECYCLE
  // -------------------------
  useEffect(() => {
    isMounted.current = true;

    const initFetch = async () => {
      await fetchSBTs(false, true);
      refreshIntervalRef.current = setInterval(async () => {
        if (isMounted.current) {
          const currentBlock = await contractScripts.getLatestBlockNumber(provider);
          if (currentBlock > lastFetchBlock) {
            console.log("New blocks detected, updating...");
            await fetchSBTs(false, false);
          }
        }
      }, 30000);
    };

    initFetch();
    initializeEventListener();

    return () => {
      isMounted.current = false;
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
      contractScripts.removeSBTEventListener(provider);
    };
  }, [provider, network]); // eslint-disable-line react-hooks/exhaustive-deps

  // -------------------------
  // EVENT LISTENER
  // -------------------------
  const initializeEventListener = () => {
    contractScripts.listenForSBTEvents(provider, handleNewEvent);
  };

  const handleNewEvent = async (event) => {
    console.log("New SBT event:", event);
    if (event.eventSignature === "SBTCreated(address)") {
      await updateCacheFromEvent(event);
    } else if (event.eventSignature === "Issued(address,uint256)") {
      await fetchSBTs(false, false);
    } else if (event.eventSignature === "Transfer(address,address,uint256)") {
      await fetchSBTs(false, false);
    }
  };

  const updateCacheFromEvent = async (event) => {
    const networkID = network?.id;
    if (!networkID) return;
    try {
      const sbtAddress = event.args?.sbtAddress || event.sbtAddress;
      const addrLower = sbtAddress.toLowerCase();
      const sbtInfo = await contractScripts.getSbtMetadata(provider, sbtAddress);
      let mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(
        provider,
        sbtAddress,
        0,
        'latest'
      );
      let burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(
        provider,
        sbtAddress,
        0,
        'latest'
      );
      mintedAddresses = mintedAddresses.map(a => a.toLowerCase());
      burnedAddresses = burnedAddresses.map(a => a.toLowerCase());

      const latestBlock = await contractScripts.getLatestBlockNumber(provider);

      // stable load order
      if (typeof originalLoadOrderRef.current[addrLower] !== 'number') {
        originalLoadCounterRef.current += 1;
        originalLoadOrderRef.current[addrLower] = originalLoadCounterRef.current;
      }

      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          sbtList: {
            ...cache[networkID]?.sbtList,
            [addrLower]: {
              sbtAddress,
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
              blockNumber: latestBlock
            }
          },
          lastBlock: latestBlock
        }
      };
      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      setCache(newCache);

      if (isMounted.current) {
        setSbtList((prev) => {
          const newList = [...prev];
          const idx = newList.findIndex(
            x => x && x.sbtAddress && x.sbtAddress.toLowerCase() === addrLower
          );
          if (idx === -1) {
            newList.push({
              sbtAddress,
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
              blockNumber: latestBlock
            });
          } else {
            newList[idx] = {
              sbtAddress,
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
              blockNumber: latestBlock
            };
          }
          return newList;
        });
      }
    } catch (err) {
      console.error("Error in updateCacheFromEvent:", err);
    }
  };

  // -------------------------
  // HELPERS
  // -------------------------
  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchSBTs(true);
    setRefreshing(false);
  };

  const handleClearCache = async () => {
    localStorage.removeItem('sbtCache');
    setCache({});
    await fetchSBTs(true);
  };

  const isMintingLive = (sbt) => {
    if (!sbt?.sbtInfo) return false;
    const now = Math.floor(Date.now() / 1000);
    const endTime = sbt.sbtInfo.mintingEndTime;
    return (endTime === 0 || (endTime && endTime > now));
  };

  // -------------------------
  // FILTERING
  // -------------------------
  // 1) If unlisted + not in featured => hide
  // 2) If excludePasswordLocked => hide hasPasswordMint
  const baseFilteredList = sbtList.filter((sbt) => {
    if (!sbt?.sbtInfo) return false;
    const isFeatured = featured_SBTs_LIST.some(
      f => f.toLowerCase() === sbt.sbtAddress.toLowerCase()
    );
    if (sbt.sbtInfo.unlisted && !isFeatured) {
      return false;
    }
    if (excludePasswordLocked && sbt.sbtInfo.hasPasswordMint) {
      return false;
    }
    return true;
  });

  // Minting categories
  const mintingLiveList = baseFilteredList.filter(isMintingLive);
  const expiredList = baseFilteredList.filter(x => !isMintingLive(x));

  // For featured
  const featuredSBTs = featured_SBTs_LIST
    .map(fAddr => {
      const found = sbtList.find(
        x => x && x.sbtAddress?.toLowerCase() === fAddr.toLowerCase()
      );
      return found || null;
    })
    .filter(Boolean);

  const displayedFeatured = featuredSBTs.filter(s => {
    if (excludePasswordLocked && s.sbtInfo.hasPasswordMint) return false;
    return true;
  });

  // -------------------------
  // RENDER SECTIONS
  // -------------------------
  const renderFeaturedSection = () => {
    if (loading) {
      return (
        <div className={styles.loadingSection}>
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </div>
      );
    }
    return (
      <div id={styles.featuredSBTsContainer}>
        {displayedFeatured.map(sbt => {
          if (!sbt || !sbt.sbtAddress) return null;
          return (
            <SBTPage
              key={sbt.sbtAddress.toLowerCase()}
              miniaturized
              miniMintable
              SBTAddress={sbt.sbtAddress}
              account={account}
              provider={provider}
              network={network}
              loginComplete={loginComplete}
              toggleLoginModal={toggleLoginModal}
            />
          );
        })}
      </div>
    );
  };

  const renderSBTButton = (sbt) => {
    if (!sbt || !sbt.sbtAddress) return null;
    const netMinted = sbt.mintedAddresses.length - sbt.burnedAddresses.length;
    const { name, description, image, hasPasswordMint } = sbt.sbtInfo;
    return (
      <button
        key={sbt.sbtAddress}
        className={styles.sbtItem}
        onClick={() => (window.location.href = `/sbt/${sbt.sbtAddress}`)}
      >
        <div className={styles.sbtImage}>
          <img src={image} alt="SBT Thumbnail" width="150" height="150" />
        </div>
        <div className={styles.sbtInfo}>
          <p className={styles.sbtName}>
            {name}
            {hasPasswordMint && (
              <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />
            )}
          </p>
          <p className={styles.sbtDescription}>{description}</p>
          <p className={styles.sbtNetMinted}>Net Minted: {netMinted}</p>
        </div>
      </button>
    );
  };

  // Determine root class based on viewMode
  const rootClassName =
    viewMode === 'modal' ? styles.modalViewContainer : styles.standardViewContainer;

  // If mini => render “compact layout”:
  if (miniaturized) {
    // We also show the password lock filter in the modal
    const isModal = (viewMode === 'modal');

    const featuredLowerSet = new Set(featured_SBTs_LIST.map(a => a.toLowerCase()));
    const miniFeatured = sbtList.filter(
      s => s && featuredLowerSet.has(s.sbtAddress.toLowerCase())
    ).filter(s => {
      if (excludePasswordLocked && s.sbtInfo.hasPasswordMint) return false;
      return true;
    });
    const miniMintingLive = mintingLiveList.filter(
      x => !featuredLowerSet.has(x.sbtAddress.toLowerCase())
    );
    const miniExpired = expiredList.filter(
      x => !featuredLowerSet.has(x.sbtAddress.toLowerCase())
    );

    return (
      <div className={`${styles.miniaturizedBase} ${rootClassName}`}>
        {/* In the modal specifically, let’s surface the “Exclude password locked” checkbox */}
        {isModal && (
          <div className={styles.filterContainer}>
            <label className={styles.filterLabel}>
              <input
                type="checkbox"
                checked={excludePasswordLocked}
                onChange={() => setExcludePasswordLocked(!excludePasswordLocked)}
              />
              Exclude Password-Locked SBTs
            </label>
          </div>
        )}

        <h2 className={styles.sectionTitle}>Featured Groups</h2>
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
        ) : (
          <div className={styles.sbtGrid}>
            {miniFeatured.map(sbt => (
              <SBTPage
                key={sbt.sbtAddress.toLowerCase()}
                miniaturized
                miniMintable
                SBTAddress={sbt.sbtAddress}
                account={account}
                provider={provider}
                network={network}
                loginComplete={loginComplete}
                toggleLoginModal={toggleLoginModal}
              />
            ))}
          </div>
        )}

        <h2 className={styles.sectionTitle}>Minting Live</h2>
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
        ) : (
          <div className={styles.sbtGrid}>
            {miniMintingLive.map(renderSBTButton)}
          </div>
        )}

        <h2 className={styles.sectionTitle}>Minting Expired</h2>
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
        ) : (
          <div className={styles.sbtGrid}>
            {miniExpired.map(renderSBTButton)}
          </div>
        )}
      </div>
    );
  }

  // Otherwise (not mini), we do the standard full page layout:
  return (
    <div className={`${styles.standardBase} ${rootClassName}`}>
      <div className={styles.header}>
        <Button
          className={styles.refreshButton}
          onClick={handleRefresh}
          disabled={refreshing}
        >
          <FontAwesomeIcon icon={faSync} spin={refreshing} /> Refresh
        </Button>
        <Button
          className={styles.clearCacheButton}
          onClick={handleClearCache}
          disabled={refreshing}
        >
          <FontAwesomeIcon icon={faTrash} /> Clear Cache
        </Button>
        <Button
          className={styles.expandButton}
          onClick={() => setShowCreateGroup(!showCreateGroup)}
        >
          <FontAwesomeIcon icon={faPlus} />{' '}
          {showCreateGroup ? 'Exit Group Creation' : 'Create Group'}
        </Button>
      </div>

      {/* Filter for password-locked SBTs (always in standard view) */}
      <div className={styles.filterContainer}>
        <label className={styles.filterLabel}>
          <input
            type="checkbox"
            checked={excludePasswordLocked}
            onChange={() => setExcludePasswordLocked(!excludePasswordLocked)}
          />
          Exclude Password-Locked SBTs
        </label>
      </div>

      {showCreateGroup && (
        <CreateGroup
          account={account}
          loginComplete={loginComplete}
          provider={provider}
          toggleLoginModal={toggleLoginModal}
          expanded={showCreateGroup}
          network={network}
        />
      )}

      <h2 className={styles.sectionTitle}>Featured SBTs</h2>
      {renderFeaturedSection()}

      <h2 className={styles.sectionTitle}>Minting Live</h2>
      {loading ? (
        <div className={styles.loadingSection}>
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </div>
      ) : (
        mintingLiveList.map(renderSBTButton)
      )}

      <h2 className={styles.sectionTitle}>Minting Expired</h2>
      {loading ? (
        <div className={styles.loadingSection}>
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </div>
      ) : (
        expiredList.map(renderSBTButton)
      )}
    </div>
  );
};

export default SBTsList;
