import React, { Component } from 'react';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Label,
  Input,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Alert,
  CustomInput
} from 'reactstrap';
import Select from 'react-select';

// Importing styles
import styles from './SurveyTool.module.scss';
import "../../assets/css/m_w.css";
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faCaretUp, faLock, faUnlock, faPlus, faMinus, faCaretDown, faCheck, faTimes, faArrowLeft, faArrowRight, faQuestionCircle, faSpinner, faSearch, faExpand, faExternalLinkAlt, faFilter, faExclamationCircle, faClipboard, faCog } from '@fortawesome/free-solid-svg-icons';
// Components
import AudioInput from './AudioInput.jsx';
import CreateSurvey from './CreateSurvey';
import SurveyResults from './SurveyResults';
import QuestionFilter from './QuestionFilter';
import SingleQuestionResponse from './SingleQuestionResponse';

// Crypto Functionality (encrypt/decrypt) and variables
import { ARWEAVE_ACTIVE } from '../../variables/CONTRACT_ADDRESSES';
import contractScripts from '../Buttons/contractScripts.js';
import { encrypt } from '@metamask/eth-sig-util';
import { ethers, utils } from 'ethers';
import sha256 from 'crypto-js/sha256';
import Slider from 'react-rangeslider';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';

class SurveyTool extends Component {
  constructor(props) {
    super(props);
  
    // State remains unchanged
    this.state = {
      cache: {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {},
      },
      latestBlockNumber: 0,
      events: [],
      showResultsModal: this.props.autoOpenResults || false
    };
  
    // UPDATED: Renamed localStorage key from 'surveyCache' to 'surveysCache'
    this.updateCache = (updater, callback) => {
      if (typeof updater !== 'function') {
        console.error('updateCache expects a function, received:', updater);
        return;
      }
      this.setState((prevState) => {
        const newCache = updater(prevState.cache);
        // Write to 'surveysCache' so that future reads match
        localStorage.setItem('surveysCache', JSON.stringify(newCache));
        return { cache: newCache };
      }, callback);
    };
  }
  

async componentDidMount() {
  // If path does not include survey or question, default to /questions
  if (
    !window.location.pathname.includes('/survey/') &&
    !window.location.pathname.includes('/question/') &&
    !window.location.pathname.includes('/questions') &&
    !window.location.pathname.includes('/surveys')
  ) {
    window.history.pushState({}, '', '/questions');
  }

  // ----------------------------------------------------
  // NEW CHECK: skip calling loadInitialData() if
  //           `cacheHasLoaded` is "true" OR
  //           localStorage has some question data
  // ----------------------------------------------------
  const cacheHasLoaded = (localStorage.getItem('cacheHasLoaded') === 'true');
  const networkID = this.props.network?.id?.toString();
  let skipReInit = false;

  if (cacheHasLoaded && networkID) {
    try {
      const qCacheStr = localStorage.getItem('questionsCache');
      if (qCacheStr) {
        const parsedQCache = JSON.parse(qCacheStr);
        const hasQuestions =
          parsedQCache[networkID] &&
          parsedQCache[networkID].questions &&
          Object.keys(parsedQCache[networkID].questions).length > 0;

        if (hasQuestions) {
          skipReInit = true;
          console.log('SurveyTool - skipping loadInitialData because question cache is already loaded.');
        }
      }
    } catch (err) {
      console.error('Error checking questionsCache in SurveyTool:', err);
    }
  }

  // If no data found, do the original chain fetch
  if (!skipReInit) {
    this.loadInitialData();
  }

    // If no questions yet, do an interval check to see if the local cache updates from MainSite
    this.questionsCheckIntervalCount = 0;
    this.questionsCheckInterval = setInterval(() => {
      this.questionsCheckIntervalCount++;
      const network = this.props.network;
      const networkID = network?.id;
      if (networkID) {
        let questionsCacheStr = localStorage.getItem('questionsCache');
        let questionsCache = {};
        if (questionsCacheStr) {
          try {
            questionsCache = JSON.parse(questionsCacheStr);
          } catch (e) {
            console.error('Error parsing questionsCache, resetting:', e);
            questionsCache = {};
          }
        }
        if (!questionsCache[networkID]) {
          questionsCache[networkID] = {
            questionsLatestBlock: 0,
            questions: {},
            questionResponses: {},
            questionResponsesLatestBlock: 0
          };
        }
        const questionCount = Object.keys(questionsCache[networkID].questions || {}).length;

        // Stop interval if we have some questions loaded
        if (questionCount > 0) {
          clearInterval(this.questionsCheckInterval);
          this.questionsCheckInterval = null;
        } else if (this.questionsCheckIntervalCount > 30) {
          // Stop after ~30 seconds
          clearInterval(this.questionsCheckInterval);
          this.questionsCheckInterval = null;
        }

        // Force a re-render if anything changed
        this.forceUpdate();
      } else {
        if (this.questionsCheckIntervalCount > 30) {
          clearInterval(this.questionsCheckInterval);
          this.questionsCheckInterval = null;
        }
      }
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if cache has changed in a meaningful way
    const cacheChanged =
      this.state.cache.surveyIDs.length !== prevState.cache.surveyIDs.length ||
      Object.keys(this.state.cache.arweaveContent).length !== Object.keys(prevState.cache.arweaveContent).length;

    if (cacheChanged) {
      this.fetchSurveys();
    }
  }

  componentWillUnmount() {
    if (this.questionsCheckInterval) {
      clearInterval(this.questionsCheckInterval);
      this.questionsCheckInterval = null;
    }
  }

  // openResultsModal = () => {
  //   // Decide what the base path should be based on the current "viewMode"
  //   // (assuming you track "viewMode" as either "questions" or "survey").
  //   // If you're not explicitly storing viewMode in state, adapt this check
  //   // to detect question vs. survey mode appropriately.
    
  //   // For example:
  //   let currentPath = window.location.pathname;
  
  //   // Force question paths to "/questions" if we're in questions mode:
  //   if (this.state.viewMode === "questions" && !currentPath.includes("/questions")) {
  //     currentPath = "/questions"; 
  //   }
  
  //   // Now ensure we end with "/results"
  //   if (!currentPath.endsWith("/results")) {
  //     if (!currentPath.endsWith("/")) {
  //       currentPath += "/";
  //     }
  //     currentPath += "results";
  //   }
  
  //   window.history.pushState({}, "", currentPath);
  //   this.setState({ showResultsModal: true });
  // };
  

  closeResultsModal = () => {
    let oldPath = window.location.pathname;
    if (oldPath.endsWith('/results')) {
      const trimmed = oldPath.slice(0, oldPath.length - '/results'.length);
      window.history.pushState({}, '', trimmed);
    }
    this.setState({ showResultsModal: false });
  };

  // Function to fetch responses for all questions
  fetchAllQuestionResponses = async () => {
    const { cache } = this.state;
    const { questionIDs } = cache;

    for (let questionId of questionIDs) {
      const responses = await contractScripts.getResponsesByQuestionID(this.props.provider, questionId);
      this.updateCache((prevCache) => ({
        ...prevCache,
        questionResponses: {
          ...prevCache.questionResponses,
          [questionId]: responses,
        },
      }));
    }
  };

  getSurveyData = async (surveyID) => {
    if (this.props.singleQuestionMode) {
      // Do not fetch survey data in single question mode
      return null;
    }

    console.log('Getting survey data for ID:', surveyID);

    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID is undefined in getSurveyData. Cannot proceed.');
      return null;
    }

    const networkID = this.props.network.id.toString();
    let surveysCacheStr = localStorage.getItem('surveysCache');
    let surveysCache = {};
    if (surveysCacheStr) {
      try {
        surveysCache = JSON.parse(surveysCacheStr);
      } catch (e) {
        console.error('Error parsing surveysCache in getSurveyData:', e);
        surveysCache = {};
      }
    }

    if (!surveysCache[networkID]) {
      surveysCache[networkID] = {
        surveysLatestBlock: 0,
        surveys: {},
        surveyResponses: {},
        surveyResponsesLatestBlock: {}
      };
    }

    let loweredSurveyID = surveyID.toLowerCase();
    let surveyData = surveysCache[networkID].surveys[loweredSurveyID];
    if (!surveyData) {
      // Not in cache, fetch from chain
      surveyData = await contractScripts.getSurveyDataById(this.props.provider, loweredSurveyID);
      if (!surveyData) {
        console.warn(`No survey data found for ID ${surveyID}`);
        return null;
      }
      surveyData.surveyID = loweredSurveyID;
      if (!surveyData.questionIDs) surveyData.questionIDs = [];
      if (!surveyData.creator) surveyData.creator = "";
      surveyData.id = surveyData.surveyID;

      surveysCache[networkID].surveys[loweredSurveyID] = surveyData;
      localStorage.setItem('surveysCache', JSON.stringify(surveysCache));
    }

    // // Ensure questions are also cached
    // for (let questionID of surveyData.questionIDs) {
    //   await this.ensureQuestionCached(questionID);
    // }

    return surveyData;
  };

  loadInitialData = async () => {
    console.log('Loading initial data');
    try {
      // Fetch survey IDs submitted by the user
      const surveyIDs = await contractScripts.fetchUserSubmittedSurveyIDs(this.props.provider);
      console.log('Fetched survey IDs:', surveyIDs);

      // Update cache with survey IDs
      this.updateCache(prevCache => ({
        ...prevCache,
        surveyIDs,
      }));

      // Fetch survey data and related questions
      for (let surveyID of surveyIDs) {
        const surveyData = await this.getSurveyData(surveyID);
        console.log(`Fetched survey data for ID ${surveyID}:`, surveyData);
      }

      // Fetch latest block number (if needed)
      const latestBlockNumber = await contractScripts.getLatestBlockNumber(this.props.provider);
      this.setState({ latestBlockNumber });
      console.log('Latest block number:', latestBlockNumber);
    } catch (error) {
      console.error("Error loading initial data:", error);
    }
  };

  fetchSurveys = async () => {
    console.log('Fetching surveys');
    try {
      if (!this.props.network || !this.props.network.id) {
        console.error('Network ID is undefined in fetchSurveys. Cannot proceed.');
        this.setState({ loading: false });
        return;
      }

      const networkID = this.props.network.id.toString();
      let surveysCacheStr = localStorage.getItem('surveysCache');
      let surveysCache = {};
      if (surveysCacheStr) {
        try {
          surveysCache = JSON.parse(surveysCacheStr);
        } catch (e) {
          console.error('Error parsing surveysCache, resetting:', e);
          surveysCache = {};
        }
      }

      if (!surveysCache[networkID]) {
        surveysCache[networkID] = {
          surveysLatestBlock: 0,
          surveys: {},
          surveyResponses: {},
          surveyResponsesLatestBlock: {}
        };
      }

      const surveyIDs = Object.keys(surveysCache[networkID].surveys);
      if (surveyIDs.length === 0) {
        console.log("No surveys found in cache. Fetching from chain...");
        try {
          // Fetch survey IDs from chain (all user-submitted surveys)
          const fetchedSurveyIDs = await contractScripts.fetchUserSubmittedSurveyIDs(this.props.provider);
          console.log('Fetched survey IDs from chain:', fetchedSurveyIDs);

          for (let surveyID of fetchedSurveyIDs) {
            surveyID = surveyID.toLowerCase();
            if (!surveysCache[networkID].surveys[surveyID]) {
              const surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
              if (surveyData) {
                surveyData.surveyID = surveyID;
                if (!surveyData.questionIDs) surveyData.questionIDs = [];
                if (!surveyData.creator) surveyData.creator = "";
                surveyData.id = surveyData.surveyID;
                surveysCache[networkID].surveys[surveyID] = surveyData;
              } else {
                console.warn(`No survey data found for ID ${surveyID}`);
              }
            }
          }

          localStorage.setItem('surveysCache', JSON.stringify(surveysCache));

        } catch (error) {
          console.error("Error fetching surveys from chain:", error);
        }
      }

      const updatedSurveyIDs = Object.keys(surveysCache[networkID].surveys);
      const userSubmittedSurveys = [];
      const surveySet = new Set();

      for (let surveyID of updatedSurveyIDs) {
        const surveyData = surveysCache[networkID].surveys[surveyID];
        if (surveyData && surveyData.title && surveyData.questionIDs) {
          if (!surveyData.id) {
            surveyData.id = surveyData.surveyID;
          }
          if (!surveySet.has(surveyID)) {
            surveySet.add(surveyID);
            userSubmittedSurveys.push(surveyData);
          }
        }
      }

      this.setState({ surveys: userSubmittedSurveys, loading: false }, this.updateSelectedSurvey);
      console.log('Fetched surveys (from cache or chain):', userSubmittedSurveys);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      this.setState({ loading: false });
    }
  };

  ensureQuestionCached = async (questionId) => {
    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID undefined in ensureQuestionCached');
      return;
    }

    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in ensureQuestionCached:', e);
        questionsCache = {};
      }
    }

    if (!questionsCache[networkID]) {
      questionsCache[networkID] = {
        questionsLatestBlock: 0,
        questions: {},
        questionResponses: {},
        questionResponsesLatestBlock: 0
      };
    }

    const qIdLower = questionId.toLowerCase();
    if (!questionsCache[networkID].questions[qIdLower]) {
      const questionData = await contractScripts.getQuestionData(this.props.provider, qIdLower);
      if (questionData) {
        questionData.id = qIdLower;
        if (!questionData.creator) questionData.creator = "";
        if (!questionData.tags) questionData.tags = [];
        questionsCache[networkID].questions[qIdLower] = questionData;
        localStorage.setItem('questionsCache', JSON.stringify(questionsCache));
        console.log(`Question ${qIdLower} cached.`);
      } else {
        console.warn(`Question data not found for ID: ${qIdLower}`);
      }
    }
  };

  // clearCache = () => {
  //   console.log('Clearing cache');
  //   // UPDATED: Remove the same key we actually use everywhere else: 'surveysCache'
  //   localStorage.removeItem('surveysCache');
  //   this.updateCache(() => ({
  //     surveyIDs: [],
  //     questionIDs: {},
  //     arweaveContent: {},
  //   }));
  //   console.log('Cache cleared. Reloading initial data...');
  //   this.loadInitialData();
  // };
  

  render() {
    // If singleQuestionMode is true, we skip the SurveySelector...
    if (this.props.singleQuestionMode) {
      return (
        <div id={styles.surveySelectorRow}>
          <SurveyQuestions
            questionID={this.props.questionID}
            responderAddress={this.props.responderAddress}
            singleQuestionMode={true}
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            account={this.props.account}
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
            network={this.props.network}
            cache={{}} // We still pass a cache, even if empty
            updateCache={() => {}}
          />
        </div>
      );
    }

    // If not singleQuestionMode, we render the SurveySelector with the newly added props below:
    return (
      <div id={styles.surveySelectorRow}>
        <SurveySelector
          surveyID={this.props.surveyID}
          displayAnswerMode={this.props.displayAnswerMode}
          viewAddress={this.props.viewAddress}
          toggleLoginModal={this.props.toggleLoginModal}
          account={this.props.account}
          provider={this.props.provider}
          joined={this.props.joined}
          XPBalance={this.props.XPBalance}
          ETHbalance={this.props.ETHBalance}
          availableETH={this.props.availableETH}
          lobby={this.props.lobby}
          paid={this.props.paid}
          loginComplete={this.props.loginComplete}
          loginInProgress={this.props.loginInProgress}
          network={this.props.network}
          cache={{}}
          updateCache={() => {}}
          questionID={this.props.questionID}
          responderAddress={this.props.responderAddress}
          singleQuestionMode={false}

          // Pass in the defaultTags from props:
          defaultTags={this.props.defaultTags}

          // Refresh callbacks (if any):
          refreshSurveyResponsesByID={this.props.refreshSurveyResponsesByID}
          refreshQuestionMetadata={this.props.refreshQuestionMetadata}
          refreshQuestionResponses={this.props.refreshQuestionResponses}

          autoOpenResults={this.props.autoOpenResults}
          filterState={this.props.filterState}
          isQuestionCacheReady={this.props.isQuestionCacheReady}
          networkLatestBlock={this.props.networkLatestBlock}
        />

        {/* The SurveyResults modal, controlled by showResultsModal in state */}
        <SurveyResults
          isOpen={this.state.showResultsModal}
          onClose={this.closeResultsModal}
          provider={this.props.provider}
          network={this.props.network}
          // for survey mode, you might pass surveyId or question mode
          surveyId={this.props.surveyID}
          filterState={this.props.filterState}
          refreshSurveyResponsesByID={this.props.refreshSurveyResponsesByID}
          refreshQuestionMetadata={this.props.refreshQuestionMetadata}
          refreshQuestionResponses={this.props.refreshQuestionResponses}
        />
      </div>
    );
  }
}


class SurveySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      selectedSurveyIndex: null,
      pubKey: '',
      createSurveyMode: false,
      demoMode: false,
      showResults: false, // single source of truth for SurveyResults modal
      loading: true,
      filterModalOpen: false,
      viewMode: 'questions', // can be "questions" or "survey"
      selectedTypes: [],
      filteredQuestionCount: 0,
      currentPath: window.location.pathname,
      filterState: {},
      copySurveyIdSuccess: false
    };
  }

  componentDidMount() {
    // If singleQuestionMode is true, user is directly in SurveyQuestions context, no need for normal flows
    if (!this.props.singleQuestionMode) {
      this.handleUrlBasedView();
      this.fetchSurveys();
      this.computeFilteredQuestionCount();
      window.addEventListener('popstate', this.handleUrlChange);
    }
  }

  componentWillUnmount() {
    if (!this.props.singleQuestionMode) {
      window.removeEventListener('popstate', this.handleUrlChange);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // If the local cache changed in a way that modifies number of surveys or arweave content,
    // we re-fetch them:
    const prevSurveyIDs = (prevProps.cache && Array.isArray(prevProps.cache.surveyIDs))
      ? prevProps.cache.surveyIDs
      : [];
    const currentSurveyIDs = (this.props.cache && Array.isArray(this.props.cache.surveyIDs))
      ? this.props.cache.surveyIDs
      : [];
    const prevArweave = (prevProps.cache && prevProps.cache.arweaveContent && typeof prevProps.cache.arweaveContent === 'object')
      ? prevProps.cache.arweaveContent
      : {};
    const currentArweave = (this.props.cache && this.props.cache.arweaveContent && typeof this.props.cache.arweaveContent === 'object')
      ? this.props.cache.arweaveContent
      : {};

    const cacheChanged =
      currentSurveyIDs.length !== prevSurveyIDs.length ||
      Object.keys(currentArweave).length !== Object.keys(prevArweave).length;

    if (cacheChanged) {
      this.fetchSurveys();
    }
  }

  handleUrlChange = () => {
    this.handleUrlBasedView();
  };

  /**
   * Choose whether we are in "questions" or "survey" mode
   * based on the path: 
   *   - /questions -> "questions"
   *   - /surveys or /survey/ -> "survey"
   */
  handleUrlBasedView = () => {
    const path = window.location.pathname;
  
    // First, check if path includes "/questions"
    if (path.includes("/questions")) {
      this.setState({ viewMode: "questions" });
    }
    // Else, fall back to checking for "/surveys" or "/survey/"
    else if (path === "/surveys" || path.includes("/survey/")) {
      this.setState({ viewMode: "survey" });
    }
  };

  computeFilteredQuestionCount = () => {
    if (!this.props.network || !this.props.network.id) {
      this.setState({ filteredQuestionCount: 0 });
      return;
    }
    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache:', e);
        questionsCache = {};
      }
    }

    if (!questionsCache[networkID]) {
      this.setState({ filteredQuestionCount: 0 });
      return;
    }

    const questionIDs = Object.keys(questionsCache[networkID].questions || {});
    this.setState({ filteredQuestionCount: questionIDs.length });
  };

  handleFilteredQuestionsWithState = (filteredQuestions, filterState) => {
    // store filterState so we can pass it to SurveyResults if user opens it
    this.setState({ filterState });
  };

  async fetchSurveys() {
    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID is undefined in fetchSurveys. Cannot proceed.');
      this.setState({ loading: false });
      return;
    }

    const networkID = this.props.network.id.toString();
    let surveysCacheStr = localStorage.getItem('surveysCache');
    let surveysCache = {};
    if (surveysCacheStr) {
      try {
        surveysCache = JSON.parse(surveysCacheStr);
      } catch (e) {
        console.error('Error parsing surveysCache, resetting:', e);
        surveysCache = {};
      }
    }

    if (!surveysCache[networkID]) {
      surveysCache[networkID] = {
        lastBlock: 0,
        surveys: {},
        surveyResponses: {},
        surveyResponsesLastBlock: {}
      };
    }

    // If we have no known surveys in local cache, try chain fetch
    const surveyIDs = Object.keys(surveysCache[networkID].surveys);
    if (surveyIDs.length === 0) {
      try {
        // Fetch all user-submitted surveyIDs from chain
        const fetchedSurveyIDs = await contractScripts.fetchUserSubmittedSurveyIDs(this.props.provider);
        for (let sid of fetchedSurveyIDs) {
          sid = sid.toLowerCase();
          if (!surveysCache[networkID].surveys[sid]) {
            const sData = await contractScripts.getSurveyDataById(this.props.provider, sid);
            if (sData) {
              sData.surveyID = sid;
              if (!sData.questionIDs) sData.questionIDs = [];
              if (!sData.creator) sData.creator = "";
              sData.id = sData.surveyID;
              surveysCache[networkID].surveys[sid] = sData;
            }
          }
        }
        localStorage.setItem('surveysCache', JSON.stringify(surveysCache));
      } catch (error) {
        console.error("Error fetching surveys from chain:", error);
      }
    }

    // Build up userSubmittedSurveys from cache
    const updatedSurveyIDs = Object.keys(surveysCache[networkID].surveys);
    const userSubmittedSurveys = [];
    const seen = new Set();

    for (let sid of updatedSurveyIDs) {
      const sData = surveysCache[networkID].surveys[sid];
      if (sData && sData.title && sData.questionIDs) {
        if (!sData.id) {
          sData.id = sData.surveyID;
        }
        if (!seen.has(sid)) {
          seen.add(sid);
          userSubmittedSurveys.push(sData);
        }
      }
    }

    this.setState({ surveys: userSubmittedSurveys, loading: false }, this.updateSelectedSurvey);
  }

  updateSelectedSurvey = () => {
    const { surveyID } = this.props;
    const { surveys } = this.state;
    const path = window.location.pathname;

    // If path == '/surveys', then we might show the first or none
    if (path === '/surveys') {
      this.setState({ selectedSurveyIndex: null });
      return;
    }

    const loweredSurveyID = surveyID ? surveyID.toLowerCase() : null;
    const idx = surveys.findIndex(s => s.id === loweredSurveyID);
    if (idx !== -1) {
      this.setState({ selectedSurveyIndex: idx });
    } else if (surveys.length > 0 && path === '/surveys') {
      this.setState({ selectedSurveyIndex: 0 }, () => {
        this.updateURL(surveys[0].id);
      });
    } else {
      this.setState({ selectedSurveyIndex: null });
    }
  };

  selectSurvey = (index) => {
    this.setState({ selectedSurveyIndex: index, viewMode: 'survey' }, () => {
      const newSurveyId = this.state.surveys[index].id;
      window.history.pushState({}, '', `/survey/${newSurveyId}`);
      if (this.props.onSurveyChange) {
        this.props.onSurveyChange(newSurveyId);
      }
    });
  };

  updateURL = (surveyId) => {
    if (this.props.displayAnswerMode && this.props.viewAddress) {
      window.history.pushState({}, '', `/survey/${surveyId}/${this.props.viewAddress}`);
    } else {
      window.history.pushState({}, '', `/survey/${surveyId}`);
    }
    if (this.props.onSurveyChange) {
      this.props.onSurveyChange(surveyId);
    }
  };

  /**
   * Called from the dropdown items to switch to "questions" or select a survey by index
   */
  selectOption = (option) => {
    if (option === 'questions') {
      this.setState({ viewMode: 'questions' }, () => {
        window.history.pushState({}, '', '/questions');
      });
    } else {
      // Otherwise, 'option' is an index in the surveys array
      this.selectSurvey(option);
    }
  };

  toggleFilterModal = () => {
    this.setState(prevState => ({ filterModalOpen: !prevState.filterModalOpen }));
  };

  toggleCreateMode = () => {
    this.setState(prevState => ({
      createSurveyMode: !prevState.createSurveyMode
    }));
  };

  toggleShowResults = () => {
    this.setState((prevState) => {
      const newShowResults = !prevState.showResults;
  
      // If we're turning results ON:
      if (newShowResults) {
        let currentPath = window.location.pathname;
  
        // If in questions mode but path doesn't include "/questions", force it
        if (this.state.viewMode === "questions" && !currentPath.includes("/questions")) {
          currentPath = "/questions";
        }
  
        // Append "/results" if not already present
        if (!currentPath.endsWith("/results")) {
          if (!currentPath.endsWith("/")) {
            currentPath += "/";
          }
          currentPath += "results";
        }
        window.history.pushState({}, "", currentPath);
      }
      // If we're turning results OFF:
      else {
        let oldPath = window.location.pathname;
        if (oldPath.includes("/results")) {
          // Remove "/results", trim extra slashes
          let trimmed = oldPath.replace("/results", "").replace(/\/+$/, "");
  
          // If that left nothing, pick a reasonable fallback:
          if (!trimmed) {
            if (this.state.viewMode === "questions") {
              trimmed = "/questions";
            } else if (this.props.surveyID) {
              trimmed = `/survey/${this.props.surveyID}`;
            } else {
              trimmed = "/questions";
            }
          }
          window.history.pushState({}, "", trimmed);
        }
      }
  
      // Finally, toggle the showResults state
      return { showResults: newShowResults };
    });
  };
  

  handleUpdateSelectedTypes = (selectedTypes) => {
    this.setState({ selectedTypes });
  };

  copySurveyIdToClipboard = (surveyID = null) => {
    const { surveys, selectedSurveyIndex } = this.state;
    let idToCopy = surveyID;

    if (!idToCopy) {
      const urlParams = new URLSearchParams(window.location.search);
      idToCopy = urlParams.get('surveyID');
    }
    if (!idToCopy && selectedSurveyIndex !== null && surveys[selectedSurveyIndex]) {
      idToCopy = surveys[selectedSurveyIndex].id;
    }

    if (idToCopy) {
      navigator.clipboard
        .writeText(idToCopy)
        .then(() => {
          this.setState({ copySurveyIdSuccess: true });
          setTimeout(() => {
            this.setState({ copySurveyIdSuccess: false });
          }, 2000);
        })
        .catch(err => {
          console.error('Could not copy survey ID:', err);
        });
    } else {
      console.error('No survey ID available to copy.');
    }
  };

  render() {
    const {
      surveys,
      selectedSurveyIndex,
      loading,
      viewMode,
      createSurveyMode,
      filterModalOpen,
      filteredQuestionCount,
      showResults
    } = this.state;

    const selectedSurvey =
      selectedSurveyIndex !== null && surveys[selectedSurveyIndex]
        ? surveys[selectedSurveyIndex]
        : null;

    const dropdownTitle = loading
      ? 'Loading...'
      : viewMode === 'questions'
        ? `Questions (${filteredQuestionCount})`
        : (selectedSurvey ? selectedSurvey.title : 'Select survey');

    const createSurveyIcon = createSurveyMode ? faMinus : faPlus;

    return (
      <div>
        <div id={styles.surveysRow}>
          <UncontrolledDropdown id={styles.surveysDropdown}>
            <DropdownToggle id={styles.dropdownToggle}>
              {dropdownTitle}
              {!loading && (
                <FontAwesomeIcon icon={faCaretDown} id={styles.dropdownToggleCaret} />
              )}
            </DropdownToggle>
            {!loading && (
              <DropdownMenu id={styles.dropdownMenu}>
                <DropdownItem
                  className={`${styles.dropdownItem} ${styles.questionsItem}`}
                  onClick={() => this.selectOption('questions')}
                  active={viewMode === 'questions'}
                >
                  Questions ({filteredQuestionCount})
                </DropdownItem>
                {surveys.map((survey, index) => (
                  <DropdownItem
                    className={`${styles.dropdownItem} ${styles.surveyItem}`}
                    key={index}
                    onClick={() => this.selectOption(index)}
                    active={viewMode === 'survey' && selectedSurveyIndex === index}
                  >
                    {survey.title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            )}
          </UncontrolledDropdown>

          {viewMode === 'questions' && (
            <Button id={styles.filterButton} onClick={this.toggleFilterModal}>
              Filter <FontAwesomeIcon icon={faFilter} />
            </Button>
          )}

          <Button onClick={this.toggleShowResults} id={styles.showResultsButton}>
            Results
          </Button>

          <button
            id={styles.createSurveyButton}
            onClick={this.toggleCreateMode}
            style={{ marginLeft: '10px' }}
          >
            <FontAwesomeIcon icon={createSurveyIcon} />
          </button>
        </div>

        {createSurveyMode && (
          <CreateSurvey
            account={this.props.account}
            loginComplete={this.props.loginComplete}
            provider={this.props.provider}
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            expanded={createSurveyMode}
            surveys={surveys}
            surveyIndex={selectedSurveyIndex}
            cache={this.props.cache}
            updateCache={this.props.updateCache}

            /* NEW LINE: Forward defaultTags to CreateSurvey */
            defaultTags={this.props.defaultTags}
          />
        )}

        {/* If we are not in "questions" mode, and we do have a selected survey, render its Q/A interface */}
        {viewMode !== 'questions' && selectedSurvey && (
          <SurveyQuestions
            displayAnswerMode={this.props.displayAnswerMode}
            viewAddress={this.props.viewAddress}
            account={this.props.account}
            network={this.props.network}
            provider={this.props.provider}
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            surveys={surveys}
            loginComplete={this.props.loginComplete}
            pubKey={this.state.pubKey}
            updatePubKey={(newPubkey) => this.setState({ pubKey: newPubkey })}
            surveyIndex={this.state.selectedSurveyIndex}
            surveyID={selectedSurvey.id}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
          />
        )}

        {/* The SurveyResults modal, controlling open/close via showResults */}
        {showResults && (
          <SurveyResults
            isOpen={showResults}
            onClose={this.toggleShowResults}
            provider={this.props.provider}
            network={this.props.network}
            surveyId={selectedSurvey?.id}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
            viewMode={viewMode}
            filterState={this.state.filterState}
            refreshSurveyResponsesByID={this.props.refreshSurveyResponsesByID}
            refreshQuestionMetadata={this.props.refreshQuestionMetadata}
            refreshQuestionResponses={this.props.refreshQuestionResponses}
          />
        )}

        {viewMode === 'questions' && (
          <QuestionsDashboard
            account={this.props.account}
            provider={this.props.provider}
            network={this.props.network}
            toggleLoginModal={this.props.toggleLoginModal}
            loginComplete={this.props.loginComplete}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
            onFilteredQuestionCountUpdate={(count) => this.setState({ filteredQuestionCount: count })}
            filterModalOpen={filterModalOpen}
            toggleFilterModal={this.toggleFilterModal}
            handleFilteredQuestionsWithState={this.handleFilteredQuestionsWithState}
          />
        )}
      </div>
    );
  }
}

class QuestionsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      filteredQuestions: [],
      filterLoading: false,
    };
  }

  componentDidMount() {
    this.loadQuestions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cache !== this.props.cache) {
      this.loadQuestions();
    }
  }

  loadQuestions = () => {
    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID is undefined in loadQuestions.');
      this.setState({ questions: [], filteredQuestions: [] }, () => {
        if (this.props.onFilteredQuestionCountUpdate) {
          this.props.onFilteredQuestionCountUpdate(0);
        }
      });
      return;
    }
  
    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in loadQuestions:', e);
        questionsCache = {};
      }
    }
  
    if (!questionsCache[networkID]) {
      this.setState({ questions: [], filteredQuestions: [] }, () => {
        if (this.props.onFilteredQuestionCountUpdate) {
          this.props.onFilteredQuestionCountUpdate(0);
        }
      });
      return;
    }
  
    const questionsData = questionsCache[networkID].questions || {};
    const questions = Object.keys(questionsData).map(qId => {
      const q = questionsData[qId];
      return {
        ...q,
        id: qId,
        creator: q.creator || '',
        tags: q.tags || []
      };
    });
  
    this.setState({
      questions,
      filteredQuestions: questions
    }, () => {
      if (this.props.onFilteredQuestionCountUpdate) {
        this.props.onFilteredQuestionCountUpdate(questions.length);
      }
    });
  };
  

  handleFilteredQuestions = (filteredQuestions, filterState) => {
    this.setState({ filteredQuestions }, () => {
      if (this.props.onFilteredQuestionCountUpdate) {
        this.props.onFilteredQuestionCountUpdate(filteredQuestions.length);
      }
      // Pass filterState up the chain as well if needed
      // We'll call the parent callback with both filteredQuestions and filterState
      if (this.props.handleFilteredQuestionsWithState) {
        this.props.handleFilteredQuestionsWithState(filteredQuestions, filterState);
      }
    });
  };  

  setFilterLoading = (loading) => {
    this.setState({ filterLoading: loading });
  };

  render() {
    const { filteredQuestions, filterLoading } = this.state;

    return (
      <div className={styles.questionsDashboard}>
        <div className={styles.questionsHeader}>
          {/* You can uncomment this if you want a filter button outside the modal */}
          {/* <Button id={styles.filterButton} onClick={this.props.toggleFilterModal}>
            FILTER QUESTIONS <FontAwesomeIcon icon={faFilter} />
          </Button> */}
        </div>

        <QuestionFilter
          filterModalOpen={this.props.filterModalOpen}
          toggleFilterModal={this.props.toggleFilterModal}
          questions={this.state.questions}
          questionResponses={this.props.cache.questionResponses}
          provider={this.props.provider}
          network={this.props.network}
          onFilter={this.handleFilteredQuestions}
          setFilterLoading={this.setFilterLoading}
        />

        {filterLoading ? (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <p>Applying filter...</p>
          </div>
        ) : (
          <SurveyQuestions
            account={this.props.account}
            provider={this.props.provider}
            toggleLoginModal={this.props.toggleLoginModal}
            loginComplete={this.props.loginComplete}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
            questionPool={filteredQuestions}
            isStandalone={true}
          />
        )}
      </div>
    );
  }
}

class SurveyQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysResponseState: [],
      displayAnswerMode: this.props.displayAnswerMode,
      viewAddressAnswers: '',
      noResponse: false,
      userHasResponse: false,
      userResponseEncrypted: false,
      startFresh: false,
      userAnswers: null,
      isDecrypting: false,
      jsonPreview: '',
      isEditing: false,
      isSubmitting: false,
      submitProgress: 0,
      submissionComplete: false,
      responseUrl: '',
      submissionError: '',
      currentStep: 0,
      questionPool: this.props.isStandalone || this.props.singleQuestionMode ? this.props.questionPool || [] : [],
      showJson: false,
      showQuestionsJson: false,
      showResponseJson: false,
      copiedQuestionsJson: false,
      copiedResponseJson: false,
      isLoadingResponse: false,
      parsedViewAddressAnswers: null,
    };
  this.bottomRef = React.createRef();
  this.topRef = React.createRef();
  }
  
  async componentDidMount() {
    if (this.props.singleQuestionMode) {
      await this.fetchSingleQuestionData();
      this.updateJsonPreview();
      if (this.props.responderAddress) {
        // Start by viewing the responder's answer immediately if responderAddress is provided
        this.setState({
          displayAnswerMode: true,
          isEditing: false
        }, async () => {
          // Check if the user matches the responderAddress and has a response
          if (this.props.account && this.props.account.toLowerCase() === this.props.responderAddress.toLowerCase()) {
            // If userHasResponse is already set after fetchSingleQuestionData(), show edit/fresh UI
            if (this.state.userHasResponse) {
              // We already show decrypt/edit or start fresh buttons in render method if userHasResponse is true.
            }
          }
        });
      } else {
        // If no responderAddress, default to not viewing answers
        this.setState({
          displayAnswerMode: this.props.displayAnswerMode
        });
      }
    } else if (!this.props.isStandalone) {
      await this.fetchQuestionPool();
      this.setState(
        {
          surveysResponseState: this.initializeSurveyResponseState(),
        },
        async () => {
          await this.fetchSurveyResponse();
          this.checkAndHandleStartFresh();
        }
      );
    } else {
      // isStandalone mode
      this.setState({ jsonPreview: this.prepareJsonAndHash(0) });
    }
  }
  
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.props.singleQuestionMode) {
      if (
        prevProps.questionID !== this.props.questionID ||
        prevProps.responderAddress !== this.props.responderAddress
      ) {
        await this.fetchSingleQuestionData();
        this.updateJsonPreview();
      }
      if (this.props.account !== prevProps.account) {
        await this.fetchSingleQuestionData();
        this.updateJsonPreview();
  
        if (this.state.userHasResponse) {
          // Automatically switch to editing mode
          this.setState({
            displayAnswerMode: false,
            isEditing: true,
          });
        }
      }
      if (prevState.questionPool !== this.state.questionPool) {
        this.setState({
          surveysResponseState: this.initializeSurveyResponseState(),
        });
      }
    } else if (this.props.isStandalone) {
      if (prevProps.questionPool !== this.props.questionPool) {
        this.setState(
          (prevState) => ({
            questionPool: this.props.questionPool || [],
            surveysResponseState: this.mergeSurveyResponseState(
              prevState.surveysResponseState,
              this.props.questionPool
            ),
          }),
          () => {
            this.setState({ jsonPreview: this.prepareJsonAndHash(0) });
          }
        );
      }
    } else {
      if (this.props.surveyID !== prevProps.surveyID) {
        await this.fetchQuestionPool();
        // Initialize surveysResponseState after questionPool is fetched
        this.setState(
          {
            surveysResponseState: this.initializeSurveyResponseState(),
          },
          async () => {
            await this.fetchSurveyResponse();
            this.checkAndHandleStartFresh();
          }
        );
      }
      if (
        this.props.account !== prevProps.account ||
        this.props.viewAddress !== prevProps.viewAddress
      ) {
        await this.fetchSurveyResponse();
      }
    }
  }

  initializeSurveyResponseState = () => {
    if (this.props.singleQuestionMode) {
      // Initialize state for single question mode
      const questionId = this.props.questionID;
      let initialAnswers = {};
      let initialAdditionalThoughts = {};
  
      if (this.props.questionPool && this.props.questionPool.length > 0) {
        // Use questionPool to initialize
        this.props.questionPool.forEach((question) => {
          initialAnswers[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
          initialAdditionalThoughts[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
        });
      } else if (questionId) {
        initialAnswers[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
        initialAdditionalThoughts[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
      }
  
      return [
        {
          answers: initialAnswers,
          importance: {},
          additionalComments: initialAdditionalThoughts,
        },
      ];
    } else if (this.props.isStandalone) {
      // Existing logic for standalone mode
      let initialAnswers = {};
      let initialAdditionalThoughts = {};
  
      if (this.props.questionPool && Array.isArray(this.props.questionPool)) {
        this.props.questionPool.forEach((question) => {
          initialAnswers[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
          initialAdditionalThoughts[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
        });
      }
  
      return [
        {
          answers: initialAnswers,
          importance: {},
          additionalComments: initialAdditionalThoughts,
        },
      ];
    } else {
      // Original logic for surveys with multiple questions
      const surveyIndex = this.props.surveyIndex;
      let initialAnswers = {};
      let initialAdditionalThoughts = {};
  
      if (this.state.questionPool && Array.isArray(this.state.questionPool)) {
        this.state.questionPool.forEach((question) => {
          initialAnswers[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
          initialAdditionalThoughts[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
        });
      }
  
      const newSurveysResponseState = [...this.state.surveysResponseState || []];
  
      // Ensure the array is long enough
      while (newSurveysResponseState.length <= surveyIndex) {
        newSurveysResponseState.push(null);
      }
  
      newSurveysResponseState[surveyIndex] = {
        answers: initialAnswers,
        importance: {},
        additionalComments: initialAdditionalThoughts,
      };
  
      return newSurveysResponseState;
    }
  };
  
  checkAndHandleStartFresh = () => {
    if (!this.props.viewAddress && !this.state.userHasResponse) {
      this.handleStartFresh();
    }
  };

  /**
   * fetchQuestionPool()
   * 
   * For a given surveyID, assemble the local list of question objects.
   * We do NOT re-fetch or overwrite the entire question cache. 
   * Instead, we rely on MainSite.jsx -> initializeQuestionCache for the heavy lifting.
   */
  async fetchQuestionPool() {
    console.log("fetchQuestionPool() - invoked");

    // If singleQuestionMode or standalone, skip this (already handled by ensureQuestionCached)
    if (this.props.isStandalone || this.props.singleQuestionMode) {
      return;
    }

    const { surveyID } = this.props;
    if (!surveyID) {
      console.warn("No surveyID provided, skipping fetchQuestionPool.");
      return;
    }

    if (!this.props.network || !this.props.network.id) {
      console.error("Network ID is undefined in fetchQuestionPool. Cannot proceed.");
      return;
    }

    // Load from local storage only
    const networkID = this.props.network.id.toString();
    let surveysCacheStr = localStorage.getItem("surveysCache");
    let surveysCache = {};
    if (surveysCacheStr) {
      try {
        surveysCache = JSON.parse(surveysCacheStr);
      } catch (e) {
        console.error("Error parsing surveysCache in fetchQuestionPool:", e);
        surveysCache = {};
      }
    }

    if (
      !surveysCache[networkID] ||
      !surveysCache[networkID].surveys ||
      !surveysCache[networkID].surveys[surveyID.toLowerCase()]
    ) {
      console.warn("Survey not found in local surveysCache for ID:", surveyID);
      return;
    }

    const surveyData = surveysCache[networkID].surveys[surveyID.toLowerCase()];
    if (!surveyData.questionIDs) {
      console.warn("No questionIDs found in the survey data for ID:", surveyID);
      return;
    }

    // Now gather local question objects
    let questionsCacheStr = localStorage.getItem("questionsCache");
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error("Error parsing questionsCache in fetchQuestionPool:", e);
        questionsCache = {};
      }
    }

    if (!questionsCache[networkID] || !questionsCache[networkID].questions) {
      console.warn("No questions found in questionsCache for network", networkID);
      return;
    }

    const questionPool = [];
    for (let questionId of surveyData.questionIDs) {
      const lowerId = questionId.toLowerCase();
      const cachedQ = questionsCache[networkID].questions[lowerId];
      if (cachedQ) {
        questionPool.push({ ...cachedQ, id: lowerId });
      } else {
        console.log(`Question ${lowerId} not found in local cache, skipping.`);
      }
    }

    this.setState({ questionPool }, () => {
      console.log("fetchQuestionPool() - questionPool set:", this.state.questionPool);
    });
  }
 

  ensureQuestionCached = async (questionId) => {
    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID undefined in ensureQuestionCached');
      return;
    }
  
    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in ensureQuestionCached:', e);
        questionsCache = {};
      }
    }
  
    if (!questionsCache[networkID]) {
      questionsCache[networkID] = {
        lastBlock: 0,
        questions: {},
        questionResponses: {},
        questionResponsesLastBlock: {}
      };
    }
  
    const qIdLower = questionId.toLowerCase();
    if (!questionsCache[networkID].questions[qIdLower]) {
      // Not in cache, fetch from chain
      const questionData = await contractScripts.getQuestionData(this.props.provider, qIdLower);
      if (questionData) {
        questionData.id = qIdLower;
        if (!questionData.creator) questionData.creator = "";
        if (!questionData.tags) questionData.tags = [];
        questionsCache[networkID].questions[qIdLower] = questionData;
        localStorage.setItem('questionsCache', JSON.stringify(questionsCache));
        console.log(`Question ${qIdLower} cached.`);
      } else {
        console.warn(`Question data not found for ID: ${qIdLower}`);
      }
    }
  }; 

  async fetchSingleQuestionData() {
    let questionId = this.props.questionID;
    if (!questionId) {
      console.warn("No questionID provided in singleQuestionMode.");
      this.setState({ isLoadingResponse: false });
      return;
    }
    questionId = questionId.toLowerCase();
  
    const responderAddress = this.props.responderAddress;
  
    // Ensure network available
    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID undefined in fetchSingleQuestionData');
      this.setState({ isLoadingResponse: false });
      return;
    }
  
    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in fetchSingleQuestionData:', e);
        questionsCache = {};
      }
    }
  
    if (!questionsCache[networkID]) {
      questionsCache[networkID] = {
        lastBlock: 0,
        questions: {},
        questionResponses: {},
        questionResponsesLatestBlock: 0
      };
    }
  
    // Load question data from cache or fetch if missing
    let qData = questionsCache[networkID].questions[questionId];
    if (!qData) {
      console.warn(`Question data not found in cache for: ${questionId}. Fetching from chain...`);
      await this.ensureQuestionCached(questionId);
  
      // Reload cache from localStorage after attempting the fetch
      questionsCacheStr = localStorage.getItem('questionsCache');
      if (questionsCacheStr) {
        try {
          questionsCache = JSON.parse(questionsCacheStr);
        } catch (err) {
          console.error('Error parsing questionsCache after chain fetch:', err);
          questionsCache = {};
        }
      }
  
      // Check again
      qData =
        questionsCache[networkID] &&
        questionsCache[networkID].questions &&
        questionsCache[networkID].questions[questionId]
          ? questionsCache[networkID].questions[questionId]
          : null;
  
      if (!qData) {
        console.warn(`Still no question data found for: ${questionId} after chain fetch. Aborting.`);
        this.setState({ isLoadingResponse: false });
        return;
      }
    }
  
    // Set the questionPool to this single question
    this.setState(
      {
        questionPool: [{ ...qData, id: qData.id }],
        surveysResponseState: this.initializeSurveyResponseState()
      },
      async () => {
        // If we have a responderAddress, check their response
        if (responderAddress) {
          // Show "Checking For User response..." only if we actually have a user address
          this.setState({ isLoadingResponse: true });
  
          // Check local cache first
          let existingResponse = null;
          if (
            questionsCache[networkID].questionResponses &&
            questionsCache[networkID].questionResponses[questionId] &&
            questionsCache[networkID].questionResponses[questionId][responderAddress.toLowerCase()]
          ) {
            existingResponse =
              questionsCache[networkID].questionResponses[questionId][
                responderAddress.toLowerCase()
              ];
          }
  
          if (!existingResponse) {
            // Not in local cache, fetch from chain
            const fetchedResponse = await contractScripts.getResponse(
              this.props.provider,
              responderAddress,
              questionId
            );
            if (fetchedResponse) {
              if (!questionsCache[networkID].questionResponses[questionId]) {
                questionsCache[networkID].questionResponses[questionId] = {};
              }
              questionsCache[networkID].questionResponses[questionId][
                responderAddress.toLowerCase()
              ] = fetchedResponse;
              localStorage.setItem('questionsCache', JSON.stringify(questionsCache));
              existingResponse = fetchedResponse;
            }
          }
  
          if (existingResponse) {
            this.setState({
              viewAddressAnswers: JSON.stringify(existingResponse),
              parsedViewAddressAnswers: existingResponse,
              noResponse: false
            });
          } else {
            this.setState({
              viewAddressAnswers: '',
              parsedViewAddressAnswers: null,
              noResponse: true
            });
          }
  
          // Fetch current user's answer if logged in & addresses match
          if (
            this.props.account &&
            this.props.account.toLowerCase() === responderAddress.toLowerCase()
          ) {
            const userAnswer = existingResponse ? existingResponse : null;
            if (userAnswer) {
              const hasEncryptedPortion =
                userAnswer.answer.encryptedPortion && userAnswer.answer.encryptedPortion !== '';
              this.setState({
                userHasResponse: true,
                userResponseEncrypted: hasEncryptedPortion,
                startFresh: false,
                userAnswers: userAnswer
              });
              this.prefillSingleQuestionResponse(userAnswer);
            } else {
              this.setState({
                userHasResponse: false,
                userResponseEncrypted: false,
                userAnswers: null
              });
            }
          }
  
          this.setState({ isLoadingResponse: false });
        } else {
          // No responderAddress; just show the question
          // Fetch current user's answer if account logged in
          if (this.props.account) {
            // Check if user has previously answered
            const userResponsesForQ =
              questionsCache[networkID].questionResponses[questionId];
            let userAnswer = null;
            if (userResponsesForQ && userResponsesForQ[this.props.account.toLowerCase()]) {
              userAnswer = userResponsesForQ[this.props.account.toLowerCase()];
            } else {
              // Not in cache, attempt chain fetch
              const fetchedResponse = await contractScripts.getResponse(
                this.props.provider,
                this.props.account,
                questionId
              );
              if (fetchedResponse) {
                if (!questionsCache[networkID].questionResponses[questionId]) {
                  questionsCache[networkID].questionResponses[questionId] = {};
                }
                questionsCache[networkID].questionResponses[questionId][
                  this.props.account.toLowerCase()
                ] = fetchedResponse;
                localStorage.setItem('questionsCache', JSON.stringify(questionsCache));
                userAnswer = fetchedResponse;
              }
            }
  
            if (userAnswer) {
              const hasEncryptedPortion =
                userAnswer.answer.encryptedPortion && userAnswer.answer.encryptedPortion !== '';
              this.setState({
                userHasResponse: true,
                userResponseEncrypted: hasEncryptedPortion,
                startFresh: false,
                userAnswers: userAnswer
              });
              this.prefillSingleQuestionResponse(userAnswer);
            } else {
              this.setState({
                userHasResponse: false,
                userResponseEncrypted: false,
                userAnswers: null
              });
            }
          }
  
          // Not checking another responder, so isLoadingResponse = false
          this.setState({ isLoadingResponse: false });
        }
  
        this.updateJsonPreview();
      }
    );
  }
  
  
  loadQuestionFromCache = async (questionId) => {
    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID undefined in loadQuestionFromCache');
      return null;
    }
  
    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in loadQuestionFromCache:', e);
        questionsCache = {};
      }
    }
  
    if (!questionsCache[networkID]) return null;
  
    const qIdLower = questionId.toLowerCase();
    return questionsCache[networkID].questions[qIdLower] || null;
  };
  

  mergeSurveyResponseState = (currentState, newQuestionPool) => {
    const surveyIndex = 0;
    const currentSurveyResponse = currentState[surveyIndex] || {
      answers: {},
      importance: {},
      additionalComments: {}
    };
  
    const newAnswers = { ...currentSurveyResponse.answers };
    const newAdditionalComments = { ...currentSurveyResponse.additionalComments };
    const newImportance = { ...currentSurveyResponse.importance };
  
    newQuestionPool.forEach((question) => {
      const questionId = question.id;
      if (!(questionId in newAnswers)) {
        newAnswers[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
      }
      if (!(questionId in newAdditionalComments)) {
        newAdditionalComments[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
      }
    });
  
    return [{
      answers: newAnswers,
      importance: newImportance,
      additionalComments: newAdditionalComments
    }];
  };
  
  async fetchSurveyResponse() {
    this.setState({ isLoadingResponse: true });

    if (this.props.displayAnswerMode && this.props.viewAddress) {
      try {
        const viewAnswers = await this.getSurveyResponse(
          this.props.viewAddress,
          this.props.surveyID
        );
        if (viewAnswers) {
          this.setState({
            viewAddressAnswers: JSON.stringify(viewAnswers),
            parsedViewAddressAnswers: viewAnswers, // store parsed data
            noResponse: false,
          });
        } else {
          this.setState({
            viewAddressAnswers: '',
            parsedViewAddressAnswers: null,
            noResponse: true,
          });
        }
      } catch (error) {
        console.error('Error fetching survey response:', error);
        this.setState({
          viewAddressAnswers: '',
          parsedViewAddressAnswers: null,
          noResponse: true,
        });
      }
    } else {
      this.setState({
        viewAddressAnswers: '',
        parsedViewAddressAnswers: null,
        noResponse: false,
      });
    }

    if (this.props.account) {
      try {
        const userAnswers = await this.getSurveyResponse(this.props.account, this.props.surveyID);
        if (userAnswers) {
          const hasEncryptedPortion = userAnswers.responses.some(
            (r) => r.answer.encryptedPortion && r.answer.encryptedPortion !== ''
          );
          this.setState({
            userHasResponse: true,
            userResponseEncrypted: hasEncryptedPortion,
            startFresh: false,
            userAnswers: userAnswers,
          });
          this.prefillSurveyResponses(userAnswers);
        } else {
          this.setState({
            userHasResponse: false,
            userResponseEncrypted: false,
            userAnswers: null,
          });
        }
      } catch (error) {
        console.error("Error fetching user's survey response:", error);
        this.setState({
          userHasResponse: false,
          userResponseEncrypted: false,
          userAnswers: null,
        });
      }
    }

    this.setState({ isLoadingResponse: false });
  } 
  
  getQuestionResponse = async (responderAddress, questionId) => {
    console.log("getQuestionResponse() - invoked with questionId:", questionId);

    const questionAnswer = await contractScripts.getResponse(this.props.provider, responderAddress, questionId);

    console.log("questionAnswer: ", questionAnswer);

    return questionAnswer;
  };
  
  prefillSingleQuestionResponse = (userAnswer) => {
    const surveyIndex = 0;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    const currentSurvey = newSurveysResponseState[surveyIndex];
  
    const questionId = this.props.questionID;
    currentSurvey.answers[questionId] = {
      value: this.parseAnswerValue(userAnswer.answer.value),
      encrypted: userAnswer.answer.encrypted,
      hash: userAnswer.answer.hash || '',
      encryptedPortion: userAnswer.answer.encryptedPortion || ''
    };
    currentSurvey.importance[questionId] = userAnswer.importance;
    currentSurvey.additionalComments[questionId] = {
      value: this.parseAnswerValue(userAnswer.additional.value),
      encrypted: userAnswer.additional.encrypted,
      hash: userAnswer.additional.hash || '',
      encryptedPortion: userAnswer.additional.encryptedPortion || ''
    };
  
    this.setState({
      surveysResponseState: newSurveysResponseState,
    }, () => {
      this.updateJsonPreview();
    });
  };

  // Updated prefillSurveyResponses to include encryptedPortion
  prefillSurveyResponses = (userAnswers) => {
    const newSurveysResponseState = [...this.state.surveysResponseState];
    const currentSurvey = newSurveysResponseState[this.props.surveyIndex];

    userAnswers.responses.forEach((response) => {
      const questionId = response.questionID;
      currentSurvey.answers[questionId] = {
        value: this.parseAnswerValue(response.answer.value),
        encrypted: response.answer.encrypted,
        hash: response.answer.hash || '',
        encryptedPortion: response.answer.encryptedPortion || ''
      };
      currentSurvey.importance[questionId] = response.importance;
      currentSurvey.additionalComments[questionId] = {
        value: this.parseAnswerValue(response.additional.value),
        encrypted: response.additional.encrypted,
        hash: response.additional.hash || '',
        encryptedPortion: response.additional.encryptedPortion || ''
      };
    });

    this.setState({
      surveysResponseState: newSurveysResponseState,
      jsonPreview: this.prepareJsonAndHash(this.props.surveyIndex)
    });
  };

  parseAnswerValue = (value) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  };

  handleStartFresh = () => {
    const freshState = this.initializeSurveyResponseState();
    this.setState({
      startFresh: true,
      surveysResponseState: freshState,
      displayAnswerMode: false,
      userHasResponse: false,
      userResponseEncrypted: false,
      isEditing: false,
    }, () => {
            this.updateJsonPreview();
    });
  };

  handleDecryptEdit = async () => {
    this.setState({ isDecrypting: true });
    try {
      await this.decryptMultipleAnswers();
      this.setState({
        startFresh: false,
        displayAnswerMode: false,
        isEditing: true,
        isDecrypting: false,
      }, () => {
        // Update JSON preview after decryption
        const jsonPreview = this.prepareJsonAndHash(this.props.surveyIndex);
        this.setState({ jsonPreview });
      });
    } catch (error) {
      console.error("Error decrypting answers:", error);
      this.setState({ isDecrypting: false });
    }
  };
  
  handleAnswer = (surveyIndex, questionId, answer) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    let answerStr;
  
    if (Array.isArray(answer) || typeof answer === "number") {
      answerStr = JSON.stringify(answer);
    } else {
      answerStr = answer;
    }
  
    newSurveysResponseState[surveyIndex] = newSurveysResponseState[surveyIndex] || {
      answers: {},
      importance: {},
      additionalComments: {}
    };
  
    newSurveysResponseState[surveyIndex].answers[questionId] = {
      ...newSurveysResponseState[surveyIndex].answers[questionId],
      value: answer
    };
  
    const question = this.state.questionPool.find(q => q.id === questionId);
    const isBinaryQuestion = question && question.type === 'binary';
    if (!(Array.isArray(answer)) && typeof answer !== "number" && !isBinaryQuestion) {
      const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(answerStr));
      newSurveysResponseState[surveyIndex].answers[questionId].hash = newAnswerHash;
    }
  
    this.setState({
      surveysResponseState: newSurveysResponseState,
      isEditing: true
    }, () => {
      this.updateJsonPreview();
    });
  };    
  
  handleAdditional = (surveyIndex, questionId, additionalComments) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    newSurveysResponseState[surveyIndex].additionalComments[questionId] = {
      ...newSurveysResponseState[surveyIndex].additionalComments[questionId],
      value: additionalComments
    };
  
    const newAnswer = newSurveysResponseState[surveyIndex].additionalComments[questionId].value;
    const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(newAnswer));
    newSurveysResponseState[surveyIndex].additionalComments[questionId].hash = newAnswerHash;
    this.setState({
      surveysResponseState: newSurveysResponseState,
      isEditing: true
    });
  };
  
  handleImportance = (surveyIndex, questionId, importance) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    newSurveysResponseState[surveyIndex].importance[questionId] = importance;
    this.setState({
      surveysResponseState: newSurveysResponseState,
      isEditing: true
    });
  }; 
  

  toggleShowJson = () => {
    this.setState(prevState => ({ showJson: !prevState.showJson }));
  };

  toggleDisplayAnswerMode = () => {
    this.setState(
      prevState => ({
        displayAnswerMode: !prevState.displayAnswerMode,
        isEditing: !prevState.displayAnswerMode, // Set isEditing to true when switching to fill out mode
      }),
      async () => {
        if (this.state.displayAnswerMode) {
          // Now in viewingAnswers mode
          if (this.props.singleQuestionMode && this.props.responderAddress) {
            await this.fetchSingleQuestionData();
          } else if (this.props.viewAddress) {
            await this.fetchSurveyResponse();
          }
        } else {
          // Exiting viewingAnswers mode
          this.setState({ parsedViewAddressAnswers: null });
        }
        this.updateJsonPreview();
      }
    );
  };
  
  handleUpdateResponse = async () => {
    if (this.state.isEditing) {
      await this.encryptAndUpload();
      this.setState({ isEditing: false, userHasResponse: true, userResponseEncrypted: true });
    } else {
      this.handleDecryptEdit();
    }
  };

  handleShowJsonAtBottom = () => {
    if (!this.state.showJson) {
      this.setState({ showJson: true }, () => {
        if (this.bottomRef.current) {
          this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      });
    } else {
      if (this.bottomRef.current) {
        this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  handleScrollToTop = () => {
    if (!this.state.showJson) {
      this.setState({ showJson: true }, () => {
        if (this.topRef.current) {
          this.topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      });
    } else {
      if (this.topRef.current) {
        this.topRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  toggleAnswerEncryption = (surveyIndex, questionId, newEncryptedState) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    if (newSurveysResponseState[surveyIndex].answers[questionId]) {
      newSurveysResponseState[surveyIndex].answers[questionId].encrypted = newEncryptedState;
      this.setState({ surveysResponseState: newSurveysResponseState });
    }
  };   
   

  toggleAdditionalCommentsEncryption = (surveyIndex, questionId, newEncryptedState) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    if (newSurveysResponseState[surveyIndex].additionalComments[questionId]) {
      newSurveysResponseState[surveyIndex].additionalComments[questionId].encrypted = newEncryptedState;
      this.setState({ surveysResponseState: newSurveysResponseState });
    }
  };
  

  getSurveyResponse = async (responderAddress, surveyID) => {
    console.log("getSurveyResponse() - invoked with surveyID:", surveyID);

    const surveyAnswers = await contractScripts.getSurveyResponse(this.props.provider, responderAddress, surveyID);

    console.log("surveyAnswers: ", surveyAnswers);

    return surveyAnswers;
  };

  prepareJsonAndHash = (surveyIndex, responderAddress) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const surveyHash = this.props.isStandalone
      ? undefined
      : this.props.singleQuestionMode
      ? undefined
      : this.props.surveyID;
    const surveyResponseState = this.state.surveysResponseState[surveyIndex];
    const surveyQuestions = this.state.questionPool;
  
    if (!surveyQuestions || !surveyResponseState) {
      return {};
    }
  
    const answeredQuestions =
      this.props.isStandalone || this.props.singleQuestionMode
        ? surveyQuestions.filter((question) => {
            const questionId = question.id;
            const answer = surveyResponseState.answers[questionId];
            return answer && answer.value !== '';
          })
        : surveyQuestions;
  
    const responses = answeredQuestions.map((question) => {
      const questionId = question.id;
      const answer = surveyResponseState.answers[questionId] || {};
      const additional = surveyResponseState.additionalComments[questionId] || {};
      const importance = surveyResponseState.importance[questionId];
  
      let responseItem = {
        questionID: questionId,
        responder: responderAddress || this.props.account,
        type: question.type,
        prompt: question.prompt,
        importance: importance !== undefined ? importance : null,
        answer: {
          value: answer.value,
          encrypted: answer.encrypted,
          hash: answer.hash || '',
          encryptedPortion: answer.encryptedPortion || '',
        },
        additional: {
          value: additional.value,
          encrypted: additional.encrypted,
          hash: additional.hash || '',
          encryptedPortion: additional.encryptedPortion || '',
        },
      };
  
      return responseItem;
    });
  
    if (this.props.singleQuestionMode) {
      if (responses.length > 0) {
        const singleResponse = {
          timeStamp: Date.now(),
          ...responses[0],
        };
        return singleResponse;
      } else {
        // If no response yet, show basic question info so JSON is not empty
        if (surveyQuestions.length > 0) {
          const q = surveyQuestions[0];
          return {
            timeStamp: Date.now(),
            questionID: q.id,
            // responder: null,
            type: q.type,
            prompt: q.prompt,
            // importance: null,
            answer: {
              value: '',
              encrypted: false,
              hash: '',
              encryptedPortion: '',
            },
            additional: {
              value: '',
              encrypted: false,
              hash: '',
              encryptedPortion: '',
            },
          };
        } else {
          return {};
        }
      }
    }
  
    let surveyTitle = null;
    if (surveyHash && this.props.network && this.props.network.id) {
      const networkID = this.props.network.id.toString();
      let surveysCacheStr = localStorage.getItem('surveysCache');
      let surveysCache = {};
      if (surveysCacheStr) {
        try {
          surveysCache = JSON.parse(surveysCacheStr);
        } catch (e) {
          console.error('Error parsing surveysCache in prepareJsonAndHash:', e);
          surveysCache = {};
        }
      }
      if (surveysCache[networkID] && surveysCache[networkID].surveys && surveysCache[networkID].surveys[surveyHash.toLowerCase()]) {
        const surveyData = surveysCache[networkID].surveys[surveyHash.toLowerCase()];
        if (surveyData && surveyData.title) {
          surveyTitle = surveyData.title;
        }
      }
    }
  
    const response = {
      ...(surveyTitle ? { surveyTitle: surveyTitle } : {}),
      ...(surveyHash !== undefined && { surveyID: surveyHash }),
      responder: responderAddress || this.props.account,
      timeStamp: Date.now(),
      responses: responses,
    };
  
    return response;
  };
     
  
  updateJsonPreview = () => {
    this.setState({
      jsonPreview: this.prepareJsonAndHash(this.props.surveyIndex)
    });
  };

  processJsonToTree = (json, level = 0) => {
    let output = [];

    if (json === null || json === undefined) {
      return output;
    }

    if (Array.isArray(json)) {
      json.forEach((item, index) => {
        if (item !== null && typeof item === 'object') {
          output.push({ type: 'arrayItem', key: index, level });
          output = [...output, ...this.processJsonToTree(item, level + 1)];
        } else {
          output.push({ type: 'arrayItemValue', key: index, value: item, level });
        }
      });
    } else if (typeof json === 'object') {
      Object.keys(json).forEach((key) => {
        if (json[key] !== null && typeof json[key] === 'object') {
          output.push({ type: 'objectKey', key, level });
          output = [...output, ...this.processJsonToTree(json[key], level + 1)];
        } else {
          output.push({ type: 'objectKeyValue', key, value: json[key], level });
        }
      });
    }
    return output;
  };

  jsonTreeDisplay = (jsonInput) => {
    let jsonObject;
    if (jsonInput === null || jsonInput === undefined) {
      // Return empty braces if no input
      jsonObject = {};
    } else if (typeof jsonInput === 'string') {
      try {
        jsonObject = JSON.parse(jsonInput);
      } catch (e) {
        console.error("Invalid JSON string:", e);
        // Return empty braces if invalid
        jsonObject = {};
      }
    } else if (typeof jsonInput === 'object') {
      jsonObject = jsonInput;
    } else {
      console.error("Invalid input for jsonTreeDisplay: Expected string or object, got", typeof jsonInput);
      // Return empty braces if invalid type
      jsonObject = {};
    }

    if (!jsonObject) {
      jsonObject = {};
    }

    const treeData = this.processJsonToTree(jsonObject);

    if (treeData.length === 0) {
      // If empty, still show something
      return (
        <ul className={styles.tree}>
          <li className={styles.treeItem}>{'{}'}</li>
        </ul>
      );
    }

    return (
      <ul className={styles.tree}>
        {treeData.map((node, index) => (
          <li
            key={index}
            className={styles.treeItem}
            style={{ marginLeft: `${node.level * 20}px` }}
          >
            <span className={styles.keyValueContainer}>
              {node.type === 'arrayItemValue' && (
                <span>[{node.key}]: {String(node.value)}</span>
              )}
              {node.type === 'objectKeyValue' && (
                <span>{node.key}: {String(node.value)}</span>
              )}
              {node.type === 'arrayItem' && <span>[{node.key}]</span>}
              {node.type === 'objectKey' && <span>{node.key}:</span>}
              {node.type === 'value' && <span>{String(node.value)}</span>}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  getQuestionsJson = () => {
    const questionsJson = this.state.questionPool;
    const questions = questionsJson.filter(q => q.type !== undefined);
    return questions;
  };
  
  getResponseJson = () => {
    if (this.props.singleQuestionMode) {
      // For single question mode
      if (this.props.viewAddress || this.props.responderAddress) {
        // If viewing someone else's response
        return this.state.parsedViewAddressAnswers || {};
      } else {
        // Get the current response for the single question
        const surveyIndex = 0;
        const currentSurveyResponseState = this.state.surveysResponseState[surveyIndex];
        const question = this.state.questionPool[0];
  
        if (!question || !currentSurveyResponseState) {
          // If question isn't loaded yet, return empty object
          return {};
        }
  
        const questionId = question.id;
        const answer = currentSurveyResponseState.answers[questionId] || {};
        const additional = currentSurveyResponseState.additionalComments[questionId] || {};
        const importance = currentSurveyResponseState.importance[questionId];
  
        return {
          timeStamp: Date.now(),
          questionID: questionId,
          responder: this.props.account || null,
          type: question.type,
          prompt: question.prompt,
          importance: importance !== undefined ? importance : null,
          answer: {
            value: answer.value || '',
            encrypted: answer.encrypted || false,
            hash: answer.hash || '',
            encryptedPortion: answer.encryptedPortion || '',
          },
          additional: {
            value: additional.value || '',
            encrypted: additional.encrypted || false,
            hash: additional.hash || '',
            encryptedPortion: additional.encryptedPortion || '',
          },
        };
      }
    } else if (this.props.viewAddress || this.props.responderAddress) {
      // If viewing someone else's response
      return this.state.parsedViewAddressAnswers || {};
    } else {
      // Return own response
      const resp = this.prepareJsonAndHash(this.props.surveyIndex);
      return resp || {};
    }
  };  
  
  copyJsonToClipboard = (json, type) => {
    let jsonToUse = json;
    
    // Special handling for empty or undefined json
    if (!jsonToUse || (typeof jsonToUse === 'object' && Object.keys(jsonToUse).length === 0)) {
      if (this.props.singleQuestionMode) {
        // Get fresh data for single question mode
        jsonToUse = this.getResponseJson();
      }
    }
  
    // Don't copy if we still have no valid JSON
    if (!jsonToUse || Object.keys(jsonToUse).length === 0) {
      console.warn('No valid JSON data to copy');
      return;
    }
  
    const jsonString = JSON.stringify(jsonToUse, null, 2);
    navigator.clipboard.writeText(jsonString).then(() => {
      if (type === 'questions') {
        this.setState({ copiedQuestionsJson: true });
        setTimeout(() => {
          this.setState({ copiedQuestionsJson: false });
        }, 2000);
      } else if (type === 'response') {
        this.setState({ copiedResponseJson: true });
        setTimeout(() => {
          this.setState({ copiedResponseJson: false });
        }, 2000);
      }
    }).catch(error => {
      console.error('Failed to copy JSON to clipboard:', error);
    });
  };
  
  
  toggleShowQuestionsJson = () => {
    this.setState((prevState) => ({ showQuestionsJson: !prevState.showQuestionsJson }));
  };
  
  toggleShowResponseJson = () => {
    this.setState((prevState) => ({ showResponseJson: !prevState.showResponseJson }));
  };

  // Encryption and decryption methods

  getPublicKey = async () => {
    var provider;
    const address = this.props.account;

    if (this.props.provider == "Torus") {
      provider = window.torusProvider
    }
    else { // NOTE: Specify Metamask
      provider = window.ethereum;
    }

    // const signer = provider.getSigner();

    const pubkey = await provider.request({
      "method": "eth_getEncryptionPublicKey",
      "params": [
        address
      ]
    });

    console.log("pubkey: " + pubkey)
    return pubkey;
  };

  encryptDataForPublicKey = async (publicKey, data) => { // 
    // console.log("publicKey: " + publicKey)

    // hardcoded argument: https://github.com/MetaMask/eth-sig-util/blob/67a95bab3f414ed8a5718fcb4beccc6088c0db0e/src/encryption.ts#L40C10-L40C36
    const encrypted = await encrypt({ publicKey: publicKey, data: data, version: 'x25519-xsalsa20-poly1305' });

    // console.log("encrypted: ")
    // console.log(encrypted)

    // formatting is particular for the eth_decrypt functionality: 
    // https://github.com/ethers-io/ethers.js/issues/4139#issuecomment-1615971987
    // https://metamask.github.io/test-dapp/#getEncryptionKeyButton --> https://github.com/MetaMask/test-dapp/blob/261be4fe4b9f7b774064c090faa617d7b756c3d5/src/index.js#L1320
    const encryptedFormatted = `0x${Buffer.from(JSON.stringify(encrypted), 'utf8').toString('hex')}`


    return encryptedFormatted;
  };

  decryptData = async (encryptedData) => {
    // Note: Works for Metamask / Wagmi, would need change if integrating other providers
    const provider = this.props.provider == "Torus" ? window.torusProvider : window.ethereum;

    const hexCypher = encryptedData;

    try {
      const unencryptedData = await provider.request({
        "method": "eth_decrypt",
        "params": [
          hexCypher,
          this.props.account, // address (whose public key data is encrypted for)
        ]
      });

      console.log("unencrypted: " + unencryptedData)
      return unencryptedData;
    } catch (error) {
      console.log("Error - Data Decryption: ")
      console.log(error);
    }
  };

  encryptMultipleAnswers = async (pubKey) => {
    return new Promise(async (resolve, reject) => {
      var surveyIndex =
        this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
      var currentSurvey = this.state.surveysResponseState[surveyIndex];
  
      for (let questionId in currentSurvey.answers) {
        const answer = currentSurvey.answers[questionId];
        const additional = currentSurvey.additionalComments[questionId];
  
        if (answer.encrypted && !answer.encryptedPortion && answer.value !== '*') {
          let dataToEncrypt =
            typeof answer.value === 'string' ? answer.value : JSON.stringify(answer.value);
          const encryptedData = await this.encryptDataForPublicKey(pubKey, dataToEncrypt);
          answer.encryptedPortion = encryptedData;
          answer.value = '*';
        }
  
        if (
          additional &&
          additional.encrypted &&
          !additional.encryptedPortion &&
          additional.value !== '*'
        ) {
          let dataToEncrypt =
            typeof additional.value === 'string'
              ? additional.value
              : JSON.stringify(additional.value);
          const encryptedData = await this.encryptDataForPublicKey(pubKey, dataToEncrypt);
          additional.encryptedPortion = encryptedData;
          additional.value = '*';
        }
      }
  
      const newsurveysResponseState = [...this.state.surveysResponseState];
      newsurveysResponseState[surveyIndex] = currentSurvey;
  
      this.setState({ surveysResponseState: newsurveysResponseState }, () => {
        resolve();
      });
    });
  };  

  decryptMultipleAnswers = async () => {
    var surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    var currentSurvey = this.state.surveysResponseState[surveyIndex];
  
    for (let questionId in currentSurvey.answers) {
      const answer = currentSurvey.answers[questionId];
      const additional = currentSurvey.additionalComments[questionId];
  
      if (answer.encryptedPortion) {
        try {
          const decryptedData = await this.decryptData(answer.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedData);
          } catch (e) {
            data = decryptedData;
          }
          answer.value = data;
          answer.encryptedPortion = '';
  
          const answerStr = Array.isArray(data) || typeof data === "number" ? JSON.stringify(data) : data;
          const question = this.state.questionPool.find(q => q.id === questionId);
          const isBinaryQuestion = question && question.type === 'binary';
          if (!(Array.isArray(data)) && typeof data !== "number" && !isBinaryQuestion) {
            const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(answerStr));
            answer.hash = newAnswerHash;
          }
        } catch (error) {
          console.error("Error decrypting data for question", questionId, error);
        }
      }
  
      if (additional && additional.encryptedPortion) {
        try {
          const decryptedData = await this.decryptData(additional.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedData);
          } catch (e) {
            data = decryptedData;
          }
          additional.value = data;
          additional.encryptedPortion = '';
  
          const additionalStr = data;
          const newAdditionalHash = utils.keccak256(utils.toUtf8Bytes(additionalStr));
          additional.hash = newAdditionalHash;
        } catch (error) {
          console.error("Error decrypting additional data for question", questionId, error);
        }
      }
    }
  
    const updatedsurveysResponseState = [...this.state.surveysResponseState];
    updatedsurveysResponseState[surveyIndex] = currentSurvey;
  
    this.setState(
      { surveysResponseState: updatedsurveysResponseState },
      () => {
        console.log("Successfully decrypted all answers.");
      }
    );
  };  
  
  handleDecryptQuestionAnswer = async (questionId, fieldToDecrypt = 'both') => {
    const surveyIndex =
      this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const currentSurvey = this.state.surveysResponseState[surveyIndex];
  
    if (!currentSurvey) {
      console.error('Current survey response state is undefined.');
      return;
    }
  
    const answer = currentSurvey.answers[questionId];
    const additional = currentSurvey.additionalComments[questionId];
  
    if (!answer && !additional) {
      console.error(`No answer or additional comments found for question ID: ${questionId}`);
      return;
    }
  
    try {
      if (fieldToDecrypt === 'answer' || fieldToDecrypt === 'both') {
        if (answer && answer.encryptedPortion) {
          const decryptedAnswer = await this.decryptData(answer.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedAnswer);
          } catch (e) {
            data = decryptedAnswer;
          }
          answer.value = data;
          answer.encryptedPortion = '';
  
          const answerStr = Array.isArray(data) || typeof data === 'number' ? JSON.stringify(data) : data;
          const question = this.state.questionPool.find((q) => q.id === questionId);
          const isBinaryQuestion = question && question.type === 'binary';
          if (!(Array.isArray(data)) && typeof data !== 'number' && !isBinaryQuestion) {
            const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(answerStr));
            answer.hash = newAnswerHash;
          }
        }
      }
  
      if (fieldToDecrypt === 'additional' || fieldToDecrypt === 'both') {
        if (additional && additional.encryptedPortion) {
          const decryptedAdditional = await this.decryptData(additional.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedAdditional);
          } catch (e) {
            data = decryptedAdditional;
          }
          additional.value = data;
          additional.encryptedPortion = '';
  
          const additionalStr = data;
          const newAdditionalHash = utils.keccak256(utils.toUtf8Bytes(additionalStr));
          additional.hash = newAdditionalHash;
        }
      }
  
      const updatedSurveysResponseState = [...this.state.surveysResponseState];
      updatedSurveysResponseState[surveyIndex] = currentSurvey;
  
      this.setState({ surveysResponseState: updatedSurveysResponseState }, () => {
        console.log(`Successfully decrypted ${fieldToDecrypt} for question ID: ${questionId}`);
        const jsonPreview = this.prepareJsonAndHash(surveyIndex);
        this.setState({ jsonPreview });
      });
    } catch (error) {
      console.error(
        `Error decrypting ${fieldToDecrypt} for question ID: ${questionId}`,
        error
      );
      this.setState({
        submissionError: `Failed to decrypt ${fieldToDecrypt} for question ID: ${questionId}`,
      });
    }
  };
  
  
  encryptData = async (pubKey) => {
    console.log("encryptData() - invoked");
    if (!this.props.loginComplete) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }
  
    try {
      await this.encryptMultipleAnswers(pubKey);
  
      const jsonData = this.prepareJsonAndHash(this.props.surveyIndex);
      console.log("encrypted jsonData:", jsonData);
  
      this.setState({ jsonPreview: JSON.stringify(jsonData, null, 2) });
    } catch (error) {
      console.error("Encryption error:", error);
      this.setState({ submissionError: error.message || 'Encryption failed.' });
    }
  };
  
  encryptAndUpload = async () => {
    if (!this.props.loginComplete) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }
  
    this.setState({ isSubmitting: true, submitProgress: 0, currentStep: 1 });
  
    try {
      const hasEncrypted = this.hasEncryptedAnswers();
  
      if (hasEncrypted) {
        let pubKey = this.props.pubKey;
        if (this.props.pubKey === '') {
          pubKey = await this.getPublicKey();
          this.props.updatePubKey(pubKey);
        }
        await this.encryptData(pubKey);
        await this.verifyEncryption();
      }
  
      this.setState({ currentStep: 2 });
  
      await this.submitSurveyResponse();
  
      this.setState({
        isSubmitting: false,
        submitProgress: 100,
        submissionComplete: true,
        currentStep: 3,
      });
  
      const responseUrl = `${window.location.origin}/survey/${this.props.surveyID || ''}/${this.props.account}`;
      this.setState({ responseUrl });
  
    } catch (error) {
      console.error("Failed to submit survey:", error);
      this.setState({ isSubmitting: false, submitProgress: 0, submissionError: error.message });
    }
  };  
   
  handleExitEditing = () => {
    this.setState({
      isEditing: false,
    });
  };

  verifyEncryption = async () => {
    console.log("Verifying encryption...");
    return true;
  };

  submitSurveyResponse = async () => {
    if (!this.props.loginComplete) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }
  
    const surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const surveyAnswersData = this.prepareJsonAndHash(surveyIndex);
    console.log("surveyAnswersData:", surveyAnswersData);
  
    let questionIds, questionResponses, surveyID, surveyResponse;
  
    if (this.props.singleQuestionMode) {
      // Single question mode
      if (!surveyAnswersData || !surveyAnswersData.questionID) {
        console.log("No responses to submit.");
        this.setState({
          isSubmitting: false,
          submitProgress: 0,
          submissionError: "No responses to submit.",
        });
        return;
      }
      questionIds = [surveyAnswersData.questionID];
      questionResponses = [surveyAnswersData];
      surveyID = ethers.constants.HashZero; // For single question, surveyID is HashZero
      surveyResponse = null; // No surveyResponse for single question
    } else {
      if (
        !surveyAnswersData ||
        !surveyAnswersData.responses ||
        surveyAnswersData.responses.length === 0
      ) {
        console.log("No responses to submit.");
        this.setState({
          isSubmitting: false,
          submitProgress: 0,
          submissionError: "No responses to submit.",
        });
        return;
      }
      questionIds = surveyAnswersData.responses.map((r) => r.questionID);
      questionResponses = surveyAnswersData.responses;
      surveyID = this.props.isStandalone ? ethers.constants.HashZero : this.props.surveyID;
      surveyResponse = this.props.isStandalone ? null : surveyAnswersData;
    }
  
    try {
      const response = await contractScripts.submitResponses(
        this.props.provider,
        questionIds,
        questionResponses,
        surveyID,
        surveyResponse
      );
  
      console.log("response:", response);
      if (response.status === 1) {
        console.log("Survey response submitted successfully!");
        this.setState({
          isSubmitting: false,
          submitProgress: 100,
          submissionComplete: true,
          currentStep: 3,
        });
      } else {
        console.log("Error submitting survey response.");
        this.setState({
          isSubmitting: false,
          submitProgress: 0,
          submissionError: "Submission failed.",
        });
      }
    } catch (error) {
      console.error("Failed to submit survey:", error);
      this.setState({
        isSubmitting: false,
        submitProgress: 0,
        submissionError: error.message,
      });
    }
  };
   

  renderQuestion = (question, qIndex, currentSurveyResponseState) => {
    if (!question || !question.id || !question.type) {
      console.error('Invalid question data at index:', qIndex);
      console.error("question:");
      console.error(question);
      return null;
    }
  
    if (!currentSurveyResponseState) {
      console.warn('currentSurveyResponseState is undefined in renderQuestion');
      return null;
    }
  
    const surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const answer = currentSurveyResponseState.answers[question.id] || { value: '', encrypted: false };
    
    // Check if the answer is encrypted and needs decryption
    if (answer.encrypted && answer.value === '*') {
      return (
        <Card key={question.id}>
          <CardBody id={styles.questionTitleBody}>
            <h4 id={styles.questionTitle}>
              {question.prompt}
            </h4>
            <p>This answer is encrypted. Please decrypt to view and edit.</p>
            <Button onClick={() => this.handleDecryptQuestionAnswer(question.id)} id={styles.decryptQuestionButton}>
              Decrypt Answer
            </Button>
          </CardBody>
        </Card>
      );
    } else {
      let questionComponent;

      switch (question.type) {
        case 'multichoice':
          questionComponent = (
            <FormGroup id={styles.multiChoice}>
              {question.options.map((option, oIndex) => (
                <Label check key={oIndex} className={styles.checkboxOptionText}>
                  <Input
                    type="checkbox"
                    name={`question-${question.id}`}
                    value={option}
                    onChange={(e) => {
                      const currentAnswer = currentSurveyResponseState.answers[question.id]?.value || [];
                      const newAnswer = [...currentAnswer];
                      if (e.target.checked) {
                        newAnswer.push(option);
                      } else {
                        const index = newAnswer.indexOf(option);
                        if (index > -1) {
                          newAnswer.splice(index, 1);
                        }
                      }
                      this.handleAnswer(surveyIndex, question.id, newAnswer);
                    }}
                    checked={currentSurveyResponseState.answers[question.id]?.value?.includes(option) || false}
                  />
                  {option}
                </Label>
              ))}
            </FormGroup>
          );
          break;
        case 'rating':
          questionComponent = (
            <>
              <div className={styles.importanceSlider}>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  value={currentSurveyResponseState.answers[question.id]?.value || 0}
                  tooltip={false}
                  onChange={(ratingAnswer) => this.handleAnswer(surveyIndex, question.id, ratingAnswer)}
                  id={styles.ratingSlider}
                  style={{ width: '200px' }}
                />
              </div>
              <FormText id={styles.ratingLabelText}>
                {currentSurveyResponseState.answers[question.id]?.value || 0}
              </FormText>
            </>
          );
          break;
        case 'binary':
          questionComponent = (
            <FormGroup id={styles.binaryChoice}>
              {['Agree', 'Unsure', 'Disagree'].map((option, oIndex) => (
                <Label check key={oIndex} className={`${styles.radioOptionText} ${styles[option.toLowerCase()]} ${currentSurveyResponseState.answers[question.id]?.value === option ? styles.selected : ''}`}>
                  <Input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option}
                    checked={currentSurveyResponseState.answers[question.id]?.value === option || false}
                    onChange={() => this.handleAnswer(surveyIndex, question.id, option)}
                  />
                  {option === 'Agree' && <FontAwesomeIcon icon={faCheck} className={styles.optionIcon} />}
                  {option === 'Disagree' && <FontAwesomeIcon icon={faTimes} className={styles.optionIcon} />}
                  {option}
                </Label>
              ))}
            </FormGroup>
          );
          break;
        default: // 'freeform'
          questionComponent = (
            <AudioInput
              qIndex={qIndex}
              placeholder={'response (optional)'}
              updateFunction={(answer) => this.handleAnswer(surveyIndex, question.id, answer)}
              toggleEncryption={(newEncryptedState) => this.toggleAnswerEncryption(surveyIndex, question.id, newEncryptedState)}
              value={currentSurveyResponseState.answers[question.id]?.value || ''}
              encrypted={currentSurveyResponseState.answers[question.id]?.encrypted || false}
            />
          );
          break;
      }

      return (
        <Card key={question.id}>
          <CardBody id={styles.questionTitleBody}>
            <h4 id={styles.questionTitle}>
              {question.prompt}
            </h4>
            <InputGroup id={styles.responseInputSection}>
              {questionComponent}
              {question.type !== 'freeform' && (
                <InputGroupAddon addonType="append" id={styles.encryptAdditionalThoughts}>
                  <div id={styles.encryptOptionButton} onClick={() => this.toggleAnswerEncryption(surveyIndex, question.id, !currentSurveyResponseState.answers[question.id]?.encrypted)}>
                    <FontAwesomeIcon id={styles.encryptIcon} icon={currentSurveyResponseState.answers[question.id]?.encrypted ? faLock : faUnlock} />
                    <InputGroupText id={styles.inputGroupTextBox}>
                      <Input
                        addon
                        type="checkbox"
                        aria-label="encrypt"
                        checked={currentSurveyResponseState.answers[question.id]?.encrypted || false}
                        onChange={() => this.toggleAnswerEncryption(surveyIndex, question.id, !currentSurveyResponseState.answers[question.id]?.encrypted)}
                        id={styles.encryptCheckbox}
                      />
                      Encrypt
                    </InputGroupText>
                  </div>
                </InputGroupAddon>
              )}
            </InputGroup>
            <div className={styles.importanceSlider}>
              <h6 id={styles.importanceText}>Importance: {currentSurveyResponseState.importance[question.id] || 0} </h6>
              <Slider
                min={0}
                max={10}
                step={1}
                value={currentSurveyResponseState.importance[question.id] || 0}
                tooltip={false}
                onChange={(importance) => this.handleImportance(surveyIndex, question.id, importance)}
              />
            </div>
            <AudioInput
              qIndex={qIndex}
              updateFunction={(additionalComments) => this.handleAdditional(surveyIndex, question.id, additionalComments)}
              toggleEncryption={(newEncryptedState) => this.toggleAdditionalCommentsEncryption(surveyIndex, question.id, newEncryptedState)}
              placeholder={'related thoughts or URLs (optional)'}
              value={currentSurveyResponseState.additionalComments[question.id]?.value || ''}
              encrypted={currentSurveyResponseState.additionalComments[question.id]?.encrypted || false}
            />
          </CardBody>
        </Card>
      );
    }
  };

/*
 * This method is used for multi-question scenario (survey with multiple questions).
 * We pass isOwnResponse, the question object, and the response object to <SingleQuestionResponse>.
 */
renderQuestionAnswer = (question, response, index, isOwnResponse) => {
  if (!question || !response) {
    console.warn('renderQuestionAnswer: question or response is undefined');
    return null;
  }
  // We simply delegate to <SingleQuestionResponse>
  return (
    <SingleQuestionResponse
      key={`fullQ-${question.id}-${index}`}
      question={question}
      response={response}
      isOwnResponse={isOwnResponse}
      mode="fullscreen"    // Using "fullscreen" for multi-question survey display
      onDecryptQuestion={(qId, fieldToDecrypt) => this.handleDecryptQuestionAnswer(qId, fieldToDecrypt)}
      showImportance={true}
    />
  );
};

/*
 * This method is used for single-question scenario (or a single question from the questionPool).
 * If isOwnResponse is true, let the user decrypt/edit as well.
 */
renderSingleQuestionAnswer = (question, response, isOwnResponse) => {
  if (!question || !response) {
    console.warn('renderSingleQuestionAnswer: question or response is undefined');
    return null;
  }
  // We again pass to <SingleQuestionResponse>, typically mode="fullscreen" for the single-question page:
  return (
    <SingleQuestionResponse
      question={question}
      response={response}
      isOwnResponse={isOwnResponse}
      mode="fullscreen"
      onDecryptQuestion={(qId, fieldToDecrypt) => this.handleDecryptQuestionAnswer(qId, fieldToDecrypt)}
      showImportance={true}
    />
  );
};
  
  hasEncryptedAnswers = () => {
    const surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const currentSurvey = this.state.surveysResponseState[surveyIndex];
  
    return (
      Object.values(currentSurvey.answers).some((answer) => answer.encrypted) ||
      Object.values(currentSurvey.additionalComments).some((additional) => additional.encrypted)
    );
  };
  

/*
 * Called by multi-question surveys to render all question/response pairs.
 * We keep the existing logic, just calling renderQuestionAnswer per item.
 */
renderSurveyAnswers = (responses, isOwnResponse) => {
  if (this.props.singleQuestionMode) {
    // In singleQuestionMode, we just have one question in questionPool
    const question = this.state.questionPool[0];
    const response = responses;
    if (question && response) {
      return this.renderQuestionAnswer(question, response, 0, isOwnResponse);
    } else {
      return null;
    }
  } else {
    // multi-question scenario
    const questionMap = {};
    this.state.questionPool.forEach(q => {
      questionMap[q.id] = q;
    });

    return (
      <>
        {responses.map((response, index) => {
          const question = questionMap[response.questionID];
          if (question) {
            return this.renderQuestionAnswer(question, response, index, isOwnResponse);
          } else {
            return null;
          }
        })}
      </>
    );
  }
};

  render() {
    const surveyIndex =
      this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const currentSurveyResponseState =
      this.state.surveysResponseState && this.state.surveysResponseState.length > 0
        ? this.state.surveysResponseState[surveyIndex]
        : null;
  
    if (this.state.isLoadingResponse) {
      return (
        <div className={styles.loadingContainer}>
          <FontAwesomeIcon icon={faSpinner} spin /> Checking for user response...
        </div>
      );
    }
  
    if (!currentSurveyResponseState) {
      console.log('currentSurveyResponseState is undefined');
      console.log('surveysResponseState:', this.state.surveysResponseState);
      console.log('surveyIndex:', surveyIndex);
      const newSurveysResponseState = [...this.state.surveysResponseState];
      const currentSurvey = newSurveysResponseState[surveyIndex];
      console.log('currentSurvey:', currentSurvey);
      return <div>Loading...</div>;
    }
  
    const viewingAnswers = this.state.displayAnswerMode;
    const jsonPreview = this.prepareJsonAndHash(surveyIndex);
  
    let jsonDisplay = null;
    const notClickable = false;
    const shortenedViewAddress =
      this.props.viewAddress || this.props.responderAddress
        ? proposalScripts.getShortenedAddress(
            this.props.viewAddress || this.props.responderAddress,
            notClickable
          )
        : '';
  
    const isOwnResponse =
      (this.props.viewAddress &&
        this.props.account &&
        this.props.viewAddress.toLowerCase() === this.props.account.toLowerCase()) ||
      (this.props.responderAddress &&
        this.props.account &&
        this.props.responderAddress.toLowerCase() === this.props.account.toLowerCase()) ||
      (!this.props.viewAddress && this.props.account && this.state.userHasResponse);
  
    const getQuestionsJson = () => {
      const questionsJson = this.state.questionPool;
      const questions = questionsJson.filter(q => q.type !== undefined);
      return questions;
    };
  
    const getResponseJson = () => {
      return this.getResponseJson();
    };
  
    const jsonTreeDisplay = (jsonInput) => {
      let jsonObject;
      if (jsonInput === null || jsonInput === undefined) {
        jsonObject = {};
      } else if (typeof jsonInput === 'string') {
        try {
          jsonObject = JSON.parse(jsonInput);
        } catch (e) {
          console.error("Invalid JSON string:", e);
          jsonObject = {};
        }
      } else if (typeof jsonInput === 'object') {
        jsonObject = jsonInput;
      } else {
        console.error("Invalid input for jsonTreeDisplay:", typeof jsonInput);
        jsonObject = {};
      }
  
      const processJsonToTree = (json, level = 0) => {
        let output = [];
  
        if (json === null || json === undefined) {
          return output;
        }
  
        if (Array.isArray(json)) {
          json.forEach((item, index) => {
            if (item !== null && typeof item === 'object') {
              output.push({ type: 'arrayItem', key: index, level });
              output = [...output, ...processJsonToTree(item, level + 1)];
            } else {
              output.push({ type: 'arrayItemValue', key: index, value: item, level });
            }
          });
        } else if (typeof json === 'object') {
          Object.keys(json).forEach((key) => {
            if (json[key] !== null && typeof json[key] === 'object') {
              output.push({ type: 'objectKey', key, level });
              output = [...output, ...processJsonToTree(json[key], level + 1)];
            } else {
              output.push({ type: 'objectKeyValue', key, value: json[key], level });
            }
          });
        }
        return output;
      };
  
      const treeData = processJsonToTree(jsonObject);
  
      if (treeData.length === 0) {
        return (
          <ul className={styles.tree}>
            <li className={styles.treeItem}>{'{}'}</li>
          </ul>
        );
      }
  
      return (
        <ul className={styles.tree}>
          {treeData.map((node, index) => (
            <li
              key={index}
              className={styles.treeItem}
              style={{ marginLeft: `${node.level * 20}px` }}
            >
              <span className={styles.keyValueContainer}>
                {node.type === 'arrayItemValue' && (
                  <span>[{node.key}]: {String(node.value)}</span>
                )}
                {node.type === 'objectKeyValue' && (
                  <span>{node.key}: {String(node.value)}</span>
                )}
                {node.type === 'arrayItem' && <span>[{node.key}]</span>}
                {node.type === 'objectKey' && <span>{node.key}:</span>}
                {node.type === 'value' && <span>{String(node.value)}</span>}
              </span>
            </li>
          ))}
        </ul>
      );
    };
  
    if (viewingAnswers) {
      if (this.state.noResponse) {
        jsonDisplay = jsonTreeDisplay({
          message:
            'No response for this ' +
            (this.props.singleQuestionMode ? 'question' : 'survey') +
            ' from address: ' +
            (this.props.viewAddress || this.props.responderAddress),
        });
      } else {
        if (isOwnResponse && jsonPreview) {
          jsonDisplay = jsonTreeDisplay(jsonPreview);
        } else if (this.state.parsedViewAddressAnswers) {
          jsonDisplay = jsonTreeDisplay(this.state.parsedViewAddressAnswers);
        } else {
          jsonDisplay = null;
        }
      }
    } else {
      const respData = isOwnResponse ? jsonPreview : getResponseJson();
      jsonDisplay = jsonTreeDisplay(respData);
    }
  
    const exitEditingButton = this.state.isEditing ? (
      <Button onClick={this.handleExitEditing} id={styles.exitEditingButton}>
        Exit Editing
      </Button>
    ) : null;
  
    const viewAnswersButton =
      (this.props.viewAddress || this.props.responderAddress) && (!isOwnResponse || !this.state.isEditing) ? (
        <Button onClick={this.toggleDisplayAnswerMode} id={styles.answerSurveyButton}>
          <FontAwesomeIcon icon={viewingAnswers ? faPlus : faArrowLeft} id={styles.encryptIcon} />
          <div id={styles.surveyButtonText}>
            {viewingAnswers
              ? ` Fill out ${this.props.singleQuestionMode ? 'question' : 'survey'}`
              : ` View ${shortenedViewAddress} ${
                  this.props.singleQuestionMode ? 'answer' : 'answers'
                }`}
          </div>
        </Button>
      ) : null;
  
    let anyEncryptedAnswers = false;
    if (currentSurveyResponseState) {
      anyEncryptedAnswers =
        Object.values(currentSurveyResponseState.answers || {}).some((answer) => answer.encrypted) ||
        Object.values(currentSurveyResponseState.additionalComments || {}).some(
          (comment) => comment.encrypted
        );
    }
  
    const submitButtonText =
      this.state.isEditing && isOwnResponse
        ? `Update ${this.props.singleQuestionMode ? 'Answer' : 'Answers'}`
        : anyEncryptedAnswers
        ? 'Encrypt / Upload Responses'
        : 'Upload Responses';
  
    const userResponseNotice =
      this.state.userHasResponse && isOwnResponse ? (
        <div className={styles.userResponseNotice}>
          <p>
            Pre-existing {this.props.singleQuestionMode ? 'response' : 'survey response'} detected
          </p>
          <Button onClick={this.handleStartFresh} id={styles.startFreshButton}>
            Start Fresh
          </Button>
          <Button
            onClick={this.handleDecryptEdit}
            id={styles.decryptEditButton}
            disabled={this.state.isDecrypting}
          >
            {this.state.isDecrypting ? 'Decrypting...' : 'Decrypt / Edit All'}
          </Button>
          <Button
            onClick={!this.state.submissionComplete && !this.state.isSubmitting ? this.encryptAndUpload : undefined}
            id={styles.updateResponseButton}
            disabled={this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? 'Updating...'
              : this.state.submissionComplete
              ? 'Response Submitted'
              : `Update ${this.props.singleQuestionMode ? 'Answer' : 'Answers'}`}
          </Button>
          {this.state.submissionComplete && (() => {
            const responseLink = this.props.singleQuestionMode && this.props.account && this.props.questionID
              ? `/question/${this.props.questionID}/${this.props.account}`
              : (!this.props.singleQuestionMode && this.props.surveyID && this.props.account)
              ? `/survey/${this.props.surveyID}/${this.props.account}`
              : null;
            if (responseLink) {
              return (
                <a
                  href={responseLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: '10px' }}
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              );
            }
            return null;
          })()}
          {exitEditingButton}
        </div>
      ) : null;
  
    const responseLink = this.props.singleQuestionMode && this.props.account && this.props.questionID
      ? `/question/${this.props.questionID}/${this.props.account}`
      : (!this.props.singleQuestionMode && this.props.surveyID && this.props.account)
        ? `/survey/${this.props.surveyID}/${this.props.account}`
        : null;
  
    const submitResponseButton = (
      <div className={styles.footer} id={styles.surveyFooter}>
        <Button
          id={styles.submitSurveyButton}
          onClick={!this.state.submissionComplete && !this.state.isSubmitting ? this.encryptAndUpload : undefined}
          disabled={this.state.isSubmitting}
        >
          {this.state.isSubmitting ? (
            <div
              id={styles.uploadingEncryptingText}
            >
              <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '10px' }} />
              {this.state.currentStep === 1 && this.hasEncryptedAnswers()
                ? 'Encrypting...'
                : 'Uploading...'}
            </div>
          ) : this.state.submissionComplete ? (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />
              Response Submitted
            </div>
          ) : this.state.submissionError ? (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ marginRight: '10px' }}
              />
              {this.state.submissionError}
            </div>
          ) : (
            submitButtonText
          )}
        </Button>
        {this.state.submissionComplete && responseLink && (
          <a
            href={responseLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: '10px', display: 'inline-block' }}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        )}
      </div>
    );
  
    return (
      <div>
        <div ref={this.topRef}>
          {viewAnswersButton}
          {userResponseNotice}
          {viewingAnswers ? (
            this.state.noResponse ? (
              <div>
                No response for this{' '}
                {this.props.singleQuestionMode ? 'question' : 'survey'} from address:{' '}
                {this.props.viewAddress || this.props.responderAddress}
              </div>
            ) : (
              <div>
                {(this.props.viewAddress || this.props.responderAddress) && (
                  <h2 className={styles.viewAddressHeading}>{shortenedViewAddress} Response:</h2>
                )}
                {this.props.singleQuestionMode ? (
                  this.state.questionPool[0] &&
                  (isOwnResponse || this.state.parsedViewAddressAnswers) ? (
                    this.renderQuestionAnswer(
                      this.state.questionPool[0],
                      isOwnResponse ? jsonPreview : this.state.parsedViewAddressAnswers,
                      0,
                      isOwnResponse
                    )
                  ) : (
                    <div>No response to this question from this address.</div>
                  )
                ) : (
                  this.renderSurveyAnswers(
                    isOwnResponse
                      ? jsonPreview.responses || []
                      : this.state.parsedViewAddressAnswers
                      ? this.state.parsedViewAddressAnswers.responses || []
                      : [],
                    isOwnResponse
                  )
                )}
              </div>
            )
          ) : (
            <>
              {(!this.state.userHasResponse || this.state.startFresh || this.state.isEditing) && (
                <>
                  {submitResponseButton}
  
                  <Button className={styles.toggleJsonButton} onClick={this.handleShowJsonAtBottom}>
                    {'View JSON'}
                  </Button>
  
                  {this.state.questionPool.map((question, qIndex) =>
                    this.renderQuestion(question, qIndex, currentSurveyResponseState)
                  )}
  
                  {submitResponseButton}
  
                  <Button className={styles.toggleJsonButton} onClick={this.handleScrollToTop}>
                    {'Back To Top'}
                  </Button>
  
                </>
              )}
              {this.state.userHasResponse && !this.state.startFresh && !this.state.isEditing && (
                <div>
                  {this.props.singleQuestionMode ? (
                    this.state.questionPool[0] ? (
                      this.renderQuestionAnswer(
                        this.state.questionPool[0],
                        this.state.userAnswers,
                        0,
                        isOwnResponse
                      )
                    ) : (
                      <div>Unable to load question.</div>
                    )
                  ) : (
                    this.renderSurveyAnswers(this.state.userAnswers.responses, isOwnResponse)
                  )}
                </div>
              )}
            </>
          )}
        </div>
  
        <div ref={this.bottomRef}>
          {this.props.isStandalone ? (
            <div className={styles.jsonButtonsContainer}>
              <Button className={styles.toggleJsonButton} onClick={this.toggleShowQuestionsJson}>
                {this.state.showQuestionsJson ? 'Hide Questions .json' : 'Show Questions .json'}
              </Button>
              <Button className={styles.toggleJsonButton} onClick={this.toggleShowResponseJson}>
                {this.state.showResponseJson ? 'Hide Response .json' : 'Show Response .json'}
              </Button>
            </div>
          ) : (
            <Button className={styles.toggleJsonButton} onClick={this.toggleShowJson}>
              {this.state.showJson ? 'Hide JSON' : 'Show JSON'}
            </Button>
          )}
  
          {this.props.isStandalone && this.state.showQuestionsJson && (
            <div className={styles.jsonContainer}>
              <Button
                className={styles.copyJsonButton}
                onClick={() => this.copyJsonToClipboard(getQuestionsJson(), 'questions')}
              >
                {this.state.copiedQuestionsJson ? (
                  <FontAwesomeIcon icon={faCheck} color="green" />
                ) : (
                  <FontAwesomeIcon icon={faClipboard} />
                )}
              </Button>
              <pre id={styles.responsePreview}>{jsonTreeDisplay(getQuestionsJson())}</pre>
            </div>
          )}
          {this.props.isStandalone && this.state.showResponseJson && (
            <div className={styles.jsonContainer}>
              <Button
                className={styles.copyJsonButton}
                onClick={() => this.copyJsonToClipboard(getResponseJson(), 'response')}
              >
                {this.state.copiedResponseJson ? (
                  <FontAwesomeIcon icon={faCheck} color="green" />
                ) : (
                  <FontAwesomeIcon icon={faClipboard} />
                )}
              </Button>
              <pre id={styles.responsePreview}>{jsonTreeDisplay(getResponseJson())}</pre>
            </div>
          )}
          {!this.props.isStandalone && this.state.showJson && (
            <div className={styles.jsonContainer}>
              <Button
                className={styles.copyJsonButton}
                onClick={() => this.copyJsonToClipboard(viewingAnswers && !isOwnResponse && this.state.parsedViewAddressAnswers ? this.state.parsedViewAddressAnswers : jsonPreview, 'response')}
              >
                {this.state.copiedResponseJson ? (
                  <FontAwesomeIcon icon={faCheck} color="green" />
                ) : (
                  <FontAwesomeIcon icon={faClipboard} />
                )}
              </Button>
              <pre id={styles.responsePreview}>
                {viewingAnswers && !isOwnResponse && this.state.parsedViewAddressAnswers
                  ? jsonTreeDisplay(this.state.parsedViewAddressAnswers)
                  : jsonTreeDisplay(jsonPreview)}
              </pre>
            </div>
          )}
        </div>
      </div>
    );
  }    
}

export default SurveyTool;