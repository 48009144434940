// FILE: my-app/client/src/components/CommunityTab/CommunityTab.jsx
/*********************************************
Location: my-app/client/src/components/CommunityTab/CommunityTab.jsx
 *********************************************/
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faVoteYea, faSpinner, faScroll, faUsersCog, faBuilding, faArrowRight, faArrowLeft, faChevronDown, faChevronUp, faExclamationTriangle, faLink } from '@fortawesome/free-solid-svg-icons'; // Added faLink
import styles from './CommunityTab.module.scss';
import historicalFigures from '../../variables/historical_figure_users.json';
import logoRxC from "../../assets/img/rxcLogo.png";
import logoPolis from "../../assets/img/polisLogo.png";
import { Modal, ModalHeader, ModalBody, Collapse, UncontrolledTooltip } from 'reactstrap';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';
import contractScripts from '../Buttons/contractScripts.js';
import SBTsList from '../SBTs/SBTsList';
import SBTFilter from '../SBTs/SBTFilter';

import blockies from 'ethereum-blockies';

class CommunityTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySection: 'groups',
      showModal: false,
      modalType: null,
      modalTitle: '',
      modalGroupData: null,
      showMoreLeaderboard: false,
      hideSimulatedUsers: false,
      hideHumanUsers: false,
      sbtsCreatedCount: 0,
      loadingSbtsCreated: true,
      uniqueUsers: [],
      surveysCreatedCount: 0,
      surveyResponsesCount: 0,
      uniqueQuestionsCount: 0,
      loadingSurveyData: true,
      surveysList: [],
      questionsList: [],
      filteredUsers: [],
      loadingFilter: false,
      stats: [
        { icon: faUsers, count: 0, label: 'Users' },
        { icon: faVoteYea, count: 0, label: 'Questions' },
        { icon: faScroll, count: 0, label: 'Surveys' },
        { icon: faUsersCog, count: 0, label: 'SBT Groups' },
      ],
      lastSbtGroupsCount: null,
      initialLoadDone: false,
    };

    this.groups = [
      {
        name: 'RadicalxChange',
        logo: logoRxC,
        description: 'RadicalxChange is a global movement dedicated to reimagining the building blocks of democracy and markets in order to uphold fairness, plurality, and meaningful participation in a rapidly changing world.',
        links: [
            { url: 'https://www.radicalxchange.org/', text: 'Official Website' },
            { url: 'https://twitter.com/RadxChange', text: 'Twitter / X' }
        ]
      },
      {
        name: 'Pol.is',
        logo: logoPolis,
        description: 'Pol.is is a pioneering software platform that facilitates large-scale conversations and helps identify areas of consensus and disagreement on complicated issues.',
        links: [
            { url: 'https://pol.is/', text: 'Official Website' },
            // { url: 'https://docs.pol.is/', text: 'Documentation' }
        ]
      },
    ];
  }

  checkIfInitialLoadDone = async () => {
    // This function checks if the initial load is done by comparing the caches' lastBlock to the latest chain block
    const networkID = this.props.network?.id;
    if (!networkID) return false;

    let surveysCacheStr = localStorage.getItem('surveysCache');
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let sbtCacheStr = localStorage.getItem('sbtCache');

    let surveysCache = {};
    let questionsCache = {};
    let sbtCache = {};

    if (surveysCacheStr) {
      try { surveysCache = JSON.parse(surveysCacheStr); } catch (e) { surveysCache = {}; }
    }
    if (questionsCacheStr) {
      try { questionsCache = JSON.parse(questionsCacheStr); } catch (e) { questionsCache = {}; }
    }
    if (sbtCacheStr) {
      try { sbtCache = JSON.parse(sbtCacheStr); } catch (e) { sbtCache = {}; }
    }

    if (!surveysCache[networkID] || !questionsCache[networkID] || !sbtCache[networkID]) {
      return false;
    }

    // Use the specific latest block keys for each cache type if available
    const surveyLastBlock = surveysCache[networkID].surveysLatestBlock || surveysCache[networkID].lastBlock || 0;
    const questionLastBlock = questionsCache[networkID].questionsLatestBlock || questionsCache[networkID].lastBlock || 0;
    const sbtLastBlock = sbtCache[networkID].lastBlock || 0; // sbtCache seems to only use `lastBlock`

    // Get the latest block number from chain
    let latestBlockNumber;
    try {
      latestBlockNumber = await contractScripts.getLatestBlockNumber(this.props.provider);
    } catch (err) {
      console.error("Failed to get latest block number:", err);
      return false; // Cannot confirm if loaded if we can't get latest block
    }


    // If the minimum of these lastBlocks is >= latestBlockNumber, we consider initial load done
    const minLastBlock = Math.min(surveyLastBlock, questionLastBlock, sbtLastBlock);
    if (minLastBlock >= latestBlockNumber) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    console.log('CommunityTab mounted. Fetching initial data...');
    this.setState({ initialLoadDone: false });

    this.fetchSbtsCreatedCount();
    this.fetchSurveyDataFromCache();

    // Start updates every 1 second for the first 30 seconds, then switch to every 30 seconds
    this.updateIntervalCount = 0; // to track how many times we've run
    this.periodicStatsInterval = setInterval(() => {
      this.updateStatsPeriodically();
    }, 1000); // every 1 second initially
  }

  componentWillUnmount() {
    if (this.periodicStatsInterval) {
      clearInterval(this.periodicStatsInterval);
    }
  }

  updateStatsPeriodically = async () => {
    // This function re-fetches and updates from the cache regularly.
    // For the first 30 seconds, it updates every 1 second.
    // After 30 seconds, switch to every 30 seconds until we reach 2 minutes total.

    this.updateIntervalCount++;

    console.log('Periodically updating stats from cache...');
    await this.fetchSbtsCreatedCount();
    await this.fetchSurveyDataFromCache();

    // After updating from cache, check if initial load is done
    const loadDone = await this.checkIfInitialLoadDone();
    if (loadDone && !this.state.initialLoadDone) {
      this.setState({ initialLoadDone: true });
    }

    // If we've passed 30 updates (30 seconds), and currently interval is 1s, switch to 30s interval
    // We only do this once.
    if (this.updateIntervalCount === 30) {
      clearInterval(this.periodicStatsInterval);
      // now set interval to 30 seconds for the remainder of the 2 minutes
      // total 2 minutes = 120 seconds, after first 30 seconds, we have 90 seconds left => 3 intervals of 30s each max
      this.periodicStatsInterval = setInterval(async () => { // Added async here
        this.updateIntervalCount += 30; // increment by 30 each time since each interval is 30s
        if (this.updateIntervalCount > 120) {
          // Stop after 2 minutes total
          clearInterval(this.periodicStatsInterval);
          return;
        }
        console.log('Updating stats from cache (30s interval)...');
        await this.fetchSbtsCreatedCount(); // Added await
        await this.fetchSurveyDataFromCache(); // Added await
        const loadCheck = await this.checkIfInitialLoadDone(); // Added await
        if (loadCheck && !this.state.initialLoadDone) {
            this.setState({ initialLoadDone: true });
        }
      }, 30000); // every 30 seconds
    }

    if (this.updateIntervalCount > 120) {
      // Stop after 2 minutes total
      clearInterval(this.periodicStatsInterval);
    }
  }

  componentDidUpdate(prevProps) {
    // Refetch if network or provider changes
    if (prevProps.network?.id !== this.props.network?.id || prevProps.provider !== this.props.provider) {
      this.fetchSbtsCreatedCount();
      this.fetchSurveyDataFromCache(); // Also refetch survey data on network change
    }

    // After updates, re-check SBT Groups count from cache to ensure it's updated
    this.updateSbtGroupsCountFromCache();
  }

  fetchSbtsCreatedCount = async () => {
    const { network } = this.props;
    this.setState({ loadingSbtsCreated: true });

    const networkID = network?.id;
    if (!networkID) {
      console.error('Network ID is undefined in CommunityTab. Cannot proceed.');
      this.setState({ loadingSbtsCreated: false });
      return;
    }

    try {
      // Read from cache
      const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};

      const cachedNetworkData = sbtCache[networkID];
      if (!cachedNetworkData || !cachedNetworkData.sbtList) {
        console.log(`No SBTs found in cache for network ID ${networkID}`);
        this.setState(prevState => ({
          sbtsCreatedCount: 0,
          loadingSbtsCreated: false,
          stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: 0 } : stat),
          lastSbtGroupsCount: 0,
        }));
        return;
      }

      const sbtList = cachedNetworkData.sbtList;

      // Create a Set to store unique SBT addresses
      const uniqueSbts = new Set(Object.keys(sbtList));

      const sbtsCreated = uniqueSbts.size;
      this.setState(prevState => ({
        sbtsCreatedCount: sbtsCreated,
        loadingSbtsCreated: false,
        stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: sbtsCreated } : stat),
        lastSbtGroupsCount: sbtsCreated,
      }));
    } catch (error) {
      console.error('Error fetching SBT Groups count:', error);
      this.setState(prevState => ({
        sbtsCreatedCount: 0,
        loadingSbtsCreated: false,
        stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: 0 } : stat),
        lastSbtGroupsCount: 0,
      }));
    }
  };

  fetchSurveyDataFromCache = async () => {
    // Do not remove existing functionality or comments.
    // This function is updated to use the new cache structure (surveysCache, questionsCache, sbtCache).

    // Get the current network ID
    const network = this.props.network;
    const networkID = network?.id;
    if (!networkID) {
      console.error('Network ID is undefined in fetchSurveyDataFromCache. Cannot proceed.');
      this.setState({ loadingSurveyData: false });
      return;
    }

    // Retrieve caches from localStorage
    let surveysCacheStr = localStorage.getItem('surveysCache');
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let sbtCacheStr = localStorage.getItem('sbtCache');

    let surveysCache = {};
    let questionsCache = {};
    let sbtCache = {};

    if (surveysCacheStr) {
      try {
        surveysCache = JSON.parse(surveysCacheStr);
      } catch (e) {
        console.error('Error parsing surveysCache:', e);
        surveysCache = {};
      }
    }

    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache:', e);
        questionsCache = {};
      }
    }

    if (sbtCacheStr) {
      try {
        sbtCache = JSON.parse(sbtCacheStr);
      } catch (e) {
        console.error('Error parsing sbtCache:', e);
        sbtCache = {};
      }
    }

    if (!surveysCache[networkID]) {
      surveysCache[networkID] = {
        surveysLatestBlock: 0, // Changed from lastBlock
        surveys: {},
        surveyResponses: {},
        surveyResponsesLatestBlock: {} // Changed from surveyResponsesLastBlock
      };
    }

    if (!questionsCache[networkID]) {
      questionsCache[networkID] = {
        questionsLatestBlock: 0, // Changed from lastBlock
        questions: {},
        questionResponses: {},
        questionResponsesLatestBlock: 0 // Changed from questionResponsesLastBlock
      };
    }

    if (!sbtCache[networkID]) {
      sbtCache[networkID] = {
        lastBlock: 0,
        sbtList: {}
      };
    }

    const surveysData = surveysCache[networkID].surveys || {};
    const surveyResponsesData = surveysCache[networkID].surveyResponses || {};
    const questionsData = questionsCache[networkID].questions || {};
    const questionResponsesData = questionsCache[networkID].questionResponses || {};
    const sbtList = sbtCache[networkID].sbtList || {};

    // Count surveys
    const surveysCreatedCount = Object.keys(surveysData).length;

    // Count questions
    const uniqueQuestionsCount = Object.keys(questionsData).length;

    // Calculate survey responses count: sum of all responders across all surveys
    let surveyResponsesCount = 0;
    // Add responders from survey responses
    for (const sId in surveyResponsesData) {
      const responders = Object.keys(surveyResponsesData[sId]);
      surveyResponsesCount += responders.length;
    }

    // Collect unique users set from various sources
    const uniqueUsersSet = new Set();

    // 1) Add survey creators
    for (const sId in surveysData) {
      if (surveysData[sId].creator) {
        uniqueUsersSet.add(surveysData[sId].creator.toLowerCase());
      }
    }

    // 2) Add survey responders
    for (const sId in surveyResponsesData) {
      const responders = Object.keys(surveyResponsesData[sId]);
      responders.forEach(r => uniqueUsersSet.add(r.toLowerCase()));
    }

    // 3) Add question creators
    for (const qId in questionsData) {
      if (questionsData[qId].creator) {
        uniqueUsersSet.add(questionsData[qId].creator.toLowerCase());
      }
    }

    // 4) Add question responders
    for (const qId in questionResponsesData) {
      const responders = Object.keys(questionResponsesData[qId]);
      responders.forEach(r => uniqueUsersSet.add(r.toLowerCase()));
    }

    // 5) Add SBT creators and minted addresses from sbtCache
    for (const sbtAddress in sbtList) {
      const sbtItem = sbtList[sbtAddress];
      // SBT creators (if available)
      if (sbtItem.sbtInfo && sbtItem.sbtInfo.creator) {
        uniqueUsersSet.add(sbtItem.sbtInfo.creator.toLowerCase());
      }
      if (sbtItem.sbtInfo && sbtItem.sbtInfo.admin) { // Also check admin field
        uniqueUsersSet.add(sbtItem.sbtInfo.admin.toLowerCase());
      }
      // Minted addresses
      const mintedAddresses = sbtItem.mintedAddresses || [];
      mintedAddresses.forEach(addr => uniqueUsersSet.add(addr.toLowerCase()));
    }

    // Convert to array
    const uniqueUsers = Array.from(uniqueUsersSet);

    // Surveys list for modal: We'll list surveys with their IDs, titles, response counts, question counts
    const surveysList = Object.keys(surveysData).map(sId => {
      const survey = surveysData[sId];
      const questionIDs = survey.questionIDs || [];
      // Count responders for this survey from surveyResponsesData
      let numResponses = 0;
      if (surveyResponsesData[sId]) {
        numResponses = Object.keys(surveyResponsesData[sId]).length;
      }

      return {
        id: sId,
        title: survey.title || 'Untitled Survey',
        responsesCount: numResponses,
        questionsCount: questionIDs.length
      };
    });

    this.setState(prevState => {
      const updatedStats = prevState.stats.map(stat => {
        if (stat.label === 'Users') {
          return { ...stat, count: uniqueUsers.length };
        } else if (stat.label === 'Questions') {
          return { ...stat, count: uniqueQuestionsCount };
        } else if (stat.label === 'Surveys') {
          return { ...stat, count: surveysCreatedCount };
        } else {
          return stat; // Keep other stats (like SBT Groups) as they are
        }
      });

      return {
        uniqueUsers,
        surveysCreatedCount,
        surveyResponsesCount,
        uniqueQuestionsCount,
        surveysList,
        loadingSurveyData: false,
        stats: updatedStats,
        // IMPORTANT: Also update filteredUsers initially when uniqueUsers is set
        filteredUsers: uniqueUsers,
      };
    });
  }

  updateSbtGroupsCountFromCache = () => {
    const { network } = this.props;
    const networkID = network?.id;
    if (!networkID) {
      return;
    }

    try {
      const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
      const cachedNetworkData = sbtCache[networkID];
      if (!cachedNetworkData || !cachedNetworkData.sbtList) {
        if (this.state.lastSbtGroupsCount !== 0) {
          this.setState(prevState => ({
            stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: 0 } : stat),
            lastSbtGroupsCount: 0
          }));
        }
        return;
      }
      const sbtList = cachedNetworkData.sbtList;
      const uniqueSbts = new Set(Object.keys(sbtList));
      const sbtsCreated = uniqueSbts.size;

      // Only update state if the count has actually changed
      if (sbtsCreated !== this.state.lastSbtGroupsCount) {
        this.setState(prevState => ({
          stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: sbtsCreated } : stat),
          lastSbtGroupsCount: sbtsCreated
        }));
      }
    } catch (error) {
      console.error('Error updating SBT Groups count from cache:', error);
      // Optionally reset count on error
      if (this.state.lastSbtGroupsCount !== 0) {
          this.setState(prevState => ({
              stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: 0 } : stat),
              lastSbtGroupsCount: 0
          }));
      }
    }
  }

  toggleDisplaySection = () => {
    this.setState(prevState => ({
      displaySection: prevState.displaySection === 'groups' ? 'individuals' : 'groups'
    }));
  }

  handleUserClick = (user) => {
    if (user.username.startsWith('0x')) {
      window.open(`/u/${user.username}`, '_blank');
    } else {
      window.open(`/su/${user.username}`, '_blank');
    }
  }

  handleStatClick = (stat) => {
    // MODIFIED: Instead of setting modalContent with JSX, set modalType and modalTitle
    this.setState({
      modalTitle: `${stat.label} Details`,
      modalType: stat.label.toLowerCase().replace(' ', ''), // e.g., 'sbtgroups', 'users', 'surveys', 'questions'
      showModal: true,
      // Reset specific modal states if needed
      filteredUsers: stat.label === 'Users' ? this.state.uniqueUsers : [], // Initialize filter for 'Users' modal
      loadingFilter: stat.label === 'Users' ? true : false, // Show loading initially for filter
    }, () => {
        // Immediately turn off loading for filter if we already have users
        if (stat.label === 'Users' && this.state.uniqueUsers.length > 0) {
            this.setState({ loadingFilter: false });
        }
    });
  }

  // Removed getUserInfo as it wasn't used

  handleGroupClick = (group) => {
    // MODIFIED: Set modalType to 'group' and store group data
    this.setState({
      modalType: 'group',
      modalTitle: group.name,
      modalGroupData: group, // Store the group object
      showModal: true
    });
  }

  // Helper to toggle modal visibility
  toggleModal = () => {
    this.setState(prevState => ({
        showModal: !prevState.showModal,
        // Reset modal type/title when closing
        modalType: !prevState.showModal ? prevState.modalType : null,
        modalTitle: !prevState.showModal ? prevState.modalTitle : '',
        modalGroupData: !prevState.showModal ? prevState.modalGroupData : null,
    }));
  }

  renderLeaderboard() {
    const { uniqueUsers, hideSimulatedUsers, hideHumanUsers, showMoreLeaderboard } = this.state;

    // 'realUsers' derived from unique address list
    let realUsers = uniqueUsers.map(address => {
      return {
        name: address,
        username: address
      };
    });

    // 'simulatedUsers' are your historical figures with their own .avatar images
    let simulatedUsers = historicalFigures;

    let leaderboardData = [];

    if (hideSimulatedUsers && hideHumanUsers) {
      leaderboardData = [];
    } else if (hideSimulatedUsers) {
      leaderboardData = realUsers;
    } else if (hideHumanUsers) {
      leaderboardData = simulatedUsers;
    } else {
      leaderboardData = [...simulatedUsers, ...realUsers];
    }

    // Remove duplicates by username
    const uniqueLeaderboardData = Array.from(
      new Set(leaderboardData.map(user => user.username))
    ).map(username => {
      return leaderboardData.find(user => user.username === username);
    });

    // Sort alphabetically by name
    uniqueLeaderboardData.sort((a, b) => a.name.localeCompare(b.name));

    const topEntries = 4; // Number of entries to show initially
    const topDisplayed = uniqueLeaderboardData.slice(0, topEntries);
    const remainingEntries = uniqueLeaderboardData.slice(topEntries);

    // Create a Blockie image (in color) and return it as a data URL
    const generateBlockie = (seed) => {
      if (!seed) seed = Math.random().toString();
      const icon = blockies.create({
        seed: seed.toLowerCase(),
        size: 15,
        scale: 3
        // (no explicit color / bg color / spotcolor => random defaults in color)
      });
      return icon.toDataURL();
    };

    if (uniqueLeaderboardData.length === 0) {
      return <div className={styles.noUsers}>None yet!</div>;
    }

    return (
      <>
        {topDisplayed.map((user, index) => (
          <div
            key={index}
            className={styles.leaderboardItem}
            onClick={() => this.handleUserClick(user)}
          >
            <img
              // If it's a historical figure with user.avatar, keep that.
              // Else for addresses, generate a blockie.
              src={user.avatar ? user.avatar : generateBlockie(user.username)}
              alt={user.name}
              className={styles.avatar}
            />
            <span className={styles.name}>
              {user.username.startsWith('0x') && (
                <>
                  {proposalScripts.getShortenedAddress(user.name, true)}
                </>
              )}
              {!user.username.startsWith('0x') && (
                <>
                  {user.name}
                  <span className={styles.simBadge} id={`sim-tooltip-${index}`}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </span>
                  <UncontrolledTooltip placement="right" target={`sim-tooltip-${index}`}>
                    This is a simulated user.
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </div>
        ))}

        <Collapse isOpen={showMoreLeaderboard}>
          {remainingEntries.map((user, index) => (
            <div
              key={index + topEntries} // Ensure unique key
              className={styles.leaderboardItem}
              onClick={() => this.handleUserClick(user)}
            >
              <img
                src={user.avatar ? user.avatar : generateBlockie(user.username)}
                alt={user.name}
                className={styles.avatar}
              />
              <span className={styles.name}>
                {user.username.startsWith('0x') && (
                  <>
                    {proposalScripts.getShortenedAddress(user.name, true)}
                  </>
                )}
                {!user.username.startsWith('0x') && (
                  <>
                    {user.name}
                    <span
                      className={styles.simBadge}
                      id={`sim-tooltip-${index + topEntries}`} // Ensure unique ID
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                    <UncontrolledTooltip
                      placement="right"
                      target={`sim-tooltip-${index + topEntries}`} // Ensure unique target
                    >
                      This is a simulated user.
                    </UncontrolledTooltip>
                  </>
                )}
              </span>
            </div>
          ))}
        </Collapse>

        {uniqueLeaderboardData.length > topEntries && (
          <button
            onClick={() =>
              this.setState(prevState => ({
                showMoreLeaderboard: !prevState.showMoreLeaderboard
              }))
            }
            className={styles.showMoreButton}
          >
            {showMoreLeaderboard ? (
              <>
                Show Less <FontAwesomeIcon icon={faChevronUp} />
              </>
            ) : (
              // MODIFIED: Include count in button text
              <>
                Show More ({remainingEntries.length}) <FontAwesomeIcon icon={faChevronDown} />
              </>
            )}
          </button>
        )}
      </>
    );
  }

  // ADDED: Function to render modal content based on modalType
  renderModalContent = () => {
    const { modalType, surveysList, filteredUsers, loadingFilter, modalGroupData } = this.state;
    const { provider, network, account, loginComplete, toggleLoginModal } = this.props;

    switch (modalType) {
      case 'sbtgroups':
        // Render SBTsList directly, passing current props
        return (
          <SBTsList
            provider={provider}
            network={network}
            account={account}
            loginComplete={loginComplete}
            miniaturized={true}
            // miniMintable={true} // Assuming miniMintable is desired in the modal
            toggleLoginModal={toggleLoginModal}
            viewMode="modal" // Important: Set view mode for modal styling
          />
        );
      case 'users':
        // Render user list with filter
        return (
          <div>
            <SBTFilter
              items={this.state.uniqueUsers} // Filter based on the full uniqueUsers list
              mode="addresses"
              provider={provider}
              network={network}
              onFilter={(newFilteredUsers) => {
                  // Check if the filtered list actually changed before updating state
                  if (JSON.stringify(newFilteredUsers) !== JSON.stringify(this.state.filteredUsers)) {
                      this.setState({ filteredUsers: newFilteredUsers, loadingFilter: false });
                  } else {
                      this.setState({ loadingFilter: false }); // Ensure loading stops even if list is same
                  }
              }}
            />
            {loadingFilter ? (
              <div>Loading...</div>
            ) : (
              <div className={styles.userList}>
                {filteredUsers.map((address, index) => (
                  <div key={index} className={styles.userItem} onClick={() => window.open(`/u/${address}`, '_blank')}>
                    {proposalScripts.getShortenedAddress(address, true)}
                  </div>
                ))}
                {filteredUsers.length === 0 && <p>No matching users found.</p>}
              </div>
            )}
          </div>
        );
      case 'surveys':
        // Render survey list
        return (
          <div className={styles.surveyList}>
            {surveysList.map((survey, index) => (
              <div key={index} className={styles.surveyItem}>
                <a
                  href={`/survey/${survey.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.surveyLink}
                >
                  {survey.title}
                </a>
                <span className={styles.questionsCount}>
                  Questions: {survey.questionsCount}
                </span>
                <span
                  className={styles.responsesCount}
                  onClick={() => window.open(`/survey/${survey.id}/results`, '_blank')} // Link to results page
                  style={{ cursor: 'pointer' }} // Add pointer cursor
                >
                  Responses: {survey.responsesCount}
                </span>
              </div>
            ))}
            {surveysList.length === 0 && <p>No surveys found.</p>}
          </div>
        );
      case 'questions':
        // Render link to questions page
        return (
          <div>
            <p id={styles.seeQuestionsText}>
              See questions{' '}
              <a href="/questions" target="_blank" rel="noopener noreferrer" id={styles.seeQuestionsLinkText}>
                here
              </a>
            </p>
          </div>
        );
      case 'group':
        // Render specific group details including links
        if (!modalGroupData) return <p>Group details not available.</p>;
        return (
          <div className={styles.groupModalContent}> {/* Added wrapper for styling */}
            <img src={modalGroupData.logo} alt={`${modalGroupData.name} logo`} className={styles.groupLogoModal} />
            <p className={styles.groupDescriptionModal}>{modalGroupData.description}</p> {/* Added class */}
            {modalGroupData.links && modalGroupData.links.length > 0 && (
              <div className={styles.groupLinksModal}> {/* Added wrapper for links */}
                <h4>Related Links:</h4>
                <ul>
                  {modalGroupData.links.map((link, index) => (
                    <li key={index}>
                      <a href={link.url} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLink} /> {link.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );
      default:
        return <p>No content specified.</p>;
    }
  }


  render() {
    const { displaySection, showModal, modalTitle, hideSimulatedUsers, hideHumanUsers, loadingSbtsCreated, stats, initialLoadDone } = this.state;

    return (
      <div className={styles.communityTab}>
        <div className={styles.leaderboardSection}>
          <h2 className={styles.sectionTitle}>Leaderboard</h2>
          <div className={styles.leaderboardHeader}>
            <label className={styles.toggleLabel}>
              <input
                type="checkbox"
                checked={hideSimulatedUsers}
                onChange={() => this.setState(prevState => ({ hideSimulatedUsers: !prevState.hideSimulatedUsers }))}
                className={styles.toggleCheckbox}
              />
              Hide Simulated Users
            </label>
            <label className={styles.toggleLabel}>
              <input
                type="checkbox"
                checked={hideHumanUsers}
                onChange={() => this.setState(prevState => ({ hideHumanUsers: !prevState.hideHumanUsers }))}
                className={styles.toggleCheckbox}
              />
              Hide Human Users
            </label>
          </div>
          <div className={styles.content}>
            <div className={styles.leaderboard}>
              <div className={styles.leaderboardHeader}>
                {/* Removed Header text */}
              </div>
              {this.renderLeaderboard()}
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.statsSection}>
            <h2 className={styles.sectionTitle}>
              Statistics{' '}
              {!initialLoadDone && (
                <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: '10px' }} />
              )}
            </h2>
            <div className={styles.statsGrid}>
              {stats.map((stat, index) => (
                <div key={index} className={styles.statItem} onClick={() => this.handleStatClick(stat)}>
                  <FontAwesomeIcon icon={stat.icon} size="2x" className={styles.statIcon} />
                  <span className={styles.statCount}>
                    {stat.label === 'SBT Groups' && loadingSbtsCreated ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      stat.count
                    )}
                  </span>
                  <span className={styles.statLabel}>{stat.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.recognitionSection}>
            <h2 className={styles.sectionTitle}>Recognition</h2>
            <div className={styles.content}>
              {displaySection === 'groups' ? (
                <>
                  <div className={styles.groupsGrid}>
                    {this.groups.map((group, index) => (
                      <div key={index} className={styles.groupItem} onClick={() => this.handleGroupClick(group)}>
                        <img src={group.logo} alt={`${group.name} logo`} className={styles.groupLogo} />
                        <span>{group.name}</span>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.noIndividuals}>
                    No recognized individuals yet.
                  </div>
                  <button onClick={this.toggleDisplaySection} className={styles.switchButton}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Groups
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <Modal isOpen={showModal} toggle={this.toggleModal} className={styles.modal} size="lg"> {/* Use size="lg" for a wider modal */}
          <ModalHeader toggle={this.toggleModal} className={styles.modalHeader}>
            {modalTitle}
          </ModalHeader>
          <ModalBody className={styles.modalBody}>
            {/* Render content dynamically based on modalType */}
            {this.renderModalContent()}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default CommunityTab;