/***************************************************
 * FILE: MainSite.jsx
 * LOCATION: my-app/client/src/components/MainSite.jsx
 ***************************************************/

import React, { Component } from "react";
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAccount, updateXPBalance, updateETHBalance } from '../actions/accountActions.js';
import { updateCurrMatch, updateMatches, updateProposals } from '../actions/lobbyActions.js';
import { fetchSessionState, changeFocusedTab, toggleLoginModal, updateLoginInfo, changeFocusedMatchID, toggleDemoMode  } from '../actions/sessionStateActions.js';

// CSS
import "assets/css/m_w.css";
import styles from "./Main.module.scss";

// DemoMode Data
import web2DemoData from '../variables/web2DemoVotes.js';
import web2DemoProposals from '../variables/web2DemoProposals.js';

// Smart Contract Events / Interactions
import { featured_SBTs_LIST } from '../variables/CONTRACT_ADDRESSES.js';
import contractScripts from './Buttons/contractScripts.js';
import { ethers } from 'ethers';

// Footer-HooksHOC is a function component (allowed to use Hooks from wagmi and RainbowKit)
// it passes props to this class-component so that this component can use React Hooks
import { WagmiHooksHOC } from './HooksHOC/WagmiHooksHOC.jsx'

// Components
import Navbar from "./Navbar/Navbar.jsx";
import MainAreaTabs from "./MainContent/MainAreaTabsAlt.jsx";
import Footer from "./Footer/Footer.jsx";
import RightsideAlt from "./RightSidebar/RightSideAlt.jsx";
import AboutPage from "./AboutPage/AboutPage.jsx";
import UserPage from "./UserPage/UserPage.jsx";
import SimulatedUserPage from "./UserPage/SimUserPage.jsx";
import RiskMatrixDemo from "./DemoPages/RiskMatrixDemo.jsx";
import ContractPage from "./ContractPage/ContractPage.jsx";
import CompareAddresses from "./UserPage/CompareAddresses.jsx";
import SurveyPage from "./SurveyTool/SurveyPage.jsx";
import SurveyTool from "./SurveyTool/SurveyTool.jsx";
import SBTPage from "./SBTs/SBTPage.jsx";
import SBTsPage from "./SBTs/SBTsList.jsx";

// --- ADDED for One-Page Demo:
import OnePageDemo from "./DemoPages/OnePageDemo.jsx";

// BEGIN DEMO GROUPS JSON IMPORT
import demoGroups from '../variables/demoGroups.json';
// END DEMO GROUPS JSON IMPORT

class MainSite extends Component {
  state = {
    autoSnapToNewMatch: true,
    searchForCachedMatches: true,
    latestMatchNumber: null,
    loadedLatestMatch: false,
    loadingLatestMatch: false,
    loadedAllMatches: false,
    loadingAllMatches: false,
    loadedProposals: false,
    loadingProposals: false,
    latestBlockNumber: null,
    demoModeOverrideToFalse: false,

    // Cache readiness flags
    isSBTCacheReady: false,
    isSurveyCacheReady: false,
    isQuestionCacheReady: false,
    isAllCachesReady: false,

    // NEW: we only set this true after SBT, Survey, and Question caches are fully loaded
    cacheHasLoaded: false
  };

  async componentDidMount() {

    // -------------------------------------------------------------------
    // [ADDED] First, check whether surveysCache or questionsCache is non-empty.
    //         If both are empty, set cacheHasLoaded = false; else true.
    // -------------------------------------------------------------------
    let localSurveysStr = localStorage.getItem("surveysCache");
    let localQuestionsStr = localStorage.getItem("questionsCache");
    let hasSomeSurveyData = false;
    let hasSomeQuestionData = false;

    // Check if surveysCache is non-empty
    if (localSurveysStr) {
      try {
        let parsed = JSON.parse(localSurveysStr);
        // If any network has at least 1 survey
        for (const netID in parsed) {
          if (
            parsed[netID] &&
            parsed[netID].surveys &&
            Object.keys(parsed[netID].surveys).length > 0
          ) {
            hasSomeSurveyData = true;
            break;
          }
        }
      } catch (err) {
        console.error("Error parsing surveysCache at startup:", err);
      }
    }

    // Check if questionsCache is non-empty
    if (localQuestionsStr) {
      try {
        let parsed = JSON.parse(localQuestionsStr);
        // If any network has at least 1 question
        for (const netID in parsed) {
          if (
            parsed[netID] &&
            parsed[netID].questions &&
            Object.keys(parsed[netID].questions).length > 0
          ) {
            hasSomeQuestionData = true;
            break;
          }
        }
      } catch (err) {
        console.error("Error parsing questionsCache at startup:", err);
      }
    }

    // If we have no existing surveys OR questions, set "false". Otherwise "true".
    let alreadyHasData = (hasSomeSurveyData || hasSomeQuestionData);
    localStorage.setItem("cacheHasLoaded", alreadyHasData ? "true" : "false");
    // -------------------------------------------------------------------

    // Basic session fetch (existing code):
    this.props.fetchSessionState();

    if (this.state.demoModeOverrideToFalse) {
      this.props.toggleDemoMode(false);
    }

    console.log("this.props.urlExtension:", this.props.urlExtension);

    if (this.props.web2DemoMode) {
      // Demo mode (no real chain calls)
      console.log("web2DemoMode - skipping full on-chain interactions");
      this.loadDemoMatches();
      this.getLobbyProposals();

      // Initialize SBT cache
      await this.initializeSbtCache();
      this.setState({ isSBTCacheReady: true });
      this.startSbtEventListener(); // mostly no-op in demo

      // Initialize Survey & Question caches if network is known
      if (this.props.network && this.props.network.id) {
        await this.initializeSurveyCache();
        this.setState({ isSurveyCacheReady: true });

        // Now initialize question metadata
        await this.initializeQuestionCache();
        this.setState({ isQuestionCacheReady: true });

        // fetch question responses chunked (partial)
        await this.fetchQuestionResponsesChunked();
        this.startSurveyAndQuestionEventListener();
      }
    } else {
      // Real chain
      this.startEventListener();
      await this.loadMatchesFromLocalStorage();

      // SBT
      await this.initializeSbtCache();
      this.setState({ isSBTCacheReady: true });
      this.startSbtEventListener();

      // Survey & question caches
      if (this.props.network && this.props.network.id) {
        await this.initializeSurveyCache();
        this.setState({ isSurveyCacheReady: true });

        // same logic as SurveyTool to fetch questionIDs from chain
        await this.initializeQuestionCache();
        this.setState({ isQuestionCacheReady: true });

        // fetch question responses chunked
        await this.fetchQuestionResponsesChunked();
        this.startSurveyAndQuestionEventListener();
      }
    }

    // Now check if all caches are ready
    this.checkAllCachesReady();
  }

  componentWillUnmount() {
    if (this.props.socket !== undefined) {
      // this.props.socket.disconnect()
    }

    if (this.props.provider === "Torus") {
      window.torus.cleanup();
    }

    contractScripts.removeSBTEventListener(this.props.provider);
  }

  componentDidUpdate(prevProps) {
    console.log("this.props.matches:", this.props.matches);
    console.log("this.props.currMatch:", this.props.currMatch);
    console.log("this.props.focusedMatchID:", this.props.focusedMatchID);

    const needCurrMatchReload = !this.state.loadedLatestMatch && !this.state.loadingLatestMatch && this.props.currMatch !== undefined;
    const needAllMatchesReload = !this.state.loadedAllMatches && !this.state.loadingAllMatches;
    const needProposalsReload = !this.state.loadedProposals && !this.state.loadingProposals;

    // Network change
    if (prevProps.network?.id !== this.props.network?.id) {
      this.handleNetworkChange();
    }

    if (!this.props.web2DemoMode) {
      if (needCurrMatchReload) {
        this.loadLatestMatch();
      }
      if (needAllMatchesReload) {
        this.loadAllMatches();
      }
      if (needProposalsReload) {
        this.getLobbyProposals();
      }
    }
  }

  handleNetworkChange = async () => {
    console.log("handleNetworkChange() - re-initializing caches for new network");

    this.setState({
      isSBTCacheReady: false,
      isSurveyCacheReady: false,
      isQuestionCacheReady: false,
      isAllCachesReady: false,
      cacheHasLoaded: false
    });

    if (this.props.network && this.props.network.id) {
      // SBT
      await this.initializeSbtCache();
      this.setState({ isSBTCacheReady: true });
      this.startSbtEventListener();

      // Surveys
      await this.initializeSurveyCache();
      this.setState({ isSurveyCacheReady: true });

      // Questions
      await this.initializeQuestionCache(); // same approach as SurveyTool
      this.setState({ isQuestionCacheReady: true });

      // question responses
      await this.fetchQuestionResponsesChunked();
      this.startSurveyAndQuestionEventListener();
    }
    this.checkAllCachesReady();
  };

  checkAllCachesReady = () => {
    const { isSBTCacheReady, isSurveyCacheReady, isQuestionCacheReady } = this.state;

    // If you still want to track SBT in "isAllCachesReady," keep it.
    if (isSBTCacheReady && isSurveyCacheReady && isQuestionCacheReady) {
      this.setState({ isAllCachesReady: true });
      console.log("All caches are ready!");

      // [MODIFIED] Re-set localStorage if surveys & questions are definitely loaded
      localStorage.setItem("cacheHasLoaded", "true");
    }
  };

  sendMessageToServer = () => {
    // this.props.socket.send("MESSAGE from MainSite.jsx");
  };

  // -----------------------------------------
  // SBT LOGIC
  // -----------------------------------------
  initializeSbtCache = async () => {
    console.log("initializeSbtCache() - invoked");
    const network = this.props.network;
    const networkID = network?.id;

    if (!networkID) {
      console.error('Network ID is undefined in MainSite. Cannot proceed.');
      return;
    }

    const BATCH_SIZE = 5;
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};

    let latestBlock;
    try {
      const cachedNetworkData = cache[networkID];
      const cachedSbtList = cachedNetworkData ? cachedNetworkData.sbtList : {};
      const cacheLatestBlock = cachedNetworkData ? cachedNetworkData.lastBlock : 0;
      latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);

      let newSbts = [];
      if (cacheLatestBlock < latestBlock) {
        console.log('Fetching new SBTs from block', cacheLatestBlock + 1, 'to', latestBlock);
        newSbts = await contractScripts.getSbtsCreated(this.props.provider, cacheLatestBlock + 1, latestBlock);
      }

      const existingSbtAddresses = Object.keys(cachedSbtList).map(addr => addr.toLowerCase());
      const newSbtAddresses = newSbts.map((sbt) => sbt.sbtAddress.toLowerCase());
      const featuredLower = featured_SBTs_LIST.map(addr => addr.toLowerCase());
      const allSbtAddresses = [...new Set([...existingSbtAddresses, ...newSbtAddresses, ...featuredLower])];

      const processedSbts = {};
      for (let i = 0; i < allSbtAddresses.length; i += BATCH_SIZE) {
        const batch = allSbtAddresses.slice(i, i + BATCH_SIZE);
        const batchPromises = batch.map(async (sbtAddressLower) => {
          try {
            const cachedSBT = cachedSbtList[sbtAddressLower];
            if (cachedSBT && cachedSBT.sbtInfo && cachedSBT.mintedAddresses && cachedSBT.burnedAddresses) {
              return cachedSBT;
            } else {
              const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, sbtAddressLower);
              const mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(this.props.provider, sbtAddressLower);
              const burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(this.props.provider, sbtAddressLower);

              const normalizedMintedAddresses = mintedAddresses.map((addr) => addr.toLowerCase());
              const normalizedBurnedAddresses = burnedAddresses.map((addr) => addr.toLowerCase());

              const result = {
                sbtAddress: sbtAddressLower,
                sbtInfo,
                mintedAddresses: normalizedMintedAddresses,
                burnedAddresses: normalizedBurnedAddresses,
                blockNumber: latestBlock,
              };
              return result;
            }
          } catch (error) {
            console.error(`Error processing SBT ${sbtAddressLower}:`, error);
            return null;
          }
        });
        const batchResults = await Promise.all(batchPromises);
        batchResults.forEach((result) => {
          if (result) {
            const sbtAddressLower = result.sbtAddress.toLowerCase();
            processedSbts[sbtAddressLower] = result;
          }
        });
      }

      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          lastBlock: latestBlock,
          sbtList: {
            ...cachedSbtList,
            ...processedSbts,
          },
        },
      };

      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      console.log('Updated sbtCache in MainSite:', newCache);
    } catch (error) {
      console.error('Error initializing SBT cache:', error);
    }
  };

  startSbtEventListener = () => {
    const provider = window.provider;
    contractScripts.listenForSBTEvents(this.props.provider, this.onNewSbtEventDetected);
  };

  onNewSbtEventDetected = async (event) => {
    console.log("onNewSbtEventDetected() – invoked");
    console.log("event: ", event);

    const newEvent = event.blockNumber > this.state.latestBlockNumber;
    console.log("newEvent:", newEvent);
    console.log("event.blockNumber:", event.blockNumber);
    console.log("this.state.latestBlockNumber:", this.state.latestBlockNumber);

    if (newEvent && event.eventSignature === "SBTCreated(address)") {
      await this.onSbtCreatedDetected(event);
    } else if (newEvent && event.eventSignature === "Issued(address,uint256)") {
      await this.onSbtIssuedDetected(event);
    } else if (newEvent && event.eventSignature === "Transfer(address,address,uint256)") {
      await this.onSbtTransferDetected(event);
    } else {
      console.log("Unhandled SBT event detected");
    }

    if (newEvent) {
      this.setState({ latestBlockNumber: event.blockNumber });
    }
  };

  onSbtCreatedDetected = async (event) => {
    console.log("onSbtCreatedDetected() – invoked");
    const network = this.props.network;
    const networkID = network?.id;
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};

    try {
      const sbtAddress = event.args.sbtAddress.toLowerCase();
      const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, sbtAddress);
      let mintedAddresses = [];
      let burnedAddresses = [];

      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          sbtList: {
            ...cache[networkID]?.sbtList,
            [sbtAddress]: {
              sbtAddress,
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
            },
          },
        },
      };

      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      console.log("SBT cache updated with new SBT:", sbtAddress);
    } catch (error) {
      console.error('Error handling SBTCreated event:', error);
    }
  };

  onSbtIssuedDetected = async (event) => {
    console.log("onSbtIssuedDetected() – invoked");
    const network = this.props.network;
    const networkID = network?.id;
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};

    try {
      const toAddress = event.args.to.toLowerCase();
      const sbtAddress = event.address.toLowerCase();

      if (cache[networkID] && cache[networkID].sbtList[sbtAddress]) {
        let mintedAddresses = cache[networkID].sbtList[sbtAddress].mintedAddresses || [];
        if (!mintedAddresses.includes(toAddress)) {
          mintedAddresses.push(toAddress);
        }
        cache[networkID].sbtList[sbtAddress].mintedAddresses = mintedAddresses;
        localStorage.setItem('sbtCache', JSON.stringify(cache));
      } else {
        const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, sbtAddress);
        let mintedAddresses = [toAddress];
        let burnedAddresses = [];

        const newCache = {
          ...cache,
          [networkID]: {
            ...cache[networkID],
            sbtList: {
              ...cache[networkID]?.sbtList,
              [sbtAddress]: {
                sbtAddress,
                sbtInfo,
                mintedAddresses,
                burnedAddresses,
              },
            },
          },
        };

        localStorage.setItem('sbtCache', JSON.stringify(newCache));
      }
    } catch (error) {
      console.error('Error handling Issued event:', error);
    }
  };

  onSbtTransferDetected = async (event) => {
    console.log("onSbtTransferDetected() – invoked");
    const network = this.props.network;
    const networkID = network?.id;
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};

    try {
      const fromAddress = event.args.from.toLowerCase();
      const toAddress = event.args.to.toLowerCase();
      const sbtAddress = event.address.toLowerCase();

      // burn event if to=0x0
      if (toAddress === '0x0000000000000000000000000000000000000000') {
        if (cache[networkID] && cache[networkID].sbtList[sbtAddress]) {
          let burnedAddresses = cache[networkID].sbtList[sbtAddress].burnedAddresses || [];
          if (!burnedAddresses.includes(fromAddress)) {
            burnedAddresses.push(fromAddress);
          }
          cache[networkID].sbtList[sbtAddress].burnedAddresses = burnedAddresses;
          localStorage.setItem('sbtCache', JSON.stringify(cache));
        }
      }
    } catch (error) {
      console.error('Error handling Transfer event:', error);
    }
  };

  // -----------------------------------------
  // SURVEYS
  // -----------------------------------------
  initializeSurveyCache = async () => {
    console.log("initializeSurveyCache() - invoked");
    const network = this.props.network;
    const networkID = network?.id;
    if (!networkID) {
      console.error('Network ID is undefined in initializeSurveyCache. Cannot proceed.');
      return;
    }

    let surveysCacheStr = localStorage.getItem('surveysCache');
    let surveysCache = {};
    if (surveysCacheStr) {
      try {
        surveysCache = JSON.parse(surveysCacheStr);
      } catch (e) {
        console.error('Error parsing surveysCache, resetting:', e);
        surveysCache = {};
      }
    }

    if (!surveysCache[networkID]) {
      surveysCache[networkID] = {
        surveysLatestBlock: 0,
        surveys: {},
        surveyResponses: {},
        surveyResponsesLatestBlock: {}
      };
    }

    const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
    console.log('Latest block number (Surveys):', latestBlock);

    const responsesBlockMap = surveysCache[networkID].surveyResponsesLatestBlock || {};

    try {
      const surveyIDs = await contractScripts.fetchUserSubmittedSurveyIDs(this.props.provider);
      if (surveyIDs && surveyIDs.length > 0) {
        for (let surveyID of surveyIDs) {
          surveyID = surveyID.toLowerCase();
          if (!surveysCache[networkID].surveys[surveyID]) {
            const surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
            if (surveyData) {
              surveyData.surveyID = surveyID;
              if (!surveyData.questionIDs) surveyData.questionIDs = [];
              if (!surveyData.creator) surveyData.creator = "";
              surveysCache[networkID].surveys[surveyID] = surveyData;
            }
          }
        }
      }

      for (const surveyID in surveysCache[networkID].surveys) {
        const surveyBlock = responsesBlockMap[surveyID] || 0;
        if (latestBlock > surveyBlock) {
          console.log(`Fetching new responses for survey ${surveyID}, blocks ${surveyBlock+1}..${latestBlock}`);
          const newSurveyResponses = await contractScripts.fetchAllSurveyResponses(
            this.props.provider,
            surveyID
          );
          if (!surveysCache[networkID].surveyResponses[surveyID]) {
            surveysCache[networkID].surveyResponses[surveyID] = {};
          }
          for (const item of newSurveyResponses) {
            const responderAddr = item.responder.toLowerCase();
            surveysCache[networkID].surveyResponses[surveyID][responderAddr] = item.response;
          }
          responsesBlockMap[surveyID] = latestBlock;
        }
      }

      surveysCache[networkID].surveyResponsesLatestBlock = responsesBlockMap;
      localStorage.setItem('surveysCache', JSON.stringify(surveysCache));
      console.log('Surveys cache initialized or updated:', surveysCache);
    } catch (error) {
      console.error('Error fetching surveys or responses:', error);
    }
  };

  /**
   * initializeQuestionCache()
   * 
   * Fetches all known question IDs in chunks, merges them into the existing localStorage `questionsCache`,
   * and ensures previously known questions (including those not tied to any survey) are NOT overwritten.
   * Uses block-based flags to skip re-fetch if fromBlock > latestBlock.
   */
  async initializeQuestionCache() {
    console.log("initializeQuestionCache() - chunk-based version");
    const network = this.props.network;
    const networkID = network?.id;
    if (!networkID) {
      console.error("Network ID is undefined in initializeQuestionCache. Cannot proceed.");
      return;
    }

    let questionsCacheStr = localStorage.getItem("questionsCache");
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error("Error parsing questionsCache, resetting:", e);
        questionsCache = {};
      }
    }
    if (!questionsCache[networkID]) {
      questionsCache[networkID] = {
        questionsLatestBlock: 0,
        questions: {},
        questionResponses: {},
        questionResponsesLatestBlock: 0
      };
    }

    // We keep all existing question IDs to avoid overwriting any independent (non-survey) questions
    const existingQIDsSet = new Set(
      Object.keys(questionsCache[networkID].questions).map(s => s.toLowerCase())
    );

    // Determine fromBlock using local cache's questionsLatestBlock
    const fromBlock = (questionsCache[networkID].questionsLatestBlock || 0) + 1;
    let latestBlock;
    try {
      latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
    } catch {
      latestBlock = "latest";
    }
    if (typeof latestBlock !== "number") {
      latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
    }

    // If our fromBlock is already beyond the latestBlock, skip re-fetch to limit double-load
    if (fromBlock > latestBlock) {
      console.log("No new questionIDs to fetch; fromBlock > latestBlock");
      return;
    }

    console.log(`Fetching question IDs from block ${fromBlock} to ${latestBlock} in chunks...`);

    // Optional callbacks to show chunk progress
    const onChunkProgress = (info) => {
      const {
        chunkFrom, chunkTo, doneSoFarBlocks, totalRangeBlocks,
        chunkEventCount, overallEventCount
      } = info;
      console.log(
        `QuestionIDs chunk [${chunkFrom}..${chunkTo}]: events=${chunkEventCount}, totalSoFar=${overallEventCount}, blocksSoFar=${doneSoFarBlocks}/${totalRangeBlocks}`
      );
    };

    const onPartialData = (partialQIDs, currentToBlock) => {
      // Merge partial new questionIDs into the existing set
      partialQIDs.forEach((qId) => existingQIDsSet.add(qId.toLowerCase()));
      // Update local questionsLatestBlock to reflect partial progress
      questionsCache[networkID].questionsLatestBlock = currentToBlock;
      localStorage.setItem("questionsCache", JSON.stringify(questionsCache));
    };

    // Collect all question IDs from chain in a chunked manner
    let finalQIDs;
    try {
      finalQIDs = await contractScripts.getAllQuestionIDsChunkedWithCallback(
        this.props.provider,
        fromBlock,
        latestBlock,
        onChunkProgress,
        onPartialData
      );
    } catch (err) {
      console.error("Error chunk-fetching question IDs:", err);
      finalQIDs = [];
    }

    // Add the newly fetched IDs to our existing set
    finalQIDs.forEach((id) => existingQIDsSet.add(id.toLowerCase()));
    questionsCache[networkID].questionsLatestBlock = latestBlock;

    console.log("Fetching question metadata for new questionIDs...");
    const newQIDs = Array.from(existingQIDsSet).filter(
      (id) => !questionsCache[networkID].questions[id]
    );

    console.log("New questionIDs found:", newQIDs.length);

    // Fetch metadata in small batches to avoid large parallel calls
    const BATCH_SIZE = 10;
    for (let i = 0; i < newQIDs.length; i += BATCH_SIZE) {
      const batch = newQIDs.slice(i, i + BATCH_SIZE);
      const promises = batch.map(async (qId) => {
        try {
          const questionData = await contractScripts.getQuestionData(this.props.provider, qId);
          return { qId, questionData };
        } catch (e) {
          console.error(`Error fetching question data for ${qId}:`, e);
          return { qId, questionData: null };
        }
      });
      const results = await Promise.all(promises);
      results.forEach((item) => {
        if (item.questionData) {
          questionsCache[networkID].questions[item.qId] = {
            id: item.qId,
            ...item.questionData
          };
        } else {
          // If metadata not found, keep a placeholder but do NOT remove existing
          questionsCache[networkID].questions[item.qId] = {
            id: item.qId,
            prompt: "(Unknown Question)",
            type: "freeform",
            creator: "",
            tags: []
          };
        }
      });
      // Save after each batch
      localStorage.setItem("questionsCache", JSON.stringify(questionsCache));
      console.log(`Metadata batch fetched for ${batch.length} questionIDs.`);
    }

    // Final write to localStorage
    localStorage.setItem("questionsCache", JSON.stringify(questionsCache));
    console.log(
      "Question cache updated with chunk-based IDs + metadata. Total QIDs:",
      Object.keys(questionsCache[networkID].questions).length
    );
  }


  async fetchQuestionResponsesChunked() {
    console.log("fetchQuestionResponsesChunked() - merged fix version");
    if (!this.props.network || !this.props.network.id) {
      console.warn("No valid network in fetchQuestionResponsesChunked, aborting.");
      return;
    }

    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem("questionsCache");
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error("Error parsing questionsCache in fetchQuestionResponsesChunked:", e);
        questionsCache = {};
      }
    }
    if (!questionsCache[networkID]) {
      questionsCache[networkID] = {
        questionsLatestBlock: 0,
        questions: {},
        questionResponses: {},
        questionResponsesLatestBlock: 0
      };
    }

    let fromBlock = questionsCache[networkID].questionResponsesLatestBlock || 0;
    console.log(`Starting chunked question responses from block ${fromBlock} to 'latest'...`);

    const handleProgress = (info) => {
      const {
        chunkFrom, chunkTo, doneSoFarBlocks, totalRangeBlocks,
        chunkEventCount, overallEventCount
      } = info;
      console.log(
        `Fetched chunk [${chunkFrom}..${chunkTo}] with ${chunkEventCount} events. ` +
        `Processed blocks: ${doneSoFarBlocks}/${totalRangeBlocks}. ` +
        `Overall events so far: ${overallEventCount}.`
      );
    };

    const handlePartialData = (partialAggregator, currentToBlock) => {
      const currentQR = questionsCache[networkID].questionResponses;
      for (const qId in partialAggregator) {
        if (!currentQR[qId]) {
          currentQR[qId] = {};
        }
        partialAggregator[qId].forEach((respObj) => {
          const rAddr = respObj.responder.toLowerCase();
          currentQR[qId][rAddr] = respObj.response;
        });
      }
      if (currentToBlock > questionsCache[networkID].questionResponsesLatestBlock) {
        questionsCache[networkID].questionResponsesLatestBlock = currentToBlock;
      }

      localStorage.setItem("questionsCache", JSON.stringify(questionsCache));
    };

    try {
      await contractScripts.getQuestionResponsesChunkedWithCallback(
        this.props.provider,
        fromBlock,
        "latest",
        handleProgress,
        handlePartialData
      );
    } catch (err) {
      console.error("Error in fetchQuestionResponsesChunked:", err);
    }

    console.log("Finished chunked questionResponses loading. Data merged to localStorage.");
  }

  startSurveyAndQuestionEventListener = async () => {
    console.log("startSurveyAndQuestionEventListener() – Setting up survey & question events listener");
    contractScripts.listenForSurveyEvents(this.props.provider, this.onNewSurveyEventDetected);
    console.log("Survey & Question event listener started");
  };

  onNewSurveyEventDetected = async (event) => {
    console.log("onNewSurveyEventDetected() – invoked");
    console.log("event:", event);

    const network = this.props.network;
    const networkID = network?.id;
    if (!networkID) {
      console.error('Network ID undefined in onNewSurveyEventDetected');
      return;
    }

    let surveysCacheStr = localStorage.getItem('surveysCache');
    let surveysCache = {};
    if (surveysCacheStr) {
      try {
        surveysCache = JSON.parse(surveysCacheStr);
      } catch (e) {
        console.error('Error parsing surveysCache in onNewSurveyEventDetected:', e);
        surveysCache = {};
      }
    }
    if (!surveysCache[networkID]) {
      surveysCache[networkID] = {
        surveysLatestBlock: 0,
        surveys: {},
        surveyResponses: {},
        surveyResponsesLatestBlock: {}
      };
    }

    let questionsCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in onNewSurveyEventDetected:', e);
        questionsCache = {};
      }
    }
    if (!questionsCache[networkID]) {
      questionsCache[networkID] = {
        questionsLatestBlock: 0,
        questions: {},
        questionResponses: {},
        questionResponsesLatestBlock: 0
      };
    }

    if (event.type === 'SurveyAdded') {
      const surveyID = event.surveyId.toLowerCase();
      if (!surveysCache[networkID].surveys[surveyID]) {
        const surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
        if (surveyData) {
          surveysCache[networkID].surveys[surveyID] = surveyData;
        }
      }
    } else if (event.type === 'QuestionsAdded') {
      for (const questionIdHex of event.questionIds) {
        const questionId = questionIdHex.toLowerCase();
        if (!questionsCache[networkID].questions[questionId]) {
          const questionData = await contractScripts.getQuestionData(this.props.provider, questionId);
          if (questionData) {
            questionsCache[networkID].questions[questionId] = questionData;
          }
        }
      }
    } else if (event.type === 'ResponsesSubmitted') {
      const surveyId = event.surveyId.toLowerCase();
      const questionIds = event.questionIds.map(q => q.toLowerCase());

      const blockNumber = await contractScripts.getLatestBlockNumber(this.props.provider);

      if (surveyId && surveyId !== ethers.constants.HashZero) {
        const newSurveyResponses = await contractScripts.fetchAllSurveyResponses(
          this.props.provider,
          surveyId
        );
        if (!surveysCache[networkID].surveyResponses[surveyId]) {
          surveysCache[networkID].surveyResponses[surveyId] = {};
        }
        for (const item of newSurveyResponses) {
          const responderAddr = item.responder.toLowerCase();
          surveysCache[networkID].surveyResponses[surveyId][responderAddr] = item.response;
        }
        surveysCache[networkID].surveyResponsesLatestBlock[surveyId] = blockNumber;
      }

      for (const qId of questionIds) {
        const newQuestionResponses = await contractScripts.getResponse(this.props.provider, event.responder, qId);
        if (newQuestionResponses) {
          if (!questionsCache[networkID].questionResponses[qId]) {
            questionsCache[networkID].questionResponses[qId] = {};
          }
          questionsCache[networkID].questionResponses[qId][event.responder.toLowerCase()] = newQuestionResponses;
        }
      }
      if (blockNumber > questionsCache[networkID].questionResponsesLatestBlock) {
        questionsCache[networkID].questionResponsesLatestBlock = blockNumber;
      }
    }

    localStorage.setItem('surveysCache', JSON.stringify(surveysCache));
    localStorage.setItem('questionsCache', JSON.stringify(questionsCache));
    console.log("Updated surveysCache and questionsCache after survey event:", surveysCache, questionsCache);
  };

  // -----------------------------------------
  // LATEST MATCH TAB
  // -----------------------------------------
  loadDemoMatches = async () => {
    console.log("loadDemoMatches() - invoked");
    if (this.props.web2DemoMode) {
      this.setState({ latestMatchNumber: web2DemoData.length - 1 });
      const web2DemoDataFixedBN = await this.traverseBignumberFix(web2DemoData);
      const web2DemoDataFixedBNAndTimestamps = this.updateDemoMatchTimestamps(web2DemoDataFixedBN);
      this.props.updateMatches(web2DemoDataFixedBNAndTimestamps);
      const latestMatchObj = web2DemoDataFixedBNAndTimestamps[web2DemoData.length - 1];
      this.props.updateCurrMatch(latestMatchObj);
      this.props.changeFocusedMatchID(web2DemoData.length);
    }
  };

  updateDemoMatchTimestamps = (dataArray) => {
    return dataArray.map(data => {
      if (data.matchStatus === 'ACTIVE') {
        const currentTimestamp = moment().unix();
        data.votingStartTime = currentTimestamp - 30;
      } else if (data.matchStatus === 'PENDING') {
        const currentTimestamp = moment().unix();
        data.matchStartTime = currentTimestamp + 60;
      }
      return data;
    });
  };

  loadLatestMatch = async () => {
    if (!this.state.loadingLatestMatch && !this.state.loadedLatestMatch) {
      if (this.state.latestMatchNumber != null && this.state.latestMatchNumber > 0) {
        this.setState({ loadingLatestMatch: true });
        let latestMatchObj = await contractScripts.getMatchInfoByID(this.state.latestMatchNumber);
        this.setState({
          loadedLatestMatch: true,
          loadingLatestMatch: false,
        });
        this.props.updateCurrMatch(latestMatchObj);

        const lastRecordedMatch = this.props.matches[this.state.latestMatchNumber - 1];
        if (JSON.stringify(lastRecordedMatch) !== JSON.stringify(latestMatchObj)) {
          this.updateCurrMatchInLocalStorage(latestMatchObj);
        }
      } else {
        console.log("loadLatestMatch - ELSE-BLOCK #1 - this.state.latestMatchNumber == null");
      }
    }
  };

  loadLatestMatchNumber = async () => {
    console.log(" loadLatestMatchNumber() - invoked");
    let latestMatchNumber = await contractScripts.getLatestMatchByLobby(this.props.lobby, this.props.paid);
    console.log("latestMatchNumber:", latestMatchNumber);
    if (latestMatchNumber > 0) {
      this.setState({ latestMatchNumber: latestMatchNumber });
    }
  };

  loadAllMatches = async () => {
    console.log("loadAllMatches - invoked");
    let numLobbyMatches = await contractScripts.getNumberOfMatchesByLobby(this.props.lobby, this.props.paid);
    console.log("numLobbyMatches", numLobbyMatches);
    if (numLobbyMatches > 0 && !this.state.loadedAllMatches && !this.state.loadingAllMatches) {
      this.setState({ loadingAllMatches: true });
      let allMatchesObj = await contractScripts.getMatchesByLobby(this.props.lobby, this.props.paid);

      if (allMatchesObj.length !== 0) {
        console.log("ALL MATCHES UPDATED");
        this.setState({ loadedAllMatches: true });
        this.props.updateMatches(allMatchesObj);
        this.saveMatchesToLocalStorage(allMatchesObj);
        console.log("allmatchesObj:", allMatchesObj);
      }
    }
  };

  saveMatchesToLocalStorage = (matchesObj) => {
    console.log("saveMatchesToLocalStorage() - invoked");
    const matchesArrayString = JSON.stringify(matchesObj);
    localStorage.setItem('matchesCache', matchesArrayString);
  };

  loadMatchesFromLocalStorage = async () => {
    console.log("loadMatchesFromLocalStorage() - invoked");
    this.setState({ loadingAllMatches: true });
    const retrievedMatchesArrayString = localStorage.getItem('matchesCache');
    const matchesCache = JSON.parse(retrievedMatchesArrayString || "null");

    if (this.state.latestMatchNumber == null) {
      await this.loadLatestMatchNumber();
    }

    console.log("matchesCache:", matchesCache);
    console.log("this.state.latestMatchNumber:", this.state.latestMatchNumber);

    if (matchesCache != null && matchesCache.length <= this.state.latestMatchNumber && this.state.searchForCachedMatches) {
      console.log("loadMatchesFromLocalStorage() - #1 - matchesCache != null");
      const latestMatchInCache = matchesCache[matchesCache.length - 1];
      console.log("latestMatchInCache:", latestMatchInCache);
      if (latestMatchInCache.ID == this.state.latestMatchNumber) {
        this.loadLatestMatch();
        this.setState({ loadedAllMatches: true, loadingAllMatches: false });
        const matchesCacheWithProperBignumbers = await this.traverseBignumberFix(matchesCache);
        this.props.updateMatches(matchesCacheWithProperBignumbers);
      } else {
        this.loadMissingMatches(matchesCache);
      }
    } else {
      this.setState({ loadingAllMatches: false });
      this.loadAllMatches();
    }
  };

  traverseBignumberFix = async (data) => {
    if (Array.isArray(data)) {
      data.forEach(item => this.traverseBignumberFix(item));
    } else if (typeof data === 'object' && data !== null) {
      Object.entries(data).forEach(([key, value]) => {
        if (value && value.type === 'BigNumber') {
          data[key] = contractScripts.getBigNumber(value, "MemewarsSite.jsx");
        }
        this.traverseBignumberFix(value);
      });
    }
    return data;
  };

  getFocusedMatchObject = () => {
    const matches = this.props.matches;
    const focusedMatchID = this.props.focusedMatchID;

    if (matches[focusedMatchID - 1] != null) {
      return matches[focusedMatchID - 1];
    } else {
      console.log("getFocusedMatchObject() - focusedMatchID not found, loading from chain");
      this.setState({ loadingFocusedMatch: true });
      const focusedMatchObj = this.loadMatchByID(focusedMatchID);
      this.setState({
        loadedFocusedMatch: true,
        loadingFocusedMatch: false
      });
      return focusedMatchObj;
    }
  };

  extendMatchesArrayForNewMatch = async () => {
    const matchesArrayCopy = [...this.props.matches];
    console.log("matchesArrayCopy:", matchesArrayCopy);

    const latestMatchObj = await contractScripts.getMatchInfoByID(this.state.latestMatchNumber);
    console.log("latestMatchObj:", latestMatchObj);

    const matchesArrayCopyWithLatestMatchAdded = matchesArrayCopy.concat(latestMatchObj);
    console.log("matchesArrayCopyWithLatestMatchAdded:", matchesArrayCopyWithLatestMatchAdded);
    this.props.updateMatches(matchesArrayCopyWithLatestMatchAdded);
  };

  // -----------------------------------------
  // CONTRACT EVENT HANDLERS
  // -----------------------------------------
  startEventListener = async () => {
    contractScripts.listenForNewEvents(this.onNewEventDetected);
    this.setState({ listeningForEvents: true });
  };

  onNewEventDetected = async (event) => {
    console.log("onNewEventDetected() – invoked");
    console.log("event: ", event);

    const newEvent = event.blockNumber > this.state.latestBlockNumber;
    console.log("newEvent:", newEvent);
    console.log("event.blockNumber:", event.blockNumber);
    console.log("this.state.latestBlockNumber:", this.state.latestBlockNumber);

    if (newEvent && event.eventSignature === "NewMatch(uint256,bytes32,bool)") {
      this.onNewMatchDetected(event);
    }
    else if (newEvent && event.eventSignature === "MatchInfoUpdate(uint256,bytes32,bool)") {
      this.onMatchInfoUpdateDetected(event);
    }
    else if (newEvent && event.eventSignature === "MatchCancelled(uint256,bytes32,bool)") {
      this.onMatchCancelledDetected(event);
    }

    if (newEvent && event.eventSignature === "NewProposalSubmitted(bytes32,bytes32,bool,address)") {
      this.onNewProposalSubmittedDetected(event);
    }
    else if (newEvent && event.eventSignature === "ProposalVoteChange(bytes32,int256)") {
      this.onProposalVoteChangeDetected(event);
    }
    else if (newEvent && event.eventSignature === "ProposalChosen(bytes32,bytes32,bool)") {
      this.onProposalChosenDetected(event);
    }
    else if (event.eventSignature === "DefaultProposalUpdated(bytes32,bytes32,bool)") {}
    else if (event.eventSignature === "NewLobbyCreated(string,bytes32)") {}

    if (newEvent) {
      this.setState({ latestBlockNumber: event.blockNumber });
    }
  };

  onNewMatchDetected = async (event) => {
    const newMatchID = event.args[0].toNumber();
    var currMatchID = this.props.currMatch != null ? this.props.currMatch.ID : 0;
    if (newMatchID > currMatchID) {
      console.log("setting this.state.loadedLatestMatch = FALSE");
      this.setState({ latestMatchNumber: newMatchID });
      await this.extendMatchesArrayForNewMatch();
      this.setState({ loadedLatestMatch: false, loadingLatestMatch: false });
      await this.loadLatestMatch();
      if (this.state.autoSnapToNewMatch) {
        this.props.changeFocusedMatchID(newMatchID);
      }
    }
  };

  onMatchInfoUpdateDetected = async (event) => {
    console.log("onMatchInfoUpdateDetected() – invoked");
    this.setState({ loadedLatestMatch: false, loadingLatestMatch: false });
    await this.loadLatestMatch();
  };

  onMatchCancelledDetected = async (event) => {
    console.log("onMatchCancelledDetected() – invoked");
    this.setState({ loadedLatestMatch: false, loadingLatestMatch: false });
    await this.loadLatestMatch();
    if (this.props.loginComplete && this.props.currMatch != null) {
      await this.getLatestBalances();
    }
  };

  onNewProposalSubmittedDetected = async (event) => {
    console.log("onNewProposalSubmittedDetected() – invoked");
    this.setState({ loadedProposals: false, loadingProposals: false });
    this.getLobbyProposals();
  };

  onProposalVoteChangeDetected = async (event) => {
    console.log("onProposalVoteChangeDetected() – invoked");
    this.setState({ loadedProposals: false, loadingProposals: false });
    this.getLobbyProposals();
  };

  onProposalChosenDetected = async (event) => {
    console.log("onProposalChosenDetected() – invoked");
    this.setState({ loadedProposals: false, loadingProposals: false });
    this.getLobbyProposals();
  };

  // -----------------------------------------
  // PROPOSALS
  // -----------------------------------------
  getLobbyProposals = async () => {
    if (this.props.web2DemoMode) {
      console.log("getLobbyProposals() - loading demo proposals:", web2DemoProposals);
      this.props.updateProposals(web2DemoProposals);
      this.setState({ loadedProposals: true });
    }
    else if (!this.state.loadingProposals && !this.state.loadedProposals) {
      this.setState({ loadingProposals: true });
      let lobbyProposals = await contractScripts.getProposalsByLobby(this.props.lobby, this.props.paid);
      const emptyProposalsResponse = [];
      if (lobbyProposals !== emptyProposalsResponse) {
        this.props.updateProposals(lobbyProposals);
        this.setState({ loadedProposals: true });
      }
      this.setState({ loadingProposals: false });
    }
  };

  updateLatestBlockNumber = async () => {
    const lastBlockNumber = await contractScripts.getLatestBlockNumber(this.props.provider);
    this.setState({ latestBlockNumber: lastBlockNumber });
  };

  // -----------------------------------------
  // REACT ROUTING
  // -----------------------------------------

  getMainView = (relevantMatch) => {
    if (this.props.path === "/" || this.props.path === "") {
      return (
        <div id={styles.main}>
          <MainAreaTabs
            updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
            changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)}
            updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)}
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)}
            account={this.props.account}
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance}
            ETHbalance={this.props.ETHBalance}
            availableETH={this.props.availableETH}
            lobby={this.props.lobby}
            paid={this.props.paid}
            match={relevantMatch}
            currMatch={this.props.currMatch}
            matches={this.props.matches}
            proposals={this.props.proposals}
            focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
            demoMode={this.props.web2DemoMode}
            network={this.props.network}
            isAllCachesReady={this.state.isAllCachesReady}
            cacheHasLoaded={this.state.cacheHasLoaded}
          />

          <RightsideAlt
            numPlayers={this.props.numPlayers}
            account={this.props.account}
            demoMode={this.props.web2DemoMode}
          />
        </div>
      );
    }

    else if (this.props.path === "/polis") {
      return <div>Polis or something else</div>;
    }

    else if (this.props.path.includes("/compare/")) {
      const firstAddress = this.props.path.slice(4, 46);
      return (
        <CompareAddresses firstAddress={firstAddress} />
      );
    }

    else if (
      this.props.path.includes("/surveys") ||
      this.props.path.includes("/survey/") ||
      this.props.path.includes("/questions")
    ) {
      const pathParts = this.props.path.split('/');
      let autoOpenResults = false;
      if ((pathParts[2] === 'results') || (pathParts[3] === 'results')) {
        autoOpenResults = true;
      }
      const surveyID = this.props.path.slice(8, 74);
      const displayAnswerMode =
        pathParts.length > 3 && pathParts[3].startsWith('0x') && pathParts[3].length === 42;
      const viewResponseAddress = displayAnswerMode ? pathParts[3] : null;

      let filterState = {};

      return (
        <SurveyPage
          surveyID={surveyID}
          displayAnswerMode={displayAnswerMode}
          viewAddress={viewResponseAddress}
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          account={this.props.account}
          provider={this.props.provider}
          joined={this.props.joined}
          XPBalance={this.props.XPBalance}
          ETHbalance={this.props.ETHBalance}
          availableETH={this.props.availableETH}
          lobby={this.props.lobby}
          paid={this.props.paid}
          loginComplete={this.props.loginComplete}
          loginInProgress={this.props.loginInProgress}
          network={this.props.network}
          isSurveyCacheReady={this.state.isSurveyCacheReady}
          isQuestionCacheReady={this.state.isQuestionCacheReady}
          refreshSurveyResponsesByID={(id) => this.refreshSurveyResponsesByID(id)}
          refreshQuestionMetadata={() => this.refreshQuestionMetadata()}
          refreshQuestionResponses={() => this.refreshQuestionResponses()}
          autoOpenResults={autoOpenResults}
          filterState={filterState}
        />
      );
    }

    else if (this.props.path.includes("/question/")) {
      const pathParts = this.props.path.split('/');
      const questionIndex = pathParts.indexOf('question');
      const questionID = pathParts[questionIndex + 1];
      const responderAddress = pathParts[questionIndex + 2] || null;
      return (
        <SurveyTool
          questionID={questionID}
          responderAddress={responderAddress}
          singleQuestionMode={true}
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          account={this.props.account}
          provider={this.props.provider}
          joined={this.props.joined}
          XPBalance={this.props.XPBalance}
          loginComplete={this.props.loginComplete}
          loginInProgress={this.props.loginInProgress}
          network={this.props.network}
          isQuestionCacheReady={this.state.isQuestionCacheReady}
          refreshSurveyResponsesByID={(id) => this.refreshSurveyResponsesByID(id)}
          refreshQuestionMetadata={() => this.refreshQuestionMetadata()}
          refreshQuestionResponses={() => this.refreshQuestionResponses()}
        />
      );
    }

    else if (this.props.path.includes("/sbts")) {
      return (
        <SBTsPage
          provider={this.props.provider}
          account={this.props.account}
          network={this.props.network}
          modalView={true}
          loginComplete={this.props.loginComplete}
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          expanded={true}
          miniaturized={false}
          isSBTCacheReady={this.state.isSBTCacheReady}
        />
      );
    }

    else if (this.props.path.includes("/sbt/")) {
      const path = this.props.path;
      const pathParts = path.split('/');
      const sbtAddress = pathParts[2];
      const sbtPassword = pathParts.length > 3 ? pathParts[3] : null;
      return (
        <SBTPage
          SBTAddress={sbtAddress}
          sbtMintPassword={sbtPassword}
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          account={this.props.account}
          provider={this.props.provider}
          joined={this.props.joined}
          XPBalance={this.props.XPBalance}
          loginComplete={this.props.loginComplete}
          loginInProgress={this.props.loginInProgress}
          network={this.props.network}
          chains={this.props.wagmiChainOptions}
          blockNumber={this.props.wagmiBlocknumber}
          isSBTCacheReady={this.state.isSBTCacheReady}
        />
      );
    }

    else if (this.props.path.includes("/su/")) {
      const simUsername = this.props.path.slice(4);
      return (
        <SimulatedUserPage
          simUsername={simUsername}
          provider={this.props.provider}
          network={this.props.network}
        />
      );
    }

    else if (this.props.path.includes("0x")) {
      const viewAddress = this.props.path.slice(1).replace("u/", "");
      return (
        <UserPage
          viewAddress={viewAddress}
          address={this.props.address}
          provider={this.props.provider}
          network={this.props.network}
        />
      );
    }

    else if (this.props.path === "/about") {
      return (
        <AboutPage />
      );
    }

    else if (this.props.path === "/matrix") {
      return (
        <RiskMatrixDemo />
      );
    }

    else if (this.props.path === "/contracts" || this.props.path === "/contracts/") {
      return (
        <ContractPage />
      );
    }

    // -------------------------------------
    // NEW DEMO ROUTE FOR /demo[/orgName][/password]
    // -------------------------------------
    else if (this.props.path.includes("/demo")) {
      const pathParts = this.props.path.split("/");
      let orgName = pathParts[2] || null;
      let password = pathParts[3] || null;

      // If the user typed /demo/foo/password=XYZ, parse out the actual password
      if (password && password.startsWith("password=")) {
        password = password.slice("password=".length);
      }

      // Lookup org config from demoGroups
      let orgConfig = {};
      if (orgName && demoGroups[orgName]) {
        orgConfig = demoGroups[orgName];
      }

      // BEGIN ADDED - pass orgConfig fields down
      return (
        <OnePageDemo
          orgName={orgName}
          password={password}
          orgHeader={orgConfig.orgHeader}
          orderHeaderImg={orgConfig.orderHeaderImg}
          orgInfo={orgConfig.orgInfo}
          defaultTags={orgConfig.defaultTags}
          defaultFilterState={orgConfig.defaultFilterState}
          defaultFeaturedSBTs={orgConfig.defaultFeaturedSBTs}
          // END ADDED

          account={this.props.account}
          provider={this.props.provider}
          network={this.props.network}
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          loginComplete={this.props.loginComplete}
          isSBTCacheReady={this.state.isSBTCacheReady}
          isSurveyCacheReady={this.state.isSurveyCacheReady}
          isQuestionCacheReady={this.state.isQuestionCacheReady}
          refreshSurveyResponsesByID={(id) => this.refreshSurveyResponsesByID(id)}
          refreshQuestionMetadata={() => this.refreshQuestionMetadata()}
          refreshQuestionResponses={() => this.refreshQuestionResponses()}

          featuredSBTAddresses={featured_SBTs_LIST}
        />
      );
    }

  };

  render() {
    const currMatchDefined = this.props.currMatch != null;
    const noMatches = this.props.focusedMatchID == null || this.props.matches.length === 0;
    const currMatchIsFocused = noMatches || (currMatchDefined && this.props.focusedMatchID?.toString() === this.props.currMatch.ID?.toString());
    const relevantMatch = currMatchIsFocused ? this.props.currMatch : this.getFocusedMatchObject();

    const mainViewDisplay = this.getMainView(relevantMatch);

    return (
      <>
        <Navbar
          updateXPBalance={(newXPBalance) => this.newXPBalance(newXPBalance)}
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
          toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)}
          demoMode={this.props.web2DemoMode}
          account={this.props.account}
          provider={this.props.provider}
          joined={this.props.joined}
          XPBalance={this.props.XPBalance}
          ETHbalance={this.props.ETHBalance}
          availableETH={this.props.availableETH}
          lobby={this.props.lobby}
          paid={this.props.paid}
          focusedTab={this.props.focusedTab}
          loginComplete={this.props.loginComplete}
          loginInProgress={this.props.loginInProgress}
          sendTestETH={(amountToSend) => this.getUserTestETH(amountToSend)}
        />

        { mainViewDisplay }

        <Footer
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
        />
      </>
    );
  }

  refreshSurveyResponsesByID = async (surveyID) => {
    console.log("refreshSurveyResponsesByID() for surveyID:", surveyID);
    if (!this.props.network || !this.props.network.id) {
      console.warn("Network ID not available in refreshSurveyResponsesByID");
      return;
    }
    const netId = this.props.network.id.toString();
    let surveysCacheStr = localStorage.getItem('surveysCache') || '{}';
    let surveysCache = {};
    try {
      surveysCache = JSON.parse(surveysCacheStr);
    } catch (e) {
      console.error("Error parsing surveysCache:", e);
      surveysCache = {};
    }
    if (!surveysCache[netId]) {
      surveysCache[netId] = {
        surveysLatestBlock: 0,
        surveys: {},
        surveyResponses: {},
        surveyResponsesLatestBlock: {}
      };
    }

    if (
      !surveysCache[netId].surveyResponsesLatestBlock ||
      typeof surveysCache[netId].surveyResponsesLatestBlock !== 'object'
    ) {
      console.warn(
        "surveyResponsesLatestBlock was not an object. Resetting to {} to avoid collisions with older cache."
      );
      surveysCache[netId].surveyResponsesLatestBlock = {};
    }

    const currentLocalBlock = surveysCache[netId].surveyResponsesLatestBlock[surveyID.toLowerCase()] || 0;
    const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);

    if (currentLocalBlock >= latestBlock) {
      console.log("Survey is already up-to-date for surveyID:", surveyID);
      return;
    }

    console.log(
      `Fetching new responses for surveyID ${surveyID} from block ${currentLocalBlock + 1} to ${latestBlock}`
    );
    const newResponses = await contractScripts.fetchAllSurveyResponses(
      this.props.provider,
      surveyID.toLowerCase()
    );

    if (!surveysCache[netId].surveyResponses[surveyID.toLowerCase()]) {
      surveysCache[netId].surveyResponses[surveyID.toLowerCase()] = {};
    }
    for (const item of newResponses) {
      const responderAddr = item.responder.toLowerCase();
      surveysCache[netId].surveyResponses[surveyID.toLowerCase()][responderAddr] = item.response;
    }

    surveysCache[netId].surveyResponsesLatestBlock[surveyID.toLowerCase()] = latestBlock;

    localStorage.setItem('surveysCache', JSON.stringify(surveysCache));
    console.log("Survey responses updated for surveyID:", surveyID);
    return;
  };

  refreshQuestionMetadata = async () => {
    console.log("refreshQuestionMetadata() - invoked");
    if (!this.props.network || !this.props.network.id) {
      console.warn("No network for refreshQuestionMetadata, aborting.");
      return;
    }
    await this.initializeQuestionCache();
    console.log("refreshQuestionMetadata() - done");
  };

  refreshQuestionResponses = async () => {
    console.log("refreshQuestionResponses() - invoked");
    if (!this.props.network || !this.props.network.id) {
      console.warn("No network for refreshQuestionResponses, aborting.");
      return;
    }
    await this.fetchQuestionResponsesChunked();
    console.log("refreshQuestionResponses() - done");
  };
}

MainSite.propTypes = {
  updateProposals: PropTypes.func.isRequired,
  fetchSessionState: PropTypes.func.isRequired,
  fetchAccount: PropTypes.func.isRequired,
  changeFocusedTab: PropTypes.func.isRequired,
  updateXPBalance: PropTypes.func.isRequired,
  updateETHBalance: PropTypes.func.isRequired,
  toggleLoginModal: PropTypes.func.isRequired,
  updateLoginInfo: PropTypes.func.isRequired,
  updateCurrMatch: PropTypes.func.isRequired,
  updateMatches: PropTypes.func.isRequired,
  toggleDemoMode: PropTypes.func.isRequired,
  changeFocusedMatchID: PropTypes.func.isRequired,
  profile: PropTypes.object,
  account: PropTypes.string,
  provider: PropTypes.string,
  network: PropTypes.object,
  joined: PropTypes.bool,
  XPBalance: PropTypes.object,
  ETHBalance: PropTypes.object,
  availableETH: PropTypes.object,
  lobbyInfo: PropTypes.object,
  lobby: PropTypes.string,
  paid: PropTypes.bool,
  currMatch: PropTypes.object,
  matches: PropTypes.array.isRequired,
  proposals: PropTypes.array,
  sessionState: PropTypes.object,
  focusedTab: PropTypes.number,
  loginComplete: PropTypes.bool,
  loginInProgress: PropTypes.bool,
  focusedMatchID: PropTypes.number,
  web2DemoMode: PropTypes.bool,
};

const mapStateToProps = state => ({
  profile: state.profile,
  account: state.profile.account,
  provider: state.profile.provider,
  network: state.profile.network,
  joined: state.profile.joined,
  XPBalance: state.profile.XPBalance,
  ETHBalance: state.profile.ETHBalance,
  availableETH: state.profile.availableETH,
  lobbyInfo: state.lobbyInfo,
  lobby: state.lobbyInfo.lobby,
  paid: state.lobbyInfo.paid,
  currMatch: state.lobbyInfo.currMatch,
  matches: state.lobbyInfo.matches,
  proposals: state.lobbyInfo.proposals,
  sessionState: state.sessionState,
  focusedTab: state.sessionState.focusedTab,
  focusedMatchID: state.sessionState.focusedMatchID,
  focusedEntry: state.sessionState.focusedEntry,
  loginComplete: state.sessionState.loginComplete,
  loginInProgress: state.sessionState.loginInProgress,
  web2DemoMode: state.sessionState.web2DemoMode,
});

const MainSiteWithWagmiHooks = WagmiHooksHOC(MainSite);

export default connect(mapStateToProps, {
    fetchAccount,
    fetchSessionState,
    changeFocusedTab,
    toggleLoginModal,
    updateLoginInfo,
    updateCurrMatch,
    updateMatches,
    updateXPBalance,
    updateETHBalance,
    updateProposals,
    changeFocusedMatchID,
    toggleDemoMode
})(MainSiteWithWagmiHooks);
