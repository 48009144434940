import React, { useState } from 'react';
import styles from './AboutPage.module.scss';
import cipPhoto from '../../assets/img/cipPhoto.png';

const AboutPage = () => {
  const [showRoadmap, setShowRoadmap] = useState(false);
  const [showPresent, setShowPresent] = useState(false);
  const [showFuture, setShowFuture] = useState(false);

  return (
    <div className={styles.aboutPageContainer}>
      {/* Title / Heading */}
      <h1 className={styles.mainTitle}>Context Engine (contextengine@protonmail.com)</h1>

      {/* Short Description */}
      <section className={styles.section}>
        {/* <h2 className={styles.sectionTitle}>Short Description</h2> */}
        <p>
          CE is software for large-group discourse / topic mapping / prediction tracking / priority
          surfacing on AI policy issues via gamified social voting, aiming to familiarize users with
          ethereum wallets (as PKI / login) and{" "} quadratic voting while improving the quality of AI policy debates.
        </p>

        {/* Embedded Google Drive Video */}
        <iframe
          src="https://drive.google.com/file/d/1nss6RZnF4yFwMFE6kjSW3ESi3ImpMcnf/preview"
          className={styles.demoVideo}
          allow="autoplay"
          allowFullScreen
          title="Quadratic Voting Demo"
        />
      </section>

      {/* Summary */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Summary</h2>
        <p>
          <strong>Context Engine</strong> is a set of <strong>software tools</strong> built
          to help coordinate a whole-society response to rapid AI progress. The
          first stage (<strong>Augmented Deliberation</strong>) is 95% complete and meant to
          address:
        </p>
        <ul className={styles.customList}>
          <li>
            <em>
              Oversimplified (AI/…) discourse (from inadequate formats + info
              overwhelm)
            </em>{" "}
            results in tribalism and lower-dimensional debate than is needed for{" "}
            <a
              href="https://www.goodreads.com/en/book/show/55711592"
              target="_blank"
              rel="noopener noreferrer"
            >
              productive conflict
            </a>{" "}
            (to prepare society for technological shocks)
            <ul>
              <li>
                Leaders (political/industry) can under-specify positions due to
                absence of basic set of questions
              </li>
              <li>
                Debate is unapproachable for lay people (no time-efficient or
                intuitive “map” of relevant questions)
              </li>
            </ul>
          </li>
          <li>
            <em>
              No simple way to record views / predictions (in short/long format)
              on various (AI/…) topics
            </em>
            , such that responses:
            <ul>
              <li>
                Can be public and/or encrypted (for easy comparison and/or
                retroactive ZK evaluation)
              </li>
              <li>
                In standard format which can be summarized / aggregated /
                compared / evaluated / operated on by AI systems
              </li>
              <li>
                Are stored permanently in open-source way (Ethereum Smart
                Contracts on Base L2 + Arweave)
              </li>
              <li>
                Can be filtered/encrypted by varied attestations,
                proof-of-human credentials, and/or{" "}
                <a
                  href="https://www.ledger.com/academy/topics/blockchain/what-is-a-soulbound-token"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SBT
                </a>{" "}
                (non-transferrable NFTs) issued by disparate communities
              </li>
            </ul>
          </li>
        </ul>
      </section>

      {/* Toggle Roadmap Section */}
      <section className={styles.section}>
        <div
          className={styles.toggleHeader}
          onClick={() => setShowRoadmap(!showRoadmap)}
        >
          <h2 className={styles.sectionTitle}>Stages / Milestones (Roadmap)</h2>
          <span className={styles.toggleIcon}>
            {showRoadmap ? "–" : "+"}
          </span>
        </div>
        {showRoadmap && (
          <div className={styles.collapsibleContent}>
            <ul className={styles.customList}>
              <li>
                <strong>Stage 1:</strong>
                <ul>
                  <li>
                    Goal: Create a useful extension of pol.is (large-group
                    deliberation tool)
                  </li>
                  <li>
                    Goal: Populate live “AI Safety Debate Database” via
                    simulated historical figures
                  </li>
                  <li>
                    Overlap With:{" "}
                    <a
                      href="http://Pol.is"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pol.is
                    </a>
                    ,{" "}
                    <a
                      href="https://cip.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CIP
                    </a>
                    ,{" "}
                    <a
                      href="https://ai.objectives.institute/about-aoi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AOI
                    </a>{" "}
                    (TalkToTheCity), RxC (
                    <a
                      href="https://www.radicalxchange.org/wiki/quadratic-voting/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Quadratic Voting
                    </a>
                    )
                  </li>
                </ul>
              </li>
              <li>
                <strong>Stage 2:</strong>
                <ul>
                  <li>
                    Goal: Operate a public trial to add data to live “AI Safety
                    Database” → improve quality of AI policy discourse
                  </li>
                  <li>
                    Overlap With Orgs / Tools:{" "}
                    <a
                      href="https://www.radicalxchange.org/wiki/social-identity/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RxC
                    </a>
                    ,{" "}
                    <a
                      href="https://www.publiceditor.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Public Editor
                    </a>
                    ,{" "}
                    <a
                      href="https://ai.objectives.institute/about-aoi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AOI
                    </a>
                    ,{" "}
                    <a
                      href="https://www.societylibrary.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Society Library
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Stage 3:</strong>
                <ul>
                  <li>
                    Goal: Add advanced cryptographic aspects to existing tools
                  </li>
                  <li>
                    Overlap With Orgs / Tools:{" "}
                    <a
                      href="https://0xparc.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      0xPARC
                    </a>
                    ,{" "}
                    <a
                      href="https://hackmd.io/@UZefrcjJRqqup81fgbVkVw/rJctne6DA#Some-previous-Dapp-submissions-on-INCO"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Inco
                    </a>
                    ,{" "}
                    <a
                      href="https://github.com/zkemail"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ZKemail
                    </a>
                    , …
                  </li>
                </ul>
              </li>
              <li>
                <strong>Stage 4 / 5 / … :</strong>
                <ul>
                  <li>
                    Goal: Collaborative group-prompting tools (for worldbuilding
                    / “future imagining”)
                  </li>
                  <li>
                    Goal: Familiarize people with quadratic voting, FHE, ZK
                    proofs via ^
                  </li>
                  <li>
                    Goal: “Data Labor” tools for groups to profit from data in
                    privacy-preserving ways
                  </li>
                  <li>
                    Goal: Trial idea for funding public goods via opt-in novel
                    ads experiment
                  </li>
                  <li>
                    Goal: AI Agents that learn from preferences and represent
                    groups in governance
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              (CE aims to fit some of these categories. Source:{" "}
              <a
                href="https://cip.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                CIP
              </a>
              )
            </p>
            <img
              src={cipPhoto}
              alt="CIP source"
              className={styles.roadmapImage}
            />
          </div>
        )}
      </section>

      {/* Toggle Present Functionalities */}
      <section className={styles.section}>
        <div
          className={styles.toggleHeader}
          onClick={() => setShowPresent(!showPresent)}
        >
          <h2 className={styles.sectionTitle}>Present Functionalities</h2>
          <span className={styles.toggleIcon}>
            {showPresent ? "–" : "+"}
          </span>
        </div>
        {showPresent && (
          <div className={styles.collapsibleContent}>
            <ul className={styles.customList}>
              <li>
                CE already accepts multi-format survey inputs, stores them on
                Arweave, stores the Arweave hashes in Smart Contracts (deployed
                on the Base Sepolia L2 Testnet for now), and outputs .CSVs for
                generating pol.is-style / TalkToTheCity reports.
              </li>
              <li>
                CE also allows people to deploy custom SBTs (on Base Sepolia L2
                for now), and there is the beginning of an interactive version
                of the Deepmind Policy Atlas (see “Debate Map” in the demo).
              </li>
              <li>
                CE also has a prototype tool that generates seed-statements
                compatible with existing tooling (pol.is, TalkToTheCity,
                SocietyLibrary) from URL or pasted content (see “Database” in
                demo).
              </li>
            </ul>
          </div>
        )}
      </section>

      {/* Toggle Future Functionalities / Roadmap */}
      <section className={styles.section}>
        <div
          className={styles.toggleHeader}
          onClick={() => setShowFuture(!showFuture)}
        >
          <h2 className={styles.sectionTitle}>
            Future Functionalities / Roadmap
          </h2>
          <span className={styles.toggleIcon}>
            {showFuture ? "–" : "+"}
          </span>
        </div>
        {showFuture && (
          <div className={styles.collapsibleContent}>
            <ul className={styles.customList}>
              <li>ZK Privacy</li>
              <li>SBT Private Groups and Data</li>
              <li>ZK Passport for privacy-preserving location map of users</li>
            </ul>
            <p>
              <strong>Stage 3: Advanced Cryptographic Functionalities</strong>
            </p>
            <ul className={styles.customList}>
              <li>
                <strong>Zero-Knowledge Proofs (ZK) / OpenPassport:</strong>
                <ul>
                  <li>Encrypted future predictions / survey responses</li>
                  <li>Store predictions on AI progress/policy outcomes</li>
                  <li>Enable retroactive evaluation to identify top predictors</li>
                  <li>“Trust voting” on leaders in government / industry</li>
                  <li>AI lab employee whistleblowing toolkit</li>
                  <li>Filter by Privacy-preserving Proof-of-Unique-Human</li>
                </ul>
              </li>
              <li>
                <strong>Threshold Encryption with Silent Setup:</strong> Private
                formation / data sharing / “data labor” for SBT groups
              </li>
              <li>
                <strong>Fully Homomorphic Encryption (FHE):</strong>{" "}
                Privacy-preserving group model training and preference
                aggregation
              </li>
            </ul>
            <p>
              <strong>Rationale and AI Safety Benefits</strong>:
            </p>
            <ul className={styles.customList}>
              <li>Enhance trust in collective decision-making processes</li>
              <li>Incentivize accurate forecasting and responsible development</li>
              <li>Facilitate anonymous reporting of AI safety issues</li>
              <li>Protect individual privacy while leveraging collective intelligence</li>
              <li>Create accountable yet flexible governance structures</li>
              <li>Allow groups to safely monetize data/expertise for AI training</li>
            </ul>
            <p>
              <strong>Groups to work with / software to leverage:</strong> 0xParc,
              zkEmail, Zama (FHE), Inco (FHE), …
            </p>
          </div>
        )}
      </section>
    </div>
  );
};

export default AboutPage;
