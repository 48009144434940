/***************************************************
 * FILE: SBTsPage.jsx
 * LOCATION: my-app/client/src/components/SBTs/SBTsPage.jsx
 ***************************************************/

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './SBTsPage.module.scss';
import SBTsList from './SBTsList';
import CreateGroup from './CreateSBTGroup';
import SBTPage from './SBTPage';
import { featured_SBTs_LIST } from '../../variables/CONTRACT_ADDRESSES.js';

class SBTsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSBTsList: false,
      showCreateGroup: false,
      loadingSBTs: true,
    };

    this.toggleSBTsList = this.toggleSBTsList.bind(this);
    this.toggleCreateGroup = this.toggleCreateGroup.bind(this);

    // We track the timeout so we can clear it if unmounted
    this.timeoutId = null;
  }

  componentDidMount() {
    // Simulate loading of SBTs
    this.timeoutId = setTimeout(() => {
      this.setState({ loadingSBTs: false });
    }, 1000);
  }

  componentWillUnmount() {
    // Clear the pending timeout so state isn’t updated after unmount
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  toggleSBTsList() {
    this.setState(prevState => ({ showSBTsList: !prevState.showSBTsList }));
  }

  toggleCreateGroup() {
    this.setState(prevState => ({ showCreateGroup: !prevState.showCreateGroup }));
  }

  render() {
    // ADDED: usage stub for orgInfo
    if (this.props.orgInfo) {
      console.log('[SBTsPage] orgInfo received:', this.props.orgInfo);
    }

    const { provider, network, account, loginComplete, toggleLoginModal } = this.props;
    const { showSBTsList, showCreateGroup, loadingSBTs } = this.state;

    // actualFeaturedList override
    const actualFeaturedList = (this.props.defaultFeaturedSBTs && this.props.defaultFeaturedSBTs.length)
      ? this.props.defaultFeaturedSBTs
      : featured_SBTs_LIST;

    console.log('SBTsPage loginComplete:', loginComplete);

    return (
      <div>
        {this.props.miniaturized ? (
          <div>
            <h2 className={styles.featuredTitle}>Featured Groups</h2>
            {loadingSBTs ? (
              <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
            ) : (
              <div className={styles.sbtGrid}>
                {actualFeaturedList.map((sbtAddress, index) => (
                  <SBTPage
                    key={index}
                    SBTAddress={sbtAddress}
                    account={account}
                    provider={provider}
                    network={network}
                    miniaturized={true}
                    miniMintable={true}
                    loginComplete={loginComplete}
                    toggleLoginModal={toggleLoginModal}
                    //
                    sbtMintPassword={this.props.password}
                  />
                ))}
              </div>
            )}

            <h2 className={styles.sectionTitle}>Minting Live</h2>
            <SBTsList
              provider={provider}
              network={network}
              account={account}
              loginComplete={loginComplete}
              miniaturized={true}
              toggleLoginModal={toggleLoginModal}
            />

            <div className={styles.container}>
              <button
                onClick={() => window.open("/sbts", "_blank")}
                className={styles.expandButton}
              >
                <FontAwesomeIcon icon={faExpand} /> View All SBTs
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h2 className={styles.featuredTitle}>Featured Groups</h2>
            {loadingSBTs ? (
              <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
            ) : (
              <div className={styles.sbtGrid}>
                {actualFeaturedList.map((sbtAddress, index) => (
                  <SBTPage
                    key={index}
                    SBTAddress={sbtAddress}
                    account={account}
                    provider={provider}
                    network={network}
                    miniaturized={true}
                    miniMintable={true}
                    loginComplete={loginComplete}
                    toggleLoginModal={toggleLoginModal}
                    //
                    sbtMintPassword={this.props.password}
                  />
                ))}
              </div>
            )}

            <div className={styles.container}>
              <div id={styles.buttonRow}>
                <button
                  onClick={() => (window.location.href = "/sbts")}
                  className={styles.backButton}
                >
                  <FontAwesomeIcon icon={faExpand} /> Full SBTs list
                </button>
                <button
                  id={styles.showResultsButton}
                  onClick={this.toggleCreateGroup}
                >
                  {showCreateGroup ? "Exit Group Creation" : "Create Group"}
                </button>
              </div>
            </div>

            {showCreateGroup && (
              <CreateGroup
                account={account}
                loginComplete={loginComplete}
                provider={provider}
                toggleLoginModal={toggleLoginModal}
                expanded={showCreateGroup}
                network={network}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default SBTsPage;
