/***************************************************
 * FILE: OnePageDemo_Class.jsx
 * LOCATION: my-app/client/src/components/DemoPages/OnePageDemo_Class.jsx
 ***************************************************/

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

// Import images/logos
import foresightLogo from '../../assets/img/foresight_logo.svg';
import foresightHeader from '../../assets/img/foresight_header.png';

// Styles
import styles from './OnePageDemo.module.scss';

// Sub-components we *do* want in this mini demo:
import SurveyPage from '../SurveyTool/SurveyPage.jsx';
import SBTsPage from '../SBTs/SBTsPage.jsx';
import PolisReport from '../PolisReport/PolisReport.jsx';

/**
 * From SurveyResults-style logic:
 * Build an aggregator of form { questionId -> [ { responder, questionId, response }, ... ] }
 * Only include non-encrypted, 'binary' type data.
 * This matches how SurveyResults typically aggregates for pol.is style reports.
 */
function buildAggregatorFromLocalCache(networkObj) {
  if (!networkObj) return {};

  const questionResponses = networkObj.questionResponses || {};
  const aggregatorMap = {};

  // For each questionId, gather all responders and responses
  Object.keys(questionResponses).forEach((qId) => {
    const responderMap = questionResponses[qId];
    aggregatorMap[qId] = [];

    Object.keys(responderMap).forEach((resAddr) => {
      let parsed;
      try {
        parsed = typeof responderMap[resAddr] === 'string'
          ? JSON.parse(responderMap[resAddr])
          : responderMap[resAddr];
      } catch {
        parsed = null;
      }
      if (!parsed) return;

      // Must be 'binary' and not encrypted
      // The typical shape is: { type: 'binary', answer: { value: 'Agree'|'Disagree'|'Unsure', encrypted: bool } }
      if (parsed.type === 'binary' && parsed.answer && !parsed.answer.encrypted) {
        aggregatorMap[qId].push({
          responder: resAddr,
          questionId: qId,
          response: JSON.stringify(parsed)
        });
      }
    });
  });

  return aggregatorMap;
}

class OnePageDemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Collapsible states
      showQuestions: false,
      showGroups: false,
      showResults: false,

      // "About" sub-collapsibles
      showGroupsAbout: false,
      showResultsAbout: false,

      // Let user open SurveyResults in question-mode
      autoOpenResults: false,

      // PolisReport aggregator
      aggregatorData: {},

      // Keep disclaimers on for PolisReport
      disclaimersActive: true,

      // Example SBT-based filter state (if needed)
      filterState: {
        includedSBTs: [],
        excludedSBTs: [],
        onlyVerifiedHumans: false
      }
    };

    // Bind methods
    this.handleOpenResults = this.handleOpenResults.bind(this);
    this.resetDemoURL = this.resetDemoURL.bind(this);
    this.toggleQuestions = this.toggleQuestions.bind(this);
    this.toggleGroups = this.toggleGroups.bind(this);
    this.toggleGroupsAbout = this.toggleGroupsAbout.bind(this);
    this.toggleResults = this.toggleResults.bind(this);
    this.toggleResultsAbout = this.toggleResultsAbout.bind(this);
    this.buildAggregator = this.buildAggregator.bind(this);
  }

  componentDidMount() {
    // ADDED check: If the question cache is already loaded, do not re-init anything
    const alreadyHasCache = localStorage.getItem("cacheHasLoaded") === "true";
    if (alreadyHasCache) {
      console.log("OnePageDemo - recognized existing question cache. Skipping any re-init.");
    }

    // Attempt aggregator build from local storage. This does not overwrite or remove any question data.
    this.buildAggregator();
  }


  componentDidUpdate(prevProps) {
    // Rebuild aggregator if questionCache readiness or network changes
    if (
      (this.props.isQuestionCacheReady && !prevProps.isQuestionCacheReady) ||
      (this.props.network && prevProps.network && this.props.network.id !== prevProps.network.id)
    ) {
      this.buildAggregator();
    }
  }

  buildAggregator() {
    if (this.props.isQuestionCacheReady && this.props.network && this.props.network.id) {
      try {
        const netIdStr = this.props.network.id.toString();
        const qCacheStr = localStorage.getItem('questionsCache');
        if (!qCacheStr) {
          this.setState({ aggregatorData: {} });
          return;
        }
        const qCache = JSON.parse(qCacheStr);
        if (!qCache[netIdStr]) {
          this.setState({ aggregatorData: {} });
          return;
        }
        // Build aggregator using the same approach as SurveyResults
        const aggregatorObj = buildAggregatorFromLocalCache(qCache[netIdStr]);
        this.setState({ aggregatorData: aggregatorObj });
      } catch (err) {
        console.error("Error building aggregator in OnePageDemo (class):", err);
        this.setState({ aggregatorData: {} });
      }
    } else {
      this.setState({ aggregatorData: {} });
    }
  }

  handleOpenResults() {
    if (!this.state.showQuestions) {
      this.setState({ showQuestions: true });
    }
    // Replace the path to /questions/results
    window.history.pushState({}, '', '/questions/results');
    this.setState({ autoOpenResults: true });
  }

  resetDemoURL() {
    const { orgName, password } = this.props;
    const baseDemoURL = `/demo${orgName ? `/${orgName}` : ''}${password ? `/${password}` : ''}`;
    window.history.pushState({}, '', baseDemoURL);
  }

  toggleQuestions() {
    this.setState(
      (prevState) => ({ showQuestions: !prevState.showQuestions }),
      () => {
        // If user collapses "Questions," also reset autoOpenResults
        if (!this.state.showQuestions) {
          this.setState({ autoOpenResults: false });
        }
        this.resetDemoURL();
      }
    );
  }

  toggleGroups() {
    this.setState(
      (prevState) => ({ showGroups: !prevState.showGroups }),
      () => {
        this.resetDemoURL();
      }
    );
  }

  toggleGroupsAbout() {
    this.setState((prevState) => ({ showGroupsAbout: !prevState.showGroupsAbout }));
  }

  toggleResults() {
    this.setState(
      (prevState) => ({ showResults: !prevState.showResults }),
      () => {
        this.resetDemoURL();
      }
    );
  }

  toggleResultsAbout() {
    this.setState((prevState) => ({ showResultsAbout: !prevState.showResultsAbout }));
  }

  render() {
    const {
      orgName,
      password,
      orgHeader,
      orderHeaderImg,
      orgInfo,
      defaultTags,
      defaultFilterState,
      defaultFeaturedSBTs
    } = this.props;

    const baseDemoURL = `/demo${orgName ? `/${orgName}` : ''}${password ? `/${password}` : ''}`;

    return (
      <div className={styles.onePageDemoContainer}>
        {/* -- Simple branding / header area -- */}
        <div className={styles.brandingSection}>
          <img
            src={foresightLogo}
            alt="Foresight Logo"
            className={styles.brandLogo}
          />

          <div className={styles.eventBlurb}>
            <p id={styles.blurbText}>
              <strong>Vision Weekend Puerto Rico 2025</strong>, hosted by the Foresight Institute, brings together leaders in nanotech, biotech, neurotech, computation, and space technology. The focus is on "Paths to Progress" with presentations covering topics like existential hope, longevity, brain-computer interfaces, AI security, fusion energy, and more
            </p>
            <img
              src={foresightHeader}
              alt="Foresight Banner"
              className={styles.headerImage}
            />
            <p id={styles.blurbText}>
              <strong>Context Engine</strong> is software for coordination / discourse in large groups.
              This prototype allows users to create and respond to questions → see what beliefs are
              consensus or controversial by group
            </p>
          </div>
        </div>

        {/* -- Collapsible for "Questions" (SurveyPage) -- */}
        <div className={styles.sectionContainer}>
          <h2 onClick={this.toggleQuestions} className={styles.sectionHeader}>
            {this.state.showQuestions ? (
              <FontAwesomeIcon icon={faCaretUp} className={styles.sectionToggleIcon} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} className={styles.sectionToggleIcon} />
            )}
            Questions ‎  <span className={styles.subTitleDim}> – Answer or Add</span>&nbsp;
          </h2>
          {this.state.showQuestions && (
            <div className={styles.miniSectionContent}>
              <SurveyPage
                miniMode={true}
                account={this.props.account}
                provider={this.props.provider}
                network={this.props.network}
                toggleLoginModal={this.props.toggleLoginModal}
                loginComplete={this.props.loginComplete}

                // Additional org-based props
                orgInfo={orgInfo}
                defaultTags={defaultTags}
                defaultFilterState={defaultFilterState}

                autoOpenResults={this.state.autoOpenResults}

                // Pass refresh callbacks exactly as requested:
                refreshSurveyResponsesByID={this.props.refreshSurveyResponsesByID}
                refreshQuestionMetadata={this.props.refreshQuestionMetadata}
                refreshQuestionResponses={this.props.refreshQuestionResponses}
              />
            </div>
          )}
        </div>

        {/* -- Collapsible for "Groups" (SBTs) -- */}
        <div className={styles.sectionContainer}>
          <h2 onClick={this.toggleGroups} className={styles.sectionHeader}>
            {this.state.showGroups ? (
              <FontAwesomeIcon icon={faCaretUp} className={styles.sectionToggleIcon} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} className={styles.sectionToggleIcon} />
            )}
            Groups ‎  <span className={styles.subTitleDim}> – Join or Create</span>&nbsp;
          </h2>

          {this.state.showGroups && (
            <div className={styles.miniSectionContent}>
              <div className={styles.aboutContainer}>
                <div
                  className={styles.aboutHeader}
                  onClick={this.toggleGroupsAbout}
                >
                  About&nbsp;
                  {this.state.showGroupsAbout ? (
                    <FontAwesomeIcon icon={faCaretUp} id={styles.aboutArrowIcon} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} id={styles.aboutArrowIcon} />
                  )}
                </div>
                {this.state.showGroupsAbout && (
                  <div className={styles.aboutContent}>
                    <p id={styles.aboutText}>
                      Soulbound tokens (non-transferrable NFTs) enable digital groups to organize and coordinate
                      by representing membership, roles, and permissions on-chain. SBTs can enable
                      private spaces for collective action, decision-making, and training custom
                      AI models together, unlocking new possibilities for decentralized governance.
                    </p>
                  </div>
                )}
              </div>

              {password && (
                <p id={styles.detectedPassword}>
                  (Detected password: <strong>{password}</strong>)
                </p>
              )}

              {this.props.isSBTCacheReady ? (
                <SBTsPage
                  provider={this.props.provider}
                  network={this.props.network}
                  account={this.props.account}
                  loginComplete={this.props.loginComplete}
                  toggleLoginModal={this.props.toggleLoginModal}
                  miniaturized={false}

                  // Additional org-based props
                  orgInfo={orgInfo}
                  defaultFeaturedSBTs={defaultFeaturedSBTs}

                  sbtMintPassword={password}
                />
              ) : (
                <p style={{ fontStyle: 'italic' }}>
                  Loading SBT data ...
                </p>
              )}
            </div>
          )}
        </div>

        {/* -- Collapsible for "Results" (PolisReport) -- */}
        <div className={styles.sectionContainer}>
          <h2 onClick={this.toggleResults} className={styles.sectionHeader}>
            {this.state.showResults ? (
              <FontAwesomeIcon icon={faCaretUp} className={styles.sectionToggleIcon} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} className={styles.sectionToggleIcon} />
            )}
            Results ‎  <span className={styles.subTitleDim}> – View or Save</span>&nbsp;
          </h2>
          {this.state.showResults && (
            <div className={styles.miniSectionContent}>
              <div className={styles.aboutContainer}>
                <div
                  className={styles.aboutHeader}
                  onClick={this.toggleResultsAbout}
                >
                  About&nbsp;
                  {this.state.showResultsAbout ? (
                    <FontAwesomeIcon icon={faCaretUp} id={styles.aboutArrowIcon} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} id={styles.aboutArrowIcon} />
                  )}
                </div>
                {this.state.showResultsAbout && (
                  <div className={styles.aboutContent}>
                    <p id={styles.aboutText}>
                      This section displays result visualizations. Click "Full Results"
                      above (in Questions) to see more detailed breakdowns of responses, filter
                      by group membership, and export data as an interactive pol.is report.
                    </p>
                  </div>
                )}
              </div>

              <div style={{ marginTop: '15px', alignSelf: 'center' }}>
                <button
                  onClick={this.handleOpenResults}
                  style={{
                    display: 'inline-block',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    padding: '8px 14px',
                    borderRadius: '4px',
                    backgroundColor: '#7063f5',
                    color: '#fff',
                    border: '1px solid #5e54e5',
                    cursor: 'pointer'
                  }}
                >
                  View Full Results
                </button>
              </div>

              <div style={{ marginTop: '20px' }}>
                <PolisReport
                  onePageDemo={true}
                  miniMode={true}
                  account={this.props.account}
                  provider={this.props.provider}
                  network={this.props.network}
                  loginComplete={this.props.loginComplete}

                  // Our unified aggregator object:
                  questionResponses={this.state.aggregatorData}

                  disclaimersActive={this.state.disclaimersActive}
                  filterState={this.state.filterState}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OnePageDemo;
