import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faLock, faCopy, faCheck, faBookmark, faExpand, faChevronUp, faChevronDown, faUser, faSpinner, faArrowLeft, faInfinity, faTimes, faExternalLinkAlt, faInfoCircle, faCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import { ethers } from 'ethers';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
import styles from './SBTPage.module.scss';
import SBTFilter from '../SBTs/SBTFilter';

class SBTPage extends Component {
  _isMounted = false;
  state = {
    sbtInfo: null,
    userHasSBT: false,
    userIsSbtAdmin: false,
    claimCountdown: 12,
    error: null,
    copiedAddress: null,
    network: this.props.network,
    bookmarked: false,
    showModal: false,
    mintedAddresses: [],
    burnedAddresses: [],
    showStats: true,
    showActions: true,
    showMoreDetails: false,
    showAdminSection: false,
    intervalId: null,
    loadingMintersBurners: true,
    mintingStatus: 'idle',
    burningStatus: 'idle',
    mintPassword: '',
    mintStep: 0,
    relevantQuestions: [],
    relevantDocuments: [],
    showPasswordAlert: false,
    mintCountdown: null,
    transactionHash: null,
    burnSearchInput: '',
    burnSearchResult: null,
    burnSearchType: null,
    filteredMintedUsers: [],
    loadingMintedFilter: false,
    lastTransactionType: null,
    adminInvitesToGenerate: '',
    adminGeneratedPasswords: [],
    manualPasswordInput: '',
    createGroupMode: false,
    passwordGenerationCount: '',
    mintingAddressesFilterInitialized: false,
    includePreviousPasswords: false,
    exportFormat: 'json',
    cachedPasswords: [],
    newPasswords: [],
    demoModeOverrideToFalse: false,
    lastMintTxHash: null,
    lastBurnTxHash: null
  };

  async componentDidMount() {
    this._isMounted = true;
    const { SBTAddress } = this.props;
    if (SBTAddress) {
      await this.loadSBTInfo();
    }
    this.startMintingEndCountdown();
    this.checkForMintPassword();
    this.fetchRelevantInfo();
    this.loadCachedPasswords();
  }

  componentDidUpdate(prevProps) {
    if (this.props.SBTAddress !== prevProps.SBTAddress || this.props.network !== prevProps.network) {
      this.loadSBTInfo();
      this.setState({ network: this.props.network.id });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  loadCachedPasswords = () => {
    const stored = JSON.parse(localStorage.getItem('createdSBTs')) || {};
    // Normalize all keys to lowercase to ensure consistent lookup
    const normalizedStored = {};
    for (let k in stored) {
      normalizedStored[k.toLowerCase()] = stored[k];
    }

    let sbtAddress;

    if (Array.isArray(this.props.SBTAddress)) {
      const foundEntry = this.props.SBTAddress.find(entry => entry.sbtAddress !== undefined);
      sbtAddress = foundEntry ? foundEntry.sbtAddress : null;
    } else {
      sbtAddress = this.props.SBTAddress && this.props.SBTAddress.sbtAddress !== undefined 
        ? this.props.SBTAddress.sbtAddress
        : this.props.SBTAddress;
    }

    if (typeof sbtAddress === 'string') {
      sbtAddress = sbtAddress.toLowerCase();
    }

    let cached = [];
    if (sbtAddress && normalizedStored[sbtAddress] && Array.isArray(normalizedStored[sbtAddress].passwords)) {
      cached = normalizedStored[sbtAddress].passwords;
    } else if (Array.isArray(normalizedStored.passwords)) {
      cached = normalizedStored.passwords;
    }

    this.setState({ cachedPasswords: cached });
  };

  async loadSBTInfo() {
    const SBTAddress = Array.isArray(this.props.SBTAddress) 
      ? this.props.SBTAddress.find(entry => entry.sbtAddress !== undefined)?.sbtAddress 
      : (this.props.SBTAddress && this.props.SBTAddress.sbtAddress !== undefined ? this.props.SBTAddress.sbtAddress : this.props.SBTAddress);

    if (!SBTAddress) {
      return;
    }

    const SBTAddressLower = SBTAddress.toLowerCase();

    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    const networkID = this.props.network.id;
    const cachedSBT = cache[networkID] && cache[networkID].sbtList[SBTAddressLower];
    let fromBlock = 0;
    let latestBlock;

    try {
      if (cachedSBT && cachedSBT.blockNumber) {
        fromBlock = cachedSBT.blockNumber + 1;
      }
      const sbtInfo = cachedSBT && cachedSBT.sbtInfo 
        ? cachedSBT.sbtInfo 
        : await contractScripts.getSbtMetadata(this.props.provider, SBTAddress);

      latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);

      let mintedAddresses = cachedSBT && cachedSBT.mintedAddresses ? cachedSBT.mintedAddresses.slice() : [];
      let burnedAddresses = cachedSBT && cachedSBT.burnedAddresses ? cachedSBT.burnedAddresses.slice() : [];

      if (!cachedSBT || cachedSBT.blockNumber < latestBlock) {
        const newMinted = await contractScripts.getAddressesWhoMintedSBT(this.props.provider, SBTAddress, cachedSBT ? cachedSBT.blockNumber+1 : 0, 'latest');
        const newBurned = await contractScripts.getAddressesWhoBurnedSBT(this.props.provider, SBTAddress, cachedSBT ? cachedSBT.blockNumber+1 : 0, 'latest');

        const mintedSet = new Set(mintedAddresses);
        newMinted.forEach(addr => mintedSet.add(addr.toLowerCase()));
        mintedAddresses = Array.from(mintedSet);

        const burnedSet = new Set(burnedAddresses);
        newBurned.forEach(addr => burnedSet.add(addr.toLowerCase()));
        burnedAddresses = Array.from(burnedSet);
      }

      this.setState({ sbtInfo, mintedAddresses, burnedAddresses, loadingMintersBurners: false });

      let creator = sbtInfo.creator || sbtInfo.admin;
      let adminAddr = sbtInfo.admin || sbtInfo.admin_;
      if (this.props.account) {
        const userAddressLower = this.props.account.toLowerCase();
        const userHasSBT = mintedAddresses.includes(userAddressLower) && !burnedAddresses.includes(userAddressLower);
        const userIsSbtAdmin = (adminAddr && adminAddr.toLowerCase() === userAddressLower) || (creator && creator.toLowerCase() === userAddressLower);
        this.setState({ userHasSBT, userIsSbtAdmin });
      }

      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          lastBlock: latestBlock,
          sbtList: {
            ...cache[networkID]?.sbtList,
            [SBTAddressLower]: {
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
              blockNumber: latestBlock,
              sbtAddress: SBTAddress,
            }
          }
        }
      };
      localStorage.setItem('sbtCache', JSON.stringify(newCache));
    } catch (error) {
      console.error("Error in loadSBTInfo:", error);
      this.setState({ error: error.message });
    }
  }

  startMintingEndCountdown() {
    const intervalId = setInterval(() => {
      const { sbtInfo } = this.state;
      if (sbtInfo && sbtInfo.mintingEndTime) {
        const now = new Date().getTime();
        const endTime = sbtInfo.mintingEndTime * 1000;
        const distance = endTime - now;

        if (distance < 0) {
          clearInterval(intervalId);
          this.setState({ mintCountdown: null });
        } else {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          this.setState({
            mintCountdown: `${days}d ${hours}h ${minutes}m ${seconds}s`,
          });
        }
      }
    }, 1000);

    this.setState({ intervalId });
  }

  /**
   * Extended logic to also detect a password if the URL has /demo/<orgname>/<password>
   * or the standard /sbt/<address>/<password>.
   */
  checkForMintPassword = () => {
    const pathname = window.location.pathname;
    const parts = pathname.split('/');

    // Original SBT path check (like /sbt/0xAddress/password)
    if (parts.length >= 4 && parts[1] === 'sbt') {
      const mintPassword = parts[3];
      this.setState({ 
        mintPassword,
        mintStep: 0,
        showPasswordAlert: true
      });
    }

    // New optional format: /demo/<orgname>/<password>
    // for example: /demo/myOrg/secret123
    if (parts.length >= 4 && parts[1] === 'demo') {
      const detectedPassword = parts[3];
      this.setState({
        mintPassword: detectedPassword,
        mintStep: 0,
        showPasswordAlert: true
      });
    }
  };

  fetchRelevantInfo = () => {
    this.setState({
      relevantQuestions: ['What is the purpose of this SBT?', 'How can I use this SBT?'],
      relevantDocuments: ['SBT Whitepaper', 'Community Guidelines'],
    });
  };

  handleMint = async () => {
    if (!this.props.account) {
      // If user is not logged in, open login modal
      this.props.toggleLoginModal(true);
      return;
    }

    const { SBTAddress } = this.props;
    const { sbtInfo, mintPassword, mintStep, manualPasswordInput } = this.state;

    try {
      if (sbtInfo.hasPasswordMint) {
        const effectivePassword = mintPassword && mintPassword.trim() !== '' ? mintPassword : (manualPasswordInput || '').trim();
        if (effectivePassword === '') {
          return;
        }

        if (mintStep === 0) {
          this.setState({ mintingStatus: 'pending', lastTransactionType: 'mint' });

          const userCommit = ethers.utils.solidityKeccak256(
            ["string", "address"],
            [effectivePassword, this.props.account]
          );

          const tx = await contractScripts.startClaim(this.props.provider, SBTAddress, userCommit);
          this.setState({
            mintStep: 1,
            mintingStatus: 'idle',
            transactionHash: tx.transactionHash
          });
          this.startClaimCountdown();
          this.cacheTransactionHash(tx.transactionHash);
        } else if (mintStep === 2) {
          this.setState({ mintingStatus: 'pending', lastTransactionType: 'mint' });
          const tx = await contractScripts.claimWithPassword(this.props.provider, SBTAddress, effectivePassword);
          this.setState({
            mintStep: 3,
            mintPassword: '',
            manualPasswordInput: '',
            mintingStatus: 'success',
            transactionHash: tx.transactionHash,
            lastTransactionType: 'mint',
            lastMintTxHash: tx.transactionHash
          });
          await this.loadSBTInfo();
          this.cacheTransactionHash(tx.transactionHash);
        }
      } else {
        this.setState({ mintingStatus: 'pending', lastTransactionType: 'mint' });
        const tx = await contractScripts.claim(this.props.provider, SBTAddress);
        this.setState({
          mintingStatus: 'success',
          transactionHash: tx.transactionHash,
          lastTransactionType: 'mint',
          lastMintTxHash: tx.transactionHash
        });
        await this.loadSBTInfo();
        this.cacheTransactionHash(tx.transactionHash);
      }
    } catch (error) {
      console.error("Minting failed in handleMint:", error);
      this.setState({ error: error.message, mintingStatus: 'failure' });
    }
  };

  /**
   * Helper to handle mini-mint in the mini view. 
   * Checks if user is logged in, otherwise opens login modal, then calls handleMint.
   */
  miniMintHandler = async () => {
    // Instead of checking this.props.loginComplete, we now check this.props.account.
    // This ensures that once the user connects (i.e. has a non-null account),
    // we proceed with minting rather than toggling the login modal again.
    console.log("miniMintHandler: account:", this.props.account);
    // console.log("miniMintHandler: loginComplete:", this.props.loginComplete);
    if (!this.props.account) {
      this.props.toggleLoginModal(true);
      return;
    }
    // If user is logged in (account is defined), proceed with mint.
    await this.handleMint();
  };


  /**
   * Mini burn handler in mini view (if the user is allowed to burn).
   * We'll reuse the same logic from handleBurn, but simplified for the owner's 1 token.
   */
  miniBurnHandler = async () => {
    if (!this.props.account) {
      this.props.toggleLoginModal(true);
      return;
    }

    try {
      this.setState({ burningStatus: 'pending', lastTransactionType: 'burn' });

      const { SBTAddress } = this.props;
      const tokenIdToBurn = await contractScripts.getSBTTokenIdByOwner(this.props.provider, SBTAddress, this.props.account);
      if (!tokenIdToBurn) {
        this.setState({ error: "No valid token ID found", burningStatus: 'failure' });
        return;
      }

      const tx = await contractScripts.burnToken(this.props.provider, SBTAddress, tokenIdToBurn);
      await this.loadSBTInfo();
      await this.updateCache();
      this.setState({
        burningStatus: 'success',
        transactionHash: tx.transactionHash,
        lastTransactionType: 'burn',
        lastBurnTxHash: tx.transactionHash
      });
      this.cacheTransactionHash(tx.transactionHash);
    } catch (error) {
      console.error("Burn failed in miniBurnHandler:", error);
      this.setState({ error: error.message, burningStatus: 'failure' });
    }
  };

  handleBurnSearchChange = async (event) => {
    const input = event.target.value;
    this.setState({ burnSearchInput: input, burnSearchResult: null, burnSearchType: null });

    if (!input) return;

    const { SBTAddress } = this.props;

    if (input.startsWith('0x') && input.length === 42) {
      try {
        const tokenId = await contractScripts.getSBTTokenIdByOwner(this.props.provider, SBTAddress, input);
        if (tokenId) {
          this.setState({
            burnSearchResult: { address: input, tokenId },
            burnSearchType: 'address'
          });
        }
      } catch (error) {
        console.error("Error searching by address:", error);
      }
    }
    else if (/^\d+$/.test(input)) {
      try {
        const address = await contractScripts.getOwnerByTokenId(this.props.provider, SBTAddress, input);
        if (address) {
          this.setState({
            burnSearchResult: { address, tokenId: input },
            burnSearchType: 'tokenId'
          });
        }
      } catch (error) {
        console.error("Error searching by token ID:", error);
      }
    }
  };

  handleBurn = async () => {
    if (!this.props.account) {
      this.props.toggleLoginModal(true);
      return;
    }

    const { SBTAddress } = this.props;
    const { sbtInfo, burnSearchResult } = this.state;

    const userAddress = this.props.account.toLowerCase();
    const isAdminBurn = this.state.userIsSbtAdmin && (sbtInfo.burnAuth === 0 || sbtInfo.burnAuth === 2);
    const isOwnerBurn = this.state.userHasSBT && 
      (
        sbtInfo.burnAuth === 1 || 
        sbtInfo.burnAuth === 2 || 
        (sbtInfo.burnAuth === 0 && userAddress === sbtInfo.admin.toLowerCase()) || 
        (sbtInfo.burnAuth === 1 && this.state.userHasSBT)
      );

    let tokenIdToBurn;

    if (isAdminBurn && burnSearchResult && burnSearchResult.tokenId) {
      // admin scenario with explicit input
    } else if (isOwnerBurn) {
      tokenIdToBurn = await contractScripts.getSBTTokenIdByOwner(this.props.provider, SBTAddress, this.props.account);
      if (!tokenIdToBurn) {
        this.setState({ error: "No valid token ID found" });
        return;
      }
    } else if (this.state.userIsSbtAdmin && (sbtInfo.burnAuth === 0 || sbtInfo.burnAuth === 2) && !burnSearchResult) {
      // Admin scenario without input
    } else {
      this.setState({ error: "You are not authorized to burn this SBT." });
      return;
    }

    try {
      this.setState({ burningStatus: 'pending', lastTransactionType: 'burn' });
      const tx = await contractScripts.burnToken(this.props.provider, SBTAddress, tokenIdToBurn);
      await this.loadSBTInfo();
      await this.updateCache();
      this.setState({
        burningStatus: 'success',
        transactionHash: tx.transactionHash,
        burnSearchInput: '',
        burnSearchResult: null,
        burnSearchType: null,
        lastTransactionType: 'burn',
        lastBurnTxHash: tx.transactionHash
      });
      this.cacheTransactionHash(tx.transactionHash);
    } catch (error) {
      this.setState({
        error: error.message,
        burningStatus: 'failure',
        burnSearchInput: '',
        burnSearchResult: null,
        burnSearchType: null
      });
    }
  };

  updateCache = async () => {
    const { SBTAddress } = this.props;
    if (!SBTAddress) return;

    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    const network = this.props.network;
    const networkID = network?.id;

    const sbtAddressLower = SBTAddress.toLowerCase();
    const sbtInfo = this.state.sbtInfo;
    const mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(this.props.provider, SBTAddress, 0, 'latest');
    const burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(this.props.provider, SBTAddress, 0, 'latest');
    const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);

    const newCache = {
      ...cache,
      [networkID]: {
        ...cache[networkID],
        lastBlock: latestBlock,
        sbtList: {
          ...cache[networkID]?.sbtList,
          [sbtAddressLower]: {
            sbtAddress: SBTAddress,
            sbtInfo,
            mintedAddresses: mintedAddresses.map(a => a.toLowerCase()),
            burnedAddresses: burnedAddresses.map(a => a.toLowerCase()),
            blockNumber: latestBlock,
          }
        }
      }
    };
    localStorage.setItem('sbtCache', JSON.stringify(newCache));
  };

  startClaimCountdown = () => {
    let countdown = 12;
    const countdownInterval = setInterval(() => {
      countdown--;
      this.setState({ claimCountdown: countdown });
      if (countdown === 0) {
        clearInterval(countdownInterval);
        this.setState({ mintStep: 2, claimCountdown: 12 });
      }
    }, 1000);
  };

  copyToClipboard = (text, addressType) => {
    navigator.clipboard.writeText(text).then(() => {
      this.setState({ copiedAddress: addressType }, () => {
        setTimeout(() => this.setState({ copiedAddress: null }), 2500);
      });
    });
  };

  bookmarkSBT = () => {
    const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || {};
    if (!bookmarks.sbts) bookmarks.sbts = [];
    if (!bookmarks.sbts.includes(this.props.SBTAddress)) {
      bookmarks.sbts.push(this.props.SBTAddress);
      localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
      this.setState({ bookmarked: true });
    }
    this.storeSBTDetails();
  };

  storeSBTDetails = () => {
    const sbtDetails = { ...this.state.sbtInfo, address: this.props.SBTAddress };
    localStorage.setItem('sbtDetails', JSON.stringify(sbtDetails));
  };

  getExplorerUrl = (address) => {
    const network = this.props.network;
    return network ? `${network.blockExplorers.default.url}/address/${address}` : 'https://sepolia.etherscan.io/address/' + address;
  };

  getExplorerLink = (hash) => {
    const network = this.props.network;
    return network ? `${network.blockExplorers.default.url}/tx/${hash}` : 'https://sepolia.etherscan.io/tx/' + hash;
  };

  openMintedModal = () => {
    this.setState({ showModal: true }, () => {
      if (!this.state.mintingAddressesFilterInitialized) {
        this.setState({ filteredMintedUsers: this.state.mintedAddresses.filter(addr => !this.state.burnedAddresses.includes(addr)), mintingAddressesFilterInitialized: true });
      }
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  toggleStats = () => {
    this.setState(prevState => ({ showStats: !prevState.showStats }));
  };

  toggleActions = () => {
    this.setState(prevState => ({ showActions: !prevState.showActions }));
  };

  toggleMoreDetails = () => {
    this.setState(prevState => ({ showMoreDetails: !prevState.showMoreDetails }));
  };

  toggleAdminSection = () => {
    this.setState(prevState => ({ showAdminSection: !prevState.showAdminSection }));
  };

  renderAddressLink = (address) => {
    const shortenedAddress = proposalScripts.getShortenedAddress(address, false);
    return (
      <>
        <a href={`/u/${address}`} target="_blank" rel="noopener noreferrer">
          {shortenedAddress}
        </a>
        <button onClick={() => this.copyToClipboard(address, 'contract')} className={styles.copyButton}>
          <FontAwesomeIcon icon={this.state.copiedAddress === 'contract' ? faCheck : faCopy} />
        </button>
        <a href={this.getExplorerUrl(address)} target="_blank" rel="noopener noreferrer" className={styles.expandButton}>
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
      </>
    );
  };

  handleGenerateAdminInvites = async () => {
    if (!this.state.passwordGenerationCount || this.state.passwordGenerationCount <= 0) return;

    const newPasswordList = this.generateRandomPasswords(this.state.passwordGenerationCount);
    console.log("Generated passwords (unhashed):", newPasswordList);

    const hashedPasswords = newPasswordList.map(password => ethers.utils.keccak256(ethers.utils.toUtf8Bytes(password)));

    try {
      const { SBTAddress } = this.props;
      const tx = await contractScripts.addHashedPasswords(this.props.provider, SBTAddress, hashedPasswords);
      console.log("addHashedPasswords transaction hash:", tx.transactionHash);

      this.cacheTransactionHash(tx.transactionHash);

      let createdSBTs = JSON.parse(localStorage.getItem('createdSBTs') || '{}');
      const sbtAddrLower = SBTAddress.toLowerCase();
      if (!createdSBTs[sbtAddrLower]) {
        createdSBTs[sbtAddrLower] = { passwords: [] };
      }
      createdSBTs[sbtAddrLower].passwords = [...(createdSBTs[sbtAddrLower].passwords || []), ...newPasswordList];
      localStorage.setItem('createdSBTs', JSON.stringify(createdSBTs));

      this.setState({ adminGeneratedPasswords: newPasswordList, passwordGenerationCount: '' });
      this.loadCachedPasswords();
    } catch (error) {
      console.error("Error adding hashed passwords:", error);
      this.setState({ error: error.message });
    }
  };

  generateRandomPasswords = (count) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const generated = new Set();
    while (generated.size < count) {
      let result = '';
      for (let j = 0; j < 8; j++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      if (!generated.has(result)) {
        generated.add(result);
      }
    }
    return Array.from(generated);
  };

  exportPasswords = () => {
    const { exportFormat, includePreviousPasswords, cachedPasswords, adminGeneratedPasswords } = this.state;
    const { SBTAddress } = this.props;

    let sbtAddr = Array.isArray(SBTAddress) 
      ? SBTAddress.find(entry => entry.sbtAddress !== undefined)?.sbtAddress 
      : (SBTAddress && SBTAddress.sbtAddress !== undefined ? SBTAddress.sbtAddress : SBTAddress);

    if (typeof sbtAddr === 'string') {
      sbtAddr = sbtAddr.toLowerCase();
    }

    // Combine adminGeneratedPasswords and cachedPasswords
    const combinedPasswords = [...(cachedPasswords || []), ...(adminGeneratedPasswords || [])];

    const onlyCachedPasswords = (adminGeneratedPasswords.length === 0 && combinedPasswords.length > 0);
    const effectiveIncludePreviousPasswords = onlyCachedPasswords ? true : includePreviousPasswords;

    let passwordsToExport;
    if (adminGeneratedPasswords.length > 0) {
      passwordsToExport = effectiveIncludePreviousPasswords ? combinedPasswords : adminGeneratedPasswords;
    } else {
      passwordsToExport = combinedPasswords;
    }

    const baseUrl = window.location.origin;
    const inviteLinks = passwordsToExport.map((password) => ({
      password,
      inviteLink: `${baseUrl}/sbt/${sbtAddr}/${password}`
    }));

    const date = new Date().toISOString().slice(0, 10);
    const sbtSymbol = this.state.sbtInfo?.name || 'SBT';
    const sbtName = this.state.sbtInfo?.name || 'UnnamedSBT';

    let content;
    let fileName;

    if (exportFormat === 'json') {
      content = JSON.stringify(inviteLinks, null, 2);
      fileName = `${sbtSymbol}_${sbtName}_passwords_${date}.json`;
    } else if (exportFormat === 'csv') {
      content = 'index,password,inviteLink\n' + 
        inviteLinks.map((item, index) => `${index},${item.password},${item.inviteLink}`).join('\n');
      fileName = `${sbtSymbol}_${sbtName}_passwords_${date}.csv`;
    }

    const blob = new Blob([content], { type: exportFormat === 'json' ? 'application/json' : 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  renderMintButton() {
    const { sbtInfo, mintStep, claimCountdown, mintingStatus, mintPassword, userHasSBT, burningStatus } = this.state;
    if (userHasSBT) {
      return null;
    }
    if (!sbtInfo || sbtInfo.hasPasswordMint === undefined) {
      return null;
    }
    const now = Math.floor(Date.now() / 1000);
    if (sbtInfo.mintingEndTime !== 0 && sbtInfo.mintingEndTime < now) {
      return null;
    }

    if (!sbtInfo.hasPasswordMint) {
      return (
        <div>
          <button
            onClick={this.handleMint}
            disabled={(mintingStatus !== 'idle' && mintingStatus !== 'success' && mintingStatus !== 'failure')}
            className={`${styles.actionButton} ${styles.mintButton}`}
          >
            {mintingStatus === 'idle' && 'Mint'}
            {mintingStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
            {(mintingStatus === 'success' && burningStatus !== 'success') && <>Minted <FontAwesomeIcon icon={faCheck} /></>}
            {mintingStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
          </button>
        </div>
      );
    }

    switch (mintStep) {
      case 0:
        return (
          <div id={styles.mintButtonArea}>
            {!mintPassword && (
              <div className={styles.passwordEntry}>
                <input
                  type="text"
                  className={styles.input}
                  value={this.state.manualPasswordInput || ''}
                  onChange={(e) => this.setState({ manualPasswordInput: e.target.value })}
                  placeholder="Enter password"
                />
              </div>
            )}
            <button
              onClick={this.handleMint}
              disabled={(mintingStatus !== 'idle') || (!mintPassword && (!this.state.manualPasswordInput || this.state.manualPasswordInput.trim() === ''))}
              className={`${styles.actionButton} ${styles.mintButton}`}
            >
              {mintingStatus === 'idle' && <>Start Claim <FontAwesomeIcon icon={faLock} /></>}
              {mintingStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
              {(mintingStatus === 'success' && burningStatus !== 'success') && <>Minted <FontAwesomeIcon icon={faCheck} /></>}
              {mintingStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
            </button>
            {(mintPassword && mintPassword !== '') && (
              <p className={styles.passwordDetected}>Password Detected: {mintPassword}</p>
            )}
          </div>
        );
      case 1:
        return (
          <div className={styles.mintProcess}>
            <p className={styles.claimCountdown}>
              Waiting period: {claimCountdown} seconds
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className={styles.tooltip}
                id="countdownTooltip"
                style={{opacity:0.5}}
              />
              <UncontrolledTooltip placement="right" target="countdownTooltip" delay={{show:0, hide:2500}}>
                This countdown is a security measure to prevent front-running your password reveal.
              </UncontrolledTooltip>
            </p>
          </div>
        );
      case 2:
        return (
          <div>
            <button
              onClick={this.handleMint}
              disabled={(mintingStatus !== 'idle') || (!mintPassword && (!this.state.manualPasswordInput || this.state.manualPasswordInput.trim() === ''))}
              className={`${styles.actionButton} ${styles.mintButton}`}
            >
              {mintingStatus === 'idle' && <>Finish Claim <FontAwesomeIcon icon={faLock} /></>}
              {mintingStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
              {(mintingStatus === 'success' && burningStatus !== 'success') && <>Minted <FontAwesomeIcon icon={faCheck} /></>}
              {mintingStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
            </button>
            {(mintPassword && mintPassword !== '') && (
              <p className={styles.passwordDetected}>Password Detected: {mintPassword}</p>
            )}
            {!mintPassword && (
              <p className={styles.passwordDetected}>Password Entered: {this.state.manualPasswordInput}</p>
            )}
          </div>
        );
      case 3:
        return (
          <div className={styles.mintProcess}>
            <p className={styles.mintSuccess}>SBT successfully minted!</p>
          </div>
        );
      default:
        return null;
    }
  }

  renderBurnButton = () => {
    const { sbtInfo, userHasSBT, burningStatus, mintedAddresses, burnedAddresses } = this.state;
    if (!sbtInfo) return null;

    const userAddressLower = this.props.account ? this.props.account.toLowerCase() : null;
    const canOwnerBurn = userHasSBT && (
      sbtInfo.burnAuth === 1 ||
      sbtInfo.burnAuth === 2 ||
      (sbtInfo.burnAuth === 0 && sbtInfo.admin.toLowerCase() === userAddressLower) ||
      (sbtInfo.burnAuth === 1 && userHasSBT)
    );

    if (!userHasSBT || !canOwnerBurn) {
      return null;
    }

    return (
      <div>
        <button
          onClick={this.handleBurn}
          disabled={burningStatus !== 'idle' && burningStatus !== 'success' && burningStatus !== 'failure'}
          className={`${styles.actionButton} ${styles.burnButton}`}
        >
          {burningStatus === 'idle' && 'Burn'}
          {burningStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
          {burningStatus === 'success' && <>Burned <FontAwesomeIcon icon={faCheck} /></>}
          {burningStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
        </button>
      </div>
    );
  };

  renderRelevantInfo = () => {
    const { sbtInfo } = this.state;
    const documentURLs = sbtInfo && sbtInfo.documentURLs ? sbtInfo.documentURLs : [];
    const tags = sbtInfo && sbtInfo.tags ? sbtInfo.tags : [];
    const documentIDHashes = sbtInfo && sbtInfo.documentIDHashes ? sbtInfo.documentIDHashes : [];

    return (
      <div className={styles.relevantInfo}>
        <Alert color="info">
          <FontAwesomeIcon icon={faInfoCircle} style={{opacity:0.5}}/>
          This section shows relevant documents, URLs, tags, and IDs.
        </Alert>
        {documentURLs.length > 0 && (
          <div style={{marginTop:'10px'}}>
            <h4>Additional Document URLs:</h4>
            <ul>
              {documentURLs.map((url, index) => {
                const docHash = encodeURIComponent(url);
                return (
                  <li key={index}>
                    <a href={`${window.location.origin}/doc/${docHash}`} target="_blank" rel="noopener noreferrer">
                      {url}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {documentIDHashes.length > 0 && (
          <div className={styles.docIDsSection}>
            <h4>Document ID Hashes:</h4>
            <ul>
              {documentIDHashes.map((hash, index) => {
                const docHash = encodeURIComponent(hash);
                return (
                  <li key={index}>
                    <a href={`${window.location.origin}/doc/${docHash}`} target="_blank" rel="noopener noreferrer">
                      {hash}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {tags.length > 0 && (
          <div className={styles.tagsSection}>
            <h4>Tags:</h4>
            <ul>
              {tags.map((tag, index) => {
                const tagEnc = encodeURIComponent(tag);
                return (
                  <li key={index}>
                    <a href={`${window.location.origin}/tag/${tagEnc}`} target="_blank" rel="noopener noreferrer">
                      {tag}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  };

  cacheTransactionHash = (txHash) => {
    const userAddress = this.props.account?.toLowerCase();
    if (!userAddress) return;
    let txCache = JSON.parse(localStorage.getItem('transactions')) || {};
    if (!txCache[userAddress]) txCache[userAddress] = [];
    txCache[userAddress].push(txHash);
    localStorage.setItem('transactions', JSON.stringify(txCache));
  };

  handleExportFormatChange = (event) => {
    this.setState({ exportFormat: event.target.value });
  };

  handleIncludePreviousPasswordsChange = (event) => {
    this.setState({ includePreviousPasswords: event.target.checked });
  };

  renderAdminActions = () => {
    const { userIsSbtAdmin, sbtInfo, burnSearchInput, burnSearchResult, burningStatus, adminGeneratedPasswords, cachedPasswords, includePreviousPasswords, exportFormat } = this.state;
    if (!userIsSbtAdmin || !sbtInfo) return null;

    const canAdminBurn = sbtInfo.burnAuth === 0 || sbtInfo.burnAuth === 2;
    const showPasswordGen = (sbtInfo.hasPasswordMint && sbtInfo.maxTokens === "0");
    const showNoMoreInvites = (sbtInfo.hasPasswordMint && sbtInfo.maxTokens !== "0");

    const combinedPasswords = [...(cachedPasswords || []), ...(adminGeneratedPasswords || [])];

    const { SBTAddress } = this.props;
    let sbtAddr = Array.isArray(SBTAddress) 
      ? SBTAddress.find(entry => entry.sbtAddress !== undefined)?.sbtAddress 
      : (SBTAddress && SBTAddress.sbtAddress !== undefined ? SBTAddress.sbtAddress : SBTAddress);

    if (typeof sbtAddr === 'string') {
      sbtAddr = sbtAddr.toLowerCase();
    }

    const baseUrl = window.location.origin;

    const justGeneratedPasswords = adminGeneratedPasswords.length > 0;
    const onlyCachedPasswords = (adminGeneratedPasswords.length === 0 && combinedPasswords.length > 0);
    const renderIncludePreviousCheckbox = justGeneratedPasswords; 
    const effectiveIncludePreviousPasswords = onlyCachedPasswords ? true : includePreviousPasswords;

    return (
      <div className={styles.adminActions}>
        {canAdminBurn && (
          <div className={styles.adminBurnSection}>
            <h4>Burn SBT</h4>
            <div className={styles.burnInputGroup}>
              <input
                type="text"
                value={burnSearchInput}
                onChange={this.handleBurnSearchChange}
                placeholder="Enter Address (0x...) or Token ID"
                className={styles.input}
              />
              {burnSearchResult && (
                <div className={styles.burnSearchResult}>
                  {burnSearchResult.tokenId && (
                    <p>Token ID: {burnSearchResult.tokenId}</p>
                  )}
                  {burnSearchResult.address && (
                    <p>Owner: {proposalScripts.getShortenedAddress(burnSearchResult.address, false)}</p>
                  )}
                </div>
              )}
              <button
                onClick={async () => {
                  if (!burnSearchResult) {
                    this.setState({ error: "No token selected to burn" });
                    return;
                  }
                  this.setState({ burningStatus: 'pending', lastTransactionType: 'burn' });
                  const tx = await contractScripts.burnToken(this.props.provider, SBTAddress, burnSearchResult.tokenId);
                  await this.loadSBTInfo();
                  await this.updateCache();
                  this.setState({
                    burningStatus: 'success',
                    transactionHash: tx.transactionHash,
                    burnSearchInput: '',
                    burnSearchResult: null,
                    burnSearchType: null,
                    lastTransactionType: 'burn',
                    lastBurnTxHash: tx.transactionHash
                  });
                  this.cacheTransactionHash(tx.transactionHash);
                }}
                className={styles.actionButton}
                disabled={(burningStatus !== 'idle' && burningStatus !== 'success' && burningStatus !== 'failure') || !burnSearchResult}
              >
                {burningStatus === 'idle' && 'Burn SBT'}
                {burningStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
                {burningStatus === 'success' && <>Burned <FontAwesomeIcon icon={faCheck} /></>}
                {burningStatus === 'failure' && <FontAwesomeIcon icon={faTimes} />}
              </button>
            </div>
          </div>
        )}

        {showPasswordGen && (
          <div className={styles.inviteGenerationSection}>
            <h4>Generate Additional Password Invites</h4>
            <p>Since there's no max token limit, you can generate more password-based invites as admin.</p>
            <div className={styles.inviteGenerationControls}>
              <input
                type="number"
                value={this.state.passwordGenerationCount || ''}
                onChange={(e) => {
                  const val = parseInt(e.target.value);
                  this.setState({ passwordGenerationCount: isNaN(val) ? '' : val });
                }}
                placeholder="Number of additional passwords"
                className={styles.input}
              />
              <button
                onClick={this.handleGenerateAdminInvites}
                className={styles.actionButton}
                disabled={!this.state.passwordGenerationCount || this.state.passwordGenerationCount <= 0}
              >
                Generate Invites
              </button>
            </div>
            {(combinedPasswords && combinedPasswords.length > 0) ? (
              <div className={styles.generatedPasswordsList}>
                <h5>Generated Passwords (including cached):</h5>
                <ul>
                  {combinedPasswords.map((pw, idx) => (
                    <li key={idx}>
                      {pw} - <a href={`${baseUrl}/sbt/${sbtAddr}/${pw}`} target="_blank" rel="noopener noreferrer">{`${baseUrl}/sbt/${sbtAddr}/${pw}`}</a>
                    </li>
                  ))}
                </ul>
                <p>These passwords are now stored in localStorage and/or newly generated.</p>
                <div className={styles.exportOptions}>
                  {renderIncludePreviousCheckbox && (
                    <label>
                      <input
                        type="checkbox"
                        checked={effectiveIncludePreviousPasswords}
                        onChange={this.handleIncludePreviousPasswordsChange}
                      />
                      Include previous passwords
                    </label>
                  )}
                  {!renderIncludePreviousCheckbox && onlyCachedPasswords && (
                    <p style={{fontStyle:'italic'}}>All previously cached passwords are included.</p>
                  )}
                  <select value={exportFormat} onChange={this.handleExportFormatChange} className={styles.exportFormatSelect}>
                    <option value="json">JSON</option>
                    <option value="csv">CSV</option>
                  </select>
                  <button onClick={this.exportPasswords} className={styles.exportButton}>Export Passwords</button>
                </div>
              </div>
            ) : null}
          </div>
        )}

        {showNoMoreInvites && combinedPasswords.length > 0 && (
          <div className={styles.inviteGenerationSection}>
            <h4>Previously Generated Password Invites</h4>
            <p>These were previously cached or generated passwords from when the SBT was created:</p>
            <ul>
              {combinedPasswords.map((pw, idx) => (
                <li key={idx}>
                  {pw} - <a href={`${baseUrl}/sbt/${sbtAddr}/${pw}`} target="_blank" rel="noopener noreferrer">{`${baseUrl}/sbt/${sbtAddr}/${pw}`}</a>
                </li>
              ))}
            </ul>
            <div className={styles.exportOptions}>
              {adminGeneratedPasswords.length > 0 && (
                <label>
                  <input
                    type="checkbox"
                    checked={effectiveIncludePreviousPasswords}
                    onChange={this.handleIncludePreviousPasswordsChange}
                  />
                  Include previous passwords
                </label>
              )}
              {adminGeneratedPasswords.length === 0 && combinedPasswords.length > 0 && (
                <p style={{fontStyle:'italic'}}>All previously cached passwords are included.</p>
              )}
              <select value={exportFormat} onChange={this.handleExportFormatChange} className={styles.exportFormatSelect}>
                <option value="json">JSON</option>
                <option value="csv">CSV</option>
              </select>
              <button onClick={this.exportPasswords} className={styles.exportButton}>Export Passwords</button>
            </div>
          </div>
        )}

        {showNoMoreInvites && combinedPasswords.length === 0 && (
          <div className={styles.inviteGenerationSection}>
            <h4>No Additional Password Invites</h4>
            <p>Max tokens are set, so all invites should have been created initially. No more invites can be generated, and there are no cached passwords found.</p>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { SBTAddress, miniaturized, miniMintable, sbtMintPassword } = this.props;
    const {
      sbtInfo,
      userHasSBT,
      userIsSbtAdmin,
      mintCountdown,
      error,
      bookmarked,
      showModal,
      mintedAddresses,
      burnedAddresses,
      showStats,
      showActions,
      showMoreDetails,
      showAdminSection,
      loadingMintersBurners,
      mintingStatus,
      burningStatus,
      showPasswordAlert,
      transactionHash,
      lastMintTxHash,
      lastBurnTxHash,
      // The key pieces for password logic:
      mintStep,
      mintPassword,            // stored password (from URL or user input originally)
      manualPasswordInput,     // text input state for the password field
      claimCountdown,
    } = this.state;

    // -------------------------------
    // MINI / MINIATURIZED RENDER
    // -------------------------------
    if (miniaturized) {
      // If we don't yet have sbtInfo loaded
      if (!this.state.sbtInfo) {
        return <div className={styles.loading}>Loading SBT info...</div>;
      }

      if (!SBTAddress) {
        return null;
      }

      if (error) {
        return <div className={styles.error}>Error: {error}</div>;
      }

      const imageUrl = sbtInfo.image;
      const sbtName = sbtInfo.name;
      const sbtAddress = SBTAddress;
      const now = Math.floor(Date.now() / 1000);
      const isMintingActive = (sbtInfo.mintingEndTime === 0 || sbtInfo.mintingEndTime > now);

      // Decide if we show the password input bar:
      // Condition: SBT is password-protected, user does not hold it yet, and minting is open, and we're still in mint steps 0..2
      const showPasswordInput =
        sbtInfo.hasPasswordMint === true &&
        !userHasSBT &&
        isMintingActive &&
        mintStep >= 0 &&
        mintStep < 3;

      // For convenience, let's ensure manualPasswordInput is pre-filled if we have a URL password
      // (This can also be done in componentDidMount, but checking here is fine.)
      const effectivePasswordInput = manualPasswordInput || mintPassword || sbtMintPassword || "";

      // Decide what we show for the mini "Mint" or "Burn" area
      let miniMintArea = null;

      // 1) If user does not have the SBT yet
      if (!userHasSBT) {
        // 1a) If minting ended, hide button entirely:
        if (!isMintingActive) {
          miniMintArea = <div style={{ marginTop: '10px' }}>Minting Ended</div>;
        }
        // 1b) If minting is active and the parent says we allow mini minting
        else if (miniMintable) {
          // If it's password-protected, we do multi-step (commit → wait → reveal)
          if (sbtInfo.hasPasswordMint) {
            // Step 0: user must start claim
            if (mintStep === 0) {
              miniMintArea = (
                <div id={styles.mintButtonArea} style={{ marginTop: '10px' }}>
                  {showPasswordInput && (
                    <div className={styles.passwordEntry}>
                      <input
                        type="text"
                        className={styles.input}
                        value={effectivePasswordInput}
                        onChange={(e) => this.setState({ manualPasswordInput: e.target.value })}
                        placeholder="Enter password"
                      />
                    </div>
                  )}
                  <button
                    onClick={this.miniMintHandler}
                    disabled={
                      mintingStatus !== 'idle' ||
                      (effectivePasswordInput.trim() === "")
                    }
                    className={`${styles.actionButton} ${styles.mintButton}`}
                  >
                    {mintingStatus === 'idle' && 'Start Claim'}
                    {mintingStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
                    {mintingStatus === 'failure' && <>Failed <FontAwesomeIcon icon={faTimes} /></>}
                    {mintingStatus === 'success' && <>Minted <FontAwesomeIcon icon={faCheck} /></>}
                  </button>
                </div>
              );
            }
            // Step 1: waiting period for front-run protection
            else if (mintStep === 1) {
              miniMintArea = (
                <div className={styles.mintProcess} style={{ marginTop: '10px' }}>
                  <p className={styles.claimCountdown}>
                    Waiting period: {claimCountdown} seconds
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className={styles.tooltip}
                      id="miniCountdownTooltip"
                      style={{ opacity: 0.5, marginLeft: '5px' }}
                    />
                  </p>
                  <p style={{ fontStyle: 'italic', fontSize: '0.9em', marginTop: '5px' }}>
                    (Please wait before revealing your password)
                  </p>
                </div>
              );
            }
            // Step 2: reveal password
            else if (mintStep === 2) {
              miniMintArea = (
                <div style={{ marginTop: '10px' }}>
                  {showPasswordInput && (
                    <div className={styles.passwordEntry} style={{ marginBottom: '5px' }}>
                      <input
                        type="text"
                        className={styles.input}
                        value={effectivePasswordInput}
                        onChange={(e) => this.setState({ manualPasswordInput: e.target.value })}
                        placeholder="Enter password"
                      />
                    </div>
                  )}
                  <button
                    onClick={this.miniMintHandler}
                    disabled={
                      mintingStatus !== 'idle' ||
                      (effectivePasswordInput.trim() === "")
                    }
                    className={`${styles.actionButton} ${styles.mintButton}`}
                  >
                    {mintingStatus === 'idle' && 'Finish Claim'}
                    {mintingStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
                    {mintingStatus === 'failure' && <>Failed <FontAwesomeIcon icon={faTimes} /></>}
                    {mintingStatus === 'success' && <>Minted <FontAwesomeIcon icon={faCheck} /></>}
                  </button>
                </div>
              );
            }
            // Step 3 or more: minted
            else if (mintStep >= 3) {
              miniMintArea = (
                <div className={styles.mintProcess} style={{ marginTop: '10px' }}>
                  <p className={styles.mintSuccess}>SBT successfully minted!</p>
                </div>
              );
            }
          } else {
            // Not password-protected => single-step mint
            miniMintArea = (
              <button
                onClick={this.miniMintHandler}
                className={`${styles.actionButton} ${styles.mintButton}`}
                style={{ marginTop: '10px' }}
                disabled={mintingStatus === 'pending'}
              >
                {mintingStatus === 'idle' && 'Mint'}
                {mintingStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
                {mintingStatus === 'failure' && <>Failed <FontAwesomeIcon icon={faTimes} /></>}
                {mintingStatus === 'success' && <>Minted <FontAwesomeIcon icon={faCheck} /></>}
              </button>
            );
          }
        }
      }
      // 2) If the user already has the SBT
      else {
        // Determine if we can burn
        const userAddressLower = this.props.account ? this.props.account.toLowerCase() : null;
        const canOwnerBurn =
          userHasSBT &&
          (
            sbtInfo.burnAuth === 1 ||
            sbtInfo.burnAuth === 2 ||
            (sbtInfo.burnAuth === 0 && sbtInfo.admin.toLowerCase() === userAddressLower) ||
            (sbtInfo.burnAuth === 1 && userHasSBT)
          );
        const canAdminBurn = userIsSbtAdmin && (sbtInfo.burnAuth === 0 || sbtInfo.burnAuth === 2);
        const canBurnMini = canOwnerBurn || canAdminBurn;

        if (canBurnMini) {
          miniMintArea = (
            <button
              onClick={this.miniBurnHandler}
              className={`${styles.actionButton} ${styles.burnButton}`}
              style={{ marginTop: '10px' }}
              disabled={burningStatus === 'pending'}
            >
              {burningStatus === 'idle' && 'Burn'}
              {burningStatus === 'pending' && <FontAwesomeIcon icon={faSpinner} spin />}
              {burningStatus === 'failure' && <>Failed <FontAwesomeIcon icon={faTimes} /></>}
              {burningStatus === 'success' && <>Burned <FontAwesomeIcon icon={faCheck} /></>}
            </button>
          );
        } else {
          miniMintArea = <div style={{ marginTop: '10px' }}>Collected</div>;
        }
      }

      return (
        <div className={styles.sbtItem}>
          <div className={styles.iconOverlay}>
            {isMintingActive && (
              <div className={styles.liveIndicator}></div>
            )}
            {sbtInfo.hasPasswordMint && (
              <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />
            )}
          </div>
          <div
            className={styles.miniImageContainer}
            onClick={() => window.open(`${window.location.origin}/sbt/${sbtAddress}`, '_blank')}
          >
            <img
              src={imageUrl}
              alt={sbtName}
              className={styles.sbtImage}
            />
          </div>
          <p id={styles.miniSbtName}>{sbtName}</p>
          <p id={styles.miniSbtAddress}>{proposalScripts.getShortenedAddress(sbtAddress, false)}</p>
          {miniMintArea}
        </div>
      );
    }

    // -------------------------------
    // FULL RENDER MODE (unchanged)
    // -------------------------------
    if (!SBTAddress) {
      return null;
    }

    if (error) {
      return <div className={styles.error}>Error: {error}</div>;
    }

    let mintEndDisplay;
    let fullMintEndDate = '';
    if (sbtInfo && sbtInfo.mintingEndTime) {
      const endTime = sbtInfo.mintingEndTime * 1000;
      fullMintEndDate = new Date(endTime).toLocaleString('en-US', {
        month: 'long', day: 'numeric', year: 'numeric'
      });
      const unixTS = sbtInfo.mintingEndTime;

      if (endTime > Date.now()) {
        mintEndDisplay = (
          <p>
            <span className={styles.label}>Minting ends:</span>
            <span>{mintCountdown}</span>
          </p>
        );
      } else {
        mintEndDisplay = (
          <p>
            <span className={styles.label}>Minting Expired</span>:
            <span
              className={styles.expiredTime}
              id="mintExpiredTooltip"
              style={{ cursor:'pointer' }}
              onClick={() => this.copyToClipboard(unixTS.toString(), 'time')}
            >
              {fullMintEndDate}
            </span>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              style={{ marginLeft:'5px', color:'#00ff9d', cursor:'pointer', opacity:0.5 }}
              id="expiredTimeQuestionMark"
            />
            <UncontrolledTooltip placement="right" target="expiredTimeQuestionMark" delay={{ show:0, hide:2500 }}>
              Click date to copy Unix timestamp: {unixTS}
            </UncontrolledTooltip>
          </p>
        );
      }
    }

    const burnAuthLabels = ["Issuer Only", "Owner Only", "Both", "Neither"];
    const addressDisplay = proposalScripts.getShortenedAddress(SBTAddress, false);
    const netMinted = mintedAddresses.filter(addr => !burnedAddresses.includes(addr)).length;

    return (
      <div className={styles.sbtPage}>
        <button onClick={() => window.location.href = "/sbts"} className={styles.backButton}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to SBTs list
        </button>
        {showPasswordAlert && (
          <Alert color="info" className={styles.passwordAlert}>
            Password detected – click "start claim" to mint
          </Alert>
        )}
        {sbtInfo ? (
          <>
            <div className={styles.sbtInfo}>
              <div className={styles.leftColumn}>
                <div className={styles.bookmarkIcon}>
                  <button
                    onClick={this.bookmarkSBT}
                    className={styles.bookmarkButton}
                    style={{ color: bookmarked ? '#FFD700' : undefined }}
                  >
                    <FontAwesomeIcon icon={faBookmark} />
                  </button>
                  <a
                    href={this.getExplorerUrl(SBTAddress)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.contractLink}
                  >
                    {addressDisplay}
                  </a>
                  <button
                    onClick={() => this.copyToClipboard(SBTAddress, 'contract')}
                    className={styles.copyButton}
                  >
                    <FontAwesomeIcon icon={this.state.copiedAddress === 'contract' ? faCheck : faCopy} />
                  </button>
                </div>
                <div className={styles.image}>
                  <img src={sbtInfo.image} alt="SBT" />
                </div>
                <div className={styles.description}>
                  <h1>{sbtInfo.name}</h1>
                  <p>{sbtInfo.description}</p>
                </div>
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.statsSection}>
                  <h2 className={`${styles.sectionHeader} ${styles.roundedHeader}`} onClick={this.toggleStats}>
                    STATS <FontAwesomeIcon icon={showStats ? faChevronUp : faChevronDown} />
                  </h2>
                  {showStats && (
                    <div className={styles.stats}>
                      <p>
                        <span className={styles.label}>Minted:</span>
                        {netMinted} / {sbtInfo.maxTokens === "0" ? <FontAwesomeIcon icon={faInfinity} /> : sbtInfo.maxTokens}
                        <button onClick={this.openMintedModal} className={styles.expandButton}>
                          <FontAwesomeIcon icon={faUser} />
                        </button>
                      </p>
                      {mintEndDisplay}
                      <p>
                        <span className={styles.label}>Burnable by:</span> {burnAuthLabels[sbtInfo.burnAuth]}
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          className={styles.tooltip}
                          id="burnAuthQuestionMark"
                          style={{ marginLeft:'5px', color:'#00ff9d', cursor:'pointer', opacity:0.5 }}
                        />
                        <UncontrolledTooltip placement="right" target="burnAuthQuestionMark" delay={{show:0, hide:2500}}>
                          Specify who can burn the token: Admin Only, Owner Only, Both, or Neither.
                        </UncontrolledTooltip>
                      </p>
                      <p>
                        <span className={styles.label}>Deployer:</span> {this.renderAddressLink(sbtInfo.admin)}
                      </p>
                      <p>
                        <span className={styles.label}>Admin:</span> {this.renderAddressLink(sbtInfo.admin)}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.actionsSection}>
                  <h2 className={`${styles.sectionHeader} ${styles.roundedHeader}`} onClick={this.toggleActions}>
                    ACTIONS <FontAwesomeIcon icon={showActions ? faChevronUp : faChevronDown} />
                  </h2>
                  {showActions && (
                    <div className={styles.actions}>
                      {this.renderMintButton()}
                      {this.renderBurnButton()}
                      {mintingStatus === 'success' && lastMintTxHash && burningStatus !== 'success' && (
                        <div className={styles.mintProcess}>
                          <p className={styles.mintSuccess}>
                            SBT successfully minted!
                            <br />
                            Mint Tx Hash:{' '}
                            <a
                              href={this.getExplorerLink(lastMintTxHash)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {proposalScripts.getShortenedTransactionHash(lastMintTxHash)}
                            </a>
                          </p>
                        </div>
                      )}
                      {burningStatus === 'success' && lastBurnTxHash && (
                        <div className={styles.mintProcess}>
                          <p className={styles.mintSuccess}>
                            SBT successfully burned!
                            <br />
                            Burn Tx Hash:{' '}
                            <a
                              href={this.getExplorerLink(lastBurnTxHash)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {proposalScripts.getShortenedTransactionHash(lastBurnTxHash)}
                            </a>
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {userIsSbtAdmin && (
                  <div className={styles.adminSection}>
                    <h2 className={`${styles.sectionHeader} ${styles.roundedHeader}`} onClick={this.toggleAdminSection}>
                      ADMIN <FontAwesomeIcon icon={showAdminSection ? faChevronUp : faChevronDown} />
                    </h2>
                    {showAdminSection && (
                      <div className={styles.adminContainer}>
                        {this.renderAdminActions()}
                      </div>
                    )}
                  </div>
                )}
                <div className={styles.moreDetailsSection}>
                  <h2 className={`${styles.sectionHeader} ${styles.roundedHeader}`} onClick={this.toggleMoreDetails}>
                    MORE <FontAwesomeIcon icon={showMoreDetails ? faChevronUp : faChevronDown} />
                  </h2>
                  {showMoreDetails && this.renderRelevantInfo()}
                </div>
              </div>
            </div>
          </>
        ) : (
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        )}

        <Modal
          isOpen={showModal}
          toggle={this.closeModal}
          id={styles.mintingAddressModal}
        >
          <ModalHeader toggle={this.closeModal}>Minted Addresses</ModalHeader>
          <ModalBody>
            {loadingMintersBurners ? (
              <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            ) : (
              <div>
                <SBTFilter
                  items={this.state.mintedAddresses.filter(addr => !this.state.burnedAddresses.includes(addr))}
                  mode="addresses"
                  provider={this.props.provider}
                  network={this.props.network}
                  onFilter={(filtered) => {
                    if (JSON.stringify(filtered) !== JSON.stringify(this.state.filteredMintedUsers)) {
                      this.setState({ filteredMintedUsers: filtered, loadingMintedFilter: false });
                    }
                  }}
                  autoExpand={false}
                />
                {this.state.loadingMintedFilter ? (
                  <div>Loading...</div>
                ) : (
                  <ul className={styles.mintingAddressList}>
                    {(this.state.filteredMintedUsers || this.state.mintedAddresses.filter(addr => !this.state.burnedAddresses.includes(addr))).map((address, index) => (
                      <li key={index} className={styles.mintingAddressItem}>
                        <a href={`/u/${address}`} target="_blank" rel="noopener noreferrer">
                          {proposalScripts.getShortenedAddress(address, false)}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default SBTPage;
