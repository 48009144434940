// === BEGIN UPDATED FILE: aiScripts.js ===
import axios from 'axios';
import {
  USER_ANTHROPIC_KEY,
  DEFAULT_ANTHROPIC_KEY,
  CLOUDFLARE_CORS_WORKER_URL,
} from '../variables/CONTRACT_ADDRESSES.js';

/**
 * The existing prompt import for rewriting user text with minimal changes:
 */
import { aiRewritePrompt } from '../prompts/aiRewritePrompt.js';

const ANTHROPIC_API_URL = 'https://api.anthropic.com/v1/messages';


/**
 * Extract main content from HTML by removing scripts, styles, footers, etc.
 */
const extractMainContent = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const elementsToRemove = ['script', 'style', 'iframe', 'nav', 'footer', 'header', 'aside'];
  elementsToRemove.forEach((tag) => {
    doc.querySelectorAll(tag).forEach((el) => el.remove());
  });

  const contentSelectors = [
    'main',
    'article',
    '.content',
    '#content',
    '.main-content',
    '#main-content',
    'body'
  ];

  for (const selector of contentSelectors) {
    const element = doc.querySelector(selector);
    if (element) {
      const text = element.textContent.replace(/\s+/g, ' ').trim();
      if (text.length > 100) {
        return text;
      }
    }
  }

  return doc.body.textContent.replace(/\s+/g, ' ').trim();
};

/**
 * Wrapper to call Anthropic AI via a Cloudflare Worker or direct request.
 * This function is referenced by other AI utility methods.
 */
export const callAnthropicAI = async (prompt) => {
  try {
    const requestBody = {
      prompt: prompt,
      model: 'claude-3-5-sonnet-20240620',
      max_tokens_to_sample: 4096,
      temperature: 0.7,
    };

    console.log('Sending request to Anthropic:', requestBody);

    const response = await fetch(CLOUDFLARE_CORS_WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });

    const data = await response.json();

    if (!response.ok) {
      console.error('Anthropic API Error:', data);
      throw new Error(data.error || 'API request failed');
    }

    console.log('Anthropic API Response:', data);

    if (!data.completion && data.content) {
      return data.content[0].text;
    } else if (data.completion) {
      return data.completion;
    } else {
      throw new Error('Unexpected Anthropic response format');
    }
  } catch (error) {
    console.error('Error calling Anthropic AI:', error);
    throw error;
  }
};

/**
 * Attempt to fetch HTML content from a URL directly; if it fails or is blocked,
 * fallback to CLOUDFLARE_CORS_WORKER_URL. Then parse the HTML to extract text.
 */
export const fetchContentFromURL = async (url) => {
  try {
    const validatedUrl = new URL(url);
    if (!validatedUrl.protocol.match(/^https?:$/)) {
      throw new Error('URL must start with http:// or https://');
    }

    try {
      const directResp = await fetch(validatedUrl.href);
      if (!directResp.ok) {
        throw new Error(`HTTP error! status: ${directResp.status}`);
      }
      const contentType = directResp.headers.get('content-type');
      if (contentType && contentType.includes('text/html')) {
        const htmlContent = await directResp.text();
        const extractedContent = extractMainContent(htmlContent);
        if (extractedContent && extractedContent.length > 100) {
          return extractedContent;
        }
      }
    } catch (directFetchError) {
      console.warn('Direct fetch failed, fallback to worker:', directFetchError);
    }

    // Fallback
    const workerResponse = await fetch(CLOUDFLARE_CORS_WORKER_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ url: validatedUrl.href, action: 'fetch_url' })
    });

    if (!workerResponse.ok) {
      const errorData = await workerResponse.json();
      throw new Error(errorData.error || 'Failed to fetch URL content');
    }

    const data = await workerResponse.json();
    if (!data.content) {
      throw new Error('No content received from URL');
    }

    // Clean up HTML if needed
    if (typeof data.content === 'string' && data.content.includes('<')) {
      return extractMainContent(data.content);
    }
    return data.content;
  } catch (error) {
    console.error('Error fetching URL content:', error);
    throw new Error(`URL Error: ${error.message}`);
  }
};

/**
 * Analyze a set of survey responses by calling the AI with a broad summary prompt.
 */
export const analyzeSurveyResponses = async (responses) => {
  const prompt = `Analyze the following survey responses and provide a summary of the key findings: ${JSON.stringify(responses)}`;
  try {
    const analysis = await callAnthropicAI(prompt);
    return analysis;
  } catch (error) {
    console.error('Error analyzing survey responses:', error);
    throw error;
  }
};

// --- UPDATED PROMPT FOR RANKING QUESTIONS ---
const rankQuestionsPrompt = `
You are given:
1) A user query: "{{userQuery}}"
2) A list of question objects with IDs and prompts:
{{questionListStr}}

Your task:
- Identify the top {{topX}} question IDs that best match the user query.
- Return them as a VALID JSON object, using this exact format:

{
  "selectedQuestionIDs": [
    "0xSomeID",
    "0xAnotherID",
    ...
  ]
}

No commentary, disclaimers, or extra text. Output must only be that JSON object.
`;

/**
 * rankQuestionsAI
 * Given a user query string, a list of question objects, and an integer topX,
 * calls the AI to produce a JSON object with a "selectedQuestionIDs" array in
 * descending order of relevance.
 */
export async function rankQuestionsAI(userQuery, questionList, topX = 10) {
  try {
    // Build a text block listing the questions
    const questionListStr = questionList
      .map((q, idx) => `Q${idx + 1}: ID=${q.id}, Prompt="${q.prompt}"`)
      .join('\n');

    // Interpolate the prompt
    let finalPrompt = rankQuestionsPrompt
      .replace('{{userQuery}}', userQuery)
      .replace('{{questionListStr}}', questionListStr)
      .replace('{{topX}}', String(topX));

    console.log('rankQuestionsAI - constructed prompt:', finalPrompt);

    // Call AI
    const rawOutput = await callAnthropicAI(finalPrompt.trim());
    console.log('rankQuestionsAI rawOutput:', rawOutput);

    // Attempt to parse the returned object
    let parsed;
    try {
      parsed = JSON.parse(rawOutput.trim());
    } catch (err) {
      console.warn('Could not parse AI output as JSON object, trying fallback braces check:', err);
      // Fallback: attempt to find a { ... } block with quick regex
      const match = rawOutput.match(/\{[\s\S]*\}/);
      if (match) {
        parsed = JSON.parse(match[0]);
      }
    }

    // Validate structure
    if (!parsed || !Array.isArray(parsed.selectedQuestionIDs)) {
      console.error('AI did not return an object with "selectedQuestionIDs": [...]');
      return [];
    }

    // Optional type-checking: ensure each array element is a string
    const questionIDs = parsed.selectedQuestionIDs.filter(
      (id) => typeof id === 'string' && id.trim() !== ''
    );

    // If AI returned more than topX, slice it
    if (questionIDs.length > topX) {
      return questionIDs.slice(0, topX);
    }

    return questionIDs;
  } catch (error) {
    console.error('Error in rankQuestionsAI:', error);
    return [];
  }
}

/**
 * NEW FUNCTION: Minimal rewriting of user text to remove filler words and add punctuation.
 * Uses the imported aiRewritePrompt and callAnthropicAI to do minimal edits without
 * changing overall meaning. Returns the cleaned-up text as a string.
 */
export async function requestAiRewrite(originalText) {
  try {
    // Fill in the user's text in the prompt
    const finalPrompt = aiRewritePrompt.replace('<USER_TEXT>', originalText);

    // Call Anthropic (or your chosen AI service)
    const cleaned = await callAnthropicAI(finalPrompt);

    // Return the AI's result (which should be plain text with minimal filler words).
    return cleaned.trim();
  } catch (error) {
    console.error('requestAiRewrite error:', error);
    // Fallback: return the original text if AI fails
    return originalText;
  }
}
// === END UPDATED FILE: aiScripts.js ===
