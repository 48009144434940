/***************************************************
 * FILE: AudioInput.jsx
 * LOCATION: my-app/client/src/components/SurveyTool/AudioInput.jsx
 ***************************************************/

import React, { useState, useEffect, useRef } from 'react';
import {
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faCircle,
  faLock,
  faUnlock,
  faArrowLeft,
  faHandSparkles  // for the AI rewrite button icon
} from '@fortawesome/free-solid-svg-icons';
import { useWhisper } from '@chengsokdara/use-whisper';

// Import the updated SCSS module:
import styles from './AudioInput.module.scss';

// AI
import { requestAiRewrite } from '../../utilities/aiScripts.js';

// OpenAI API
import { OPENAI_API_KEY } from '../../variables/CONTRACT_ADDRESSES.js';
const apiKey = OPENAI_API_KEY;

/**
 * AudioInput component with:
 *   - optional encryption checkbox
 *   - optional AI rewrite if enableAiRewrite is true
 *
 * PROPS:
 * - placeholder: string
 * - updateFunction(newValue: string): callback to parent when text changes
 * - toggleEncryption(newEncryptedState: boolean): parent callback
 * - value: current text value
 * - encrypted: boolean for encryption
 * - hideEncryption: if true, hides the encryption checkbox
 * - enableAiRewrite: if true, show the “AI rewrite” button logic
 */
const AudioInput = ({
  placeholder,
  updateFunction,
  toggleEncryption,
  value,
  encrypted,
  hideEncryption = false,
  enableAiRewrite = true
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [encryptBoxChecked, setEncryptBoxChecked] = useState(encrypted);
  const [liveTranscript, setLiveTranscript] = useState("");   // Live speech-to-text
  const [userText, setUserText] = useState("");               // Displayed text

  // States for AI rewrite
  const [originalText, setOriginalText] = useState("");
  const [rewrittenText, setRewrittenText] = useState("");
  const [aiRewriteActive, setAiRewriteActive] = useState(false);
  const [waitingForAI, setWaitingForAI] = useState(false);
  const [waitingSeconds, setWaitingSeconds] = useState(0);
  const timerRef = useRef(null);

  const { transcript, startRecording, stopRecording } = useWhisper({
    apiKey: apiKey,
    removeSilence: false,
    streaming: true,
    timeSlice: 1000, // 1 second
  });

  // Keep track of the streaming transcript
  useEffect(() => {
    setLiveTranscript(transcript.text);
  }, [transcript.text]);

  // Initialize userText from the parent prop when it changes
  useEffect(() => {
    if (value && value !== placeholder) {
      setUserText(value);
    } else if (!value) {
      setUserText("");
    }
  }, [value, placeholder]);

  // Keep encryption checkbox in sync
  useEffect(() => {
    setEncryptBoxChecked(encrypted);
  }, [encrypted]);

  // Clear any timer on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  /**
   * Handling microphone record/stop logic
   */
  const handleRecordClick = () => {
    if (isRecording) {
      stopRecording();
      const updatedText = `${userText} ${liveTranscript}`.trim();
      setUserText(updatedText);
      updateFunction(updatedText);
    } else {
      startRecording();
    }
    setIsRecording((prev) => !prev);
  };

  /**
   * Handling text input changes
   */
  const handleInputChange = (e) => {
    if (!waitingForAI) {
      setUserText(e.target.value);
      updateFunction(e.target.value);
    }
  };

  /**
   * Encryption checkbox toggle
   */
  const handleEncryptCheckbox = () => {
    const newEncrypted = !encryptBoxChecked;
    setEncryptBoxChecked(newEncrypted);
    toggleEncryption(newEncrypted);
  };

  /**
   * AI rewrite logic
   */
  const showAiRewriteButton =
    enableAiRewrite && userText.trim().length > 0 && !aiRewriteActive && !waitingForAI;
  const showBackArrowButton = enableAiRewrite && aiRewriteActive && !waitingForAI;

  const startWaitingTimer = () => {
    setWaitingForAI(true);
    setWaitingSeconds(0);
    timerRef.current = setInterval(() => {
      setWaitingSeconds((prev) => prev + 1);
    }, 1000);
  };

  const stopWaitingTimer = () => {
    setWaitingForAI(false);
    setWaitingSeconds(0);
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  /**
   * Actually calls the requestAiRewrite function from aiScripts.js
   */
  const handleAiRewrite = async () => {
    try {
      // Store original text
      setOriginalText(userText);
      setRewrittenText("");

      // Show waiting state
      startWaitingTimer();
      setUserText(`Waiting for AI... 0s`);
      updateFunction(`Waiting for AI... 0s`);

      // Call the AI rewriting function
      const cleaned = await requestAiRewrite(originalText || userText);

      // Done waiting
      stopWaitingTimer();

      // Now we have AI's revised text
      setRewrittenText(cleaned);
      setAiRewriteActive(true);

      // Update displayed text
      setUserText(cleaned);
      updateFunction(cleaned);
    } catch (err) {
      console.error("AI rewrite error:", err);
      // If there's an error, revert
      stopWaitingTimer();
      setAiRewriteActive(false);
      setRewrittenText("");
      setUserText(originalText || userText);
      updateFunction(originalText || userText);
    }
  };

  // Update text with "Waiting for AI... Xs" while waiting
  useEffect(() => {
    if (waitingForAI) {
      const newWaitStr = `Waiting for AI... ${waitingSeconds}s`;
      setUserText(newWaitStr);
      updateFunction(newWaitStr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingSeconds]);

  /**
   * Revert to the original text
   */
  const handleRevertText = () => {
    setAiRewriteActive(false);
    setRewrittenText("");
    setUserText(originalText);
    updateFunction(originalText);
  };

  /**
   * Placeholder logic
   */
  const getPlaceholder = () => {
    if (userText) return '';
    return placeholder;
  };

  return (
    <div className={styles.audioInputContainer}>
      {/* topControls: AI rewrite + encryption */}
      <div className={styles.topControls}>
        {/* AI rewrite button */}
        {showAiRewriteButton && (
          <button
            onClick={handleAiRewrite}
            title="AI rewrite"
            className={styles.aiRewriteButton}
          >
            <FontAwesomeIcon icon={faHandSparkles} />
            AI rewrite
          </button>
        )}
        {/* Back arrow to revert */}
        {showBackArrowButton && (
          <button
            onClick={handleRevertText}
            title="Revert to original"
            className={styles.backArrowButton}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        )}
        {/* Encryption controls (same styling as old code, no differences) */}
        {!hideEncryption && (
          <div
            className={styles.encryptOptionButton}
            onClick={handleEncryptCheckbox}
          >
            <FontAwesomeIcon
              className={styles.encryptIcon}
              icon={encryptBoxChecked ? faLock : faUnlock}
            />
            <InputGroupText className={styles.inputGroupText}>
              <input
                addon="true"
                type="checkbox"
                aria-label="encrypt"
                checked={encryptBoxChecked}
                onChange={handleEncryptCheckbox}
                className={styles.encryptCheckbox}
              />
              Encrypt
            </InputGroupText>
          </div>
        )}
      </div>

      {/* The text input + microphone */}
      <div className={styles.inputRow}>
        <Input
          type="textarea"
          placeholder={getPlaceholder()}
          value={isRecording ? liveTranscript : userText}
          className={styles.audioTextarea}
          onChange={handleInputChange}
          readOnly={isRecording || waitingForAI}
        />
        <button
          onClick={handleRecordClick}
          className={`${styles.microphoneButton} ${isRecording ? styles.recording : ''}`}
        >
          {isRecording ? (
            <FontAwesomeIcon icon={faCircle} />
          ) : (
            <FontAwesomeIcon icon={faMicrophone} />
          )}
        </button>
      </div>
    </div>
  );
};

export default AudioInput;
