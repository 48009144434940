import React from 'react';
import { FormGroup, Label, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from './SBTSelector.module.scss';
import SBTSelector from './SBTSelector.jsx';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSpinner, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

/**
 * Helper to unify an aggregator or a question array with all known questions from questionsCache,
 * so zero-response or standalone questions won't get dropped. 
 * The logic is used when mode indicates question-based filtering.
 */
function unifyAggregatorWithAllLocalQuestions(baseItems, networkID, mode) {
  if (!networkID) return baseItems;

  let questionsCacheStr = localStorage.getItem('questionsCache') || '';
  let questionsCache = {};
  try {
    questionsCache = JSON.parse(questionsCacheStr);
  } catch (err) {
    questionsCache = {};
  }
  if (!questionsCache[networkID] || !questionsCache[networkID].questions) {
    return baseItems;
  }

  const allKnownQIDs = Object.keys(questionsCache[networkID].questions || {});

  // If baseItems is an array of question objects, unify by adding missing from cache
  // OR if baseItems is an aggregator object => questionID => array of responses, unify likewise
  // Previously, we skipped "responder" in this condition. We now add it back:
  if (
    mode === 'creatorAndResponder' ||
    mode === 'creator' ||
    mode === 'questions' ||
    mode === 'questionResponses' ||
    mode === 'responder'  // <--- ADDED to restore question-responder aggregator unification
  ) {
    if (Array.isArray(baseItems)) {
      const existingIDs = new Set(baseItems.map((q) => (q.id || '').toLowerCase()));
      const newArray = [...baseItems];
      allKnownQIDs.forEach((qIdLower) => {
        if (!existingIDs.has(qIdLower)) {
          const qObj = questionsCache[networkID].questions[qIdLower];
          if (qObj) newArray.push({ ...qObj });
        }
      });
      return newArray;
    } else if (typeof baseItems === 'object' && baseItems !== null) {
      const newObj = { ...baseItems };
      allKnownQIDs.forEach((qIdLower) => {
        if (!newObj[qIdLower]) {
          newObj[qIdLower] = [];
        }
      });
      return newObj;
    }
  }

  return baseItems;
}


class SBTFilter extends React.Component {
  constructor(props) {
    super(props);
    // We store all local filter states in one object. If parent passes `externalSBTFilterState`, restore them here.
    this.state = {
      selectedSBTGroupsCreator:
        (props.externalSBTFilterState && props.externalSBTFilterState.selectedSBTGroupsCreator) ||
        [],
      excludedSBTGroupsCreator:
        (props.externalSBTFilterState && props.externalSBTFilterState.excludedSBTGroupsCreator) ||
        [],
      selectedSBTGroupsResponder:
        (props.externalSBTFilterState && props.externalSBTFilterState.selectedSBTGroupsResponder) ||
        [],
      excludedSBTGroupsResponder:
        (props.externalSBTFilterState && props.externalSBTFilterState.excludedSBTGroupsResponder) ||
        [],
      selectedSBTGroups:
        (props.externalSBTFilterState && props.externalSBTFilterState.selectedSBTGroups) || [],
      excludedSBTGroups:
        (props.externalSBTFilterState && props.externalSBTFilterState.excludedSBTGroups) || [],
      onlyVerifiedHumans:
        (props.externalSBTFilterState && props.externalSBTFilterState.onlyVerifiedHumans) || false,
      showFilterOptions: props.autoExpand || false,
      loading: false,

      // Snapshot to compare so we don't re-apply identical filters over and over
      lastAppliedFilterSnapshot: null
    };
  }

  componentDidMount() {
    // If there's an existing external state that implies active filters, apply them once on mount
    if (this.hasAnyFilterActive()) {
      this.applyFilter();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // If local state or props changed in a relevant way, re-apply
    const fieldsToCheck = [
      'selectedSBTGroupsCreator',
      'excludedSBTGroupsCreator',
      'selectedSBTGroupsResponder',
      'excludedSBTGroupsResponder',
      'selectedSBTGroups',
      'excludedSBTGroups',
      'onlyVerifiedHumans'
    ];

    let shouldReapply = false;
    for (let f of fieldsToCheck) {
      if (prevState[f] !== this.state[f]) {
        shouldReapply = true;
        break;
      }
    }

    if (prevProps.items !== this.props.items || prevProps.mode !== this.props.mode) {
      shouldReapply = true;
    }

    if (shouldReapply) {
      this.applyFilter();
    }
  }

  hasAnyFilterActive() {
    const {
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans
    } = this.state;

    return (
      selectedSBTGroupsCreator.length > 0 ||
      excludedSBTGroupsCreator.length > 0 ||
      selectedSBTGroupsResponder.length > 0 ||
      excludedSBTGroupsResponder.length > 0 ||
      selectedSBTGroups.length > 0 ||
      excludedSBTGroups.length > 0 ||
      onlyVerifiedHumans
    );
  }

  getLocalFilterState() {
    return {
      selectedSBTGroupsCreator: this.state.selectedSBTGroupsCreator,
      excludedSBTGroupsCreator: this.state.excludedSBTGroupsCreator,
      selectedSBTGroupsResponder: this.state.selectedSBTGroupsResponder,
      excludedSBTGroupsResponder: this.state.excludedSBTGroupsResponder,
      selectedSBTGroups: this.state.selectedSBTGroups,
      excludedSBTGroups: this.state.excludedSBTGroups,
      onlyVerifiedHumans: this.state.onlyVerifiedHumans
    };
  }

  applyFilter = async () => {
    const { items, mode, provider, network } = this.props;
    const {
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans
    } = this.state;

    const networkID = network?.id;
    console.log('Network ID used in SBTFilter:', networkID);

    // Create a small snapshot to compare
    const newFilterSnapshot = JSON.stringify({
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans,
      mode,
      itemCount: Array.isArray(items)
        ? items.length
        : typeof items === 'object'
        ? Object.keys(items).length
        : items
    });

    // Identify if we have an aggregator object in single-filter mode
    // so we can skip the normal snapshot check.  // ADDED
    const isAggregatorObject =
      items && typeof items === 'object' && !Array.isArray(items);

    // If aggregator in single-filter mode, skip snapshot check.  // ADDED
    if (!isAggregatorObject && this.state.lastAppliedFilterSnapshot === newFilterSnapshot) {
      // Means we already applied these exact filters; avoid re-render loops
      return;
    }

    this.setState({ lastAppliedFilterSnapshot: newFilterSnapshot, loading: true });

    if (!networkID) {
      console.error('Network ID is undefined in SBTFilter. Cannot proceed.');
      this.setState({ loading: false });
      if (this.props.onFilter) {
        this.props.onFilter([], this.getLocalFilterState());
      }
      return;
    }

    if (!items || !this.hasAnyFilterActive()) {
      // No items or no active filter => pass items straight through
      this.setState({ loading: false });
      if (this.props.onFilter) {
        this.props.onFilter(items || [], this.getLocalFilterState());
      }
      return;
    }

    // Gather all SBT addresses we need to look up
    const allSBTAddresses = new Set([
      ...selectedSBTGroupsCreator.map((sbt) => sbt.address.toLowerCase()),
      ...excludedSBTGroupsCreator.map((sbt) => sbt.address.toLowerCase()),
      ...selectedSBTGroupsResponder.map((sbt) => sbt.address.toLowerCase()),
      ...excludedSBTGroupsResponder.map((sbt) => sbt.address.toLowerCase()),
      ...selectedSBTGroups.map((sbt) => sbt.address.toLowerCase()),
      ...excludedSBTGroups.map((sbt) => sbt.address.toLowerCase())
    ]);

    const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    console.log('sbtCache:', sbtCache);

    let sbtListData = {};
    if (sbtCache[networkID] && sbtCache[networkID].sbtList) {
      sbtListData = sbtCache[networkID].sbtList;
    }

    // Build a map of SBT -> set of minted holders (minus burned)
    const sbtHoldersMap = {};
    for (const sbtAddress of allSBTAddresses) {
      let mintedAddresses = [];
      let burnedAddresses = [];
      let netMintedAddresses = [];
      if (
        sbtListData[sbtAddress] &&
        sbtListData[sbtAddress].mintedAddresses &&
        sbtListData[sbtAddress].burnedAddresses
      ) {
        mintedAddresses = sbtListData[sbtAddress].mintedAddresses.map((addr) =>
          addr.toLowerCase()
        );
        burnedAddresses = sbtListData[sbtAddress].burnedAddresses.map((addr) =>
          addr.toLowerCase()
        );
        netMintedAddresses = mintedAddresses.filter(
          (addr) => !burnedAddresses.includes(addr)
        );
        sbtHoldersMap[sbtAddress] = new Set(netMintedAddresses);
      } else {
        try {
          mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(provider, sbtAddress);
          burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(provider, sbtAddress);
          mintedAddresses = mintedAddresses.map((addr) => addr.toLowerCase());
          burnedAddresses = burnedAddresses.map((addr) => addr.toLowerCase());
          netMintedAddresses = mintedAddresses.filter(
            (addr) => !burnedAddresses.includes(addr)
          );
          sbtHoldersMap[sbtAddress] = new Set(netMintedAddresses);

          // Update sbtCache
          if (!sbtCache[networkID]) {
            sbtCache[networkID] = { sbtList: {} };
          }
          if (!sbtCache[networkID].sbtList[sbtAddress]) {
            sbtCache[networkID].sbtList[sbtAddress] = {};
          }
          sbtCache[networkID].sbtList[sbtAddress].mintedAddresses = mintedAddresses;
          sbtCache[networkID].sbtList[sbtAddress].burnedAddresses = burnedAddresses;
          localStorage.setItem('sbtCache', JSON.stringify(sbtCache));
        } catch (error) {
          console.error('Error fetching SBT holders:', error);
          sbtHoldersMap[sbtAddress] = new Set();
        }
      }
    }

    console.log('sbtHoldersMap:', sbtHoldersMap);

    // Helper to check address vs. "include" and "exclude" sets
    const doesAddressPassFilters = (address, selectedSBTs, excludedSBTs) => {
      if (!address) return true;
      const lowerAddr = address.toLowerCase();

      // Exclude logic
      for (const sbt of excludedSBTs) {
        const sbtAddr = sbt.address.toLowerCase();
        const holdersSet = sbtHoldersMap[sbtAddr];
        if (holdersSet && holdersSet.has(lowerAddr)) {
          return false;
        }
      }
      // Include logic
      if (selectedSBTs.length > 0) {
        let holdsIncludedSBT = false;
        for (const sbt of selectedSBTs) {
          const sbtAddr = sbt.address.toLowerCase();
          const holdersSet = sbtHoldersMap[sbtAddr];
          if (holdersSet && holdersSet.has(lowerAddr)) {
            holdsIncludedSBT = true;
            break;
          }
        }
        if (!holdsIncludedSBT) {
          return false;
        }
      }
      return true;
    };

    // -------------------------------------------
    // CREATOR AND RESPONDER MODE
    // -------------------------------------------
    if (mode === 'creatorAndResponder') {
      if (!items) {
        console.warn('creatorAndResponder mode: no items supplied');
        this.setState({ loading: false });
        if (this.props.onFilter) {
          this.props.onFilter([], this.getLocalFilterState());
        }
        return;
      }

      if (Array.isArray(items)) {
        // Existing array logic
        let filteredQuestions = [];
        let filteredResponsesByQuestion = {};

        for (let questionObj of items) {
          if (
            doesAddressPassFilters(
              questionObj.creator,
              selectedSBTGroupsCreator,
              excludedSBTGroupsCreator
            )
          ) {
            filteredQuestions.push(questionObj);
          }
        }

        // Now build a question->response aggregator from local questionsCache
        for (let qObj of filteredQuestions) {
          const qID = qObj.id?.toLowerCase();
          let questionsCacheStr = localStorage.getItem('questionsCache');
          let questionsCache = {};
          if (questionsCacheStr) {
            try {
              questionsCache = JSON.parse(questionsCacheStr);
            } catch (e) {
              console.error('Error parsing questionsCache in SBTFilter:', e);
              questionsCache = {};
            }
          }
          let networkIDLocal = this.props.network?.id?.toString() || '';
          let qResponses = [];
          if (
            questionsCache[networkIDLocal] &&
            questionsCache[networkIDLocal].questionResponses &&
            questionsCache[networkIDLocal].questionResponses[qID]
          ) {
            const addresses = Object.keys(
              questionsCache[networkIDLocal].questionResponses[qID]
            );
            for (let addr of addresses) {
              const responseData =
                questionsCache[networkIDLocal].questionResponses[qID][addr];
              qResponses.push({
                responder: addr,
                questionId: qID,
                response: responseData
              });
            }
          }

          let finalFilteredResponses = [];
          for (let resp of qResponses) {
            if (
              doesAddressPassFilters(
                resp.responder,
                selectedSBTGroupsResponder,
                excludedSBTGroupsResponder
              )
            ) {
              finalFilteredResponses.push(resp);
            }
          }
          filteredResponsesByQuestion[qID] = finalFilteredResponses;
        }

        if (onlyVerifiedHumans) {
          // Possibly do more checks
        }

        if (this.props.onFilterCreators) {
          this.props.onFilterCreators(filteredQuestions, this.getLocalFilterState());
        }
        if (this.props.onFilterResponders) {
          this.props.onFilterResponders(
            filteredResponsesByQuestion,
            this.getLocalFilterState()
          );
        }

        if (this.props.onFilter) {
          const combinedResult = {
            filteredQuestions,
            filteredResponsesByQuestion
          };
          this.props.onFilter(combinedResult, this.getLocalFilterState());
        }
        this.setState({ loading: false });
        return;
      }
      // aggregator-object version for creatorAndResponder
      else if (typeof items === 'object' && items !== null) {
        const unifiedAgg = unifyAggregatorWithAllLocalQuestions(items, networkID, 'creatorAndResponder');

        let finalAggregator = {};
        let questionsCacheStr = localStorage.getItem('questionsCache');
        let questionsCache = {};
        if (questionsCacheStr) {
          try {
            questionsCache = JSON.parse(questionsCacheStr);
          } catch (err) {
            console.error('Error parsing questionsCache for aggregator approach:', err);
            questionsCache = {};
          }
        }
        const networkIDLocal = this.props.network?.id?.toString() || '';

        let filteredQuestions = [];

        for (let qId of Object.keys(unifiedAgg)) {
          const rawArray = unifiedAgg[qId] || [];
          let questionCreator = null;
          if (
            questionsCache[networkIDLocal] &&
            questionsCache[networkIDLocal].questions &&
            questionsCache[networkIDLocal].questions[qId]
          ) {
            questionCreator = questionsCache[networkIDLocal].questions[qId].creator;
          }
          let keepThisQuestion = true;
          if (questionCreator) {
            keepThisQuestion = doesAddressPassFilters(
              questionCreator,
              selectedSBTGroupsCreator,
              excludedSBTGroupsCreator
            );
          }
          if (!keepThisQuestion) {
            continue;
          }
          if (
            questionsCache[networkIDLocal] &&
            questionsCache[networkIDLocal].questions[qId]
          ) {
            filteredQuestions.push(questionsCache[networkIDLocal].questions[qId]);
          }

          const keptEntries = rawArray.filter((entryObj) => {
            if (
              entryObj.responder &&
              !doesAddressPassFilters(
                entryObj.responder,
                selectedSBTGroupsResponder,
                excludedSBTGroupsResponder
              )
            ) {
              return false;
            }
            return true;
          });
          if (keptEntries.length > 0) {
            finalAggregator[qId] = keptEntries;
          }
        }

        if (onlyVerifiedHumans) {
          // Possibly do more checks
        }

        if (this.props.onFilterCreators) {
          this.props.onFilterCreators(filteredQuestions, this.getLocalFilterState());
        }
        if (this.props.onFilterResponders) {
          const byQuestion = {};
          for (const qId of Object.keys(finalAggregator)) {
            byQuestion[qId] = finalAggregator[qId];
          }
          this.props.onFilterResponders(byQuestion, this.getLocalFilterState());
        }

        if (this.props.onFilter) {
          this.props.onFilter(finalAggregator, this.getLocalFilterState());
        }

        this.setState({ loading: false });
        return;
      } else {
        console.warn(
          'creatorAndResponder mode: expected array or aggregator object, got something else.'
        );
        this.setState({ loading: false });
        if (this.props.onFilter) {
          this.props.onFilter([], this.getLocalFilterState());
        }
        return;
      }
    }
    // -------------------------------------------
    // Single-filter aggregator logic // ADDED
    // (for creator, responder, questions, questionResponses)
    // -------------------------------------------
    else if (
      (mode === 'creator' ||
        mode === 'responder' ||
        mode === 'questions' ||
        mode === 'questionResponses') &&
      typeof items === 'object' &&
      items !== null &&
      !Array.isArray(items)
    ) {
      const unifiedAgg = unifyAggregatorWithAllLocalQuestions(items, networkID, mode);

      let finalAggregator = {};
      let questionsCacheStr = localStorage.getItem('questionsCache');
      let questionsCache = {};
      if (questionsCacheStr) {
        try {
          questionsCache = JSON.parse(questionsCacheStr);
        } catch (err) {
          console.error('Error parsing questionsCache for aggregator approach:', err);
          questionsCache = {};
        }
      }
      const networkIDLocal = this.props.network?.id?.toString() || '';

      let filteredQuestions = [];

      for (let qId of Object.keys(unifiedAgg)) {
        // If aggregator shape is an object instead of array, convert it.  // ADDED
        let rawVal = unifiedAgg[qId];
        let rawArray = Array.isArray(rawVal)
          ? rawVal
          : Object.keys(rawVal).map((respAddr) => {
              const potentialObj = rawVal[respAddr];
              // If potentialObj has its own "responder" field, keep it;
              // else embed "responder: respAddr" so the filter works.  // ADDED
              if (potentialObj && typeof potentialObj === 'object' && potentialObj.responder) {
                return potentialObj;
              } else {
                return {
                  responder: respAddr,
                  response: potentialObj
                };
              }
            });

        // For "creator" or "questions" modes, check question creator
        let keepThisQuestion = true;
        if (mode === 'creator' || mode === 'questions') {
          let questionCreator = null;
          if (
            questionsCache[networkIDLocal] &&
            questionsCache[networkIDLocal].questions &&
            questionsCache[networkIDLocal].questions[qId]
          ) {
            questionCreator = questionsCache[networkIDLocal].questions[qId].creator;
          }
          if (questionCreator) {
            keepThisQuestion = doesAddressPassFilters(
              questionCreator,
              selectedSBTGroupsCreator,
              excludedSBTGroupsCreator
            );
          }
        }
        if (!keepThisQuestion) {
          continue;
        }

        // If we do keep the question, push it to "filteredQuestions"
        if (
          (mode === 'creator' || mode === 'questions') &&
          questionsCache[networkIDLocal] &&
          questionsCache[networkIDLocal].questions[qId]
        ) {
          filteredQuestions.push(questionsCache[networkIDLocal].questions[qId]);
        }

        // For "responder" or "questionResponses", filter the array by entryObj.responder
        let keptEntries = rawArray;
        if (mode === 'responder' || mode === 'questionResponses') {
          keptEntries = rawArray.filter((entryObj) => {
            if (
              entryObj.responder &&
              !doesAddressPassFilters(
                entryObj.responder,
                selectedSBTGroupsResponder,
                excludedSBTGroupsResponder
              )
            ) {
              return false;
            }
            return true;
          });
        }

        if (keptEntries.length > 0) {
          finalAggregator[qId] = keptEntries;
        }
      }

      if (onlyVerifiedHumans) {
        // Possibly do more checks
      }

      if ((mode === 'creator' || mode === 'questions') && this.props.onFilterCreators) {
        this.props.onFilterCreators(filteredQuestions, this.getLocalFilterState());
      }
      if ((mode === 'responder' || mode === 'questionResponses') && this.props.onFilterResponders) {
        const byQuestion = {};
        for (const qId of Object.keys(finalAggregator)) {
          byQuestion[qId] = finalAggregator[qId];
        }
        this.props.onFilterResponders(byQuestion, this.getLocalFilterState());
      }

      if (this.props.onFilter) {
        this.props.onFilter(finalAggregator, this.getLocalFilterState());
      }

      this.setState({ loading: false });
      return;
    }
    // -------------------------------------------
    // DEFAULT ELSE: array filtering or other object
    // -------------------------------------------
    else {
      if (!items) {
        console.warn(`No items supplied for mode="${mode}".`);
        this.setState({ loading: false });
        if (this.props.onFilter) {
          this.props.onFilter([], this.getLocalFilterState());
        }
        return;
      }

      const filterItem = (item) => {
        if (!item) return false;

        if (mode === 'addresses') {
          if (typeof item !== 'string') {
            console.error('Expected item to be a string in addresses mode, but got:', item);
            return false;
          }
          const address = item.toLowerCase();
          // Exclude
          for (const sbt of excludedSBTGroups) {
            const sbtAddr = sbt.address.toLowerCase();
            const holdersSet = sbtHoldersMap[sbtAddr];
            if (holdersSet && holdersSet.has(address)) {
              return false;
            }
          }
          // Include
          if (selectedSBTGroups.length > 0) {
            let holdsIncludedSBT = false;
            for (const sbt of selectedSBTGroups) {
              const sbtAddr = sbt.address.toLowerCase();
              const holdersSet = sbtHoldersMap[sbtAddr];
              if (holdersSet && holdersSet.has(address)) {
                holdsIncludedSBT = true;
                break;
              }
            }
            if (!holdsIncludedSBT) {
              return false;
            }
          }
          if (onlyVerifiedHumans) {
            // ...
          }
          return true;
        } else {
          // question-based item or aggregator sub-item
          let addressToCheckCreator = null;
          let addressToCheckResponder = null;

          if (item.creator) {
            addressToCheckCreator = item.creator.toLowerCase();
          }
          if (item.responder) {
            addressToCheckResponder = item.responder.toLowerCase();
          }

          if (mode === 'creator' || mode === 'questions') {
            if (addressToCheckCreator) {
              if (
                !doesAddressPassFilters(
                  addressToCheckCreator,
                  selectedSBTGroupsCreator,
                  excludedSBTGroupsCreator
                )
              ) {
                return false;
              }
            }
          }
          if (mode === 'responder' || mode === 'questionResponses') {
            if (addressToCheckResponder) {
              if (
                !doesAddressPassFilters(
                  addressToCheckResponder,
                  selectedSBTGroupsResponder,
                  excludedSBTGroupsResponder
                )
              ) {
                return false;
              }
            }
          }
          if (onlyVerifiedHumans && (addressToCheckCreator || addressToCheckResponder)) {
            // ...
          }
          return true;
        }
      };

      let filteredResult;
      if (Array.isArray(items)) {
        filteredResult = items.filter(filterItem);
      } else if (typeof items === 'object') {
        const newObj = {};
        for (const [key, val] of Object.entries(items)) {
          if (Array.isArray(val)) {
            const filteredArr = val.filter((subItem) => filterItem(subItem));
            if (filteredArr.length > 0) newObj[key] = filteredArr;
          } else {
            const entries = Object.entries(val);
            const filteredPairs = entries.filter(([resp, respVal]) => filterItem(respVal));
            if (filteredPairs.length > 0) {
              const recon = {};
              for (let [k, v] of filteredPairs) {
                recon[k] = v;
              }
              newObj[key] = recon;
            }
          }
        }
        filteredResult = newObj;
      } else {
        // Unexpected type
        console.warn('SBTFilter: unsupported items type:', typeof items);
        filteredResult = items;
      }

      console.log('Filtered items:', filteredResult);

      this.setState({ loading: false }, () => {
        if (this.props.onFilter) {
          this.props.onFilter(filteredResult, this.getLocalFilterState());
        }
      });
    }
  };


  // Handlers for adding/removing SBT “include” or “exclude”
  handleAddSBTIncludeCreator = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroupsCreator.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        selectedSBTGroupsCreator: [...prev.selectedSBTGroupsCreator, sbtObject]
      }));
    }
  };
  handleRemoveSBTIncludeCreator = (address) => {
    this.setState((prev) => ({
      selectedSBTGroupsCreator: prev.selectedSBTGroupsCreator.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTExcludeCreator = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroupsCreator.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        excludedSBTGroupsCreator: [...prev.excludedSBTGroupsCreator, sbtObject]
      }));
    }
  };
  handleRemoveSBTExcludeCreator = (address) => {
    this.setState((prev) => ({
      excludedSBTGroupsCreator: prev.excludedSBTGroupsCreator.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTIncludeResponder = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroupsResponder.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        selectedSBTGroupsResponder: [...prev.selectedSBTGroupsResponder, sbtObject]
      }));
    }
  };
  handleRemoveSBTIncludeResponder = (address) => {
    this.setState((prev) => ({
      selectedSBTGroupsResponder: prev.selectedSBTGroupsResponder.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTExcludeResponder = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroupsResponder.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        excludedSBTGroupsResponder: [...prev.excludedSBTGroupsResponder, sbtObject]
      }));
    }
  };
  handleRemoveSBTExcludeResponder = (address) => {
    this.setState((prev) => ({
      excludedSBTGroupsResponder: prev.excludedSBTGroupsResponder.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTInclude = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroups.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        selectedSBTGroups: [...prev.selectedSBTGroups, sbtObject]
      }));
    }
  };
  handleRemoveSBTInclude = (address) => {
    this.setState((prev) => ({
      selectedSBTGroups: prev.selectedSBTGroups.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTExclude = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroups.find((sbt) => sbt.address === address)) {
      this.setState((prev) => ({
        excludedSBTGroups: [...prev.excludedSBTGroups, sbtObject]
      }));
    }
  };
  handleRemoveSBTExclude = (address) => {
    this.setState((prev) => ({
      excludedSBTGroups: prev.excludedSBTGroups.filter((sbt) => sbt.address !== address)
    }));
  };

  toggleVerifiedHumans = () => {
    this.setState((prev) => ({
      onlyVerifiedHumans: !prev.onlyVerifiedHumans
    }));
  };

  toggleFilterOptions = () => {
    this.setState((prev) => ({
      showFilterOptions: !prev.showFilterOptions
    }));
  };

  render() {
    const {
      showFilterOptions,
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans,
      loading
    } = this.state;

    const { mode, hideUI } = this.props;

    let buttonText = 'Filter';
    if (
      mode === 'questions' ||
      mode === 'questionResponses' ||
      mode === 'creatorAndResponder'
    ) {
      buttonText = 'Response Filter';
    }
    let loadingText = loading ? 'Applying filter...' : '';

    if (hideUI) {
      return <div style={{ display: 'none' }} />;
    }

    return (
      <div className={styles.sbtFilter}>
        {/* If not autoExpand, show a settings/cog button to toggle filter options */}
        {!this.props.autoExpand && (
          <Button onClick={this.toggleFilterOptions} id={styles.filterButton}>
            {buttonText} <FontAwesomeIcon icon={faFilter} id={styles.filterButtonIcon} /> 
          </Button>
        )}

        {/* If autoExpand is true, or the user toggled showFilterOptions, display the filter UI */}
        {(this.props.autoExpand || showFilterOptions) && (
          <div className={styles.filterOptions}>
            {/* Mode: 'responder' => filter by SBT group(s) of Survey Responder */}
            {mode === 'responder' && (
              <>
                <h5>SBT Group(s) of Survey Responder</h5>
                <SBTSelector
                  id="includeResponder"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTIncludeResponder}
                  onRemoveSBT={this.handleRemoveSBTIncludeResponder}
                  selectedSBTs={selectedSBTGroupsResponder}
                  label={'Include Responses from SBT Holders'}
                  // We rely on SBTSelector to display the selected addresses
                />

                <SBTSelector
                  id="excludeResponder"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTExcludeResponder}
                  onRemoveSBT={this.handleRemoveSBTExcludeResponder}
                  selectedSBTs={excludedSBTGroupsResponder}
                  label={'Exclude Responses from SBT Holders'}
                />

                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={onlyVerifiedHumans}
                      onChange={this.toggleVerifiedHumans}
                    />
                    Only see verified human responses
                  </Label>
                </FormGroup>
              </>
            )}

            {/* Mode: 'addresses' => filter addresses by SBT group(s) */}
            {mode === 'addresses' && (
              <>
                <SBTSelector
                  id="includeAddresses"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTInclude}
                  onRemoveSBT={this.handleRemoveSBTInclude}
                  selectedSBTs={selectedSBTGroups}
                  label={'Include Addresses holding SBT'}
                />

                <SBTSelector
                  id="excludeAddresses"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTExclude}
                  onRemoveSBT={this.handleRemoveSBTExclude}
                  selectedSBTs={excludedSBTGroups}
                  label={'Exclude Addresses holding SBT'}
                />

                <FormGroup check id={styles.verifiedHumansLabel}>
                  <Label check className={styles.greyedOutLabel}>
                    <Input
                      type="checkbox"
                      checked={onlyVerifiedHumans}
                      onChange={this.toggleVerifiedHumans}
                      id="humanOnlyCheckbox"
                      disabled={true} // Not yet implemented
                    />
                    <span id="verifiedHumansLabel">
                      Only show verified human addresses<sup>soon</sup>
                    </span>
                  </Label>
                  <UncontrolledTooltip placement="right" target="verifiedHumansLabel">
                    We will use standards like OpenPassport soon.
                  </UncontrolledTooltip>
                </FormGroup>
              </>
            )}

            {/* Mode: 'creator', 'creatorAndResponder', 'questions', or 'questionResponses' => question-based filtering */}
            {(mode === 'creator' ||
              mode === 'creatorAndResponder' ||
              mode === 'questions' ||
              mode === 'questionResponses') && (
              <>
                {/* If creator-based filtering is relevant */}
                {(mode === 'creator' ||
                  mode === 'creatorAndResponder' ||
                  mode === 'questions') && (
                  <>
                    <SBTSelector
                      id="includeCreator"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTIncludeCreator}
                      onRemoveSBT={this.handleRemoveSBTIncludeCreator}
                      selectedSBTs={selectedSBTGroupsCreator}
                      label={'Include Questions created by SBT Holders'}
                    />

                    <SBTSelector
                      id="excludeCreator"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTExcludeCreator}
                      onRemoveSBT={this.handleRemoveSBTExcludeCreator}
                      selectedSBTs={excludedSBTGroupsCreator}
                      label={'Exclude Questions created by SBT Holders'}
                    />
                  </>
                )}

                {/* If responder-based filtering is relevant */}
                {(mode === 'responder' ||
                  mode === 'creatorAndResponder' ||
                  mode === 'questionResponses') && (
                  <>
                    <h5>SBT Group(s) of Question Responder</h5>
                    <SBTSelector
                      id="includeResponder2"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTIncludeResponder}
                      onRemoveSBT={this.handleRemoveSBTIncludeResponder}
                      selectedSBTs={selectedSBTGroupsResponder}
                      label={'Include Responses from SBT Holders'}
                    />

                    <SBTSelector
                      id="excludeResponder2"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTExcludeResponder}
                      onRemoveSBT={this.handleRemoveSBTExcludeResponder}
                      selectedSBTs={excludedSBTGroupsResponder}
                      label={'Exclude Responses from SBT Holders'}
                    />

                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={onlyVerifiedHumans}
                          onChange={this.toggleVerifiedHumans}
                        />
                        Only see verified human responses
                      </Label>
                    </FormGroup>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {loading && (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <p>{loadingText}</p>
          </div>
        )}
      </div>
    );
  }
}

export default SBTFilter;