/***************************************************
 * FILE: DatabaseTool.jsx
 * LOCATION: my-app/client/src/components/AiDatabase/DatabaseTool.jsx
 ***************************************************/

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faDownload,
  faQuestionCircle,
  faCheck,
  faTimes,
  faUpload,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import styles from './DatabaseTool.module.scss';

import { callAnthropicAI, fetchContentFromURL } from '../../utilities/aiScripts.js';
import AudioInput from '../SurveyTool/AudioInput.jsx';
import CreateSurvey from '../SurveyTool/CreateSurvey.jsx';
import contractScripts from '../Buttons/contractScripts.js';

// Import the .txt prompt
import { seedGenPrompt } from '../../prompts/seedGenPrompt.js';

import sha256 from 'crypto-js/sha256';

export default function DatabaseTool({
  provider,
  network,
  account,
  loginComplete,
  toggleLoginModal,
  minified = false,
  defaultTags,
  onQuestionsGenerated,
  hideEncryption = true,
  generateTitle = false,
  enableAutoTags = true,
}) {
  const [ingressType, setIngressType] = useState('url');
  const [urlInput, setUrlInput] = useState('');
  const [pastedText, setPastedText] = useState('');
  const [textEncrypted, setTextEncrypted] = useState(false);
  const [questionTypes, setQuestionTypes] = useState({
    binary: true,
    freeform: true,
    rating: true,
    multichoice: true,
  });
  const [count, setCount] = useState(10);

  const [seedStatements, setSeedStatements] = useState([]);
  const [selectedStatements, setSelectedStatements] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [waitingSeconds, setWaitingSeconds] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [surveyTitle, setSurveyTitle] = useState('');
  const [statementsToUpload, setStatementsToUpload] = useState([]);
  const [prefilledAnswers, setPrefilledAnswers] = useState([]);
  const [autoGenerateTags, setAutoGenerateTags] = useState(true);
  const [showCreateSurvey, setShowCreateSurvey] = useState(false);
  const [documentURLs, setDocumentURLs] = useState([]);

  useEffect(() => {
    let intervalId;
    if (loading) {
      setWaitingSeconds(0);
      intervalId = setInterval(() => {
        setWaitingSeconds((prev) => prev + 1);
      }, 1000);
      setTimerInterval(intervalId);
    } else {
      if (timerInterval) {
        clearInterval(timerInterval);
        setTimerInterval(null);
      }
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [loading]);

  // Whenever questionTypes changes, clear out old seeds
  useEffect(() => {
    setSeedStatements([]);
    setAnswers({});
    setSelectedStatements([]);
  }, [questionTypes]);

  /**
   * Updated single combined prompt for questions, tags, and title.
   * The <DefaultTags> block is replaced if we have defaultTags,
   * or left empty if not.
   */
  function buildSinglePrompt(sourceDocContent) {
    let prompt = seedGenPrompt;
    // Gather chosen question types
    const selected = Object.keys(questionTypes).filter((t) => questionTypes[t]);
    const joinedTypes = selected.join(',') || 'binary,multichoice,rating,freeform';

    // If defaultTags is present, pass them in the <DefaultTags> block. Otherwise pass empty
    let defaultTagsStr = '';
    if (Array.isArray(defaultTags) && defaultTags.length > 0) {
      defaultTagsStr = defaultTags.join(', ');
    }

    prompt = prompt
      .replace('<SourceDocContent>', sourceDocContent)
      .replace('<NumSeedStatements>', String(count))
      .replace('<Types>', joinedTypes)
      .replace('<DefaultTags>', defaultTagsStr);

    return prompt;
  }

  /**
   * Single AI call that returns a JSON with shape:
   * {
   *   "surveyTitle": "...",
   *   "questions": [
   *     {
   *       "prompt": "...",
   *       "questionType": "binary|rating|freeform|multichoice",
   *       "options": [...], // for multichoice
   *       "tags": [...],
   *       "answer": {...},
   *       "additional": {...}
   *     },
   *     ...
   *   ]
   * }
   */
  async function makeSingleAiCall(sourceDocContent) {
    const prompt = buildSinglePrompt(sourceDocContent);
    const raw = await callAnthropicAI(prompt);
    let data;
    try {
      data = JSON.parse(raw);
    } catch (err) {
      console.error('AI returned invalid JSON:', err, raw);
      throw new Error('AI parsing error. Check console output.');
    }
    if (!Array.isArray(data.questions)) {
      throw new Error('No "questions" array found in AI response');
    }

    // If the AI gave us a suggested title
    if (data.surveyTitle) {
      setSurveyTitle(data.surveyTitle);
    }

    // If we have defaultTags, we filter the AI’s tags so that
    // only relevant ones from defaultTags remain. Otherwise, keep them as is.
    // Also ensure each question has answer/additional placeholders.
    data.questions.forEach((q) => {
      if (!q.tags) {
        q.tags = [];
      }
      if (!q.options) {
        q.options = [];
      }
      if (!q.answer) {
        q.answer = { value: '', encrypted: false, hash: '' };
      }
      if (!q.additional) {
        q.additional = { value: '', encrypted: false, hash: '' };
      }

      if (Array.isArray(defaultTags) && defaultTags.length > 0) {
        // Filter AI-provided tags to only the relevant ones that appear in defaultTags
        const defaultTagSet = new Set(defaultTags.map((t) => t.toLowerCase()));
        const filtered = (q.tags || []).filter((tag) =>
          defaultTagSet.has(tag.toLowerCase())
        );
        q.tags = filtered;
      }
    });

    return data;
  }

  /**
   * Submits user’s content (URL or text) to the AI exactly once,
   * returning questions + optional tags + optional title.
   */
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSeedStatements([]);
    setSelectedStatements([]);
    setAnswers({});
    setSurveyTitle('');
    setShowCreateSurvey(false);

    try {
      let content = '';
      if (ingressType === 'text') {
        const text = pastedText.trim();
        if (!text || text.length < 50) {
          throw new Error('Please provide at least 50 characters of text.');
        }
        content = text;
      } else if (ingressType === 'url') {
        const trimmedUrl = urlInput.trim();
        if (!trimmedUrl) {
          throw new Error('Please enter a valid URL.');
        }
        let fetched;
        try {
          fetched = await fetchContentFromURL(trimmedUrl);
        } catch (err) {
          throw new Error(`Failed to fetch URL: ${err.message}`);
        }
        if (!fetched || fetched.length < 50) {
          throw new Error('Extracted content from URL is too short.');
        }
        content = fetched;
      } else {
        throw new Error('Only "text" or "url" ingestion is supported.');
      }

      // Single AI call
      const data = await makeSingleAiCall(content);

      // Filter question types if needed
      const selectedT = Object.keys(questionTypes).filter((t) => questionTypes[t]);
      let finalQuestions = data.questions.filter((q) => selectedT.includes(q.questionType));
      if (finalQuestions.length > count) {
        finalQuestions = finalQuestions.slice(0, count);
      }

      if (minified) {
        // If “minified” mode, we pass them up to onQuestionsGenerated.
        // If user is not autoGenerateTags, we hide them (but keep them internally).
        if (!autoGenerateTags) {
          finalQuestions.forEach((q) => {
            q.tags = []; // keep them hidden
          });
        }
        const finalQs = finalQuestions.map((q) => ({
          id: generateQuestionId(q.questionType, q.prompt, q.options || []),
          type: q.questionType,
          prompt: q.prompt,
          options: q.questionType === 'multichoice' ? q.options : undefined,
          tags: q.tags || [],
        }));
        const docURLs = ingressType === 'url' ? [urlInput.trim()] : [];
        setDocumentURLs(docURLs);

        if (typeof onQuestionsGenerated === 'function') {
          onQuestionsGenerated(finalQs, docURLs, data.surveyTitle || '', autoGenerateTags);
        }
      } else {
        // Not minified => show them in the UI
        finalQuestions.forEach((st) => {
          // Ensure placeholders
          if (!st.answer || typeof st.answer !== 'object') {
            st.answer = { value: '', encrypted: false, hash: '' };
          }
          if (!st.additional || typeof st.additional !== 'object') {
            st.additional = { value: '', encrypted: false, hash: '' };
          }
        });
        setSeedStatements(finalQuestions);
        initializeAnswerStates(finalQuestions);

        // If we used “url,” store it in doc URLs
        if (ingressType === 'url') {
          setDocumentURLs([urlInput.trim()]);
        } else {
          setDocumentURLs([]);
        }
      }
    } catch (err) {
      setError(err.message || 'Error generating questions.');
    } finally {
      setLoading(false);
    }
  }

  function generateQuestionId(type, prompt, options = []) {
    let dataToHash = `${type}:${prompt.toLowerCase()}`;
    if (type === 'multichoice' && options.length > 0) {
      dataToHash += `:${options.join(',').toLowerCase()}`;
    }
    const h = sha256(dataToHash).toString();
    return '0x' + h;
  }

  function initializeAnswerStates(statements) {
    const ans = {};
    statements.forEach((s, idx) => {
      switch (s.questionType) {
        case 'binary':
          ans[idx] = 'Unsure';
          break;
        case 'rating':
          ans[idx] = 5;
          break;
        case 'freeform':
          ans[idx] = '';
          break;
        case 'multichoice':
          ans[idx] = [];
          break;
        default:
          ans[idx] = '';
      }
    });
    setAnswers(ans);
  }

  function handleSelectAll() {
    const allIdx = seedStatements.map((_, i) => i);
    setSelectedStatements(allIdx);
  }

  function handleStatementSelection(idx) {
    setSelectedStatements((prev) => {
      if (prev.includes(idx)) {
        return prev.filter((n) => n !== idx);
      }
      return [...prev, idx];
    });
  }

  function handleUploadSelected() {
    const chosen = seedStatements.filter((_, idx) => selectedStatements.includes(idx));
    const questionObjs = chosen.map((st) => ({
      id: generateQuestionId(st.questionType, st.prompt, st.options || []),
      type: st.questionType,
      prompt: st.prompt,
      options: st.questionType === 'multichoice' ? st.options : undefined,
      tags: autoGenerateTags ? st.tags : []
    }));
    const prefAnswers = selectedStatements.map((idx) => answers[idx]);
    setStatementsToUpload(questionObjs);
    setPrefilledAnswers(prefAnswers);
    setShowCreateSurvey(true);
  }

  // We do not do extra AI calls for tags, so skip or log only:
  async function regenerateTagsForQuestion(idx) {
    console.log('Skipping extra AI request. Tags are already generated or defaulted internally.');
  }

  function renderQuestionDisplay(statement, index) {
    return (
      <div key={index} className={styles.questionDisplay}>
        <div className={styles.questionText}>{statement.prompt}</div>
        <div className={styles.answerDisplay}>
          {statement.questionType === 'binary' && (
            <FormGroup id={styles.binaryChoice}>
              {['Agree', 'Unsure', 'Disagree'].map((option) => (
                <Label
                  check
                  key={option}
                  className={`${styles.radioOptionText} ${
                    styles[option.toLowerCase()]
                  } ${answers[index] === option ? styles.selected : ''}`}
                >
                  <Input
                    type="radio"
                    value={option}
                    checked={answers[index] === option}
                    onChange={() =>
                      setAnswers((prev) => ({ ...prev, [index]: option }))
                    }
                  />
                  {option}
                </Label>
              ))}
            </FormGroup>
          )}
          {statement.questionType === 'freeform' && (
            <Input
              type="textarea"
              value={answers[index] || ''}
              onChange={(e) =>
                setAnswers((prev) => ({ ...prev, [index]: e.target.value }))
              }
              placeholder="Your answer here..."
              className={styles.freeformAnswer}
            />
          )}
          {statement.questionType === 'rating' && (
            <div className={styles.ratingAnswer}>
              <input
                type="range"
                min="0"
                max="10"
                value={answers[index] || 5}
                onChange={(e) =>
                  setAnswers((prev) => ({
                    ...prev,
                    [index]: parseInt(e.target.value, 10),
                  }))
                }
                className={styles.ratingSlider}
              />
              <span className={styles.ratingValue}>{answers[index] || 5}/10</span>
            </div>
          )}
          {statement.questionType === 'multichoice' && (
            <div className={styles.multichoiceAnswer}>
              {statement.options.map((opt, oidx) => (
                <Label check key={oidx} className={styles.multichoiceOption}>
                  <Input
                    type="checkbox"
                    checked={(answers[index] || []).includes(opt)}
                    onChange={() => {
                      const curr = answers[index] || [];
                      if (curr.includes(opt)) {
                        setAnswers((prev) => ({
                          ...prev,
                          [index]: curr.filter((x) => x !== opt),
                        }));
                      } else {
                        setAnswers((prev) => ({
                          ...prev,
                          [index]: [...curr, opt],
                        }));
                      }
                    }}
                  />
                  {opt}
                </Label>
              ))}
            </div>
          )}
        </div>

        {/* If AI provided or default tags exist, show them if autoGenerateTags is true */}
        {enableAutoTags && statement.tags && statement.tags.length > 0 && (
          <div className={styles.tagsField}>
            <strong>Tags:</strong> {autoGenerateTags ? statement.tags.join(', ') : '(Hidden)'}
          </div>
        )}

        {/* No real re-generation call - we skip extra AI requests */}
        {enableAutoTags && (
          <Button
            size="sm"
            style={{ marginTop: '5px' }}
            onClick={() => regenerateTagsForQuestion(index)}
          >
            Generate tags
          </Button>
        )}

        <input
          type="checkbox"
          checked={selectedStatements.includes(index)}
          onChange={() => handleStatementSelection(index)}
          className={styles.statementCheckbox}
        />
      </div>
    );
  }

  function renderMinifiedCreateSurvey() {
    if (!showCreateSurvey || statementsToUpload.length === 0) {
      return null;
    }

    const preformedSurvey = surveyTitle ? { title: surveyTitle } : null;

    return (
      <div className={styles.createSurveyContainer}>
        <CreateSurvey
          miniaturized={true}
          preformedQuestions={statementsToUpload}
          preformedSurvey={preformedSurvey}
          prefilledAnswers={prefilledAnswers}
          account={account}
          loginComplete={loginComplete}
          provider={provider}
          toggleLoginModal={toggleLoginModal}
          defaultTags={defaultTags}
          onUploadComplete={(surveyHash) => {
            setShowCreateSurvey(false);
            setStatementsToUpload([]);
            alert(`Upload complete! Survey ID: ${surveyHash}`);
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={
        minified
          ? `${styles.databaseTool} ${styles.minified}`
          : styles.databaseTool
      }
    >
      <div className={styles.header}>
        <h2>AI Database Tool {minified ? '(Minified)' : ''}</h2>
      </div>

      <form onSubmit={handleSubmit}>
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Content to Ingest</h3>
          <div className={styles.ingressOptions}>
            <button
              type="button"
              onClick={() => setIngressType('text')}
              className={`${styles.ingressButton} ${
                ingressType === 'text' ? styles.active : ''
              }`}
            >
              Text
            </button>
            <button
              type="button"
              onClick={() => setIngressType('url')}
              className={`${styles.ingressButton} ${
                ingressType === 'url' ? styles.active : ''
              }`}
            >
              URL
            </button>
            <button
              type="button"
              disabled
              className={`${styles.ingressButton} ${styles.disabled}`}
            >
              Multiple (Soon)
            </button>
            <button
              type="button"
              disabled
              className={`${styles.ingressButton} ${styles.disabled}`}
            >
              Audio (Soon)
            </button>
          </div>

          {ingressType === 'text' && (
            <div className={styles.textInputGroup}>
              <AudioInput
                placeholder="Speak or type text here"
                updateFunction={(val) => setPastedText(val)}
                toggleEncryption={(bool) => setTextEncrypted(bool)}
                value={pastedText}
                encrypted={textEncrypted}
                hideEncryption={hideEncryption}
              />
            </div>
          )}
          {ingressType === 'url' && (
            <div style={{ marginTop: '15px' }}>
              <Input
                type="url"
                value={urlInput}
                onChange={(e) => setUrlInput(e.target.value)}
                placeholder="Enter a URL (http:// or https://)"
                required
              />
            </div>
          )}
        </div>

        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Question Types to Generate</h3>
          <div className={styles.questionTypeOptions}>
            {Object.entries(questionTypes).map(([typ, checked]) => (
              <label key={typ} className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() =>
                    setQuestionTypes((prev) => ({ ...prev, [typ]: !prev[typ] }))
                  }
                />
                {typ.charAt(0).toUpperCase() + typ.slice(1)}
              </label>
            ))}
          </div>
        </div>

        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Number of Questions</h3>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Input
              type="number"
              value={count}
              onChange={(e) => setCount(parseInt(e.target.value, 10))}
              min="1"
              max="25"
              required
              style={{ width: '80px' }}
            />
            <Button color="secondary" onClick={() => setCount(10)}>
              10
            </Button>
            <Button color="secondary" onClick={() => setCount(15)}>
              15
            </Button>
            <Button color="secondary" onClick={() => setCount(20)}>
              20
            </Button>
          </div>
        </div>

        {enableAutoTags && (
          <div className={styles.formSection}>
            <h3 className={styles.sectionTitle}>Auto-generate Tags</h3>
            <Label check className={styles.checkboxLabel}>
              <Input
                type="checkbox"
                checked={autoGenerateTags}
                onChange={() => setAutoGenerateTags(!autoGenerateTags)}
              />
              Auto-generate tags from AI response
            </Label>
          </div>
        )}

        <Button type="submit" className={styles.generateButton} disabled={loading}>
          {loading ? (
            <>
              Waiting for AI… {waitingSeconds}s <FontAwesomeIcon icon={faSpinner} spin />
            </>
          ) : (
            'Generate Questions'
          )}
        </Button>
      </form>

      {error && (
        <div className={styles.error} style={{ marginTop: '10px' }}>
          {error}
        </div>
      )}

      {!minified && surveyTitle && (
        <div className={styles.surveyTitleBanner}>
          <strong>AI-Generated Title:</strong> {surveyTitle}
        </div>
      )}

      {!minified && seedStatements.length > 0 && (
        <div className={styles.results}>
          <div className={styles.resultsHeader}>
            <h3>Generated Seed Statements:</h3>
            <button onClick={handleSelectAll} className={styles.selectAllButton}>
              Select All
            </button>
          </div>
          <div className={styles.statementsContainer}>
            {seedStatements.map((st, idx) => renderQuestionDisplay(st, idx))}
          </div>
          <div className={styles.uploadButtonsContainer}>
            <button onClick={handleUploadSelected} className={styles.addButton}>
              Upload {selectedStatements.length} Selected
            </button>
          </div>
        </div>
      )}

      {!minified && renderMinifiedCreateSurvey()}
    </div>
  );
}
