/***************************************************
 * FILE: QuestionFilter.jsx
 * LOCATION: my-app/client/src/components/SurveyTool/QuestionFilter.jsx
 ***************************************************/

import React, { Component } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import styles from './QuestionFilter.module.scss';
import SBTFilter from '../SBTs/SBTFilter.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faChevronDown, faStar, faRobot, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';

import AudioInput from './AudioInput.jsx';
import { rankQuestionsAI } from '../../utilities/aiScripts.js';

/**
 * This component provides the UI and logic for filtering questions based on type, tags,
 * SBT ownership (creator or responder), popularity, and (when enabled) AI search/ranking.
 * 
 * The AI-based filter is currently placed at the top but disabled so it cannot be used.
 * A summary of selected filters is displayed at the top, allowing removal of each filter item by clicking.
 */
const questionTypes = ['freeform', 'multichoice', 'rating', 'binary'];

const modalStyles = {
  backgroundColor: 'white',
  fontSize: '16px'
};

class QuestionFilter extends Component {
  constructor(props) {
    super(props);

    // If in "resultsMode" with an externally provided filterState, use that; otherwise use defaults
    const usingResultsMode = this.props.resultsMode || false;
    const existingFilterState =
      usingResultsMode && this.props.filterState ? this.props.filterState : {};

    // Merge given questions with local cache so zero-answer or offscreen questions appear
    const mergedQuestions = this.mergeQuestionsWithCache(props.questions);

    this.state = {
      mergedQuestions,
      cachedQuestionResponses: this.props.questionResponses || {},

      // Basic filter states
      selectedTypes: existingFilterState.selectedTypes || [],
      selectedTags: existingFilterState.selectedTags || [],
      sortByImportance: existingFilterState.sortByImportance || false,
      sbtFilteredQuestions: null, // final array after SBT-based filtering

      // SBT filter internal state
      sbtFilterLocalState: existingFilterState.sbtFilterLocalState || null,

      // AI filter (currently disabled)
      aiSearchQuery: existingFilterState.aiSearchQuery || '',
      aiRankingCount: 10, // top X from AI
      // We set the collapsible sections so that ONLY “tags” is auto-open. Others default to false:
      expandedSections: {
        ai: false,
        types: false,
        sbts: false,
        popular: false,
        tags: true
      },
      filterLoading: false,

      // For “Top X questions” by total importance or # of responses
      showTopQuestions: existingFilterState.showTopQuestions || false,
      topQuestionsCount: existingFilterState.topQuestionsCount || 10,
      showTopQuestionsByResponses: existingFilterState.showTopQuestionsByResponses || false,

      // Pending states used to update instantly for certain controls:
      pendingSelectedTypes: existingFilterState.selectedTypes || [],
      pendingSortByImportance: existingFilterState.sortByImportance || false,
      pendingSbtFilteredQuestions: null,
      pendingShowTopQuestions: existingFilterState.showTopQuestions || false,
      pendingTopQuestionsCount: existingFilterState.topQuestionsCount || 10,
      pendingShowTopQuestionsByResponses:
        existingFilterState.showTopQuestionsByResponses || false,

      // Count how many questions pass the current pending filter config
      filteredQuestionsCount: 0,

      // We store the last fully applied filter set to avoid re-applying identical filters
      lastAppliedFilterState: null
    };
  }

  componentDidMount() {
    if (this.props.enableLocalStorage) {
      this.loadFilterStateFromLocalStorage();
    }
    if (!this.props.questionResponses) {
      this.loadQuestionResponsesFromLocalStorage();
    }

    this.computeFilteredQuestionsCount();
  }

  componentDidUpdate(prevProps, prevState) {
    // If "questions" array changes, re-merge
    if (prevProps.questions !== this.props.questions) {
      const reMerged = this.mergeQuestionsWithCache(this.props.questions);
      this.setState({ mergedQuestions: reMerged }, () => {
        this.computeFilteredQuestionsCount();
      });
    }

    // If any pending states changed, recalc the count
    if (
      prevState.pendingSelectedTypes !== this.state.pendingSelectedTypes ||
      prevState.pendingSortByImportance !== this.state.pendingSortByImportance ||
      prevState.pendingSbtFilteredQuestions !== this.state.pendingSbtFilteredQuestions ||
      prevState.aiSearchQuery !== this.state.aiSearchQuery ||
      prevState.pendingShowTopQuestions !== this.state.pendingShowTopQuestions ||
      prevState.pendingTopQuestionsCount !== this.state.pendingTopQuestionsCount ||
      prevState.pendingShowTopQuestionsByResponses !== this.state.pendingShowTopQuestionsByResponses ||
      prevState.selectedTags !== this.state.selectedTags
    ) {
      this.computeFilteredQuestionsCount();
    }

    // Save local filter state if enabled
    if (this.props.enableLocalStorage) {
      this.saveFilterStateToLocalStorage();
    }
  }

  // ----------------------------------------------------------------------------------
  // LOCAL STORAGE FOR FILTER STATE
  // ----------------------------------------------------------------------------------
  loadFilterStateFromLocalStorage() {
    try {
      const modeKey =
        this.props.filterType === 'results'
          ? 'questionFilterState_results'
          : 'questionFilterState_questions';
      const saved = localStorage.getItem(modeKey);
      if (saved) {
        const parsed = JSON.parse(saved);
        this.setState((prevState) => ({
          selectedTypes: parsed.selectedTypes || prevState.selectedTypes,
          sortByImportance:
            typeof parsed.sortByImportance === 'boolean'
              ? parsed.sortByImportance
              : prevState.sortByImportance,
          showTopQuestions:
            typeof parsed.showTopQuestions === 'boolean'
              ? parsed.showTopQuestions
              : prevState.showTopQuestions,
          topQuestionsCount: parsed.topQuestionsCount || prevState.topQuestionsCount,
          aiSearchQuery: parsed.aiSearchQuery || prevState.aiSearchQuery,
          sbtFilterLocalState: parsed.sbtFilterLocalState || prevState.sbtFilterLocalState,
          selectedTags: parsed.selectedTags || prevState.selectedTags,
          showTopQuestionsByResponses:
            typeof parsed.showTopQuestionsByResponses === 'boolean'
              ? parsed.showTopQuestionsByResponses
              : prevState.showTopQuestionsByResponses,

          // Also update pending states:
          pendingSelectedTypes: parsed.selectedTypes || prevState.pendingSelectedTypes,
          pendingSortByImportance:
            typeof parsed.sortByImportance === 'boolean'
              ? parsed.sortByImportance
              : prevState.pendingSortByImportance,
          pendingShowTopQuestions:
            typeof parsed.showTopQuestions === 'boolean'
              ? parsed.showTopQuestions
              : prevState.pendingShowTopQuestions,
          pendingTopQuestionsCount:
            parsed.topQuestionsCount || prevState.pendingTopQuestionsCount,
          pendingShowTopQuestionsByResponses:
            typeof parsed.showTopQuestionsByResponses === 'boolean'
              ? parsed.showTopQuestionsByResponses
              : prevState.pendingShowTopQuestionsByResponses
        }));
      }
    } catch (error) {
      console.error('Error loading filter state from localStorage:', error);
    }
  }

  saveFilterStateToLocalStorage() {
    try {
      const {
        selectedTypes,
        sortByImportance,
        showTopQuestions,
        topQuestionsCount,
        aiSearchQuery,
        sbtFilterLocalState,
        selectedTags,
        showTopQuestionsByResponses
      } = this.state;

      const dataToStore = {
        selectedTypes,
        sortByImportance,
        showTopQuestions,
        topQuestionsCount,
        aiSearchQuery,
        sbtFilterLocalState,
        selectedTags,
        showTopQuestionsByResponses
      };

      const modeKey =
        this.props.filterType === 'results'
          ? 'questionFilterState_results'
          : 'questionFilterState_questions';
      localStorage.setItem(modeKey, JSON.stringify(dataToStore));
    } catch (error) {
      console.error('Error saving filter state to localStorage:', error);
    }
  }

  // ----------------------------------------------------------------------------------
  // MERGE QUESTIONS WITH CACHE & LOAD RESPONSES FROM LOCAL
  // ----------------------------------------------------------------------------------
  mergeQuestionsWithCache(sourceQuestions) {
    if (!sourceQuestions || !Array.isArray(sourceQuestions)) return sourceQuestions || [];
    if (!this.props.network || !this.props.network.id) return sourceQuestions;

    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem('questionsCache') || '';
    let questionsCache = {};
    try {
      questionsCache = JSON.parse(questionsCacheStr);
    } catch (err) {
      questionsCache = {};
    }
    if (!questionsCache[networkID] || !questionsCache[networkID].questions) {
      return sourceQuestions;
    }

    const allCacheQIDs = Object.keys(questionsCache[networkID].questions);
    const existingIDs = new Set(sourceQuestions.map((q) => (q.id || '').toLowerCase()));
    const merged = [...sourceQuestions];

    allCacheQIDs.forEach((qIdLower) => {
      if (!existingIDs.has(qIdLower)) {
        const qObj = questionsCache[networkID].questions[qIdLower];
        if (qObj) {
          merged.push({ ...qObj });
        }
      }
    });

    return merged;
  }

  loadQuestionResponsesFromLocalStorage() {
    if (!this.props.network || !this.props.network.id) {
      console.warn('Network ID undefined; cannot load questionResponses from localStorage.');
      return;
    }
    try {
      const networkID = this.props.network.id.toString();
      let questionsCacheStr = localStorage.getItem('questionsCache');
      let questionsCache = {};
      if (questionsCacheStr) {
        try {
          questionsCache = JSON.parse(questionsCacheStr);
        } catch (e) {
          console.error('Error parsing questionsCache:', e);
          questionsCache = {};
        }
      }
      if (!questionsCache[networkID]) {
        return;
      }
      const cachedResponses = questionsCache[networkID].questionResponses || {};
      this.setState({ cachedQuestionResponses: cachedResponses });
    } catch (error) {
      console.error('Error in loadQuestionResponsesFromLocalStorage:', error);
    }
  }

  parseResponse = (responseData) => {
    try {
      return typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    } catch (error) {
      console.error('Error parsing response data in QuestionFilter:', error);
      return null;
    }
  };

  // ----------------------------------------------------------------------------------
  // FILTER LOGIC
  // ----------------------------------------------------------------------------------
  setFilterLoading = (loading) => {
    this.setState({ filterLoading: loading });
    if (this.props.setFilterLoading) {
      this.props.setFilterLoading(loading);
    }
  };

  handleFilteredQuestions = (filtered, newSbtFilterLocalState) => {
    // "filtered" can be an array or an object { filteredQuestions, filteredResponsesByQuestion }
    let realFilteredQuestions = [];
    let filteredResponsesByQuestion = {};

    if (Array.isArray(filtered)) {
      realFilteredQuestions = filtered;
    } else if (
      filtered &&
      filtered.filteredQuestions &&
      Array.isArray(filtered.filteredQuestions)
    ) {
      realFilteredQuestions = filtered.filteredQuestions;
      filteredResponsesByQuestion = filtered.filteredResponsesByQuestion || {};
    }

    this.setState(
      {
        pendingSbtFilteredQuestions: realFilteredQuestions,
        sbtFilterLocalState: newSbtFilterLocalState
      },
      () => {
        this.handleApplyFilters(true);
      }
    );

    // Also pass final array or aggregator up to parent
    if (this.props.onFilter) {
      const newFilterState = {
        ...this.getCurrentFilterState(),
        sbtFilterLocalState: newSbtFilterLocalState
      };
      if (Object.keys(filteredResponsesByQuestion).length > 0) {
        this.props.onFilter(
          {
            filteredQuestions: realFilteredQuestions,
            filteredResponsesByQuestion
          },
          newFilterState
        );
      } else {
        this.props.onFilter(realFilteredQuestions, newFilterState);
      }
    }
  };

  getCurrentFilterState() {
    return {
      selectedTypes: this.state.selectedTypes,
      sortByImportance: this.state.sortByImportance,
      showTopQuestions: this.state.showTopQuestions,
      topQuestionsCount: this.state.topQuestionsCount,
      aiSearchQuery: this.state.aiSearchQuery,
      sbtFilterLocalState: this.state.sbtFilterLocalState,
      selectedTags: this.state.selectedTags,
      showTopQuestionsByResponses: this.state.showTopQuestionsByResponses
    };
  }

  applyAISearchFilter(questions, aiSearchQuery) {
    if (!aiSearchQuery.trim()) {
      return questions;
    }
    const queryLower = aiSearchQuery.trim().toLowerCase();
    return questions.filter((q) =>
      q.prompt ? q.prompt.toLowerCase().includes(queryLower) : false
    );
  }

  handleApplyAIFilter = async () => {
    // AI-based filter is disabled for now
    alert("AI-based filter is temporarily disabled.");
  };

  handleApplyFilters(immediate = false) {
    const { mergedQuestions } = this.state;
    if (!mergedQuestions) return;

    const newSelectedTypes = this.state.pendingSelectedTypes;
    const newSortByImportance = this.state.pendingSortByImportance;
    const newShowTopQuestions = this.state.pendingShowTopQuestions;
    const newTopQuestionsCount = this.state.pendingTopQuestionsCount;
    const newAiSearchQuery = this.state.aiSearchQuery;
    const newPendingSbtFilteredQuestions = this.state.pendingSbtFilteredQuestions;
    const newShowTopQuestionsByResponses = this.state.pendingShowTopQuestionsByResponses;
    const newSelectedTags = this.state.selectedTags;

    const potentialFilterStateObj = {
      newSelectedTypes: [...newSelectedTypes].sort(),
      newSortByImportance,
      newShowTopQuestions,
      newTopQuestionsCount,
      newAiSearchQuery,
      newPendingSbtFilteredQuestionsLength: newPendingSbtFilteredQuestions
        ? newPendingSbtFilteredQuestions.length
        : -1,
      newShowTopQuestionsByResponses,
      newSelectedTags: [...newSelectedTags].sort()
    };

    if (
      this.state.lastAppliedFilterState &&
      JSON.stringify(this.state.lastAppliedFilterState) === JSON.stringify(potentialFilterStateObj)
    ) {
      return;
    }
    this.setState({ lastAppliedFilterState: potentialFilterStateObj });

    if (!immediate) {
      this.setState({
        selectedTypes: newSelectedTypes,
        sortByImportance: newSortByImportance,
        showTopQuestions: newShowTopQuestions,
        topQuestionsCount: newTopQuestionsCount,
        aiSearchQuery: newAiSearchQuery,
        sbtFilteredQuestions: newPendingSbtFilteredQuestions,
        showTopQuestionsByResponses: newShowTopQuestionsByResponses,
        selectedTags: newSelectedTags
      });
    } else {
      this.setState({
        selectedTypes: newSelectedTypes,
        sortByImportance: newSortByImportance,
        showTopQuestions: newShowTopQuestions,
        topQuestionsCount: newTopQuestionsCount,
        aiSearchQuery: newAiSearchQuery,
        sbtFilteredQuestions: newPendingSbtFilteredQuestions,
        showTopQuestionsByResponses: newShowTopQuestionsByResponses,
        selectedTags: newSelectedTags
      });
    }

    // If user selected "Top X questions" by importance or # responses
    if (newShowTopQuestions || newShowTopQuestionsByResponses) {
      let finalQuestions = [...mergedQuestions];
      if (newPendingSbtFilteredQuestions !== null) {
        const sbtIds = newPendingSbtFilteredQuestions.map((q) => q.id);
        finalQuestions = finalQuestions.filter((q) => sbtIds.includes(q.id));
      }
      if (newSelectedTypes.length > 0) {
        finalQuestions = finalQuestions.filter((q) => newSelectedTypes.includes(q.type));
      }
      if (newSelectedTags.length > 0) {
        finalQuestions = finalQuestions.filter((q) => {
          if (!q.tags || !Array.isArray(q.tags)) return false;
          return q.tags.some((tag) => newSelectedTags.includes(tag));
        });
      }

      const relevantResponses = this.props.questionResponses || this.state.cachedQuestionResponses;

      // "top X by # of responses"
      if (newShowTopQuestionsByResponses) {
        const questionsWithCount = finalQuestions.map((q) => {
          const qLower = (q.id || '').toLowerCase();
          const responsesObj = relevantResponses[qLower] || {};
          const count = Object.keys(responsesObj).length;
          return { ...q, totalResponses: count };
        });
        questionsWithCount.sort((a, b) => b.totalResponses - a.totalResponses);
        const topQuestionsByResp = questionsWithCount.slice(0, newTopQuestionsCount);

        const filterState = this.buildFilterState();
        if (this.props.onFilter) {
          this.props.onFilter(topQuestionsByResp, filterState);
        }
        this.computeFilteredQuestionsCount();
        return;
      }

      // Else top X by total importance
      const questionsWithImportance = finalQuestions.map((q) => {
        const qLower = (q.id || '').toLowerCase();
        const respondersObj = relevantResponses[qLower] || {};
        let totalImportance = 0;
        Object.keys(respondersObj).forEach((resp) => {
          const parsed = this.parseResponse(respondersObj[resp]);
          totalImportance += parsed?.importance || 0;
        });
        return { ...q, totalImportance };
      });
      questionsWithImportance.sort((a, b) => b.totalImportance - a.totalImportance);
      const topQuestions = questionsWithImportance.slice(0, newTopQuestionsCount);

      const filterState = this.buildFilterState();
      if (this.props.onFilter) {
        this.props.onFilter(topQuestions, filterState);
      }
      this.computeFilteredQuestionsCount();
      return;
    }

    // Otherwise normal scenario
    let finalQuestions = [...mergedQuestions];
    if (newPendingSbtFilteredQuestions !== null) {
      const sbtIds = newPendingSbtFilteredQuestions.map((q) => q.id);
      finalQuestions = finalQuestions.filter((q) => sbtIds.includes(q.id));
    }
    if (newSelectedTypes.length > 0) {
      finalQuestions = finalQuestions.filter((q) => newSelectedTypes.includes(q.type));
    }
    if (newSelectedTags.length > 0) {
      finalQuestions = finalQuestions.filter((q) => {
        if (!q.tags || !Array.isArray(q.tags)) return false;
        return q.tags.some((tag) => newSelectedTags.includes(tag));
      });
    }
    finalQuestions = this.applyAISearchFilter(finalQuestions, newAiSearchQuery);

    if (newSortByImportance) {
      const relevantResponses = this.props.questionResponses || this.state.cachedQuestionResponses;
      const questionsWithImportance = finalQuestions.map((q) => {
        const qLower = (q.id || '').toLowerCase();
        const respondersObj = relevantResponses[qLower] || {};
        let totalImportance = 0;
        Object.keys(respondersObj).forEach((resp) => {
          const parsed = this.parseResponse(respondersObj[resp]);
          totalImportance += parsed?.importance || 0;
        });
        return { ...q, totalImportance };
      });
      questionsWithImportance.sort((a, b) => b.totalImportance - a.totalImportance);
      finalQuestions = questionsWithImportance;
    }

    const filterState = this.buildFilterState();
    if (this.props.onFilter) {
      this.props.onFilter(finalQuestions, filterState);
    }
    this.computeFilteredQuestionsCount();
  }

  buildFilterState() {
    return {
      selectedTypes: this.state.selectedTypes,
      sortByImportance: this.state.sortByImportance,
      showTopQuestions: this.state.showTopQuestions,
      topQuestionsCount: this.state.topQuestionsCount,
      aiSearchQuery: this.state.aiSearchQuery,
      sbtFilterLocalState: this.state.sbtFilterLocalState,
      selectedTags: this.state.selectedTags,
      showTopQuestionsByResponses: this.state.showTopQuestionsByResponses
    };
  }

  toggleSection = (section) => {
    // AI filter is forcibly closed & disabled, so ignore toggles for 'ai'
    if (section === 'ai') {
      return;
    }
    this.setState((prevState) => ({
      expandedSections: {
        ...prevState.expandedSections,
        [section]: !prevState.expandedSections[section]
      }
    }));
  };

  computeFilteredQuestionsCount() {
    const { mergedQuestions } = this.state;
    if (!mergedQuestions) {
      if (this.state.filteredQuestionsCount !== 0) {
        this.setState({ filteredQuestionsCount: 0 });
      }
      return;
    }

    const {
      pendingSelectedTypes,
      pendingSbtFilteredQuestions,
      pendingShowTopQuestions,
      pendingTopQuestionsCount,
      pendingShowTopQuestionsByResponses,
      aiSearchQuery,
      selectedTags
    } = this.state;

    if (pendingShowTopQuestions || pendingShowTopQuestionsByResponses) {
      const newCount = Math.min(mergedQuestions.length, pendingTopQuestionsCount);
      if (newCount !== this.state.filteredQuestionsCount) {
        this.setState({ filteredQuestionsCount: newCount });
      }
      return;
    }

    let finalList = [...mergedQuestions];
    if (pendingSbtFilteredQuestions !== null) {
      const sbtIds = pendingSbtFilteredQuestions.map((q) => q.id);
      finalList = finalList.filter((q) => sbtIds.includes(q.id));
    }
    if (pendingSelectedTypes.length > 0) {
      finalList = finalList.filter((q) => pendingSelectedTypes.includes(q.type));
    }
    if (selectedTags.length > 0) {
      finalList = finalList.filter((q) => {
        if (!q.tags || !Array.isArray(q.tags)) return false;
        return q.tags.some((tag) => selectedTags.includes(tag));
      });
    }
    if (aiSearchQuery.trim()) {
      const queryLower = aiSearchQuery.trim().toLowerCase();
      finalList = finalList.filter((q) =>
        q.prompt ? q.prompt.toLowerCase().includes(queryLower) : false
      );
    }

    const newLength = finalList.length;
    if (newLength !== this.state.filteredQuestionsCount) {
      this.setState({ filteredQuestionsCount: newLength });
    }
  }

  handleTypeSelection = (type) => {
    let newSelectedTypes = [...this.state.pendingSelectedTypes];
    if (newSelectedTypes.includes(type)) {
      newSelectedTypes = newSelectedTypes.filter((t) => t !== type);
    } else {
      newSelectedTypes.push(type);
    }
    this.setState({ pendingSelectedTypes: newSelectedTypes }, () => {
      this.handleApplyFilters(true);
    });
  };

  handleTagSelection = (tag) => {
    let updatedTags = [...this.state.selectedTags];
    if (updatedTags.includes(tag)) {
      updatedTags = updatedTags.filter((t) => t !== tag);
    } else {
      updatedTags.push(tag);
    }
    this.setState({ selectedTags: updatedTags }, () => {
      this.handleApplyFilters(true);
    });
  };

  handleSortByImportance = () => {
    this.setState(
      (prevState) => ({ pendingSortByImportance: !prevState.pendingSortByImportance }),
      () => {
        this.handleApplyFilters(true);
      }
    );
  };

  handleCancelFilters = () => {
    // Revert pending changes
    this.setState(
      {
        pendingSelectedTypes: this.state.selectedTypes,
        pendingSortByImportance: this.state.sortByImportance,
        pendingSbtFilteredQuestions: this.state.sbtFilteredQuestions,
        pendingShowTopQuestions: this.state.showTopQuestions,
        pendingTopQuestionsCount: this.state.topQuestionsCount,
        pendingShowTopQuestionsByResponses: this.state.showTopQuestionsByResponses,
        aiSearchQuery: this.state.aiSearchQuery,
        sbtFilterLocalState: null,
        selectedTags: this.state.selectedTags
      },
      () => {
        if (!this.props.resultsMode) {
          this.props.toggleFilterModal();
        }
      }
    );
  };

  toggleShowTopQuestions = (byResponses = false) => {
    if (byResponses) {
      this.setState(
        (prev) => ({
          pendingShowTopQuestionsByResponses: !prev.pendingShowTopQuestionsByResponses,
          pendingShowTopQuestions: false
        }),
        () => {
          this.handleApplyFilters(true);
        }
      );
    } else {
      this.setState(
        (prev) => ({
          pendingShowTopQuestions: !prev.pendingShowTopQuestions,
          pendingShowTopQuestionsByResponses: false
        }),
        () => {
          this.handleApplyFilters(true);
        }
      );
    }
  };

  // ----------------------------------------------------------------------------------
  // TAGS + FILTER SUMMARY
  // ----------------------------------------------------------------------------------
  getAllTagsFromQuestions() {
    const { mergedQuestions } = this.state;
    if (!mergedQuestions || !Array.isArray(mergedQuestions)) return [];
    const tagSet = new Set();
    mergedQuestions.forEach((q) => {
      if (q.tags && Array.isArray(q.tags)) {
        q.tags.forEach((tag) => tagSet.add(tag));
      }
    });
    return Array.from(tagSet);
  }

  removeTypeFilter = (type) => {
    const newPending = this.state.pendingSelectedTypes.filter((t) => t !== type);
    this.setState(
      {
        pendingSelectedTypes: newPending
      },
      () => {
        this.handleApplyFilters(true);
      }
    );
  };

  removeTagFilter = (tag) => {
    const newTags = this.state.selectedTags.filter((t) => t !== tag);
    this.setState(
      {
        selectedTags: newTags
      },
      () => {
        this.handleApplyFilters(true);
      }
    );
  };

  removeTopQuestionsFilter = () => {
    this.setState(
      {
        pendingShowTopQuestions: false,
        pendingShowTopQuestionsByResponses: false
      },
      () => {
        this.handleApplyFilters(true);
      }
    );
  };

  removeSBTFilterItem = (item) => {
    const { role, sbtAddress } = item;
    const localState = this.state.sbtFilterLocalState || {};
    let updatedState = { ...localState };

    if (role === 'creatorInclude' && updatedState.selectedSBTGroupsCreator) {
      updatedState.selectedSBTGroupsCreator = updatedState.selectedSBTGroupsCreator.filter(
        (x) => x.address.toLowerCase() !== sbtAddress.toLowerCase()
      );
    } else if (role === 'creatorExclude' && updatedState.excludedSBTGroupsCreator) {
      updatedState.excludedSBTGroupsCreator = updatedState.excludedSBTGroupsCreator.filter(
        (x) => x.address.toLowerCase() !== sbtAddress.toLowerCase()
      );
    } else if (role === 'responderInclude' && updatedState.selectedSBTGroupsResponder) {
      updatedState.selectedSBTGroupsResponder = updatedState.selectedSBTGroupsResponder.filter(
        (x) => x.address.toLowerCase() !== sbtAddress.toLowerCase()
      );
    } else if (role === 'responderExclude' && updatedState.excludedSBTGroupsResponder) {
      updatedState.excludedSBTGroupsResponder = updatedState.excludedSBTGroupsResponder.filter(
        (x) => x.address.toLowerCase() !== sbtAddress.toLowerCase()
      );
    } else if (role === 'include' && updatedState.selectedSBTGroups) {
      updatedState.selectedSBTGroups = updatedState.selectedSBTGroups.filter(
        (x) => x.address.toLowerCase() !== sbtAddress.toLowerCase()
      );
    } else if (role === 'exclude' && updatedState.excludedSBTGroups) {
      updatedState.excludedSBTGroups = updatedState.excludedSBTGroups.filter(
        (x) => x.address.toLowerCase() !== sbtAddress.toLowerCase()
      );
    }

    this.setState({ sbtFilterLocalState: updatedState }, () => {
      this.handleFilteredQuestions(this.state.mergedQuestions, updatedState);
    });
  };

  getFilterSummaryItems() {
    const items = [];

    // 1) Show "Top X questions" if active
    if (this.state.pendingShowTopQuestions) {
      items.push({
        type: 'special',
        label: `Top ${this.state.pendingTopQuestionsCount} by importance`,
        onRemove: () => this.removeTopQuestionsFilter()
      });
    }
    if (this.state.pendingShowTopQuestionsByResponses) {
      items.push({
        type: 'special',
        label: `Top ${this.state.pendingTopQuestionsCount} by # responses`,
        onRemove: () => this.removeTopQuestionsFilter()
      });
    }

    // 2) Show question types
    (this.state.pendingSelectedTypes || []).forEach((t) => {
      items.push({
        type: 'questionType',
        label: `${t}`,
        onRemove: () => this.removeTypeFilter(t)
      });
    });

    // 3) Show tags
    (this.state.selectedTags || []).forEach((tag) => {
      items.push({
        type: 'tag',
        label: `#${tag}`,
        onRemove: () => this.removeTagFilter(tag)
      });
    });

    // 4) SBT filter items
    const st = this.state.sbtFilterLocalState || {};
    // creator includes
    if (Array.isArray(st.selectedSBTGroupsCreator)) {
      st.selectedSBTGroupsCreator.forEach((obj) => {
        items.push({
          type: 'sbt',
          label: `Creator+ ${obj.name || obj.address}`,
          onRemove: () =>
            this.removeSBTFilterItem({
              role: 'creatorInclude',
              sbtAddress: obj.address
            })
        });
      });
    }
    // creator excludes
    if (Array.isArray(st.excludedSBTGroupsCreator)) {
      st.excludedSBTGroupsCreator.forEach((obj) => {
        items.push({
          type: 'sbt',
          label: `Creator- ${obj.name || obj.address}`,
          onRemove: () =>
            this.removeSBTFilterItem({
              role: 'creatorExclude',
              sbtAddress: obj.address
            })
        });
      });
    }
    // responder includes
    if (Array.isArray(st.selectedSBTGroupsResponder)) {
      st.selectedSBTGroupsResponder.forEach((obj) => {
        items.push({
          type: 'sbt',
          label: `Responder+ ${obj.name || obj.address}`,
          onRemove: () =>
            this.removeSBTFilterItem({
              role: 'responderInclude',
              sbtAddress: obj.address
            })
        });
      });
    }
    // responder excludes
    if (Array.isArray(st.excludedSBTGroupsResponder)) {
      st.excludedSBTGroupsResponder.forEach((obj) => {
        items.push({
          type: 'sbt',
          label: `Responder- ${obj.name || obj.address}`,
          onRemove: () =>
            this.removeSBTFilterItem({
              role: 'responderExclude',
              sbtAddress: obj.address
            })
        });
      });
    }
    // addresses mode includes
    if (Array.isArray(st.selectedSBTGroups)) {
      st.selectedSBTGroups.forEach((obj) => {
        items.push({
          type: 'sbt',
          label: `Include: ${obj.name || obj.address}`,
          onRemove: () =>
            this.removeSBTFilterItem({
              role: 'include',
              sbtAddress: obj.address
            })
        });
      });
    }
    // addresses mode excludes
    if (Array.isArray(st.excludedSBTGroups)) {
      st.excludedSBTGroups.forEach((obj) => {
        items.push({
          type: 'sbt',
          label: `Exclude: ${obj.name || obj.address}`,
          onRemove: () =>
            this.removeSBTFilterItem({
              role: 'exclude',
              sbtAddress: obj.address
            })
        });
      });
    }

    return items;
  }

  // ----------------------------------------------------------------------------------
  // RENDER
  // ----------------------------------------------------------------------------------
  renderCollapsibleSection(title, sectionKey, icon, content, disabled = false) {
    const { expandedSections } = this.state;
    const isOpen = expandedSections[sectionKey];
    const clickable = !disabled;

    return (
      <div className={styles.filterSection}>
        <div
          className={styles.sectionHeader}
          onClick={() => {
            if (clickable) {
              this.toggleSection(sectionKey);
            }
          }}
          style={{ cursor: clickable ? 'pointer' : 'not-allowed', opacity: disabled ? 0.5 : 1 }}
        >
          <h3>
            <FontAwesomeIcon icon={icon} className="me-2" />
            {title}
          </h3>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`${styles.icon} ${isOpen ? styles.expanded : ''}`}
          />
        </div>
        {isOpen && !disabled && <div className={styles.sectionContent}>{content}</div>}
      </div>
    );
  }

  render() {
    // *** If resultsMode is true, only render inline if the parent says filter is open ***
    if (this.props.resultsMode) {
      if (!this.props.filterModalOpen) {
        return null; // do not show the filter at all
      }
    }

    const isInline = this.props.resultsMode;
    const {
      expandedSections,
      pendingSelectedTypes,
      aiSearchQuery,
      aiRankingCount,
      filterLoading,
      sbtFilterLocalState,
      pendingShowTopQuestions,
      pendingShowTopQuestionsByResponses,
      pendingTopQuestionsCount,
      selectedTags,
      filteredQuestionsCount
    } = this.state;

    const isOtherFiltersDisabled =
      this.state.pendingShowTopQuestions || this.state.pendingShowTopQuestionsByResponses;

    // Gather summary items
    const summaryItems = this.getFilterSummaryItems();

    // Prepare tags listing
    const allTags = this.getAllTagsFromQuestions();

    // Main body content
    const bodyContent = (
      <div>

        {/* MOST POPULAR (Top X) */}
        {this.renderCollapsibleSection(
          'Most Popular',
          'popular',
          faStar,
          <div>
            <FormGroup>
              <Label className={styles.filterOption}>
                <Input
                  type="checkbox"
                  checked={pendingShowTopQuestions}
                  onChange={() => this.toggleShowTopQuestions(false)}
                  disabled={false}
                />
                Show top
                <Input
                  type="number"
                  min="1"
                  value={pendingTopQuestionsCount}
                  onChange={(e) => {
                    const val = e.target.value ? parseInt(e.target.value, 10) : 1;
                    this.setState({ pendingTopQuestionsCount: val }, () => {
                      if (pendingShowTopQuestions || pendingShowTopQuestionsByResponses) {
                        this.handleApplyFilters(true);
                      }
                    });
                  }}
                  disabled={!pendingShowTopQuestions && !pendingShowTopQuestionsByResponses}
                  id={styles.topQuestionsCountInput}
                />
                questions (by total importance)
              </Label>
            </FormGroup>

            <FormGroup>
              <Label className={styles.filterOption}>
                <Input
                  type="checkbox"
                  checked={pendingShowTopQuestionsByResponses}
                  onChange={() => this.toggleShowTopQuestions(true)}
                />
                Show top
                <Input
                  type="number"
                  min="1"
                  value={pendingTopQuestionsCount}
                  onChange={(e) => {
                    const val = e.target.value ? parseInt(e.target.value, 10) : 1;
                    this.setState({ pendingTopQuestionsCount: val }, () => {
                      if (pendingShowTopQuestions || pendingShowTopQuestionsByResponses) {
                        this.handleApplyFilters(true);
                      }
                    });
                  }}
                  disabled={!pendingShowTopQuestions && !pendingShowTopQuestionsByResponses}
                  id={styles.topQuestionsCountInput}
                />
                questions (by # of responses)
              </Label>
            </FormGroup>
            {(pendingShowTopQuestions || pendingShowTopQuestionsByResponses) && (
              <small className="text-muted">
                This overrides other filters (type, tag, etc.)
              </small>
            )}
          </div>
        )}

        <div className={isOtherFiltersDisabled ? styles.disabledSection : ''}>

          {/* TAGS */}
          {this.renderCollapsibleSection(
            'Tags',
            'tags',
            faFilter,
            isOtherFiltersDisabled ? (
              <p className={styles.disabledText}>
                Disabled by “Top X questions” selection.
              </p>
            ) : (
              (() => {
                const tagsList = this.getAllTagsFromQuestions();
                if (!tagsList.length) {
                  return <p>No tags found in current questions.</p>;
                }
                return (
                  <div className={styles.tagsContainer}>
                    {tagsList.map((tag) => {
                      const isSelected = selectedTags.includes(tag);
                      return (
                        <div
                          key={tag}
                          className={`${styles.tagBubble} ${
                            isSelected ? styles.tagBubbleSelected : ''
                          }`}
                          onClick={() => this.handleTagSelection(tag)}
                        >
                          #{tag}
                        </div>
                      );
                    })}
                  </div>
                );
              })()
            ),
            false
          )}

          {/* QUESTION TYPES */}
          {this.renderCollapsibleSection(
            'Question Types',
            'types',
            faFilter,
            <FormGroup>
              {questionTypes.map((type, idx) => (
                <Label key={idx} className={styles.filterOption}>
                  <Input
                    type="checkbox"
                    value={type}
                    checked={pendingSelectedTypes.includes(type)}
                    onChange={() => this.handleTypeSelection(type)}
                    disabled={isOtherFiltersDisabled}
                  />
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </Label>
              ))}
            </FormGroup>,
            false
          )}

          {/* SBT GROUPS */}
          {this.renderCollapsibleSection(
            this.props.creatorAndResponderMode
              ? 'Group(s) of Question Creator / Responder'
              : 'Group(s) of Question Creator',
            'sbts',
            faStar,
            isOtherFiltersDisabled ? (
              <p className={styles.disabledText}>
                Disabled by “Top X questions” selection.
              </p>
            ) : (
              <SBTFilter
                items={this.state.mergedQuestions}
                provider={this.props.provider}
                network={this.props.network}
                mode={this.props.creatorAndResponderMode ? 'creatorAndResponder' : 'creator'}
                onFilter={this.handleFilteredQuestions}
                setFilterLoading={this.setFilterLoading}
                autoExpand={true}
                externalSBTFilterState={sbtFilterLocalState}
              />
            ),
            false
          )}

          {/* AI FILTER (TOP, default-closed, disabled) */}
          {this.renderCollapsibleSection(
            'AI Filter (coming soon)',
            'ai',
            faRobot,
            <FormGroup>
              <AudioInput
                hideEncryption={true}
                placeholder="(disabled)"
                value={aiSearchQuery}
                updateFunction={() => {}}
                disabled={true}
              />
              <div style={{ marginTop: '10px' }}>
                <Label style={{ marginBottom: '5px', fontSize: '0.95rem' }}>
                  Number of questions to return:
                </Label>
                <Input
                  type="number"
                  min="1"
                  value={aiRankingCount}
                  onChange={() => {}}
                  style={{
                    width: '120px',
                    marginBottom: '10px',
                    fontSize: '1.25rem',
                    color: 'grey'
                  }}
                  disabled={true}
                />
                <Button color="info" disabled={true} onClick={this.handleApplyAIFilter}>
                  Apply AI Filter
                </Button>
                <p style={{ marginTop: '5px', fontStyle: 'italic', color: '#888' }}>
                  This feature is disabled for now.
                </p>
              </div>
            </FormGroup>,
            true // forcibly disabled
          )}

        </div>

        {filterLoading && (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <p>Applying filter...</p>
          </div>
        )}
      </div>
    );

    if (isInline) {
      // "resultsMode" => render inline
      return (
        <div className={styles.questionFilterInline}>
          {/* Filter summary at the top */}
          {summaryItems.length > 0 && (
            <div className={styles.filterSummaryContainer}>
              <div className={styles.filterSummaryLabel}>Selected Filters:</div>
              <div className={styles.summaryItemsRow}>
                {summaryItems.map((item, idx) => (
                  <div key={idx} className={styles.filterBubble} onClick={item.onRemove}>
                    <span>{item.label}</span>
                    <FontAwesomeIcon icon={faTimes} className={styles.removeIcon} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className={styles.filterSummaryCount}>
            {filteredQuestionsCount} question(s) match current filters
          </div>

          {bodyContent}
        </div>
      );
    } else {
      // Normal "non-resultsMode," show a Modal
      return (
        <Modal
          isOpen={this.props.filterModalOpen}
          toggle={this.handleCancelFilters}
          style={modalStyles}
        >
          <ModalHeader toggle={this.handleCancelFilters} className={styles.modalHeader}>
            Filter Questions ({filteredQuestionsCount})
          </ModalHeader>
          <ModalBody className={styles.modalBody}>
            {summaryItems.length > 0 && (
              <div className={styles.filterSummaryContainer}>
                <div className={styles.filterSummaryLabel}>Selected Filters:</div>
                <div className={styles.summaryItemsRow}>
                  {summaryItems.map((item, idx) => (
                    <div key={idx} className={styles.filterBubble} onClick={item.onRemove}>
                      <span>{item.label}</span>
                      <FontAwesomeIcon icon={faTimes} className={styles.removeIcon} />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {bodyContent}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.props.toggleFilterModal}>
              See Questions
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
}

export default QuestionFilter;
